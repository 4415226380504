import { useContext, createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../firebase';
import Swal from "sweetalert2";
import { getDataOneUser, addDataUsers } from '../services/auth/users';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const authorizedEmails = [
    "anthonymmarinbolivar@gmail.com",
    "joelmarbella97@gmail.com",
    "gomez.joel.0709@gmail.com",
    // "bradley.crompton@hotmail.com",
    // "blarsen@toughcountry.net",
    // "bryantcail3@gmail.com",
    // "connieberanek@gmail.com",
    // "yaceyko.e@gmail.com",
    // "rnrcontracting@telus.net",
    // "michaelcpsross@gmail.com",
    // "myrtlejanice@gmail.com",
    // "thebottomline80@hotmail.com",
    // "wendym30004@gmail.com",
    // pmoraga.firebase@gmail.com,
  ];

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider(); 

    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("Successful login");
        //console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });

  };

  const logOut = () => {
    signOut(auth);
    //Aqui falta un redireccionar
  }

  useEffect(() => {

    /// NO TIENEN ACCESO LOS SIGUIENTES EMAIL:
    /* 
    1- Claytondoll@yahoo.ca
    2- frankfeil@outlook.com
    3- jennifergleeson76@icloud.com
    4- rnrcontracting@telus.net --> POSIBLE NO FUNCIONE, PERO FUE AGREGADA
    5- jsinclair453@hotmail.com
    6- mighter@shaw.ca
    7- thebottomline80@hotmail.com  --> POSIBLE NO FUNCIONE, PERO FUE AGREGADA
    
    */
    if(user && user.email) {
      const userEmailDomain = user.email.split('@')[1];

      //if (user && !authorizedEmails.includes(user.email)) {
        if (!authorizedEmails.includes(user.email) && userEmailDomain !== "t49.ca") {

        signOut(auth);
        Swal.fire({
          title: 'Access denied',
          text: 'Sorry, the email you have entered is not authorized.',
          icon: 'error',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonColor: '#173d38',
          confirmButtonText: 'CONTINUE'
        }).then(() => {
          window.location.href = '/signin'; // Esto debería cambiar a tu método de navegación preferido
        });
    }
    }
  }, [user]);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     setUser(currentUser);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        const userDocSnap = await getDataOneUser(currentUser.uid);

        if(userDocSnap?.length === 0){
          addDataUsers(currentUser);
        }

      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);
  

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};