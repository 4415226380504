import React, { useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import '../style/accounting.css'

const idRole = 'accounting';

const Accounting = () => {
    
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (loading) {
          return;
        }
        if (!user) navigate("/signin");
      }, [user, loading]);


    return (
        <div className="dialog">
            <h1>This is Accounting page</h1>
            <h4>Coming soon</h4>
        </div>
      )
    }
    
export default Accounting