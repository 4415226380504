import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsLeadSourcesConfig } from '../../../services/columnsList';
import Button from '../../button';
import ModalC from '../../modal';
import { UserAuth } from '../../../context/AuthContext';
import { Input } from 'antd';
import '../../../style/table.css'
import ExportPDF from "../../../services/exports/exportPDF";
import ExportCSV from "../../../services/exports/exportCSV";
import { transformedData } from '../../../services/exports/transformedData';
import FormLeadSourceReport from './formConfig';

function TableConfig() {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);

  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsDate, setFilteredItemsDate] = useState([]);
  const [modalTransportReport, setModalTransportReport] = useState(false); 
  const [titleModalTransportReport, setTitleModalTrasportReport] = useState('');
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    if (data != null) {
      changeFilter("");
    }
    setPending(false);
  }, [data]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const changeFilter = (e) => { 

    let newFilter = [];

    if(data?.length > 0){
      newFilter = data?.filter(
      item => item?.clientName && item?.clientName.toLowerCase().includes(e.toLowerCase()) 
      || item?.shuttle?.label && item?.shuttle?.label.toLowerCase().includes(e.toLowerCase())
      || item?.leadSource?.label && item?.leadSource?.label.toLowerCase().includes(e.toLowerCase())
      || item?.taxes?.label && item?.taxes?.label.toLowerCase().includes(e.toLowerCase())
      || item?.id && item?.id.toLowerCase().includes(e.toLowerCase())
    );

    setFilteredItemsDate([]);
    setFilteredItems(newFilter);
  }
  setFilterText(e);

  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => changeFilter(e.target.value)} filtertext={filterText} />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const actionTransportReport = (dat) => {

    if(dat){
      setFilteredItems(dat);
      setData(dat);
      setPending(false);
    }
    setModalTransportReport(false);
  }

  return (
    <div className="content">
      <div className='row'>
      <div className="col-md-6"></div> {/* Columna vacía */}
      <div className="col-md-6 mr-auto">
            <div className="gap-2 m-auto d-flex justify-content-end" >
                <Button className="color-green btn btn-outline-secondary p-2" onClick={() => {
                  setModalTransportReport(true);
                  setTitleModalTrasportReport("Lead source report");
                  setEdit(null);
                }} >Generate a report</Button>
                {filteredItems?.length > 0 ? (
                <>
                {/* <ExportPDF data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsLeadSourcesConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsLeadSourcesConfig(), true)} totals={[amount, surcharge, taxAmount, total, tip]} agents={false} transport={true} filename={"Daily transport report"}></ExportPDF> */}
                {/* <ExportCSV data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsLeadSourcesConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsLeadSourcesConfig(), true)} totals={[amount, surcharge, taxAmount, total, tip]} agents={false} transport={true} filename={"Daily transport report"}></ExportCSV> */}
                </>
                ) : ("")}
                </div>
          </div>
      </div>
      <div className="m-3">
        <>
        <DataTable
          columns={columnsLeadSourcesConfig()}
          data={filteredItems}
          selectableRows
          onSelectedRowsChange={handleChange}
          pagination
          clearSelectedRows={toggledClearRows}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          progressPending={pending}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
        </>
      </div>
      <ModalC
          show={modalTransportReport}
          onHide={() => setModalTransportReport(false)}>
          <FormLeadSourceReport title={titleModalTransportReport} action={actionTransportReport} textButton={titleModalTransportReport} setModal={setModalTransportReport}></FormLeadSourceReport>
        </ModalC>
    </div>
  );
}

export default TableConfig;
