import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'

function FormConfig({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [representative_name, setRepresentativeName] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [commission, setCommission] = useState("");

    useEffect(() => {
        if (lastData) {
            setId(lastData?.id);
            setRepresentativeName(lastData?.representative_name);
            setName(lastData?.name);
            setPhone(lastData?.phone);
            setEmail(lastData?.email);
            setCommission(lastData?.commission);
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formRepresentativeName">
                        <div className="d-inline-flex">
                            <Form.Label>Representative's name</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the representative's name"
                            value={representative_name}
                            onChange={(e) => setRepresentativeName(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formName">
                        <div className="d-inline-flex">
                            <Form.Label>Agent name</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the agent name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhone">
                        <div className="d-inline-flex">
                            <Form.Label>Phone</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="number" placeholder="Enter the agent phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    

                    

                </div>

                <div className='col-md-6'>
                    
                    <Form.Group className="mb-3" controlId="formEmail">
                        <div className="d-inline-flex">
                            <Form.Label>Email</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter agent email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formCommission">
                        <div className="d-inline-flex">
                            <Form.Label>Commission in %</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="number" placeholder="Enter agent commission"
                            value={commission}
                            onChange={(e) => setCommission(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>
                </div>
                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {

                    var data = {
                        id,
                        representative_name,
                        name,
                        phone,
                        email,
                        commission,
                    };

                    if (name !== '' && phone !== '' && email !== '' && representative_name !== '' && commission !== '') {
                        action(data);
                    } else {
                        Swal.fire({ 
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "All fields with (*) are required",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                          }); 
                    }
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;