import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import { Card, CardGroup, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import {  getDataSales, addDataSales, editDataSale, deleteDataSale } from '../../../services/pointOfSale/sale';
import {  editCart, setCart } from '../../../services/pointOfSale/carts';
import {  paymentTypes  } from '../../../services/Tools';
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";

const idRole = 'payment';

const Payment = ({data = [], changeData}) => {

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [paymentBalance, setPaymentBalance] = useState([]);

    const [amount, setAmount] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [expirationMonth, setExpirationMonth] = useState("");
    const [expirationYear, setExpirationYear] = useState("");
    const [cvv, setCvv] = useState("");
    const [cardName, setCardName] = useState("");
    const [playerName, setPlayerName] = useState("");
    const [idSale, setIdSale] = useState("");

    useEffect(() => {
        if (loading) {
        // maybe trigger a loading screen
        return;
        }
        if (!user) navigate("/signin");
    }, [user, loading]);

    useEffect(() => {
        if (data) {

            setAmount(data?.balance);
            setPaymentType({ value: data.value, label: data.label });

            let newPaymentBalance = [];
            for (let i = 0; data?.payment_balance_matriz?.length > i; i++) {
                newPaymentBalance[i] = data?.payment_balance_matriz[i];
            }

            setPaymentBalance(newPaymentBalance);
        }
    }, [data])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      } 
    }

    const makePayment = () => {

        if (paymentType) {
            //editCart(data);
            data['items'] = data?.items;
            data['amount'] = amount;
            data['cardNumber'] = cardNumber;
            data['expirationMonth'] = expirationMonth;
            data['expirationYear'] = expirationYear;
            data['cvv'] = cvv;
            data['cardName'] = cardName;
            data['playerName'] = playerName;   
            
            if(data?.paid){
                data['paid'] = parseFloat(amount) + parseFloat(data?.paid);
            }else{
                data['paid'] = parseFloat(amount);
            }

            if(parseFloat(amount) > parseFloat(data?.balance)){
                Swal.fire({
                    title: 'Error!',
                    text: 'The amount is greater than the amount owed', //This is the text
                    icon: 'error',
                    confirmButtonColor: '#173d38',
                  })
                  setAmount(data?.balance);

            }else{

                if(!data?.idCart){
                    data['idCart'] = data?.id;
                }

                if(paymentType.value !== "Credit"){
                    if(amount !== '' && playerName !== ''){
                        data['balance'] = (parseFloat(data?.balance) - parseFloat(amount));

                            setAmount(data?.balance);

                            var arrayPaymentBalance = [];
                            let payment_balance_matriz = paymentBalance;
                            let today = new Date();
                            let now = today.toLocaleString();
                            let newPaymentBalance = {
                                paymentType: paymentType,
                                playerName: playerName,
                                amount: amount,
                                balance: data?.balance,
                                updated: now,
                                changeBy: auth?.currentUser?.displayName,
                            }

                            paymentBalance.forEach(element => {
                                arrayPaymentBalance.push(element);
                            });
                            arrayPaymentBalance.push(newPaymentBalance);
                            payment_balance_matriz = arrayPaymentBalance;
                            setPaymentBalance(arrayPaymentBalance);

                            data['paymentBalance'] = arrayPaymentBalance;

                            let cart = {
                                items: JSON.stringify(data?.items),
                                user: JSON.stringify(user),
                                subTotal: JSON.stringify(parseFloat(data?.subTotal)),
                                total: JSON.stringify(parseFloat(data?.total)),
                                balance: JSON.stringify(parseFloat(data?.balance)),
                                amount: JSON.stringify(parseFloat(amount)),
                                playerName: JSON.stringify(playerName),
                                paid: JSON.stringify(data?.paid),
                                paymentBalance: JSON.stringify(data?.paymentBalance),
                              }

                        if(data?.balance === 0){
                            addDataSales(data, data?.id, true);
                            setCart(cart, data?.idCart);
                            
                            navigate("/pointofsale");
                        }else{
                            addDataSales(data, data?.id, false);
                            setCart(cart, data.idCart);

                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "The payment was successful, the balance due is "+data?.balance,
                                confirmButtonColor: '#173d38',
                                confirmButtonText: "Ok",
                            });
                        }
                        changeData(data);

                    }else{
                        Swal.fire({
                            title: 'Error!',
                            text: 'All fields with (*) are required', //This is the text
                            icon: 'error',
                            confirmButtonColor: '#173d38',
                        })
                    }
                            
                }else{
                    if(amount !== '' && cardNumber !== '' && expirationMonth !== '' && expirationYear !== '' && cvv !== '' && cardName !== ''){
                        data['balance'] = (parseFloat(data?.balance) - parseFloat(amount)) ;
                        setAmount(data?.balance);

                        var arrayPaymentBalance = [];
                        let payment_balance_matriz = paymentBalance;
                        let today = new Date();
                        let now = today.toLocaleString();
                        let newPaymentBalance = {
                            paymentType: paymentType,
                            playerName: playerName,
                            amount: amount,
                            balance: data?.balance,
                            updated: now,
                            changeBy: auth?.currentUser?.displayName,
                        }
                        
                        paymentBalance.forEach(element => {
                            arrayPaymentBalance.push(element);
                        });
                        arrayPaymentBalance.push(newPaymentBalance);
                        payment_balance_matriz = arrayPaymentBalance;
                        setPaymentBalance(arrayPaymentBalance);

                        let cart = {
                            items: JSON.stringify(data?.items),
                            user: JSON.stringify(user),
                            subTotal: JSON.stringify(parseFloat(data?.subTotal)),
                            total: JSON.stringify(parseFloat(data?.total)),
                            balance: JSON.stringify(parseFloat(data?.balance)),
                            amount: JSON.stringify(parseFloat(amount)),
                            playerName: JSON.stringify(playerName),
                            paid: JSON.stringify(parseFloat(data?.paid)),
                            cardNumber: JSON.stringify(data?.cardNumber),
                            expirationMonth: JSON.stringify(data?.expirationMonth),
                            expirationYear: JSON.stringify(data?.expirationYear),
                            cvv: JSON.stringify(data?.cvv),
                            cardName: JSON.stringify(data?.cardName),
                        }

                        if(data?.balance === 0){
                            addDataSales(data, data?.id, true);
                            setCart(cart, data?.idCart);
                            
                            navigate("/pointofsale");
                        }else{
                            addDataSales(data, data?.id, false);
                            setCart(cart, data.idCart);

                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "The payment was successful, the balance due is "+data?.balance,
                                confirmButtonColor: '#173d38',
                                confirmButtonText: "Ok",
                            });
                        }
                        changeData(data);

                    }else{
                        Swal.fire({
                            title: 'Error!',
                            text: 'All fields with (*) are required', //This is the text
                            icon: 'error',
                            confirmButtonColor: '#173d38',
                        })
                    }
                }
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'The payment type is required, choose one', //This is the text
                icon: 'error',
                confirmButtonColor: '#173d38',
              })
        }
    }

    return (
        <div style={{ textAlign: 'initial', fontSize:15 }}>
            <h5 style={{ textAlign: 'initial' }}>PAYMENT</h5>
          <hr></hr>
            <Form.Group className="mb-3">
                <div className="d-inline-flex">
                    <Form.Label>Payment Type</Form.Label>
                    <h5 className="required">*</h5>
                </div>
                <Select placeholder={paymentTypes[0].value} options={paymentTypes} value={paymentType[0]}
                    onChange={(e) => setPaymentType(e)} />
            </Form.Group>  
          <hr></hr>
          <Form.Group style={{ padding: 5 }}>
            <div className="d-block" style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <div className="d-inline-flex">
                    <Form.Label>Amount </Form.Label>
                    <h5 className="required">*</h5>
                </div>
              </div>

              <div className="d-inline-flex" style={{ width: "100%" }}>
                <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>$</strong></Form.Label>
                <Form.Control type="number" placeholder="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    onKeyDown={(e) => keypress(e)}
                />
              </div>
            </div>
          </Form.Group>

            {paymentType?.value === "Credit" || paymentType?.value == null ? (
            <>
                <Form.Group style={{ padding: 5 }}>
                    <div className="d-block" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                        <div className="d-inline-flex">
                            <Form.Label>Card Number </Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                    </div>

                    <div className="d-inline-flex" style={{ width: "100%" }}>
                        <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>Visa</strong></Form.Label>
                        <Form.Control type="text" placeholder="Card Number"
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </div>
                    </div>
                </Form.Group>

                <Form.Group style={{ padding: 5 }}>
                    <div className="d-inline-flex" style={{ width: "100%" }}>
                        <div className="d-block">
                            <div style={{ width: "90%" }}>
                                <div className="d-inline-flex">
                                    <Form.Label>Expiration Month</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
                            </div>
                            <div className="d-inline-flex" style={{ width: "100%" }}>
                                <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>MM</strong></Form.Label>
                                <Form.Control type="number" placeholder="MM" maxLength={2} minLength={2}
                                    value={expirationMonth}
                                    onChange={(e) => setExpirationMonth(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </div>
                        </div>

                        <div className="d-block">
                            <div style={{ width: "100%" }}>
                                <div className="d-inline-flex">
                                    <Form.Label>Expiration Year</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
                            </div>
                            <div className="d-inline-flex" style={{ width: "100%" }}>
                                <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>YYYY</strong></Form.Label>
                                <Form.Control type="text" placeholder="YYYY" maxLength={4} minLength={4}
                                    value={expirationYear}
                                    onChange={(e) => setExpirationYear(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </div>
                        </div>

                        <div className="d-block">
                            <div style={{ width: "100%" }}>
                                <div className="d-inline-flex">
                                    <Form.Label>CVV</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
                            </div>
                            <div className="d-inline-flex" style={{ width: "100%" }}>
                                <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>CVV</strong></Form.Label>
                                <Form.Control type="number" placeholder="CVV" maxLength={5}
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </div>
                        </div>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3">
                        <div className="d-inline-flex">
                            <Form.Label>Name On Card</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Form.Control type="text" placeholder="Name"
                            value={cardName}
                            onChange={(e) => setCardName(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                </Form.Group>
            </>
            ) : (
                <>
                <Form.Group className="mb-3">
                    <div className="d-inline-flex">
                        <Form.Label>Player Name</Form.Label>
                        <h5 className="required">*</h5>
                    </div>
                    <Form.Control type="text" placeholder="Name"
                        value={playerName}
                        onChange={(e) => setPlayerName(e.target.value)}
                        onKeyDown={(e) => keypress(e)}
                    />
                </Form.Group>
                </>
            )}

          <Form.Group style={{ padding: 5 }}>
            <Button style={{ width: "-webkit-fill-available", background: '#173d38' }}  onClick={() => { makePayment() }} className="card-text">Make Payment</Button>
          </Form.Group>
        </div>
      )
}

export default Payment;