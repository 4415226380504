import Button from "../components/button";
import { FiEdit2 } from "react-icons/fi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
// import { AiFillEye } from "react-icons/ai";
// import { CheckBoxInput } from "react-bootstrap-form/lib/inputs";
// import { Checkbox } from "antd";
// import Form from 'react-bootstrap/Form';
import '../style/pointOfSale/pointOfSale.css'
import { BsCreditCard2BackFill } from "react-icons/bs";
import { GrDocumentPdf } from "react-icons/gr";
import { roleVisit } from '../services/Tools';

function columnsAdventures(simplified = true, editObj = null, deleteObj = null, generateInvoice = null, paymentObj = null, userInfo = null) {
    var header = [
      {
        name: 'Id',
        selector: row => row?.id?.slice(0, 8),
        sortable: true,
        omit: simplified
      },
      {
        name: 'Client name',
        cell: (row) => <div style={{width:"400px"}}>{ row?.client?.label ? (row?.client?.label) : (row?.newClientData + " (UNREGISTERED)")}</div>,
        sortable: true,
      },
      {
        name: 'Adventure',
        selector: row => row?.adventure?.label,
        sortable: true,
      },
      {
        name: 'Adults',
        selector: row => row?.adultNumber,
        sortable: true,
      },
      {
        name: 'Youths',
        selector: row => row?.youthNumber,
        sortable: true,
      },
      {
        name: 'Start date',
        selector: row => row?.startDate,
        sortable: true,
      },
      {
        name: 'Start Time',
        selector: row => row?.startTime,
        sortable: true,
        omit: simplified
      },
      // {
      //   name: 'End Time',
      //   selector: row => row?.endTime,
      //   sortable: true,
      // },
    ]
      if (userInfo && userInfo.role !== roleVisit) {
        header.push(
        {
          name: 'Balance',
          selector: row => "$ " + ( row?.balance ? row?.balance : 0 ),
          sortable: true,
          omit: simplified
        },
        {
        name: 'Actions',
        cell: (row) => <div className="button-actions">
          <>
            <Button className="color-green btn btn-outline-secondary p-1 mx-1" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  
            <Button className="color-red btn btn-outline-secondary p-1 mx-1" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> 
          </>
          <Button className="btn btn-outline-secondary p-1 mx-1" onClick={() => { generateInvoice(row?.id) }}><FaFileInvoiceDollar></FaFileInvoiceDollar></Button> 
          { row?.balance !== 0 && !row?.paid ? (
            <Button className="btn btn-outline-secondary p-1 mx-1" onClick={() => { paymentObj(row?.id) }}><BsCreditCard2BackFill></BsCreditCard2BackFill></Button>
          ) : ("")}

          </div>
          }
        );
      }
    
    return header;
}
  
function columnsReservations(simplified = true, editObj = null, deleteObj = null, paymentObj = null, generateInvoice = null, userInfo = null, uploadPDFObj = null) {
    var header = [ 
      {
        name: 'Id',
        selector: row => row?.id?.slice(0, 8),
        sortable: true,
        omit: simplified
      },
      {
        name: 'Client name',
        // selector: row => <div style={{width:"400px"}}>{ row?.client?.label ? (row?.client?.label) : (row?.newClientData + " (NO REGISTER)")}</div>,
        sortable: true,
        cell: (row) => <div style={{width:"400px"}}>{ row?.client?.label ? (row?.client?.label) : (row?.newClientData + " (UNREGISTERED)")}</div>
      },
      {
        name: 'Shuttle',
        selector: row => row?.shuttle?.label,
        sortable: true,
      },
      {
        name: 'Pick up',
        selector: row => row?.pickUp?.label,
        sortable: true,
      },
      {
        name: 'Number of people',
        selector: row => row?.nop,
        sortable: true,
      },
      {
        name: 'Start date',
        selector: row => row?.startDate,
        sortable: true,
      },
      // {
      //   name: 'End date',
      //   selector: row => row.endDate,
      //   sortable: true,
      // },
      {
        name: 'Start time',
        selector: row => row.startTime,
        sortable: true,
        omit: simplified
      },
      // {
      //   name: 'End time',
      //   selector: row => row.endTime,
      //   sortable: true,
      // },
      // {
      //   name: 'Total',
      //   selector: row => row.total,
      //   sortable: true,
      // },
      
    ]
    if (userInfo && userInfo.role !== roleVisit) {
      header.push(
      {
        name: 'Balance',
        selector: row => "$ " + ( row?.balance ? row?.balance : 0 ),
        sortable: true,
        omit: simplified
      },
      {
        name: 'Actions',
        cell: (row) => 
        <div className="button-actions">
          <>
          <Button className="color-green btn btn-outline-secondary p-1 mx-1" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button> 
          <Button className="color-red btn btn-outline-secondary p-1 mx-1" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> 
          </>

          { row?.showInvoice ? (
            <Button className="btn btn-outline-secondary p-1 mx-1" onClick={() => { generateInvoice(row?.id) }}><FaFileInvoiceDollar></FaFileInvoiceDollar></Button> 
          ) : ("")}
          
          <Button className="btn btn-outline-secondary p-1 mx-1" onClick={() => { uploadPDFObj(row?.id) }}><GrDocumentPdf></GrDocumentPdf></Button> 

          { row?.balance !== 0 && !row?.paid ? (
              <Button className="btn btn-outline-secondary p-1 mx-1" onClick={() => { paymentObj(row?.id) }}><BsCreditCard2BackFill></BsCreditCard2BackFill></Button>
          ) : ("")} 

        </div>
      }
      );
    }

    return header;
}

function columnsRentals(simplified = true, editObj = null, deleteObj = null, generateInvoice, paymentObj = null, userInfo = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id?.slice(0, 8),
      sortable: true,
      omit: simplified
    },
    {
      name: 'Client name',
      // selector: row => row.name,
      cell: (row) => <div style={{width:"400px"}}>{ row?.client?.label ? (row?.client?.label) : (row?.newClientData + " (UNREGISTERED)")}</div>,

      sortable: true,
    },
    {
      name: 'Rental item',
      selector: row => <div onClick={() => { editObj(row?.id) }}>
        { row?.rental?.length > 1 ? <p> {row?.rental?.[0]?.label } <small>{" +" + parseFloat(row?.rental?.length - 1) + " more" }</small></p> : row?.rental?.[0]?.label }
        </div>,
      sortable: true,
    },
    {
      name: 'Pickup date',
      selector: row => row?.pickupDate,
      sortable: true,
    },
    {
      name: 'Drop off date',
      selector: row => row?.dropOffDate,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Pickup time',
      selector: row => row?.pickupTime,
      sortable: true,
    },
    {
      name: 'Drop off time',
      selector: row => row?.dropOffTime,
      sortable: true,
      omit: simplified
    },
    ]
    if (userInfo && userInfo.role !== roleVisit) {
      header.push(
        {
          name: 'Balance',
          selector: row => "$ " + ( row?.balance ? row?.balance : 0 ),
          sortable: true,
          omit: simplified
        },
        {
          name: 'Actions',
          cell: (row) => <div className="button-actions">
          { userInfo?.role !== roleVisit ? (
            <>
            <Button className="color-green btn btn-outline-secondary p-1 mx-1" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  
            <Button className="color-red btn btn-outline-secondary p-1 mx-1" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> 
            </>
          ) : ("") }
          <Button className="btn btn-outline-secondary p-1 mx-1" onClick={() => { generateInvoice(row?.id) }}><FaFileInvoiceDollar></FaFileInvoiceDollar></Button> 

          { row?.balance !== 0 && !row?.paid ? (
            userInfo?.role !== roleVisit ? (
              <>
              <Button className="btn btn-outline-secondary p-1 mx-1" onClick={() => { paymentObj(row?.id) }}><BsCreditCard2BackFill></BsCreditCard2BackFill></Button>
              </>
            ) : ("")
          ) : ("")}
        </div>
        }
      );
    }
  
  return header;
}

function columnsRetails(simplified = true, editObj = null, deleteObj = null, generateInvoice, paymentObj = null, userInfo) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id?.slice(0, 8),
      sortable: true,
      omit: simplified
    },
    {
      name: 'Client name',
      // selector: row => row.name,
      cell: (row) => <div style={{width:"400px"}}>{ row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData + " (UNREGISTERED)" : "NO INFO")}</div>,

      sortable: true,
    },
    {
      name: 'Retail item',
      selector: row => <div onClick={() => { editObj(row?.id) }}>
        { row?.retail?.length > 1 ? <p> {row?.retail?.[0]?.label } <small>{" +" + parseFloat(row?.retail?.length - 1) + " more" }</small></p> : row?.retail?.[0]?.label }
        </div>,
      sortable: true,
    },
    {
      name: 'Sale date',
      selector: row => row?.startDate + " " + row?.startTime,
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ "+ row?.total,
      sortable: true,
    },
    {
      name: 'Balance',
      selector: row => "$ "+ row?.balance,
      sortable: true,
      omit: simplified
    },
    // {
    //   name: 'Drop off time',
    //   selector: row => row?.dropOffTime,
    //   sortable: true,
    // },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions">
        { userInfo?.role !== roleVisit ? (
          <>
          <Button className="color-green btn btn-outline-secondary p-1 mx-1" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  
          <Button className="color-red btn btn-outline-secondary p-1 mx-1" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> 
          </>
        ) : ("") }
        <Button className="btn btn-outline-secondary p-1 mx-1" onClick={() => { generateInvoice(row?.id) }}><FaFileInvoiceDollar></FaFileInvoiceDollar></Button> 
        { row?.balance !== 0 && !row?.paid ? (
          userInfo?.role !== roleVisit ? (
          <Button className="btn btn-outline-secondary p-1 mx-1" onClick={() => { paymentObj(row?.id) }}><BsCreditCard2BackFill></BsCreditCard2BackFill></Button>
        ) : ("")
        ) : ("")}
            
        </div>
    }
  ]
  return header;
}

function columnsReservationsReports() {
  var header = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client name',
      selector: row => row?.client?.label,
      sortable: true,
    },
    {
      name: 'Shuttle',
      selector: row => row?.shuttle?.label,
      sortable: true,
    },
    {
      name: 'Pick up',
      selector: row => row?.pickUp?.label,
      sortable: true,
    },
    {
      name: 'State',
      selector: row => row?.state,
      sortable: true,
      omit: true
    },
    {
      name: 'Number of people',
      selector: row => row?.nop,
      sortable: true,
    },
    {
      name: 'Start date',
      selector: row => row?.startDate,
      sortable: true,
    },
    {
      name: 'Start time',
      selector: row => row?.startTime,
      sortable: true,
    },
  ]
  return header;
}

function columnsClientReservation(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Shuttle',
      selector: row => row.shuttle.label,
      sortable: true,
    },
    {
      name: 'Pick Up',
      selector: row => row?.pickUp?.label,
      sortable: true,
    },
    {
      name: 'Number of people',
      selector: row => row?.nop,
      sortable: true,
    },
    {
      name: 'Start date',
      selector: row => row?.startDate,
      sortable: true,
    },
    // {
    //   name: 'End date',
    //   selector: row => row?.endDate,
    //   sortable: true,
    // },
    {
      name: 'Start time',
      selector: row => row?.startTime,
      sortable: true,
    },
    // {
    //   name: 'End time',
    //   selector: row => row?.endTime,
    //   sortable: true,
    // },
    {
      name: 'Total',
      selector: row => row?.total,
      sortable: true,
    },
    // {
    //   name: 'Balance',
    //   selector: row => row.balance,
    //   sortable: true,
    // },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}
  
function columnsProduct(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Product',
      selector: row => row.product,
      sortable: true,
    },
    {
      name: 'In stock',
      selector: row => row.stock,
      sortable: true,
    },
    {
      name: 'Price',
      selector: row => "$ "+ row.price,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsRentalItem(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Rental item',
      selector: row => row?.rental,
      sortable: true,
    },
    {
      name: 'In stock',
      selector: row => row?.stock,
      sortable: true,
    },
    {
      name: 'Price by 3 hours',
      selector: row => row?.priceByHour ? ("$ " + row?.priceByHour) : "",
      sortable: true,
    },
    {
      name: 'Price day',
      selector: row => row?.priceByDay ? ("$ " + row?.priceByDay) : "",
      sortable: true,
    },
    // {
    //   name: 'Rental By',
    //   selector: row => row?.rentalBy?.value,
    //   sortable: true,
    // },
    {
      name: 'Color',
      selector: row => <div><input type="color" value={row?.color} readOnly></input></div>,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsRetailItem(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Retail item',
      selector: row => row?.retail,
      sortable: true,
    },
    {
      name: 'In stock',
      selector: row => row?.stock,
      sortable: true,
    },
    {
      name: 'Price',
      selector: row => row?.price ? ("$ " + row?.price) : "",
      sortable: true,
    },
    // {
    //   name: 'Color identifier',
    //   selector: row => <div><input type="color" value={row?.color} readOnly></input></div>,
    //   sortable: true,
    // },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsRentalDuration(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Title',
      selector: row => row?.titleText,
      sortable: true,
    },
    {
      name: 'Duration',
      selector: row => row?.duration,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsAdventureConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Adventure',
      selector: row => row?.adventure,
      sortable: true,
    },
    {
      name: 'Adult rate',
      selector: row => "$ " + row?.adultRate,
      sortable: true,
    },
    {
      name: 'Youth rate',
      selector: row => "$ " + row?.youthRate,
      sortable: true,
    },
    {
      name: 'Color',
      // selector: row => row?.color,
      selector: row => <div><input type="color" value={row?.color} readOnly></input></div>,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsDietaryConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Dietary restrictions',
      selector: row => row?.dietary,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsStateConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true,
    },
    {
      name: 'State',
      selector: row => row?.value,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsSourceConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true,
    },
    {
      name: 'Lead Source',
      selector: row => row?.value,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsLeadOwnerConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Title',
      selector: row => row.label,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsShuttleConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Shuttle',
      selector: row => row?.label,
      sortable: true,
    },
     {
      name: 'Color',
      selector: row => 
      <div>
        <input type="color" value={row?.color ? row?.color : "#EEEEEE"} readOnly></input>
      </div>,
      sortable: true,
    },
    {
      name: 'Fixed rate',
      selector: row => <div> { row?.fixedRate ? ("$ " + row?.rate) : ("-") } </div>,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsVehicleConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Vehicle',
      selector: row => row?.label,
      sortable: true,
    }, 
    {
      name: 'Vehicle Plate',
      selector: row => row?.plate ? row?.plate : "-",
      sortable: true,
    }, 
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsSearchedOptionsConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Searched',
      selector: row => row?.label,
      sortable: true,
    },  
    {
      name: 'Created date',
      selector: row => row?.created_date,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsTaxesConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Type of tax',
      selector: row => row?.type,
      sortable: true,
    },
    {
      name: 'Tax',
      selector: row => row?.tax + "%",
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsDiscountConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Type of discount',
      selector: row => row?.type,
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row?.discount + "%",
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsExtraCostConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Extra cost type',
      selector: row => row?.type,
      sortable: true,
    },
    {
      name: 'Cost',
      selector: row => "$ " + row?.cost,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsPaymentType(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Payment Type',
      selector: row => row.paymentType,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsPointOfSale(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      //selector: row => row.id,
      cell: (row) => <a className="id_sale p-2 mx-2" onClick={() => { editObj(row.id) }}>{row.id.substr(1,10)}...</a>,
      sortable: true,
      omit: false
    },
    {
      name: 'Created by',
      selector: row => row.created_by_name,
      sortable: true,
    },
    {
      name: 'Created date',
      selector: row => row.created_date,
      sortable: true,
    },
    {
      name: 'Sale',
      selector: row => row.product.label,
      sortable: true,
    },
    {
      name: 'Balance due',
      selector: row => row.balance,
      sortable: true,
    },
    // {
    //   name: 'Actions',
    //   cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    // }
  ]
  return header;
}
  
function columnsSales(editObj = null, deleteObj = null) {

  var header = [
    {
      name: 'Id',
      //selector: row => row.id,
      cell: (row) => <a className="id_sale p-2 mx-2" onClick={() => { editObj(row.id) }}>{row.id.substr(1,10)}...</a>,
      sortable: true,
      omit: false
    },
    {
      name: 'Created by',
      selector: row => row.created_by_name,
      sortable: true,
    },
    {
      name: 'Last Modification',
      selector: row => row.last_modification,
      sortable: true,
    },
    // {
    //   name: 'Sale',
    //   selector: row => row.product.label,
    //   sortable: true,
    // }, 
    // {
    //   name: 'Sale',
    //   selector: row => row?.items.forEach(element => {
    //   // let e = element.product;

    //   }),
    //   //selector: row => row.ff,
    //   //sortable: true,
    // },
    {
      name: 'Balance due',
      selector: row => row.balance,
      sortable: true,
    },
    // {
    //   name: 'Actions',
    //   cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    // }
  ]
  return header;
}

function columnsAgents(editObj = null, deleteObj = null) {

  var header = [
    // {
    //   name: 'Id',
    //   //selector: row => row.id,
    //   cell: (row) => <a className="id_sale p-2 mx-2" onClick={() => { editObj(row.id) }}>{row.id.substr(1,10)}...</a>,
    //   sortable: true,
    //   omit: false
    // },
    {
      name: 'Representative',
      selector: row => row.representative_name,
      sortable: true,
    },
    {
      name: 'Agent name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Commission',
      selector: row => row.commission + "%",
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  
      {/* <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button>  */}
      </div>
    }
  ]
  return header;
}

function columnsPlaces(editObj = null, deleteObj = null) {

  var header = [
    {
      name: 'Place',
      selector: row => row.place,
      sortable: true,
    },
    // {
    //   name: 'Color',
    //   selector: row => <div><input type="color" value={row?.color} readOnly></input></div>,
    //   sortable: true,
    // },
    // {
    //   name: 'Fixed rate',
    //   selector: row => <div> { row?.fixedRate ? (<input type="checkbox" checked={row?.fixedRate} readOnly></input>) : ("-") }</div>,
    //   sortable: true,
    // },
    // {
    //   name: 'Rate',
    //   selector: row => <div> { row?.fixedRate ? ("$ " + row?.rate) : ("-") } </div>,
    //   sortable: true,
    // },
    {
      name: 'Created date',
      selector: row => row.created_date,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsInvoiceConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Service',
      selector: row => row?.service,
      sortable: true,
    },
    {
      name: 'Payment Type',
      selector: row => row?.paymentType?.label,
      sortable: true,
    },
    {
      name: 'Card Type',
      selector: row => row?.paymentType?.label === "Credit" ? (row?.cardType?.value) : ("-"),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => "$ " + parseFloat(row?.amount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    // {
    //   name: 'Tax',
    //   selector: row => row?.tax?.label+" ("+row?.tax?.value+"%)",
    //   sortable: true,
    // },
    {
      name: 'Tax Amount',
      selector: row => "$ " + parseFloat(row?.taxAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + parseFloat(row?.total).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Created date',
      selector: row => row?.created_date,
      sortable: true,
    },
    // {
    //   name: 'Actions',
    //   cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    // }
  ]
  return header;
}

function columnsPaymentConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      sortable: true,
    },
    {
      name: 'Searched',
      selector: row => row?.searched ? (row?.searchedOptions?.value) : ("-"),
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.service,
      sortable: true,
    },
    {
      name: 'Payment Type',
      selector: row => row?.paymentType?.label,
      sortable: true,
    },
    {
      name: 'Agent',
      selector: row => row?.agents?.label ? (row?.agents?.label + " " + row?.agents?.commission + "%") : ("-"),
      sortable: true,
    },
    {
      name: 'Refund Via',
      selector: row => row?.refundType?.label ? (row?.refundType?.label) : ("-"),
      sortable: true,
    },
    {
      name: 'Amount', 
      // selector: row => "$ " + parseFloat(row?.capitalPaid),
      selector: row => row?.capitalPaid ? "$ " + (parseFloat(row?.capitalPaid) + (row?.extraCostPaid ? parseFloat(row?.extraCostPaid) : 0)).toFixed(2).padStart(4, '0') : "$ 0.00",
      sortable: true,
    },
    {
      name: 'Card Surcharge',
      //selector: row => "$ " + parseFloat(row?.cardSurchargePaid ? row?.cardSurchargePaid : 0).toFixed(2).padStart(4, '0'),
      selector: row => row?.cardSurchargePaid ? "$ " + parseFloat(row?.cardSurchargePaid).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Fuel Surcharge',
      selector: row => "$ " + parseFloat(row?.fuelSurchargePaid ? row?.fuelSurchargePaid : 0).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row?.discountAmount ? "$ " + parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Tax',
      selector: row => "$ " + parseFloat(row?.taxAmountPaid).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Tip',
      selector: row => "$ " + (row?.tipAmountPaid ? parseFloat(row?.tipAmountPaid).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + parseFloat(row?.newAmountPaid).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.startDate ? row?.startDate : "-",
      sortable: true,
    },
    {
      name: 'Payment date',
      selector: row => row?.created_date,
      sortable: true,
    },
  ]
  return header;
}

function columnsDriverReportConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: row => row?.startDate,
      sortable: true,
    },
    {
      name: 'Start Time',
      selector: row => row?.startTime,
      sortable: true,
    },
    {
      name: 'End Date',
      selector: row => row?.endDate,
      sortable: true,
    },
    {
      name: 'End Time',
      selector: row => row?.endTime,
      sortable: true,
    },
    {
      name: 'Shuttle Type',
      selector: row => row?.shuttle?.label,
      sortable: true,
    },
    {
      name: 'Pick Up',
      selector: row => row?.pickUp?.label,
      sortable: true,
    },
    {
      name: 'Drop Off',
      selector: row => row?.dropOff?.label,
      sortable: true,
    },
    {
      name: 'Driver name',
      selector: row => row?.driverName ? row?.driverName : "",
      sortable: true,
    },
  
  ]
  return header;
}

function columnsLeadSourcesConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.clientName ? (row?.clientName) : (row?.client?.label ? row?.client?.label : "-"),
      sortable: true,
    },
    {
      name: 'Source',
      selector: row => row?.leadSource?.label ? row?.leadSource?.label : "",
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row?.amount ? ("$ " + row?.amount) : ("$ " + 0),
      sortable: true,
    },
    {
      name: 'Extra Amount',
      selector: row => row?.extraAmount ? ("$ " + row?.extraAmount) : ("$ " + 0),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row?.discountAmount ? ("$ " + row?.discountAmount) : ("$ " + 0),
      sortable: true,
    },
    {
      name: 'Tax Type',
      selector: row => row?.taxes?.label ? row?.taxes?.label : "-",
      sortable: true,
    },
    {
      name: 'Tax',
      selector: row => row?.taxAmount ? ("$ " + row?.taxAmount) : ("$ " + 0),
      sortable: true,
    },
    {
      name: 'Surcharge',
      selector: row => row?.fuelSurchargeAmount ? ("$ " + row?.fuelSurchargeAmount) : ("$ " + 0),
      sortable: true,
    },
    {
      name: 'Tip',
      selector: row => row?.tipAmount ? ("$ " + row?.tipAmount) : ("$ " + 0),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => row?.total ? ("$ " + (parseFloat(row?.total) + parseFloat(row?.tipAmount))) : ("$ " + 0),
      sortable: true,
    },
  
  ]
  return header;
}

function columnsPaymentAdventureConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.adventure?.label,
      sortable: true,
    },
    {
      name: 'Payment Type',
      selector: row => row?.paymentType?.label,
      sortable: true,
    },
    {
      name: 'Agent',
      selector: row => row?.agents?.label ? (row?.agents?.label + " " + row?.agents?.commission + "%") : ("-"),
      sortable: true,
    },
    {
      name: 'Refund Via',
      selector: row => row?.refundType?.label ? (row?.refundType?.label) : ("-"),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row?.capitalPaid ? "$ " + (parseFloat(row?.capitalPaid) + (row?.extraCostPaid ? parseFloat(row?.extraCostPaid) : 0)).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Card Surcharge',
      //selector: row => "$ " + parseFloat(row?.cardSurchargePaid ? row?.cardSurchargePaid : 0).toFixed(2).padStart(4, '0'),
      selector: row => row?.cardSurchargePaid ? "$ " + parseFloat(row?.cardSurchargePaid).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Fee',
      selector: row => row?.waterFeePaid ? "$ " + parseFloat(row?.waterFeePaid).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row?.discountAmount ? "$ " + parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    }, 
    {
      name: 'Tax',
      selector: row => row?.taxAmountPaid ? "$ " + parseFloat(row?.taxAmountPaid).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Tip',
      selector: row => "$ " + (row?.tipAmountPaid ? parseFloat(row?.tipAmountPaid).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => row?.newAmountPaid ? "$ " + parseFloat(row?.newAmountPaid).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.startDate,
      sortable: true,
    },
    {
      name: 'Payment date',
      selector: row => row?.created_date,
      sortable: true,
    },
  ]
  return header;
}

function columnsPaymentRentalConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      sortable: true, 
    },
    {
      name: 'Product',
      selector: row => row?.rental?.[0]?.label,
      sortable: true,
    },
    {
      name: 'Payment Type',
      selector: row => row?.paymentType?.label,
      sortable: true,
    },
    {
      name: 'Agent',
      selector: row => row?.agents?.label ? (row?.agents?.label + " " + row?.agents?.commission + "%") : ("-"),
      sortable: true,
    },
    {
      name: 'Refund Via',
      selector: row => row?.refundType?.label ? (row?.refundType?.label) : ("-"),
      sortable: true,
    },
    {
      name: 'Amount',
      // selector: row => "$ " + parseFloat(row?.capitalPaid).toFixed(2).padStart(4, '0'),
      selector: row => row?.capitalPaid ? "$ " + (parseFloat(row?.capitalPaid) + (row?.extraCostPaid ? parseFloat(row?.extraCostPaid) : 0)).toFixed(2).padStart(4, '0') : "$ 0.00",
      sortable: true,
    },
    {
      name: 'Card Surcharge',
      selector: row => row?.cardSurchargePaid ? "$ " + parseFloat(row?.cardSurchargePaid).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row?.discountAmount ? "$ " + parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Tax',
      selector: row => "$ " + parseFloat(row?.taxAmountPaid).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Tip',
      selector: row => "$ " + (row?.tipAmountPaid ? parseFloat(row?.tipAmountPaid).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + parseFloat(row?.newAmountPaid).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.pickupDate ? row?.pickupDate : "-",
      sortable: true,
    },
    {
      name: 'Payment date',
      selector: row => row?.created_date,
      sortable: true,
    },
  ]
  return header;
}

function columnsPaymentRetailConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "NO INFO"),      
      sortable: true, 
    },
    {
      name: 'Retail',
      // selector: row => row?.retail?.[0]?.label,
      selector: row => 
      row?.retail?.length > 1 ? row?.retail?.[0]?.label + " +" + parseFloat(row?.retail?.length - 1) + " more" : row?.retail?.[0]?.label,
      sortable: true,
    },
    {
      name: 'Payment Type',
      selector: row => row?.paymentType?.label,
      sortable: true,
    },
    {
      name: 'Refund Via',
      selector: row => row?.refundType?.label ? (row?.refundType?.label) : ("-"),
      sortable: true,
    },
    {
      name: 'Amount',
      // selector: row => row?.capitalPaid ? "$ " + parseFloat(row?.capitalPaid).toFixed(2).padStart(4, '0') : 0,
      selector: row => "$ " + (row?.capitalPaid ? (parseFloat(row?.capitalPaid) + (row?.extraCostPaid ? parseFloat(row?.extraCostPaid) : 0)).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Card Surcharge',
      selector: row => row?.cardSurchargePaid ? "$ " + parseFloat(row?.cardSurchargePaid).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => "$ " + (row?.discountAmount ? parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0') : "0"),
      sortable: true,
    },
    {
      name: 'GST Tax',
      selector: row => "$ " + (row?.taxAmountPaidGST ? parseFloat(row?.taxAmountPaidGST).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'PST Tax',
      selector: row =>  "$ " + (row?.taxAmountPaidPST ? parseFloat(row?.taxAmountPaidPST).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Tip',
      selector: row => "$ " + (row?.tipAmount ? parseFloat(row?.tipAmount).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + (row?.newAmountPaid ? parseFloat(row?.newAmountPaid).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.startDate ? row?.startDate : "-",
      sortable: true,
    },
    {
      name: 'Payment date',
      selector: row => row?.created_date,
      sortable: true,
    },
  ]
  return header;
}

function columnsHistoryReservationConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.clientName ? (row?.clientName) : (row?.client?.label ? row?.client?.label : "-"),
      sortable: true,
    },
    {
      name: 'Searched',
      selector: row => row?.searched ? (row?.searchedOptions?.value) : ("-"),
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.shuttle?.label,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => "$ " + (parseFloat(row?.amount) + parseFloat(row?.extraAmount)).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => "$ " + parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Fuel Surcharge',
      selector: row => "$ " + parseFloat(row?.fuelSurchargeAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Tax',
      selector: row => "$ " + parseFloat(row?.taxAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + parseFloat(row?.total).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: row => row?.startDate,
      sortable: true,
    },
  ]
  return header;
}

function columnsClientServicesConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.clientName ? (row?.clientName) : row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row?.email ? row?.email : ("-"),
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.type,
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => row?.type == "Transport" ? row?.shuttle?.label : row?.adventure?.label,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => "$ " + (row?.type == "Transport" ? (parseFloat(row?.amount) + parseFloat(row?.extraAmount)).toFixed(2).padStart(4, '0') : (parseFloat(row?.amount)).toFixed(2).padStart(4, '0')),
      sortable: true,
    },
    {
      name: 'Water Fee',
      selector: row => "$ " + (parseFloat( row?.type == "Adventure" ? row?.waterFeeAmount : 0 )).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => "$ " + parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Fuel Surcharge',
      selector: row =>  "$ " + parseFloat(row?.type == "Transport" ? row?.fuelSurchargeAmount : 0).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Tax',
      selector: row => "$ " + parseFloat(row?.taxAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + parseFloat(row?.total !== "" ? row?.total : 0).toFixed(2).padStart(4, '0') ,
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: row => row?.startDate,
      sortable: true,
    },
  ]
  return header;
}

function columnsHistoryAdventureConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.clientName ? (row?.clientName) : (row?.client?.label ? row?.client?.label : "-"),
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.adventure?.label,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row?.amount ? ("$ " + (parseFloat(row?.amount) + (row?.extraAmount ? parseFloat(row?.extraAmount) : 0 ) ).toFixed(2).padStart(4, '0')) : "$ 0.00",
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => "$ " + parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Fee',
      selector: row => "$ " + parseFloat(row?.waterFeeAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },  
    {
      name: 'Tax',
      selector: row => "$ " + parseFloat(row?.taxAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => row?.total ? ("$ " + parseFloat(row?.total).toFixed(2).padStart(4, '0')) :  "$ 0.00",
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: row => row?.startDate,
      sortable: true,
    },
  ]
  return header;
}

function columnsHistoryRentalConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.clientName ? (row?.clientName) : (row?.client?.label ? row?.client?.label : "-"),
      sortable: true,
    },
    {
      name: 'Service',
      // selector: row => row?.rental?.[0]?.label,
      selector: row => 
        row?.rental?.length > 1 ? row?.rental?.[0]?.label + " +" + parseFloat(row?.rental?.length - 1) + " more" : row?.rental?.[0]?.label,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => "$ " + parseFloat(row?.amount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => "$ " + parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Tax',
      selector: row => "$ " + parseFloat(row?.taxAmount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + parseFloat(row?.total).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Pickup Date',
      selector: row => row?.pickupDate,
      sortable: true,
    },
  ]
  return header;
}

function columnsAllActivitiesConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      sortable: true,
    },
    {
      name: 'Activitie',
      selector: row => row?.adventure?.label ? ( "Adventure" ) : 
      (row?.shuttle?.label ? "Transport" : "Rental" ),
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.adventure?.label ? ( row?.adventure?.label ) : 
      (row?.shuttle?.label ? row?.shuttle?.label : 
      (row?.rental?.length > 1 ? row?.rental?.[0]?.label + " +" + parseFloat(row?.rental?.length - 1) + " more" : row?.rental?.[0]?.label )),
      sortable: true,
    },
    {
      name: 'Agent',
      selector: row => row?.agents?.label ? (row?.agents?.label + " " + row?.agents?.commission + "%") : ("-"),
      sortable: true,
    },
    {
      name: 'Invoiced via',
      selector: row => row?.invoicedVia?.label ? (row?.invoicedVia?.label) : ("-"),
      sortable: true,
    },
    {
      name: 'SubTotal',
      selector: row => row?.amount ? "$ " + (parseFloat(row?.amount) + (row?.extraAmount ? row?.extraAmount : 0)).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => row?.discountAmount ? "$ " + parseFloat(-row?.discountAmount).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    }, 
    {
      name: 'Total',
      selector: row => "$ " + (parseFloat(row?.amount ? (parseFloat(row?.amount) + (row?.extraAmount ? row?.extraAmount : 0)): 0) - parseFloat(row?.discountAmount ? row?.discountAmount : 0)).toFixed(2).padStart(4, '0'),
      sortable: true,
    }, 
    {
      name: 'Card Surcharge',
      selector: row => row?.cardSurchargeAmount ? "$ " + parseFloat(row?.cardSurchargeAmount).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Fuel',
      selector: row => row?.fuelSurchargeAmount ? ("$ " + parseFloat(row?.fuelSurchargeAmount).toFixed(2).padStart(4, '0')) : "-",
      sortable: true,
    },
    {
      name: 'Fee',
      selector: row => row?.waterFeeAmount ? "$ " + parseFloat(row?.waterFeeAmount).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    }, 
    // {
    //   name: 'Discount',
    //   selector: row => "$ " + parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0'),
    //   sortable: true,
    // },
  
    {
      name: 'Tax',
      selector: row => row?.taxAmount ? "$ " + parseFloat(row?.taxAmount).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => row?.total ? "$ " + parseFloat(row?.total).toFixed(2).padStart(4, '0') : "-",
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: row => row?.rental?.length > 0 ? row?.pickupDate : row?.startDate, 
      sortable: true,
    },
  ]
  return header;
}

function columnsAllActivitiesRevenueConfig() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      sortable: true,
    },
    {
      name: 'Activitie',
      selector: row => row?.adventure?.label ? ( "Adventure" ) : 
      (row?.service ? "Transport" : ( row?.rental?.length > 0 ? "Rental" : "Retail" ) ),
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.adventure?.label ? ( row?.adventure?.label ) : 
      (row?.fuelSurchargeAmount >= 0 ? row?.service : 
      (row?.rental?.length > 0 ? 
        (row?.rental?.length > 1 ? (row?.rental?.[0]?.label + " +" + parseFloat(row?.rental?.length - 1) + " more") : (row?.rental?.[0]?.label )) :
        (row?.retail?.length > 1 ? (row?.retail?.[0]?.label + " +" + parseFloat(row?.retail?.length - 1) + " more") : (row?.retail?.[0]?.label )))),
      sortable: true,
    },
    {
      name: 'Payment Type',
      selector: row => row?.paymentType?.label,
      sortable: true,
    },
    {
      name: 'Agent',
      selector: row => row?.bookingType ? (row?.bookingType !== "Directly" ? row?.agents?.label : "-") : "-",
      sortable: true,
    },
    {
      name: 'Refund Via',
      selector: row => row?.refundType?.label ? (row?.refundType?.label) : ("-"),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => "$ " + (row?.capitalPaid ? (parseFloat(row?.capitalPaid) + (row?.extraCostPaid ? parseFloat(row?.extraCostPaid) : 0)).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Fuel Surcharge',
      selector: row => "$ " + (row?.fuelSurchargePaid ? (parseFloat(row?.fuelSurchargePaid).toFixed(2).padStart(4, '0')) : 0),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => "$ " + (row?.discountAmount ? (parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0')) : 0),
      sortable: true,
    },
    {
      name: 'Tax',
      selector: row => "$ " + (row?.taxAmountPaid ? (parseFloat(row?.taxAmountPaid).toFixed(2).padStart(4, '0') ) : 0),
      sortable: true,
    },
    {
      name: 'Tip',
      selector: row => "$ " + (row?.tipAmountPaid ? parseFloat(row?.tipAmountPaid).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + parseFloat(row?.newAmountPaid).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.startDate ? (row?.startDate) : (row?.pickupDate ? row?.pickupDate : '-'),
      sortable: true,
    },
    {
      name: 'Payment date',
      selector: row => row?.created_date,
      sortable: true,
    },
  ]
  return header;
}

function columnsAllActivitiesRevenueConfig2() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      sortable: true,
    },
    {
      name: 'Activitie',
      selector: row => row?.adventure?.label ? ( "Adventure" ) : 
      (row?.service ? "Transport" : ( row?.rental?.length > 0 ? "Rental" : "Retail" ) ),
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.adventure?.label ? ( row?.adventure?.label ) : 
      (row?.fuelSurchargeAmount >= 0 ? row?.service : 
      (row?.rental?.length > 0 ? 
        (row?.rental?.length > 1 ? (row?.rental?.[0]?.label + " +" + parseFloat(row?.rental?.length - 1) + " more") : (row?.rental?.[0]?.label )) :
        (row?.retail?.length > 1 ? (row?.retail?.[0]?.label + " +" + parseFloat(row?.retail?.length - 1) + " more") : (row?.retail?.[0]?.label )))),
      sortable: true,
    },
    {
      name: 'Transaction Type',
      selector: row => row?.paymentType?.label == "Refund" ? ("Refund") : row?.paymentType?.label == "Revert" ? ("Revert") : "Payment",
      sortable: true,
    },
    {
      name: 'Payment Type',
      selector: row => 
      row?.paymentType ?
        (
        row?.paymentType?.label == "Refund" ? 
                (row?.refundType ? row?.refundType.label : "Refund") : //row?.paymentType?.label
                row?.paymentType?.label == "Revert" ?
                (row?.revertType ? row?.revertType.label : "Revert") : 
                row?.paymentType?.label
        ) : (""),
      sortable: true,
    },
    {
      name: 'Agent',
      selector: row => row?.bookingType ? (row?.bookingType !== "Directly" ? row?.agents?.label : "-") : "-",
      sortable: true,
    },
    {
      name: 'Tip',
      selector: row => "$ " + (row?.tipAmountPaid ? parseFloat(row?.tipAmountPaid).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + parseFloat(row?.newAmountPaid).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Start date',
      selector: row => row?.startDate ? (row?.startDate) : (row?.pickupDate ? row?.pickupDate : '-'),
      sortable: true,
    },
    {
      name: 'Payment date',
      selector: row => row?.created_date,
      sortable: true,
    },
  ]
  return header;
}

function columnsAllActivitiesRevenueConfigRevenue() {

  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      sortable: true,
    },
    {
      name: 'Activitie',
      selector: row => row?.adventure?.label ? ( "Adventure" ) : 
      (row?.service ? "Transport" : ( row?.rental?.length > 0 ? "Rental" : "Retail" ) ),
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.adventure?.label ? ( row?.adventure?.label ) : 
      (row?.fuelSurchargeAmount >= 0 ? row?.service : 
      (row?.rental?.length > 0 ? 
        (row?.rental?.length > 1 ? (row?.rental?.[0]?.label + " +" + parseFloat(row?.rental?.length - 1) + " more") : (row?.rental?.[0]?.label )) :
        (row?.retail?.length > 1 ? (row?.retail?.[0]?.label + " +" + parseFloat(row?.retail?.length - 1) + " more") : (row?.retail?.[0]?.label )))),
      sortable: true,
    },
    // {
    //   name: 'Payment Type',
    //   selector: row => row?.paymentType?.label,
    //   sortable: true,
    // },
    {
      name: 'Agent',
      selector: row => row?.bookingType ? (row?.bookingType !== "Directly" ? row?.agents?.label : "-") : "-",
      sortable: true,
    },
    {
      name: 'Invoiced Via',
      selector: row => row?.invoicedVia?.label ? (row?.invoicedVia?.label) : ("-"),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => "$ " + (row?.capitalPaid ? (parseFloat(row?.capitalPaid) + (row?.extraCostPaid ? parseFloat(row?.extraCostPaid) : 0)).toFixed(2).padStart(4, '0') : 0),
      sortable: true,
    },
    {
      name: 'Fuel Surcharge',
      selector: row => "$ " + (row?.fuelSurchargePaid ? (parseFloat(row?.fuelSurchargePaid).toFixed(2).padStart(4, '0')) : 0),
      sortable: true,
    },
    {
      name: 'Fee',
      selector: row => "$ " + (row?.waterFeePaid ? (parseFloat(row?.waterFeePaid).toFixed(2).padStart(4, '0')) : 0),
      sortable: true,
    },
    {
      name: 'Discount',
      selector: row => "$ " + (row?.discountAmount ? (parseFloat(row?.discountAmount).toFixed(2).padStart(4, '0')) : 0),
      sortable: true,
    },
    {
      name: 'Tax',
      selector: row => "$ " + (row?.taxAmountPaid ? (parseFloat(row?.taxAmountPaid).toFixed(2).padStart(4, '0') ) : 0),
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => "$ " + parseFloat(row?.newAmountPaid).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'Start date',
      selector: row => row?.startDate ? (row?.startDate) : (row?.pickupDate ? row?.pickupDate : '-'),
      sortable: true,
    },
  ]
  return header;
}

function columnsAgentsReportConfig() {
  var header = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client?.label ? (row?.client?.label) : (row?.newClientData ? row?.newClientData : "-"),
      // cell: (row) => <div style={{width:"400px"}}>{ row?.client?.label ? (row?.client?.label) : (row?.newClientData)}</div>,
      sortable: true,
    },
    {
      name: 'Agent',
      selector: row => row?.agents?.label,
      sortable: true,
    },
    {
      name: 'Service',
      selector: row => row?.adventure?.label ? ( row?.adventure?.label ) : 
      (row?.shuttle?.label ? row?.shuttle?.label : 
      (row?.rental?.length > 1 ? row?.rental?.[0]?.label + " +" + parseFloat(row?.rental?.length - 1) + " more" : row?.rental?.[0]?.label )),
      sortable: true,
    },
    // {
    //   name: 'Pick up',
    //   selector: row => row?.pickUp?.label,
    //   sortable: true,
    // },
    {
      name: 'Start date',
      selector: row => row?.rental?.length > 0 ? row?.pickupDate : row?.startDate, 
      sortable: true,
    },
    {
      name: 'Commission',
      selector: row => row?.agents?.commission + " %",
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => "$ " + parseFloat(row?.amount).toFixed(2).padStart(4, '0'),
      sortable: true,
    },
    {
      name: 'To pay',
      selector: row => "$ " + (parseFloat(row?.amount) * (parseFloat(row?.agents?.commission)/100)).toFixed(2),
      sortable: true,
    },
  ]
  return header;
}

function columnsEventsAdd() {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id.slice(0, 18),
      sortable: true,
    },
    {
      name: 'Client name',
      cell: (row) => <div style={{width:"400px"}}>{ row?.client?.label ? (row?.client?.label) : (row?.newClientData + " (UNREGISTERED)")}</div>,
      sortable: true,
    },
    {
      name: 'User autor',
      selector: row => row?.created_by_name ? row?.created_by_name : "-",
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.created_date ? row?.created_date?.toDate().toLocaleString() : "-",
      sortable: true,
    },
  ]
  return header;
}

function columnsEventsEdit() {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id_event ? row?.id_event.slice(0, 18) : "-",
      sortable: true,
    },
    {
      name: 'Client name',
      cell: (row) => <div style={{width:"400px"}}>{ row?.client_name ? (row?.client_name) : ("UNREGISTERED")}</div>,
      sortable: true,
    },
    {
      name: 'User autor',
      selector: row => row?.updated_by_name ? row?.updated_by_name : "-",
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.updated_date ? row?.updated_date?.toDate().toLocaleString() : "-",
      sortable: true,
    },
  ]
  return header;
}

function columnsEventsDelete() {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id_event ? row?.id_event.slice(0, 18) : row?.id.slice(0, 18),
      sortable: true,
    },
    {
      name: 'Client name',
      cell: (row) => <div style={{width:"400px"}}>{ row?.client?.label ? (row?.client?.label) : (row?.newClientData + " (UNREGISTERED)")}</div>,
      sortable: true,
    },
    {
      name: 'User autor',
      selector: row => row?.deleted_by_name ? row?.deleted_by_name : "-",
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.deleted_date ? row?.deleted_date?.toDate().toLocaleString() : "-",
      sortable: true,
    },
  ]
  return header;
}

function columnsEventsAddRetails() {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id.slice(0, 18),
      sortable: true,
    },
    {
      name: 'Retail',
      selector: row => <div>
        { row?.retail?.length > 1 ? <p> {row?.retail?.[0]?.label } <small>{" +" + parseFloat(row?.retail?.length - 1) + " more" }</small></p> : row?.retail?.[0]?.label }
        </div>,
      sortable: true,
    },
    {
      name: 'User autor',
      selector: row => row?.created_by_name ? row?.created_by_name : "-",
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.created_date ? row?.created_date?.toDate().toLocaleString() : "-",
      sortable: true,
    },
  ]
  return header;
}

function columnsEventsEditRetails() {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id_event ? row?.id_event.slice(0, 18) : "-",
      sortable: true,
    },
    {
      name: 'Retail',
      selector: row => <div>
        { row?.new_data?.retail?.length > 1 ? <p> {row?.new_data?.retail?.[0]?.label } <small>{" +" + parseFloat(row?.new_data?.retail?.length - 1) + " more" }</small></p> : row?.new_data?.retail?.[0]?.label }
        </div>,
      sortable: true,
    },
    {
      name: 'User autor',
      selector: row => row?.updated_by_name ? row?.updated_by_name : "-",
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.updated_date ? row?.updated_date?.toDate().toLocaleString() : "-",
      sortable: true,
    },
  ]
  return header;
}

function columnsEventsDeleteRetails() {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id_event ? row?.id_event.slice(0, 18) : row?.id.slice(0, 18),
      sortable: true,
    },
    {
      name: 'Retail',
      selector: row => <div>
        { row?.retail?.length > 1 ? <p> {row?.retail?.[0]?.label } <small>{" +" + parseFloat(row?.retail?.length - 1) + " more" }</small></p> : row?.retail?.[0]?.label }
        </div>,
      sortable: true,
    },
    {
      name: 'User autor',
      selector: row => row?.deleted_by_name ? row?.deleted_by_name : "-",
      sortable: true,
    },
    {
      name: 'Event date',
      selector: row => row?.deleted_date ? row?.deleted_date?.toDate().toLocaleString() : "-",
      sortable: true,
    },
  ]
  return header;
}



export { columnsAdventures, columnsReservations, columnsReservationsReports, columnsRentals,columnsRetails, columnsProduct, columnsRentalItem, columnsRetailItem, 
  columnsAdventureConfig, columnsDietaryConfig, columnsStateConfig, columnsShuttleConfig, columnsVehicleConfig, columnsSearchedOptionsConfig, columnsPointOfSale, 
  columnsPaymentType, columnsSales, columnsSourceConfig, columnsLeadOwnerConfig, columnsClientReservation, columnsAgents, columnsTaxesConfig, columnsDiscountConfig, 
  columnsPlaces, columnsInvoiceConfig, columnsAgentsReportConfig, columnsPaymentConfig, columnsPaymentAdventureConfig, columnsPaymentRentalConfig, 
  columnsPaymentRetailConfig, columnsExtraCostConfig, columnsHistoryReservationConfig, columnsClientServicesConfig, columnsHistoryAdventureConfig, 
  columnsHistoryRentalConfig, columnsAllActivitiesConfig, columnsAllActivitiesRevenueConfig, columnsAllActivitiesRevenueConfig2, columnsAllActivitiesRevenueConfigRevenue,
  columnsRentalDuration, columnsEventsAdd, columnsEventsEdit,   columnsEventsAddRetails, columnsEventsEditRetails, columnsEventsDelete, columnsEventsDeleteRetails, 
  columnsDriverReportConfig, columnsLeadSourcesConfig
}