import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { auth } from "../firebase";
import { getDataOneUser } from '../services/auth/users';
import { roleVisit } from "../services/Tools";

import Loading from "../components/loading";
import Payment from '../components/payments/tableConfig';
import PaymentAdventure from '../components/paymentsAdventure/tableConfig';
import PaymentRental from '../components/paymentsRental/tableConfig';
import PaymentRetail from '../components/paymentsRetail/tableConfig';
import AgentsReport from '../components/report/agentReport/tableConfig';
import AllActivities from '../components/report/allActivities/activitiesReport';
import ClientReports from '../components/report/clientsReport/clientsReport';
import AllActivitiesRevenue from '../components/report/allActivities/activitiesRevenueReport';
import HistoryReservation from '../components/payments/historyReport';
import HistoryAdventure from '../components/paymentsAdventure/historyReport';
import HistoryRental from '../components/paymentsRental/historyReport';
import DriverReport from '../components/report/transportReport/tableConfig';
import LeadSourcesReport from '../components/report/leadSourcesReport/tableConfig';

import "../style/home.css";

const idRole = 'report';

const Report = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setShowLoading(true);
      return;
    }

    if (user) {
      const fetchUserData = async () => {
        try {
          const userDocSnap = await getDataOneUser(user.uid);
          if (userDocSnap?.length > 0) {
            const userRole = userDocSnap[0]?.role;
            if (userRole !== roleVisit) {
              setShowLoading(false);
              setUserInfo(userDocSnap[0]);
            } else {
              localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
              window.location.href = '/home';
            }
          }
        } catch (error) {
          console.error('Error fetching user data, getDataOneUser Report');
          // Manejar el error apropiadamente, por ejemplo, mostrar un mensaje de error al usuario
        }
      };

      fetchUserData();
    } else {
      navigate("/signin");
    }
  }, [user, loading, navigate]);

  return (
    <div className="container-reports">
      {showLoading ? (
        <div style={{ textAlign: "center" }}>
          <Loading />
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Daily Revenue Reports</Accordion.Header>
                <Accordion.Body>
                  <Tabs>
                    <TabList>
                      <Tab>Transport Report</Tab>
                      <Tab>Adventure Report</Tab>
                      <Tab>Rental Report</Tab>
                      <Tab>Retail Report</Tab>
                      {/* <Tab>Adv/Trans/Rent Revenue #1</Tab>  */}
                      <Tab>Adv/Trans/Rent Revenue</Tab>
                      {/* <Tab>All Activities Revenue</Tab> */}
                    </TabList>
                    <TabPanel><Payment /></TabPanel>
                    <TabPanel><PaymentAdventure /></TabPanel>
                    <TabPanel><PaymentRental /></TabPanel>
                    <TabPanel><PaymentRetail /></TabPanel>
                    {/* <TabPanel><AllActivitiesRevenue allReport={false}></AllActivitiesRevenue></TabPanel> */}
                    <TabPanel><AllActivitiesRevenue allReport={true}></AllActivitiesRevenue></TabPanel>
                    {/* <TabPanel><AllActivitiesRevenue allReport={true}></AllActivitiesRevenue></TabPanel> */}

                  </Tabs>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Daily Departure Reports</Accordion.Header>
                <Accordion.Body>
                  <Tabs>
                    <TabList>
                      <Tab>Agents Report</Tab>
                    </TabList>
                    <TabPanel><AgentsReport /></TabPanel>
                  </Tabs>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Sales Report</Accordion.Header>
                <Accordion.Body>
                  <Tabs>
                    <TabList>
                      <Tab>Transport Report</Tab>
                      <Tab>Adventure Report</Tab>
                      <Tab>Rental Report</Tab>
                      <Tab>All Activities</Tab>
                    </TabList>
                    <TabPanel><HistoryReservation /></TabPanel>
                    <TabPanel><HistoryAdventure /></TabPanel>
                    <TabPanel><HistoryRental /></TabPanel>
                    <TabPanel><AllActivities /></TabPanel>
                  </Tabs>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>Transportation Reports</Accordion.Header>
                <Accordion.Body>
                  <Tabs>
                    <TabList>
                      <Tab>Driver Report</Tab>
                      <Tab>Lead Sources Report</Tab>
                    </TabList>
                    <TabPanel><DriverReport /></TabPanel>
                    <TabPanel><LeadSourcesReport /></TabPanel>
                  </Tabs>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>Client Reports</Accordion.Header>
                <Accordion.Body>
                  <Tabs>
                    <TabList>
                      <Tab>Client Services</Tab>
                    </TabList>
                    <TabPanel><ClientReports /></TabPanel>
                  </Tabs>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      )}
    </div>
  );
};

export default Report;
