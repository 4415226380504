import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'

function FormConfig({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [label, setLabel] = useState("");
    const [plate , setPlate ] = useState("");
    const [occupiedById , setOccupiedById ] = useState("");//Guarda el ID de la reservacion que ocupara el vehiculo
    const [startDate , setStartDate ] = useState("");
    const [endDate , setEndDate ] = useState("");
    const [startTime , setStartTime ] = useState("");
    const [endTime , setEndTime ] = useState("");

    useEffect(() => {
        if (lastData) {
            setId(lastData?.id ? lastData?.id : "");
            setLabel(lastData?.label ? lastData?.label : "");
            setPlate(lastData?.plate ? lastData?.plate : "");
            setOccupiedById(lastData?.occupiedById ? lastData?.occupiedById : "");
            setStartDate(lastData?.startDate ? lastData?.startDate : "");
            setEndDate(lastData?.endDate ? lastData?.endDate : "");
            setStartTime(lastData?.startTime ? lastData?.startTime : "");
            setEndTime(lastData?.endTime ? lastData?.endTime : "");
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    const checkData = (data) => {

        if (label !== '' && plate !== '') {
          
            action(data);
            
        } else {
            Swal.fire({
                icon: "error", //Constants = success, error, warning
                title: "Error!",
                text: "The field are required",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
              });
        }

    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formVehicle">
                        <div className="d-inline-flex">
                            <Form.Label>Vehicle name</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the vehicle name"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                </div>

                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formPlate">
                        <div className="d-inline-flex">
                            <Form.Label>Vehicle plate</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the vehicle plate"
                            value={plate}
                            onChange={(e) => setPlate(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                </div>

                <h5 className="required">All fields with (*) are required</h5>
            </div>
            
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {
                    var value = label;
                    var data = {
                        id,
                        label,
                        value,
                        plate,
                        occupiedById,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                    };

                    checkData(data);
                 
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;