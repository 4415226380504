import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc, orderBy, where, limit, serverTimestamp } from "firebase/firestore";
import { auth, db } from '../firebase';
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { server } from './serverName/Bookings';
import { numtoDate, formatDate } from './Tools';
import { addDataInvoiceAdventure, editDataInvoiceAdventure } from './invoiceAdventure';
import { ExcelRenderer } from 'react-excel-renderer';

export const fileHandler = (event, setData) => {
  let fileObj = event.target.files[0];
  //just pass the fileObj as parameter
  ExcelRenderer(fileObj, async (err, resp) => {
    var array = [];
    if (err) {
      console.log(err);
    }
    else {
      resp.rows.forEach(async (element, i) => {
        if (i !== 0 && element[0]) {

          if(element[8] !== "-"){
            var newObject = {
              adventure: {
                adultRate: element[15] ? element[15] : 0,
                label: element[13] ? element[13] : '',
                value: element[14] ? element[14] : '',
                youthRate: element[16] ? element[16] : 0,
                waterFee: true,
              },
              client: {
                label: '',
                value: '',
              },
              newClientData: element[0] ? element[0] : '',
              newClient: element[0] ? true : false,
              adultNumber: element[20] ? element[20] : 0,
              youthNumber: element[21] ? element[21] : 0,
              startDate: element[2] ? formatDate(numtoDate(element[2]), 'yyyy-MM-dd') : '',
              endDate: element[3] ? formatDate(numtoDate(element[3]), 'yyyy-MM-dd') : '',
              startTime: element[17],
              endTime: element[18],
              paid: element[6] === 0 ? true : false,
              amount: element[4] ? element[4] : 0,
              amountPaid: element[7] ? element[7] : 0,
              taxAmount: element[5] ? element[5] : 0,
              total: element[8] ? element[8] : '',
              balance: element[6] ? element[6] : 0,
              note: element[19] ? element[19] : '',
              taxes: { 
                label: 'GST',
                value: 5,
              },
              discount: { value: '', label: '' },
              discountAmount: 0,
              waterFeeAmount: 0,
            }    
            await addDataAdventures(newObject);
            array.push(newObject);
          }
        }
      });
      setData({ rows: array });
    }

    Swal.fire({
      icon: "success",
      title: "Imported",
      text: "The adventures was imported successfully",
      confirmButtonColor: '#173d38',
      confirmButtonText: "Ok",
    });
  });
}

export const getDataAdventures = async (setdata, setPending = null, showAll = false) => {

    try {
      if (auth.currentUser != null) {
        let q = null;
        let querySnapshot = null;

        if(!showAll){
          q = query(collection(db, server + "_adventures"), orderBy("startDate", "desc"));
          querySnapshot = await getDocs(q);
        }else{
          let today = new Date();
          q = query(collection(db, server + "_adventures"), where('startDate', '==', formatDate(today, "yyyy-MM-dd")));
          querySnapshot = await getDocs(q);
        }
  
        setdata({ rows: [] });
  
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });
        setdata({
          rows: array
        });
  
        if (setPending) {
          setPending(false);
        }
      }
    } catch (error) {
      console.log(error)
    }
}

export const getAdventureById = async (id, setPending = null) => {
  try {
    if (auth.currentUser != null) {
        const all = query(collection(db, server === 'bookings' ? "bookings_adventures" : "dev_adventures"), where("id", "==", id));
        const querySnapshot = await getDocs(all);
        
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });

        return array;
    }
  } catch (error) {
    console.log(error)
  }
}

export const getEventsAddAdventures = async (setdata, setPending = null) => {
  try {
 
    if (auth.currentUser != null) {
        const all = query(collection(db, server === 'bookings' ? "bookings_events_add_adventures" : "dev_events_add_adventures"), 
        orderBy("created_date", "desc"), limit(300));
        const querySnapshot = await getDocs(all);

        setdata({ rows: [] });
        
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });

        setdata({
          rows: array
        });

        if (setPending) {
          setPending(false);
        }
    }

  } catch (error) {
    console.log(error)
  }
}

export const getEventsEditAdventures = async (setdata, setPending = null) => {
  try {
 
    if (auth.currentUser != null) {
        const all = query(collection(db, server === 'bookings' ? "bookings_events_edit_adventures" : "dev_events_edit_adventures"),
        orderBy("updated_date", "desc"), limit(300));
        const querySnapshot = await getDocs(all);

        setdata({ rows: [] });
        
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });

        setdata({
          rows: array
        });

        if (setPending) {
          setPending(false);
        }
    }

  } catch (error) {
    console.log(error)
  }
}

export const getEventsDeleteAdventures = async (setdata, setPending = null) => {
  try {
 
    if (auth.currentUser != null) {
        const all = query(collection(db, server === 'bookings' ? "plan_1_delete_911_adventures" : 'dev_delete_911_adventures'),
        orderBy("deleted_date", "desc"), limit(300));

        const querySnapshot = await getDocs(all);

        setdata({ rows: [] });
        
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });

        setdata({
          rows: array
        });

        if (setPending) {
          setPending(false);
        }
    }

  } catch (error) {
    console.log(error)
  }
}

export const getAgentReportAdventure = async (setdata, setPending = null) => {
  try {

    if (auth.currentUser != null) {
        const all = query(collection(db, server === 'bookings' ? "bookings_adventures" : "dev_adventures"), 
        where("bookingType", "==", "Agent"), where("balance","==",0));
        const querySnapshot = await getDocs(all);

        setdata({ rows: [] });
        
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });

        setdata({
          rows: array
        });

        if (setPending) {
          setPending(false);
        }
    }

  } catch (error) {
    console.log(error)
  }
}

export const addDataAdventures = async (data, message = false, duplicate = false, del = false) => {
    try {
      var id = uuid();
      // let newData = {};

      const docRef = doc(
        db,
        del ? (server === 'bookings' ? "plan_1_delete_911_adventures" : 'dev_delete_911_adventures') : server + "_adventures",
        id
      );

      const userId = auth.currentUser;
      let today = new Date();
      let now = today.toLocaleString();

      if (userId !== null) {
  
        if (del) {
          data['deleted_by_id'] = userId.uid;
          data['deleted_by_email'] = userId.email;
          data['deleted_by_name'] = userId.displayName;
          data['deleted_date'] = serverTimestamp();
          data['id_event'] = data?.id ? data?.id : id;
        }else{
          data['created_by_id'] = userId.uid;
          data['created_by_email'] = userId.email;
          data['created_by_name'] = userId.displayName;
          data['created_date'] = data?.created_date ? data?.created_date : serverTimestamp();
          data['id'] = id;
        }
  
        if(!del){
          eventAddAdventures(data);
        }
  
        await setDoc(docRef, data);

        if (message) {
          Swal.fire({
            icon: "success",
            title: "Added",
            text: "The adventure was created successfully",
            confirmButtonColor: '#173d38',
            confirmButtonText: "Ok",
          });
        }
        if (duplicate) {
          Swal.fire({
            icon: "success",
            title: "Added",
            text: "The adventure was successfully doubled",
            confirmButtonColor: '#173d38',
            confirmButtonText: "Ok",
          });
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Problems with the connection to the database.",
        confirmButtonColor: '#173d38',
        confirmButtonText: "OK",
      });
    }
}

export const eventAddAdventures = async (data) => {
  try {
    var id = uuid();
    const docRef = doc(db, server + "_events_add_adventures", id );
    const userId = auth.currentUser;
    data['client_name'] = data?.client?.label ? (data?.client?.label) : (data?.newClientData + " (UNREGISTERED)");

    if (userId !== null) {
      await setDoc(docRef, data);
    }
  } catch (error) {
    console.log(error);
  }
}

// export const editDataAdventures = async (edit, invoice = null, message = false) => {
  export const editDataAdventures = async (edit, message = false, lastData = null) => {
    try {
      const docRef = doc(db, server + "_adventures", edit.id);
      const userId = auth.currentUser;
      let today = new Date();
      let now = today.toLocaleString();

      edit['updated_by_id'] = userId?.uid ? userId?.uid : "Customer-Payment";
      edit['updated_by_email'] = userId?.email ? userId?.email : "Customer-Payment";
      edit['updated_by_name'] = userId?.displayName ? userId?.displayName : "Customer-Payment"; 
      edit['updated_date'] = serverTimestamp();

      if(lastData){
        let datas = { 
          new_data: edit, 
          old_data: lastData,
          client_name: edit?.client?.label ? (edit?.client?.label) : (edit?.newClientData + " (UNREGISTERED)"),
          id_event: edit?.id,
          updated_by_id: edit['updated_by_id'],
          updated_by_email: edit['updated_by_email'],
          updated_by_name: edit['updated_by_name'],
          updated_date: edit['updated_date'],
        };

        eventEditAdventures(datas);
      }
  
      await updateDoc(docRef,
        edit
      );
      if(message){
        Swal.fire({
          icon: "success",
          title: "Edited",
          text: "The adventure was edited successfully",
          confirmButtonColor: '#173d38',
          confirmButtonText: "Ok",
        });
      }

    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Problems with the connection to the database.",
        confirmButtonColor: '#173d38',
        confirmButtonText: "OK",
      });
    }
}

export const eventEditAdventures = async (data) => {
  try {
    var id = uuid();
    const docRef = doc(db, server + "_events_edit_adventures", id );
    const userId = auth.currentUser;

    if (userId !== null) {
      await setDoc(docRef, data);
    }
  } catch (error) {
    console.log(error);
  }
}

export const deleteDataAdventures = async (element) => {
    try {
        var element_duplicated = Object.assign({}, element);
        await addDataAdventures(element_duplicated, false, false, true);

        const docRef = doc(db, server + "_adventures", element.id);
        await deleteDoc(docRef);
    } catch (error) {
        console.log(error);
    }
}