import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import img from '../assets/Horizontal_Logo.png'
import '../style/style.css'

function ModalC({ children, show, onHide, title, textButton, changeSize }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className={ changeSize ? ("mi-modal") : ("") }
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      <img className="image" src={img}></img>

        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      {(textButton ? (
        <Modal.Footer>
          <Button onClick={onHide}>{textButton}</Button>
        </Modal.Footer>
      ) : (''))}

    </Modal>
  );
}

export default ModalC;