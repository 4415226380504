import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../style/formClient.css'
import Select from 'react-select';
import { paymentTypes } from '../../services/Tools';
import { refundTypes } from '../../services/Tools';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { getCardType } from "../../services/Tools";
import {  getInvoiceByReservation } from '../../services/invoice';
import PaymentComponent, { createCheckoutSession } from '../payments/paymentForm';


function FormConfig({ title, actionPay, previewPay, data, setModalPayment }) { 

    const [id, setId] = useState("");
    const [idReservation, setIdReservation] = useState("");
    const [service, setService] = useState("");
    const [amount, setAmount] = useState("");
    const [amountPaid, setAmountPaid] = useState(0);
    const [paymentType, setPaymentType] = useState({ value: "Stripe", label: "Stripe" });
    const [refundType, setRefundType] = useState({ value: "", label: "" });
    const [revertType, setRevertType] = useState({ value: "", label: "" });
    const [taxes, setTaxes] = useState("");
    const [paid, setPaid] = useState("");
    const [taxAmount, setTaxAmount] = useState("");
    const [extraAmount, setExtraAmount] = useState("");
    const [total, setTotal] = useState("");
    const [newAmountPaid, setNewAmountPaid] = useState("");
    const [cardType, setCardType] = useState("");
    const [refund, setRefund] = useState(false);
    const [refundAmount, setRefundAmount] = useState("");
    const [revert, setRevert] = useState(false);
    const [revertAmount, setRevertAmount] = useState("");
    const [tipAmount, setTipAmount] = useState(0);
    const [tipAmountPaid, setTipAmountPaid] = useState(0);
    const [tipAmountTotalPaid, setTipAmountTotalPaid] = useState(0);
    const [vehicleType, setVehicleType] = useState([]);
    const [agents, setAgents] = useState([]);

    const [balance, setBalance] = useState("");
    const [nop, setNop] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [idClient, setIdClient] = useState("");
    const [pickUp, setPickUp] = useState("");
    const [taxAmountPaid, setTaxAmountPaid] = useState("");

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    // const [paymentBalance, setPaymentBalance] = useState([]);

    const [fuelSurcharge, setFuelSurcharge] = useState(true);
    const [fuelSurchargeAmount, setFuelSurchargeAmount] = useState(0);
    const [fuelSurchargePercentage, setFuelSurchargePercentage] = useState(9);
    const [fuelSurchargePaid, setFuelSurchargePaid] = useState(0);

    const [cardSurcharge, setCardSurcharge] = useState(true);
    const [cardSurchargeAmount, setCardSurchargeAmount] = useState(0); 
    const [cardSurchargePercentage, setCardSurchargePercentage] = useState(2.4);
    const [cardSurchargePaid, setCardSurchargePaid] = useState(0);

    const [capitalPaid, setCapitalPaid] = useState(0);
    const [extraCostPaid, setExtraCostPaid] = useState(0);
    const [extraCost, setExtraCost] = useState([]);
    const [newClient, setNewClient] = useState(false);
    const [newClientData, setNewClientData] = useState("");
    const [discount, setDiscount] = useState({ value: "", label: "" });
    const [discountAmount, setDiscountAmount] = useState(""); 

    const [searched, setSearched] = useState(false);
    const [searchedOptions, setSearchedOptions] = useState([]);

    // useEffect(() => {
    //     setCardTypeListAux(getCardType);
    // }, []);

    useEffect(() => {
        if (data) {

            setId(data?.id);
            setIdReservation(data?.id);
            setService(data?.shuttle?.label);
            setAmount(data?.amount);
            setAmountPaid(data?.amountPaid);
            setRefund(data?.refund ? data?.refund : false);
            setRefundAmount(data?.refundAmount ? data?.refundAmount : 0);
            setRefundType(data?.refundType ? data?.refundType : { value: "", label: "" });
            setRevert(data?.revert ? data?.revert : false);
            setRevertAmount(data?.revertAmount ? data?.revertAmount : 0);
            setRevertType(data?.revertType ? data?.revertType : { value: "", label: "" });
            setTaxAmountPaid(data?.taxAmountPaid ? data?.taxAmountPaid : data?.taxAmount);
            // if(data?.paymentType?.value !== null){
            //     setPaymentType({ value: data?.paymentType?.value, label: data?.paymentType?.label });
            // }
            setTaxes(data?.taxes);
            setTipAmount(data?.tipAmount ? data?.tipAmount : 0);
            setTipAmountPaid(data?.tipAmountPaid ? data?.tipAmountPaid : 0);
            setTipAmountTotalPaid(data?.tipAmountTotalPaid ? data?.tipAmountTotalPaid : 0);
            setPaid(data?.paid);
            setTaxAmount(data?.taxAmount);
            setTotal(data?.total);
            setNewClient(data?.newClient);
            setNewClientData(data?.newClientData);
            setNewAmountPaid(data?.balance);
            setBalance(data?.balance);
            setStartDate(data?.startDate);
            setEndDate(data?.endDate);
            setIdClient(data?.idClient);
            setNop(data?.nop);
            setPickUp(data?.pickUp);
            setFuelSurcharge(data?.fuelSurcharge ? data?.fuelSurcharge : false);
            setFuelSurchargeAmount(data?.fuelSurchargeAmount ? data?.fuelSurchargeAmount : 0);
            setFuelSurchargePercentage(data?.fuelSurchargePercentage ? data?.fuelSurchargePercentage : 9);
            setFuelSurchargePaid(data?.fuelSurchargePaid ? data?.fuelSurchargePaid : 0);

            setCardSurcharge(data?.cardSurcharge ? data?.cardSurcharge : false);
            setCardSurchargeAmount(data?.cardSurchargeAmount ? data?.cardSurchargeAmount : 0);
            setCardSurchargePercentage(data?.cardSurchargePercentage ? data?.cardSurchargePercentage : 2.4);
            setCardSurchargePaid(data?.cardSurchargePaid ? data?.cardSurchargePaid : 0);

            setDiscount({ value: data?.discount?.value, label: data?.discount?.label });
            setDiscountAmount(data?.discountAmount);
            setCapitalPaid(parseFloat((parseFloat(parseFloat(data?.balance) / (1 + (parseFloat(data?.taxes?.value) / 100))) / (1 + (parseFloat(data?.fuelSurchargePercentage) / 100))).toFixed(2)));

            if(data?.agents?.value){
                setAgents({ value: data?.agents?.value, label: data?.agents?.label, commission: data?.agents?.commission });
            }

            if (Array.isArray(extraCost)) {
                let dat = [...extraCost];
                let extra = 0;
                for (let i = 0; data?.extraCost?.length > i; i++) {
                    dat[i] = data?.extraCost[i];
                    extra = parseFloat(extra) + parseFloat(data?.extraCost[i]?.value);
                }
                setExtraCost(dat);
                setExtraAmount(extra);
                setExtraCostPaid(extra);
            } else {
                let dat = [];
                dat[0] = data?.extraCost[0];
                setExtraCost(dat);
                setExtraAmount(parseFloat(data?.extraCost[0]?.value));
                setExtraCostPaid(parseFloat(data?.extraCost[0]?.value));
            }

            if (Array.isArray(vehicleType)) {
                let dat = [...vehicleType];
                for (let i = 0; data?.vehicleType?.length > i; i++) {
                    dat[i] = data?.vehicleType[i];
                }
                setVehicleType(dat);
            } else {
                let dat = [];
                dat[0] = data?.vehicleType[0];
                setVehicleType(dat);
            }

            setSearched(data?.searched ? data?.searched : false);
            //Llena el campo del SEARCHED si este tiene informacion guardada
            if(data?.searched){
                setSearchedOptions({ value: data?.searchedOptions?.value, label: data?.searchedOptions?.label});
            }
            
            changeTotal(data?.balance, data?.fuelSurcharge, data?.fuelSurchargePercentage, data?.total, 
                data?.taxes, data?.extraCost, data?.tipAmount, data?.tipAmountTotalPaid, data?.cardSurcharge, data?.cardSurchargePercentage);

            // let newPaymentBalance = [];
            // for (let i = 0; data?.payment_balance_matriz?.length > i; i++) {
            //     newPaymentBalance[i] = data?.payment_balance_matriz[i];
            // }
            // setPaymentBalance(newPaymentBalance);

        }
    }, [data])

    // //Use by card Types
    // useEffect(() => {

    //     if (getCardType?.length > 0) {
    //         var aux = [];
    //         getCardType?.forEach(element => {
    //             aux.push({ value: element.value, label: element.label });
    //         });
    //         setCardTypeList(aux);
    //     }
    // }, [getCardType]);

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    const changePaymentType = (e) => {
        setPaymentType(e);
        if(e.value !== "Credit"){
            setCardType("");
        }

        if(e.value !== "Refund"){
            setRefundType({ value: "", label: "" });
        }
        
        if(e.value !== "Revert"){
            setRevertType({ value: "", label: "" });
        }
    }

    const changeTotal = (e, fuelRequired, surcharge, tot, taxe, ext, tip = 0, tipsPaid = 0, cardRequired, cardSurcharge) => {
        //surcharge = fuel surcharge
        //fuelRequired = revisa si es true o no
        //tot = total
        //taxe = taxes
        //ext = extraCost
        //e = balance

        let tax = 0;
        let preAmo = 0; //Contiene el amount, extras y fuel surcharge SIN impuesto
        let amo = 0;
        let fuel = 0;
        let card = 0;
        let capital = 0;
        let amount = e;
        let extra = 0;
        let extraPer = 0;
        let tips = parseFloat(tip);
        let tipsTotal = 0;
        let tipPer = 0; //Obtiene el porcentaje por el cual sera calculada la propina en caso que no pagen el total del monto

        if(e !== ""){
            let dif = parseFloat(tot) - parseFloat(data?.amountPaid ? data?.amountPaid : 0);
            if(parseFloat(dif) > 0){
                ext.forEach(element => {
                    if(element !== ''){
                        extra = parseFloat(extra) + (parseFloat(element?.value) * parseFloat(element?.quantity));
                    }
                });
            }

            //ENTRA A CALCULAR UN MONTO QUE SEA POSITIVO (NO REFUND)
            if(parseFloat(data?.balance) > 0){
                if(parseFloat(tip) > 0){

                    //PREGUNTA SI EL TOTAL ES DIFERENTE DEL MONTO QUE QUIERE PAGAR EL USUARIO
                    if(parseFloat(tot) !== parseFloat(e)){

                        //PREGUNTA SI EL TOTAL ES MENOR QUE EL MONTO QUE QUIERE PAGAR EL USUARIO
                        if(parseFloat(tot) < parseFloat(e)){

                            let difBalance = parseFloat(e) - parseFloat(data?.amountPaid);

                            tips = (parseFloat(e) + parseFloat(
                                difBalance < data?.balance ? data?.amountPaid : 0
                            )) - parseFloat(tot); 

                            amount = parseFloat(((parseFloat(e)) - (parseFloat(tips))).toFixed(2));
                            tipsTotal = parseFloat (tips) + parseFloat(tipsPaid);

                        //SI EL TOTAL ES MAYOR AL MONTO QUE QUIERE PAGAR EL USUARIO ENTRA AQUI
                        }else if(parseFloat(tot) < parseFloat(data?.amountPaid)){

                            tips = parseFloat(e); 
                            tipsTotal = parseFloat (tips) + parseFloat(tipsPaid);

                        }else if(parseFloat(dif) > 0){ 

                            tips = parseFloat(e) > parseFloat(dif) ? (parseFloat(e) - parseFloat(dif)) : 0; 
                            amount = parseFloat(((parseFloat(e)) - (parseFloat(tips))).toFixed(2));
                            tipsTotal = parseFloat (tips) + parseFloat(tipsPaid);

                        }



                        // if(parseFloat(tip) !== parseFloat(tipsPaid)){
                        //     //CALCULA EL PORCENTAJE ESPECIFICO A PAGAR DE TIP
                        //     tipPer = parseFloat((parseFloat(e) / parseFloat(data?.balance)));

                        //     amount = parseFloat((
                        //         (parseFloat(e)) - (
                        //             (
                        //                 (parseFloat(tip) - parseFloat(tipsPaid)) * parseFloat(tipPer) 
                        //             )
                        //         )).toFixed(2));

                        //     tips = ((parseFloat(tip) - parseFloat(tipsPaid)) * parseFloat(tipPer));
                        //     tipsTotal = ((parseFloat(tip) - parseFloat(tipsPaid)) * parseFloat(tipPer)) + parseFloat(tipsPaid);
                        
                        // }else{
                        //     amount = parseFloat(((parseFloat(e)) - (parseFloat(tip) - parseFloat(tipsPaid))).toFixed(2));
                        //     tips = (parseFloat (tip) - parseFloat(tipsPaid));
                        //     tipsTotal = (parseFloat (tip) - parseFloat(tipsPaid)) + parseFloat(tipsPaid);
                        // }

                    }else{
                        amount = parseFloat(((parseFloat(e)) - (parseFloat(tip) - parseFloat(tipsPaid))).toFixed(2));
                        tips = (parseFloat (tip) - parseFloat(tipsPaid));
                        tipsTotal = (parseFloat (tip) - parseFloat(tipsPaid)) + parseFloat(tipsPaid);
                    }
                   
                }

                if(parseFloat(amount) > 0 ){

                    if(parseFloat(data?.amountPaid) < parseFloat(tot)){
                        if(taxe?.value !== ''){

                            preAmo = (parseFloat(tot) > parseFloat(amount) ? parseFloat(amount) : parseFloat(tot)) / (1 + (parseFloat(taxe?.value) / 100));
                            extraPer = parseFloat(extra) > 0 ?  parseFloat(extra) / (parseFloat(tot) / (1 + (parseFloat(taxe?.value) / 100))) : 0;
                            extra = parseFloat (preAmo) * parseFloat(extraPer);
                            

                            if(cardRequired){
                                if(data?.cardSurchargeAmount && parseFloat(data?.cardSurchargeAmount) > 0){

                                    let dif = ((parseFloat(preAmo) - parseFloat(extra)) / (1 + ((parseFloat(fuelRequired ? surcharge : 0) + parseFloat(cardSurcharge)) / 100))).toFixed(2);

                                    //SE OBTIENE EL PORCENTAJE POR EL CUAL SE VA CALCULAR EL MONTO DEL CARD -- El porcentaje que se obtiene el de sumar el fuel y card surcharge 
                                    let per =  (parseFloat(data?.cardSurchargeAmount ? data?.cardSurchargeAmount : 0) /
                                    (parseFloat(data?.fuelSurchargeAmount ? data?.fuelSurchargeAmount : 0) + 
                                    parseFloat(data?.cardSurchargeAmount ? data?.cardSurchargeAmount : 0)) * 100).toFixed(2);

                                    card = ((parseFloat(preAmo) - parseFloat(extra)) - parseFloat(dif));
                                    card = card * parseFloat(parseFloat(per) / 100);
                                    
                                    fuel = ((parseFloat(preAmo) - parseFloat(extra) ) - parseFloat(dif)) - parseFloat(card);
                                    amo = parseFloat(dif).toFixed(2);
                                   
                                    // Redondeo del dato segun el valor de este
                                    card = (card % 1 >= 0.99) ? Math.ceil(card) : (card % 1 <= 0.01) ? Math.floor(card) : parseFloat(card.toFixed(2));
                                    fuel = (fuel % 1 >= 0.99) ? Math.ceil(fuel) : (fuel % 1 <= 0.01) ? Math.floor(fuel) : parseFloat(fuel.toFixed(2));
                                  
                                }
                            }else{

                                if(fuelRequired){
                                    amo = ((parseFloat(preAmo) - parseFloat(extra)) / (1 + (parseFloat(surcharge) / 100))).toFixed(2);
                                    fuel = (parseFloat(preAmo) - parseFloat(extra)) - parseFloat(amo);
                                }else{
                                    amo = (parseFloat(preAmo) - parseFloat(extra)).toFixed(2);
                                }
                            }

            
                            tax = (parseFloat(tot) > parseFloat(amount) ? parseFloat(amount) : parseFloat(tot)) - parseFloat(preAmo);
                            capital = parseFloat(amo);// - parseFloat(extra);

                        }
                    }
                    
                }

            }
            else{
                
                //AL ELSE ENTRA A CALCULAR UN MONTO QUE SEA NEGATIVO (UN REFUND O REVERT)
                if(taxe?.value !== ''){
                    if(parseFloat(tipsPaid) > 0){
                        //e = parseFloat(e) + parseFloat(tipsPaid);
                        tips = parseFloat(-tipsPaid);
                    }
                    // extraPer = parseFloat(extra) > 0 ?  parseFloat(extra) / (parseFloat(tot) / (1 + (parseFloat(taxe?.value) / 100))) : 0;

                    //En preAmo queda el amount, el fuel surcharge y el card surcharge
                    preAmo = (parseFloat(tot) > parseFloat(e) ? (parseFloat(e) + (data?.tipAmountTotalPaid ? parseFloat(data?.tipAmountTotalPaid)  : 0)) : parseFloat(tot)) / (1 + (parseFloat(taxe?.value) / 100));
                    // extra = parseFloat (preAmo) * parseFloat(extraPer);


                    ////AQUI ENTRA A CALCULAR EL MONTO PARA CUANDO SEA TRUE EL CARD SURCHARGE
                    // if(cardRequired){
                    //     if(data?.cardSurcharge){

                    //         let dif = ((parseFloat(preAmo)) / (1 + ((parseFloat(surcharge) + parseFloat(cardSurcharge)) / 100))).toFixed(2);
                    //         amo = parseFloat(dif);
                    //         //EL 21.05% ES EL PORCENTAJE DEL SUMAR EL FUEL SURCHARGE Y EL CARD SURCHARGE, AL SUMAR AMBOS MONTOS, EL 21.05% EQUIVALE A FUEL SURCHARGE 
                    //         card = (parseFloat(preAmo - dif));
                    //         card = card * parseFloat(parseFloat(21.05) / 100);
                    //         fuel = parseFloat(preAmo - dif) - parseFloat(card);

                    //         card = (card % 1 <= -0.99) ? Math.floor(card) : (card % 1 >= -0.01) ? Math.ceil(card) : parseFloat(card.toFixed(2));
                    //         fuel = (fuel % 1 <= -0.99) ? Math.floor(fuel) : (fuel % 1 >= -0.01) ? Math.ceil(fuel) : parseFloat(fuel.toFixed(2));

                    //     }
                    // }else{

                        // if(fuelRequired){

                        //     amo = ((parseFloat(preAmo) - parseFloat(extra)) / (1 + (parseFloat(surcharge) / 100))).toFixed(2);
                        //     fuel = (parseFloat(preAmo) - parseFloat(extra)) - parseFloat(amo);
                            
                        // }else{
                        //     amo = (parseFloat(preAmo) - parseFloat(extra)).toFixed(2);
                        // }
                    // }


                   

                    // if(cardRequired){
                    //     amo = ((parseFloat(preAmo) - parseFloat(extra)) / (1 + (parseFloat(cardSurcharge) / 100))).toFixed(2);
                    //     card = (parseFloat(preAmo) - parseFloat(extra)) - parseFloat(amo);
                    // }else{
                    //     amo = (parseFloat(preAmo) - parseFloat(extra)).toFixed(2);
                    // }
    
                    //tax = (parseFloat(tot) > parseFloat(e) ? parseFloat(e) : parseFloat(tot)) - parseFloat(preAmo);
                    tax = (parseFloat(tot) > parseFloat(e) ? (parseFloat(e) + parseFloat(tipsPaid)) : parseFloat(tot)) - parseFloat(preAmo);
                    capital = parseFloat(preAmo);// - parseFloat(extra);
                }
                
            }

            setNewAmountPaid(parseFloat(e));
            setTipAmountPaid(parseFloat(tips.toFixed(2)));
            setTipAmountTotalPaid(parseFloat(tipsTotal.toFixed(2)));
            setExtraCostPaid(parseFloat(extra));
            setTaxAmountPaid(parseFloat(tax.toFixed(2)));
            setCapitalPaid(parseFloat(capital.toFixed(2)));
            setFuelSurchargePaid(parseFloat(fuel.toFixed(2)));
            setCardSurchargePaid(parseFloat(card.toFixed(2)));

        }else{
            setNewAmountPaid(e);
            setTaxAmountPaid(0);
        }
    }

    const checkPayment = (newData, copy_link = false) => {

        if ((newData?.newAmountPaid ? parseFloat(newData.newAmountPaid) : 0) !== 0) {

            if(newData?.paymentType?.value === "Refund"){
                if(newData?.refundType?.value === ""){
                    Swal.fire({
                        icon: "error", //Constants = success, error, warning
                        title: "Error!",
                        text: "You need to choose an option for 'Refund via'",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                    return;
                }
            }

            if(newData?.paymentType?.value === "Revert"){
                if(newData?.revertType?.value === ""){
                    Swal.fire({
                        icon: "error", //Constants = success, error, warning
                        title: "Error!",
                        text: "You need to choose an option for 'Revert via'",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                    return;
                }
            }

            let newAmountPaid = parseFloat(newData?.newAmountPaid);
            let newPaid = parseFloat(amountPaid) + parseFloat(newAmountPaid);
            let newBalance = ((parseFloat(newData?.balance) + parseFloat(amountPaid)) - parseFloat(newPaid)).toFixed(2);

            let message1 = newData?.paymentType?.value == "Revert" ? ("You are trying to make a reversal, but the amount entered is greater than the balance.") :
                            newData?.paymentType?.value == "Refund" ? ("You are trying to make a refund, but the amount entered is greater than the balance.") : 
                                                                        ("Please select whether you would like to make a refund or reversal.");

            let message2 = newData?.paymentType?.value == "Revert" ? ("Do you want to do a reversal? This action can not be undone") :
                            newData?.paymentType?.value == "Refund" ? ("Do you want to do a refund? This action can not be undone") : 
                                                                        ("Please select whether you would like to make a refund or reversal.");

            if(parseFloat(newData?.balance) < 0){
                if(parseFloat(newBalance) > 0){
                    Swal.fire({
                        icon: "error", //Constants = success, error, warning
                        title: "Error!",
                        text: message1,
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                }else{
                    if(newData?.paymentType.value === "Stripe"){
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "We're sorry, but currently the system doesn't allow reversals or refunds through Stripe",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                        });
                    }else{
                        if(parseFloat(newAmountPaid) > 0){
                            Swal.fire({
                                icon: "error", //Constants = success, error, warning
                                title: "Error!",
                                text: "We're sorry, but to make a reversals or refunds the payment has to be negative.",
                                confirmButtonColor: '#173d38',
                                confirmButtonText: "OK",
                            });
                        }else{
                            if(newData?.paymentType?.value === "Refund"){
                                Swal.fire({
                                    title: 'Are you sure?',
                                    text: message2,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#173d38',
                                    confirmButtonText: 'Confirm',
                                    cancelButtonColor: '#dc3545',
                                    cancelButtonText: 'Cancel',
                                    }).then((result) => {
                                    if (result.isConfirmed) {
                                        newData['balance'] = parseFloat(newBalance);
                                        newData['amountPaid'] = parseFloat(newPaid);
                                        if(parseFloat(newBalance) === 0){
                                            newData['paid'] = true;
                                        }else{
                                            newData['paid'] = false;
                                        }
                                        actionPay(newData, copy_link);
                                    }
                                });

                            }else if(newData?.paymentType?.value === "Revert"){
                                Swal.fire({
                                    title: 'Are you sure?',
                                    text: message2,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#173d38',
                                    confirmButtonText: 'Confirm',
                                    cancelButtonColor: '#dc3545',
                                    cancelButtonText: 'Cancel',
                                    }).then((result) => {
                                    if (result.isConfirmed) {
                                        newData['balance'] = parseFloat(newBalance);
                                        newData['amountPaid'] = parseFloat(newPaid);
                                        if(parseFloat(newBalance) === 0){
                                            newData['paid'] = true;
                                        }else{
                                            newData['paid'] = false;
                                        }
                                        actionPay(newData, copy_link);
                                    }
                                });
                            }
                            else{
                                Swal.fire({
                                    icon: "error", //Constants = success, error, warning
                                    title: "Error!",
                                    text: "Please select whether you would like to make a refund or reversal.",
                                    confirmButtonColor: '#173d38',
                                    confirmButtonText: "OK",
                                });
                            }
                        }
                    }
                }
            }else{
                if(parseFloat(newBalance) < 0){
                    // Swal.fire({
                    //     icon: "error", //Constants = success, error, warning
                    //     title: "Error!",
                    //     text: "Payment cannot be more than balance, balance is "+ newData?.balance,
                    //     confirmButtonColor: '#173d38',
                    //     confirmButtonText: "OK",
                    // });
                    Swal.fire({
                        title: 'Are you sure?',
                        // parseFloat(-newBalance) : parseFloat(-newBalance),
                        text: parseFloat(tipAmount) > 0 ? 
                        "Do you want to pay with a total tip of $" + (tipAmountPaid).toFixed(2)+ " ? This action can not be undone" :
                        "Do you want to pay with a $" + (parseFloat(-newBalance))+ " tip? This action can not be undone",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#173d38',
                        confirmButtonText: 'Confirm',
                        cancelButtonColor: '#dc3545',
                        cancelButtonText: 'Cancel',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            newData['balance'] = 0;
                            newData['amountPaid'] = parseFloat(newPaid);
                            newData['paid'] = false;
                            actionPay(newData, copy_link);
                        }
                    });
                }else{
                    if(newAmountPaid < 0.50 && newData?.paymentType.value === "Stripe"){
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "Sorry, Stripe does not allow payments under $0.50, and you are trying to pay $"+ newAmountPaid,
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                        });
                    }else{
                        Swal.fire({
                            title: 'Are you sure?',
                            text: 'This action can not be undone',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#173d38',
                            confirmButtonText: 'Confirm',
                            cancelButtonColor: '#dc3545',
                            cancelButtonText: 'Cancel',
                            }).then((result) => {
                            if (result.isConfirmed) {
                                newData['balance'] = parseFloat(newBalance);
                                newData['amountPaid'] = parseFloat(newPaid);
                                if(parseFloat(newBalance) === 0){
                                    newData['paid'] = true;
                                }else{
                                    newData['paid'] = false;
                                }
                                actionPay(newData, copy_link);
                            }
                        });
                    }
                }
            }
            // }
        } else {
            Swal.fire({
                icon: "error", //Constants = success, error, warning
                title: "Error!",
                text: "Please add a quantity other than 0",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
        }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <div>
                        <Form.Group className="mb-3">
                            <div className="d-inline-flex">
                                <Form.Label>Payment Type</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Select placeholder={paymentTypes[0].value} options={paymentTypes} value={paymentType}
                                onChange={(e) => changePaymentType(e)} />
                        </Form.Group>  
                    </div>

                    { paymentType.value === "Refund" ? (
                    <> 
                    <div>
                        <Form.Group className="mb-3">
                            <div className="d-inline-flex">
                                <Form.Label>Refund via</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Select 
                                placeholder={refundTypes[0].value} 
                                options={refundTypes} 
                                value={refundType}
                                onChange={(e) => setRefundType(e)} 
                                />
                        </Form.Group>
                    </div>
                    </>
                    ) : ""}

                    { paymentType.value === "Revert" ? (
                    <> 
                    <div>
                        <Form.Group className="mb-3">
                            <div className="d-inline-flex">
                                <Form.Label>Revert via</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Select 
                                placeholder={refundTypes[0].value} 
                                options={refundTypes} 
                                value={revertType}
                                onChange={(e) => setRevertType(e)} 
                                />
                        </Form.Group>
                    </div>
                    </>
                    ) : ""}
                </div>

                <div className='col-md-6'>
                    <div>
                        <Form.Group  className="mb-3">
                            <div className="d-block" style={{ width: "100%" }}>
                                <div style={{ width: "100%" }}>
                                    <div className="d-inline-flex">
                                        <Form.Label>Total to pay </Form.Label>
                                        <h5 className="required">*</h5>
                                    </div>
                                </div>

                                <div className="d-inline-flex" style={{ width: "100%" }}>
                                    <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>$</strong></Form.Label>
                                    <Form.Control type="number" placeholder="0"
                                        value={newAmountPaid}
                                        onChange={(e) => changeTotal( 
                                            e.target.value, fuelSurcharge, 
                                            fuelSurchargePercentage, total, 
                                            taxes, extraCost, data?.tipAmount, 
                                            data?.tipAmountTotalPaid, data?.cardSurcharge,
                                            data?.cardSurchargePercentage )}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <h5 className="required">The field with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModalPayment(false) }}>Cancel</Button>

                <Button style={{ background: '#173d38' }} className='button-save' onClick={() => {
                    var newData = {
                        id,
                        idReservation,
                        service,
                        amount,
                        paymentType,
                        refundType,
                        revertType,
                        taxes,
                        paid,
                        refund,
                        refundAmount,
                        revert,
                        revertAmount,
                        tipAmount,
                        tipAmountPaid,
                        tipAmountTotalPaid,
                        taxAmount,
                        extraAmount,
                        taxAmountPaid,
                        vehicleType,
                        total,
                        newAmountPaid,
                        balance,
                        nop,
                        startDate,
                        endDate,
                        idClient,
                        pickUp,
                        fuelSurcharge,
                        fuelSurchargeAmount,
                        fuelSurchargePercentage,
                        fuelSurchargePaid,
                        cardSurcharge, 
                        cardSurchargeAmount, 
                        cardSurchargePercentage, 
                        cardSurchargePaid,
                        cardType,
                        capitalPaid,
                        newClient,
                        newClientData,
                        discount,
                        discountAmount,
                        extraCostPaid,
                        agents,
                        searched,
                        searchedOptions,
                    };
                    checkPayment(newData);
                }
                }>
                    Make Payment
                </Button>

                { paymentType?.label === "Stripe" ? (

                <Button style={{ background: '#173d38' }} className='button-save' onClick={() => {
                    var newData = {
                        id,
                        idReservation,
                        service,
                        amount,
                        paymentType,
                        refundType,
                        revertType,
                        taxes,
                        paid,
                        refund,
                        refundAmount,
                        revert,
                        revertAmount,
                        tipAmount,
                        tipAmountPaid,
                        tipAmountTotalPaid,
                        taxAmount,
                        extraAmount,
                        taxAmountPaid,
                        total,
                        newAmountPaid,
                        balance,
                        nop,
                        startDate,
                        endDate,
                        idClient,
                        pickUp,
                        fuelSurcharge,
                        fuelSurchargeAmount,
                        fuelSurchargePercentage,
                        fuelSurchargePaid,
                        cardSurcharge, 
                        cardSurchargeAmount, 
                        cardSurchargePercentage, 
                        cardSurchargePaid,
                        capitalPaid,
                        newClient,
                        newClientData,
                        discount,
                        discountAmount,
                        extraCostPaid,
                        agents,
                        searched,
                        searchedOptions,
                    };
                    checkPayment(newData, true);
                }
                }>
                    Copy link
                </Button>
                ) : ("") }
            </div>
        </form>
    );
}

export default FormConfig;