import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'
import {  rentalByItems  } from '../../../services/Tools';
import Select from 'react-select';

function FormConfig({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [rental, setRental] = useState("");
    const [stock, setStock] = useState("");
    const [rentalBy, setRentalBy] = useState({ value: '', label: '' });
    const [priceByHour, setPriceByHour] = useState("");
    const [priceByDay, setPriceByDay] = useState("");
    const [color, setColor] = useState("");

    useEffect(() => {
        if (lastData) {
            setId(lastData?.id);
            setRental(lastData?.rental);
            setStock(lastData?.stock);
            //setRentalBy(lastData.rentalBy);
            // setRentalBy({ value: lastData?.rentalBy?.value, label: lastData?.rentalBy?.label });
            setPriceByHour(lastData?.priceByHour);
            setPriceByDay(lastData?.priceByDay);
            setColor(lastData?.color);
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formRentalItem">
                        <div className="d-inline-flex">
                            <Form.Label>Rental Item</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Example: Kayat"
                            value={rental}
                            onChange={(e) => setRental(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formStock">
                        <div className="d-inline-flex">
                            <Form.Label>Quantity in stock</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="number" placeholder="0"
                            value={stock}
                            onChange={(e) => setStock(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    
                    <Form.Group className="mb-3" controlId="formColor">
                        <div className="d-inline-flex">
                            <Form.Label>Identifier color</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="color"
                            value={color}
                            onChange={(e) => lastData?.color ? ("") : (setColor(e.target.value))}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>
                </div>

                <div className='col-md-6'>
                    {/* <Form.Group className="mb-3" controlId="formRentalBy">
                        <div className="d-inline-flex">
                            <Form.Label>Rental by</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select options={rentalByItems} value={rentalBy}
                            onChange={(e) => setRentalBy(e)} />
                    </Form.Group> */}

                    <Form.Group className="mb-3" controlId="formPriceByDay">
                        <div className="d-inline-flex">
                            <Form.Label>Price by day</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-flex">
                            <div style={{ padding: 5 }}>
                                <p>$</p>
                            </div>
                            <div>
                                <Form.Control type="number" placeholder="0"
                                    value={priceByDay}
                                    onChange={(e) => setPriceByDay(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                ></Form.Control>
                            </div>
                           
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPriceByHour">
                        <div className="d-inline-flex">
                            <Form.Label>Price by 3 hours</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-flex">
                            <div style={{ padding: 5 }}>
                                <p>$</p>
                            </div>
                            <div>
                                <Form.Control type="number" placeholder="0"
                                    value={priceByHour}
                                    onChange={(e) => setPriceByHour(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                ></Form.Control>
                            </div>
                           
                        </div>
                    </Form.Group>

                </div>
                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {
                    var data = {
                        id,
                        rental,
                        stock,
                        // rentalBy,
                        priceByDay,
                        priceByHour,
                        color,
                    };

                    if (rental !== '' && stock !== '' && priceByDay !== '' && priceByHour !== '') {
                        action(data);
                    } else {
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "All fields with (*) are required",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                          });
                    }
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;