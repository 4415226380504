import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc, getCountFromServer, where } from "firebase/firestore";
import { auth, db } from '../firebase'
import { server } from './serverName/Bookings';

export const getAdventureNum = async (setdata, date = []) => {
  try {
    if (auth.currentUser != null) {

      if (date && date.length > 1) {
        let date1 = getDate(new Date(date[0]));
        let date2 = getDate(new Date(date[1]));
      
        const q = query(
          collection(db, server + "_adventures"),
          where('startDate', '>=', date1),
          where('startDate', '<=', date2),
        );
        // const querySnapshot = await getCountFromServer(q);
        const querySnapshot = await getDocs(q);
        
        var array = [];
        var num = 0;
        querySnapshot.forEach((doc) => {
          if(doc.data().refund){
            if(doc.data().balance !== 0){
              const data = doc.data();
              array.push(data);
              num++;
            }
          }else{
            const data = doc.data();
            array.push(data);
            num++;
          }
          
        });
    
        //setdata(querySnapshot.data().count);
        setdata(num);
        
      } else {
        const q = query(collection(db, server + "_adventures"));
        // const querySnapshot = await getCountFromServer(q);
        const querySnapshot = await getDocs(q);
        
        var array = [];
        var num = 0;
        querySnapshot.forEach((doc) => {
          if(doc.data().refund){
            if(doc.data().balance !== 0){
              const data = doc.data();
              array.push(data);
              num++;
            }
          }else{
            const data = doc.data();
            array.push(data);
            num++;
          }
          
        });
        
        //setdata(querySnapshot.data().count);
        setdata(num);
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const getRentalsNum = async (setdata, date = []) => {
  try {
    if (auth.currentUser != null) {
      if (date && date.length > 1) {
        let date1 = getDate(new Date(date[0]));
        let date2 = getDate(new Date(date[1]));
      
        const q = query(
          collection(db, server + "_rentals"),
          where('pickupDate', '>=', date1),
          where('pickupDate', '<=', date2),
        );

        // const querySnapshot = await getCountFromServer(q);
        // setdata(querySnapshot.data().count);
        const querySnapshot = await getDocs(q);
        
        var array = [];
        var num = 0;
        querySnapshot.forEach((doc) => {
          if(doc.data().refund){
            if(doc.data().balance !== 0){
              const data = doc.data();
              array.push(data);
              num++;
            }
          }else{
            const data = doc.data();
            array.push(data);
            num++;
          }
          
        });
        setdata(num);

      } else {
        const q = query(collection(db, server + "_rentals"));
        // const querySnapshot = await getCountFromServer(q);
        const querySnapshot = await getDocs(q);

        var array = [];
        var num = 0;
        querySnapshot.forEach((doc) => {
          if(doc.data().refund){
            if(doc.data().balance !== 0){
              const data = doc.data();
              array.push(data);
              num++;
            }
          }else{
            const data = doc.data();
            array.push(data);
            num++;
          }
          
        });  
        // setdata(querySnapshot.data().count);
        setdata(num);

      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const getReservationsNum = async (setdata, date = []) => {
  try {
    if (auth.currentUser != null) {
      if (date && date.length > 1) {
        let date1 = getDate(new Date(date[0]));
        let date2 = getDate(new Date(date[1]));
      
        const q = query(
          collection(db, server + "_reservations"),
          where('startDate', '>=', date1),
          where('startDate', '<=', date2),
        );
        // const querySnapshot = await getCountFromServer(q);
        // setdata(querySnapshot.data().count);
        const querySnapshot = await getDocs(q);
        
        var array = [];
        var num = 0;
        querySnapshot.forEach((doc) => {
          if(doc.data().refund){
            if(doc.data().balance !== 0){
              const data = doc.data();
              array.push(data);
              num++;
            }
          }else{
            const data = doc.data();
            array.push(data);
            num++;
          }
          
        });
        setdata(num);


      } else {
        const q = query(collection(db, server + "_reservations"));
        // const querySnapshot = await getCountFromServer(q);
        const querySnapshot = await getDocs(q);

        var array = [];
        var num = 0;
        querySnapshot.forEach((doc) => {
          if(doc.data().refund){
            if(doc.data().balance !== 0){
              const data = doc.data();
              array.push(data);
              num++;
            }
          }else{
            const data = doc.data();
            array.push(data);
            num++;
          }
          
        });  
  
        // setdata(querySnapshot.data().count);
        setdata(num);

      }
    }
  } catch (error) {
    console.log(error)
  }
}

const getDate = (date) => {
  let day = `${(date.getDate())}`.padStart(2,'0');
  let month = `${(date.getMonth()+1)}`.padStart(2,'0');
  let year = date.getFullYear();
  //2022-11-11
  return (`${year}-${month}-${day}`)
}
