import React from "react";
import { CSVLink } from "react-csv";

const ExportCSV = ({ data, filename, headers, totals, agents = false, transport = false, rental = false, adventure = false, retails = false, allActivities = false, sales = false }) => {

  let inside = false;

  const csvData = data?.map(item => {
    const obj = {};
    headers?.forEach((header, i) => {
      obj[header] = item[i];
    });

    if(!inside){
      if(!agents){

        obj["Amounts"] = totals?.[0];

        if(allActivities){

            obj["Fees"] = totals?.[1];
            obj["Fuel surcharge"] = totals?.[4];
            obj["Discounts"] = totals?.[6];
            obj["Taxes"] = totals?.[2];
            obj["Tips"] = totals?.[5];
            obj["Totals"] = totals?.[3];

        }else{ 

          if(transport){
            obj["Fuel surcharge"] = totals?.[1];   
            obj["Discounts"] = totals?.[5];       
            obj["Taxes"] = totals?.[2];
            obj["Tips"] = totals?.[4];
            obj["Totals"] = totals?.[3];

          }else if(rental){

            obj["Discounts"] = totals?.[4];       
            obj["Taxes"] = totals?.[1];
            obj["Tips"] = totals?.[3];
            obj["Totals"] = totals?.[2];

          }else if (adventure){

            obj["Fees"] = totals?.[1];
            obj["Discounts"] = totals?.[5];
            obj["Taxes"] = totals?.[2];
            obj["Tips"] = totals?.[4];
            obj["Totals"] = totals?.[3];

          }else if(retails){

            obj["Discounts"] = totals?.[4];       
            obj["Taxes"] = totals?.[1];
            obj["Tips"] = totals?.[3];
            obj["Totals"] = totals?.[2];

          }else if(sales){
            obj["Water fees"] = totals?.[5];   
            obj["Fuel surcharge"] = totals?.[1];   
            obj["Discounts"] = totals?.[4];       
            obj["Taxes"] = totals?.[2];
            obj["Totals"] = totals?.[3];
          }

        }
  

      }else{
        obj["Total Amount"] = totals?.[0];
        obj["Total to pay"] = totals?.[1];
      }
      inside = true;
    }
      return obj;
  });
  
  return (
    <CSVLink
      data={csvData || []}
      filename={filename}
      className="color-green btn btn-outline-secondary p-2 mx-2"
      target="_blank"
    >
      Export CSV
    </CSVLink>
  );
};

export default ExportCSV;