import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsAllActivitiesRevenueConfig, columnsAllActivitiesRevenueConfigRevenue, columnsAllActivitiesRevenueConfig2 } from '../../../services/columnsList';
// import FormConfig from './formConfig';
// import Swal from "sweetalert2";
import { getDataPaymentsAdventures, getDataPaymentsJoinAdventures } from '../../../services/paymentAdventure';
import { getDataPayments, getDataPaymentsJoinTransport  } from '../../../services/payment';
import { getDataPaymentsRentals, getDataPaymentsJoinRentals } from '../../../services/paymentRental';
import { getDataPaymentsRetails, getDataPaymentsJoinRetails } from '../../../services/paymentRetail';
import { UserAuth } from '../../../context/AuthContext';
import { Input } from 'antd';
import '../../../style/table.css'
import ExportPDF from "../../../services/exports/exportPDF";
import ExportCSVAllActivities from "../../../services/exports/exportCSVAllActivities";
import ExportCSVRevenue from "../../../services/exports/exportCSVRevenue";
import ExportCSVRevenue2 from "../../../services/exports/exportCSVRevenue2";
import { transformedData } from '../../../services/exports/transformedData';
import { DateRangePicker } from "rsuite";
import { Table } from 'react-bootstrap';

function TableConfig({ allReport = false }) {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState([]);
  const [dataReservation, setDataReservation] = useState({ rows: [] });
  const [dataAdventure, setDataAdventure] = useState({ rows: [] });
  const [dataRental, setDataRental] = useState({ rows: [] });
  const [dataRetail, setDataRetail] = useState({ rows: [] });
  const [selects, setSelects] = useState([]);
  // const [expand, setExpand] = useState(true); 
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();
  const [amount, setAmount] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [tipAmount, setTipAmount] = useState("");
  const [total, setTotal] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsDate, setFilteredItemsDate] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [waterFeePaid, setWaterFeePaid] = useState(0);
  const [surcharge, setSurcharge] = useState("");
  const [discount, setDiscount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (user != null) {
      // getDataPayments(setDataReservation, setPending);
      getDataPaymentsJoinTransport(setDataReservation, setPending);
      // getDataPaymentsAdventures(setDataAdventure, setPending, false);
      getDataPaymentsJoinAdventures(setDataAdventure, setPending, false);
      // getDataPaymentsRentals(setDataRental, setPending, false);
      getDataPaymentsJoinRentals(setDataRental, setPending);
      //getDataPaymentsJoinRetails(setDataRetail, setPending);
    }
  }, [user]);

  useEffect(() => {
    if (dataReservation?.rows?.length !== 0) {
      // setData(dataAdventure.rows.concat(dataRental.rows));
      if(allReport){
        // setData(dataAdventure.rows.concat(dataReservation.rows).concat(dataRental.rows).concat(dataRetail.rows));
        setData(dataAdventure.rows.concat(dataReservation.rows).concat(dataRental.rows));
      }else{
        setData(dataAdventure.rows.concat(dataReservation.rows).concat(dataRental.rows));
      }
    }
  }, [dataReservation, dataAdventure, dataRental/*, dataRetail*/]);

  useEffect(() => {
    if (data != null) {

    let newData = [];
    let amountTotal = 0;
    let feeTotal = 0;
    let taxTotal = 0;
    let tipTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalTotal = 0;

    if(data){

      data?.forEach(element => {
        amountTotal = element?.capitalPaid ? (parseFloat(amountTotal) + parseFloat(element?.capitalPaid) + 
                      (element?.extraCostPaid ? parseFloat(element?.extraCostPaid) : 0)) : parseFloat(amountTotal);
        
        feeTotal = element?.waterFeePaid ? parseFloat(feeTotal) + parseFloat(element?.waterFeePaid) : parseFloat(feeTotal);
        surchargeTotal = element?.fuelSurchargePaid ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargePaid) : parseFloat(surchargeTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        taxTotal = element?.taxAmountPaid ? parseFloat(taxTotal) + parseFloat(element?.taxAmountPaid) : parseFloat(taxTotal);
        tipTotal = element?.tipAmountPaid ? parseFloat(tipTotal) + parseFloat(element?.tipAmountPaid) : parseFloat(tipTotal);
        totalTotal = element?.total ? parseFloat(totalTotal) + parseFloat(element?.total) : parseFloat(totalTotal);
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      fee: feeTotal.toFixed(2),
      surcharge: surchargeTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      tip: tipTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(parseFloat(amountTotal.toFixed(2)));
    setWaterFeePaid(parseFloat(feeTotal.toFixed(2)));
    setSurcharge(parseFloat(surchargeTotal.toFixed(2)));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setTaxAmount(parseFloat(taxTotal.toFixed(2)));
    setTipAmount(parseFloat(tipTotal.toFixed(2)));
    setTotal(parseFloat(totalTotal.toFixed(2)));
    changeFilter("");

    }
  }, [data]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const changeFilter = (e) => { 

    let newFilter = [];
    if(data?.length > 0){
      newFilter = data?.filter(
        item => item?.id && item?.id.toLowerCase().includes(e.toLowerCase()) 
        || item?.adventure?.label ? ( "Adventure".toLowerCase().includes(e.toLowerCase()) ) : 
        ( item?.service ? ( "Transport".toLowerCase().includes(e.toLowerCase()) ) : 
        ( item?.rental?.length > 0 ? ( "Rental".toLowerCase().includes(e.toLowerCase()) ) :
        ( "Retail".toLowerCase().includes(e.toLowerCase()))))

        || item?.client?.label && item?.client?.label.toLowerCase().includes(e.toLowerCase())
        || item?.agents?.label && item?.agents?.label.toLowerCase().includes(e.toLowerCase())
        || item?.newClientData && item?.newClientData.toLowerCase().includes(e.toLowerCase())
        || item?.paymentType?.label && item?.paymentType?.label.toLowerCase().includes(e.toLowerCase()) 
        // || item?.startDate && item?.startDate.toLowerCase().includes(e.toLowerCase()) 
        || item?.service && item?.service.toLowerCase().includes(e.toLowerCase()) 
        || item?.rental?.[0].label && item?.rental?.[0].label.toLowerCase().includes(e.toLowerCase()) 
        || item?.adventure?.label && item?.adventure?.label.toLowerCase().includes(e.toLowerCase()) 
        );

    setFilteredItemsDate([]);
    setFilteredItems(newFilter);
  }
  setFilterText(e);

  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    let newData = [];
    let amountTotal = 0;
    let feeTotal = 0;
    let taxTotal = 0;
    let tipTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalTotal = 0;

    if(filteredItems){
      filteredItems.forEach(element => {
        // amountTotal = element?.amount ? parseFloat(amountTotal) + parseFloat(element?.amount) : parseFloat(amountTotal);
        amountTotal = element?.capitalPaid ? (parseFloat(amountTotal) + parseFloat(element?.capitalPaid) + 
                      (element?.extraCostPaid ? parseFloat(element?.extraCostPaid) : 0)) : parseFloat(amountTotal);

        feeTotal = element?.waterFeePaid ? parseFloat(feeTotal) + parseFloat(element?.waterFeePaid) : parseFloat(feeTotal);
        surchargeTotal = element?.fuelSurchargePaid ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargePaid) : parseFloat(surchargeTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        taxTotal = element?.taxAmountPaid ? parseFloat(taxTotal) + parseFloat(element?.taxAmountPaid) : parseFloat(taxTotal);
        tipTotal = element?.tipAmountPaid ? parseFloat(tipTotal) + parseFloat(element?.tipAmountPaid) : parseFloat(tipTotal);
        totalTotal = element?.newAmountPaid ? parseFloat(totalTotal) + parseFloat(element?.newAmountPaid) : parseFloat(totalTotal);
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      fee: feeTotal.toFixed(2),
      surcharge: surchargeTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      tip: tipTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setWaterFeePaid(feeTotal.toFixed(2));
    setSurcharge(parseFloat(surchargeTotal.toFixed(2)));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setTaxAmount(taxTotal.toFixed(2));
    setTipAmount(tipTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2));

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => changeFilter(e.target.value)} filtertext={filterText} />
      </>
      // <>
      //   <h5 className='search-label'>Search</h5>
      //   <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      // </>
    );
  }, [filterText, resetPaginationToggle]);

  const selectDate = (e) => {
    let aux = [];
    let newData = [];

    let date1 = getDate(new Date(e[0]));
    let date2 = getDate(new Date(e[1]));
    let amountTotal = 0;
    let feeTotal = 0;
    let taxTotal = 0;
    let tipTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalTotal = 0;

    filteredItems.forEach(element => {


      if(element?.rental){

        if(element?.created_date){
          if(element?.created_date >= date1 && element?.created_date <= date2){
          aux.push(element);
  
          // amountTotal = element?.amount ? parseFloat(amountTotal) + parseFloat(element?.amount) : parseFloat(amountTotal);
          amountTotal = element?.capitalPaid ? (parseFloat(amountTotal) + parseFloat(element?.capitalPaid) + 
                      (element?.extraCostPaid ? parseFloat(element?.extraCostPaid) : 0)) : parseFloat(amountTotal);

          feeTotal = element?.waterFeePaid ? parseFloat(feeTotal) + parseFloat(element?.waterFeePaid) : parseFloat(feeTotal);
          surchargeTotal = element?.fuelSurchargePaid ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargePaid) : parseFloat(surchargeTotal);
          discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
          taxTotal = element?.taxAmountPaid ? parseFloat(taxTotal) + parseFloat(element?.taxAmountPaid) : parseFloat(taxTotal);
          tipTotal = element?.tipAmountPaid ? parseFloat(tipTotal) + parseFloat(element?.tipAmountPaid) : parseFloat(tipTotal);
          totalTotal = element?.newAmountPaid ? parseFloat(totalTotal) + parseFloat(element?.newAmountPaid) : parseFloat(totalTotal);
          }
        }

      }else{

        if(element?.created_date){
          if(element?.created_date >= date1 && element?.created_date <= date2){
            aux.push(element);
    
            // amountTotal = element?.amount ? parseFloat(amountTotal) + parseFloat(element?.amount) : parseFloat(amountTotal);
            amountTotal = element?.capitalPaid ? (parseFloat(amountTotal) + parseFloat(element?.capitalPaid) + 
                        (element?.extraCostPaid ? parseFloat(element?.extraCostPaid) : 0)) : parseFloat(amountTotal);
  
            feeTotal = element?.waterFeePaid ? parseFloat(feeTotal) + parseFloat(element?.waterFeePaid) : parseFloat(feeTotal);
            surchargeTotal = element?.fuelSurchargePaid ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargePaid) : parseFloat(surchargeTotal);
            discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
            taxTotal = element?.taxAmountPaid ? parseFloat(taxTotal) + parseFloat(element?.taxAmountPaid) : parseFloat(taxTotal);
            tipTotal = element?.tipAmountPaid ? parseFloat(tipTotal) + parseFloat(element?.tipAmountPaid) : parseFloat(tipTotal);
            totalTotal = element?.newAmountPaid ? parseFloat(totalTotal) + parseFloat(element?.newAmountPaid) : parseFloat(totalTotal);
          }
        }

      }
      
    });

    if(aux?.length === 0){
      aux = [{
        service:"-",
        adventure: {
          label:"-",
        },
        discountAmount: 0,
        amount:0,
        taxAmount:0,
        total:0,
        startDate:"-",
      }];
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      fee: feeTotal.toFixed(2),
      surcharge: surchargeTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      tip: tipTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setWaterFeePaid(feeTotal.toFixed(2));
    setSurcharge(parseFloat(surchargeTotal.toFixed(2)));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setTaxAmount(taxTotal.toFixed(2));
    setTipAmount(tipTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2));
    setFilteredItemsDate(aux);
    setStartDate(date1);
    setEndDate(date2);
    //setFilteredItems(aux);
  } 

  const getDate = (date) => {
    let day = `${(date.getDate())}`.padStart(2,'0');
    let month = `${(date.getMonth()+1)}`.padStart(2,'0');
    let year = date.getFullYear();
    return (`${year}-${month}-${day}`)
  }

  return (
    <div className="content">
      <div className='row'>
          <div className="col-md-6 text-center">
            <h6>Range Date</h6>
            <DateRangePicker 
              placeholder="Today"
              onChange={selectDate}
            />
          </div>
          <div className="d-flex justify-content-between flex-row-reverse col-md-6">
            <div className="gap-2 m-3">
                { data !== null ?
                  <>
                  <ExportPDF 
                    data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAllActivitiesRevenueConfig())} 
                    headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAllActivitiesRevenueConfig(), true)} 
                    totals={[amount, waterFeePaid, taxAmount, total, surcharge, tipAmount, discount]} 
                    agents={false} 
                    allActivities={true} 
                    filename={"Revenue report for all activities"}>
                  </ExportPDF>

                  { allReport ? (
                    <>
                    {/* <ExportCSVAllActivities
                      data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAllActivitiesRevenueConfigRevenue())} 
                      headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAllActivitiesRevenueConfigRevenue(), true)} 
                      totals={[amount, waterFeePaid, taxAmount, total, surcharge, tipAmount, discount]} 
                      allActivities={true} 
                      startDate={startDate} 
                      endDate={endDate} 
                      filename={"Revenue report for Adv/Trans/Rent #2"}>
                    </ExportCSVAllActivities> */}

                    <ExportCSVRevenue2
                      data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAllActivitiesRevenueConfig2())} 
                      headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAllActivitiesRevenueConfig2(), true)} 
                      totals={[amount, waterFeePaid, taxAmount, total, surcharge, tipAmount, discount]} 
                      allActivities={true} 
                      startDate={startDate} 
                      endDate={endDate} 
                      filename={"Revenue report for Adv/Trans/Rent"}>
                    </ExportCSVRevenue2>
                    </>
                  ) : (
                    <>
                    <ExportCSVRevenue 
                      data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAllActivitiesRevenueConfig())} 
                      headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAllActivitiesRevenueConfig(), true)} 
                      totals={[amount, waterFeePaid, taxAmount, total, surcharge, tipAmount, discount]} 
                      agents={false} 
                      allRevenue={true} 
                      startDate={startDate} 
                      endDate={endDate} 
                      filename={"Revenue report for Adv/Trans/Rent #1"}>
                    </ExportCSVRevenue>  
                    </>
                  ) }
                  
                   
                  </>
                : ""
                }
             </div>
          </div>
      </div>
      <div className="m-3">
        <DataTable
          columns={allReport ? /*columnsAllActivitiesRevenueConfigRevenue()*/ columnsAllActivitiesRevenueConfig2() : columnsAllActivitiesRevenueConfig()}
          data={filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems}
          selectableRows
          onSelectedRowsChange={handleChange}
          pagination
          clearSelectedRows={toggledClearRows}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          progressPending={pending}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />

        { amount !== "0.00" && amount !== 0  ? (

          <Table style={{ maxWidth: "700px", marginLeft: "auto" }} striped bordered hover>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Fee</th>
              <th>Fuel surcharge</th>
              <th>Discount</th>
              <th>Tax</th>
              <th>Tip</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {tableList.map((item, index) => (
              <tr key={index}>
                <td>$ {item.amount}</td>
                <td>$ {item.fee}</td>
                <td>$ {item.surcharge}</td>
                <td>$ {item.discount}</td>
                <td>$ {item.tax}</td>
                <td>$ {item.tip}</td>
                <td>$ {item.total}</td>
              </tr>
            ))}
          </tbody>
          </Table>
        ) : ("")} 
      </div>
    </div>
  );
}

export default TableConfig;
