import { useState } from 'react';
import { Card, CardGroup, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import '../style/style.css'
import { useNavigate, useParams } from 'react-router-dom';
import { setCart, getCart } from '../services/pointOfSale/carts';
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";

function Cart({ user, items = [], remove, paid }) {

  const navigate = useNavigate();
  let { back } = useParams();

  const getSubTotal = () => {
    
    let aux_sub_total = 0;

    items.forEach(element => {
      // Need other type values
      aux_sub_total += +element.price;
    });

    return JSON.parse(aux_sub_total).toFixed(2);
  }

  const getTotal = () => {
  
    let aux_total = 0;
    
    aux_total = ((parseFloat(getSubTotal())) + ((parseFloat(getSubTotal()))*(5/100)));
    
    return JSON.parse(aux_total).toFixed(2);
  }

  const getPaid = () => {
    
    let aux_paid = 0;

    if(paid){
      aux_paid = JSON.parse(paid);
    }else{
      aux_paid = 0.00;
    }

    return JSON.parse(aux_paid).toFixed(2);
  }

  const getBalance = () => {
    let aux_balance = 0;
    let paid = getPaid(); 
    
    aux_balance = ((parseFloat(getSubTotal())) + ((parseFloat(getSubTotal()))*(5/100)) - paid);
    
    return JSON.parse(aux_balance).toFixed(2);
  }
  
  //Redirection to payment
  const checkout = () => {

    if(getBalance() <= 0){
      Swal.fire({
        icon: "warning",
        title: "Bill at 0",
        text: "The bill has been paid",
        confirmButtonColor: '#173d38',
        confirmButtonText: "Ok",
      });
    }else{

      if (items.length <= 0) {
        Swal.fire({
          icon: "warning",
          title: "Empty Cart",
          text: "You must add items to cart",
          confirmButtonColor: '#173d38',
          confirmButtonText: "Ok",
        });
      } else {

        let cart = {
          items: JSON.stringify(items),
          user: JSON.stringify(user),
          total: JSON.stringify(parseFloat(getTotal())),
          subTotal: JSON.stringify(parseFloat(getSubTotal())),
          balance: JSON.stringify(parseFloat(getBalance())),
          paid: JSON.stringify(parseFloat(getPaid())),
        }

        let id = uuid();
        if(back){
          id = back;
        }
        setCart(cart, id);

        setTimeout(() => {
          navigate(`/payment/${id}`);
        }, 200);
      }
    }
  }

  return (
    <div className='container-fluid'>
      <CardGroup>
        <Card className='p-3' style={{ textAlign: 'initial', minHeight: 100 }}>
          <Form.Group className="mb-3">
            <div className="d-inline-flex" >
              <Form.Label>Created by: <strong>{user?.displayName}</strong></Form.Label>
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <div className='row'>
              <div className='col-12'>
                {items.length > 0 ? (
                  items.map((item, index) => (
                    <div className='col-12 mb-2 border border-success p-2 mb-2 border-opacity-25 d-flex' key={index}>
                      <p className='my-auto w-100'>{item.product}</p>
                      <Button style={{ width: 25, background: 'transparent', color: 'red' }} onClick={() => {remove(item,getTotal())}} className='flex-shrink-1 p-0 border-0'>X</Button>
                    </div>
                  ))) : ("")
                }
              </div>
            </div>
          </Form.Group>
        </Card>
      </CardGroup>
      <CardGroup >
        <Card className='p-3 mt-md-5' style={{ textAlign: 'initial' }}>
          <h5 style={{ textAlign: 'initial' }}>INVOICE</h5>
          <hr></hr>
          <Form.Group className="mb-3" style={{ padding: 5 }}>
            <div className="d-inline-flex" style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <Form.Label>Sub Total: </Form.Label>
              </div>
              <div style={{ width: "50%", textAlign: "end" }}>
                <h5>$ {getSubTotal()}</h5>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" style={{ padding: 5 }}>
            <div className="d-inline-flex" style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <Form.Label>Total: </Form.Label>
              </div>
              <div style={{ width: "50%", textAlign: "end" }}>
                <h5>$ {getTotal()}</h5>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" style={{ padding: 5 }}>
            <div className="d-inline-flex" style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <Form.Label>Paid: </Form.Label>
              </div>
              <div style={{ width: "50%", textAlign: "end" }}>
                <h5>$ {getPaid()}</h5>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" style={{ padding: 5 }}>
            <div className="d-inline-flex" style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <Form.Label>Balance: </Form.Label>
              </div>
              <div style={{ width: "50%", textAlign: "end" }}>
                <h5>$ {getBalance()}</h5>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" style={{ padding: 5 }}>
            <Button style={{ width: "-webkit-fill-available", background: '#173d38' }}  onClick={() => { checkout() }} className="card-text">Checkout</Button>
          </Form.Group>
        </Card>
      </CardGroup>
    </div>
  );
}

export default Cart;