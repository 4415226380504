import jsPDF from "jspdf";
import "jspdf-autotable";
import imgw from "../../assets/Word_Logo_Color_Cream.png";
import imgz from "../../assets/Horizontal_Logo.png";
import { differenceInDays } from 'date-fns';

const generateInvoicePDF = (data, client, download = true) => {

  const doc = new jsPDF();
  // Header
  doc.setFillColor(23, 61, 56);
  doc.rect(0, 0, doc.internal.pageSize.width, 25, "F");

  // Company image
  const imgWidth = 60;
  const imgOriginalWidth = 8105;
  const imgOriginalHeight = 1641;
  const imgHeight = (imgWidth * imgOriginalHeight) / imgOriginalWidth;
  const imgMarginTop = 5;
  const imgMarginLeft = (doc.internal.pageSize.width - imgWidth) / 2;
  doc.addImage(imgw, "PNG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);

  // Text of the superior rigth corner
  doc.setFontSize(8);
  doc.setTextColor(255, 255, 255);
  doc.text("802 BC-3", doc.internal.pageSize.width - 18, 10, "right");
  doc.text("Fernie, V0B1M0", doc.internal.pageSize.width - 18, 15, "right");
  doc.text("PO Box 836", doc.internal.pageSize.width - 18, 20, "right");

  // Subtítulo
  const imgzWidth = 40;
  const imgzOriginalWidth = 8105;
  const imgzOriginalHeight = 1641;
  const imgzHeight = (imgzWidth * imgzOriginalHeight) / imgzOriginalWidth;
  const imgzMarginTop = 35;
  const imgzMarginLeft = 14;

  doc.setFontSize(19);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(23, 61, 56);
  doc.text("Invoice", 14, 33 + imgzHeight);

  doc.setFontSize(12);

  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Invoice for:", 14, 50);
  doc.setFont("helvetica", "normal");
  doc.text(`${client?.client ? client?.client : (client ? client : (data?.client?.label ? data?.client?.label : "NO CUSTOMER INFORMATION"))}`, 14, 60);
  doc.text(`${client?.email ? client?.email : ""}`, 14, 67);
  doc.text(`${client?.phone ? client?.phone : ""}`, 14, 74);
  if(client?.organisation !== ""){
    doc.text(`${client?.organisation ? client?.organisation : ""}`, 14, 81);
  }

  doc.setFont("helvetica", "bold");
  doc.text("Invoice Details", 140, 50);
  doc.setFont("helvetica", "normal");
  doc.text(`Invoice #:`, 140, 60);
  doc.text(`${data?.id?.slice(0, 8)}`, 140, 67);
  // doc.text(`Order Date: ${new Date().toLocaleDateString()}`, 140, 74);
  if(data?.created_date){
    const parts = data?.created_date?.seconds ? data?.created_date?.toDate().toLocaleDateString() : data?.created_date.split('/');
    const day = !data?.created_date?.seconds ? parts[0] : 0;
    const month = !data?.created_date?.seconds ? parts[1] : 1;
    const year = !data?.created_date?.seconds ? parts[2].split(',')[0] : ""; // Para eliminar la parte de la hora
    const date = !data?.created_date?.seconds ? `${month}/${day}/${year}` : parts;
    doc.text(`Order Date: ${date}`, 140, 74);

  }


  var tableRows = [];
  var retailAmount = 0;
  var reta1 = 0;
  let days = 0; // Guarda la diferencia de dias
  let hours = 0; //Guarda la diferencia de horas
  let start = ""; //Guarda el hora de inicio
  let end = ""; //Guarda el hora final


  for (let i = 0; data?.retail?.length > i; i++) {
          
      reta1 = parseFloat(data?.retail?.[i]?.price) * parseFloat(data?.retail?.[i]?.quantity);
      var row = [data?.retail?.[i]?.label, data?.retail?.[i]?.quantity, "$ " + reta1];

      retailAmount = parseFloat( parseFloat(retailAmount) + parseFloat(reta1));
      tableRows.push(row);
    }  

  // Service details table
  doc.autoTable({
    headStyles: { fillColor: [23, 61, 56] },
    head: [["SERVICE", "QTY", "PRICE"]],
    body: tableRows,
    startY: 90,
  });

  // Totals and payment information
  const subtotal = parseFloat(data?.amount);
  const startY = doc.autoTable.previous.finalY + 10;
  doc.setFontSize(12);
  doc.text(`Subtotal: $${subtotal.toFixed(2)}`, 195, startY, "right");

  var startY2 = startY;

  if(parseFloat(data?.discountAmount) > 0){
      startY2 = startY + 10;
      doc.text(`Discount: $${parseFloat(data?.discountAmount).toFixed(2).padStart(4, '0')}`, 195, startY2, "right");
  }

  var startY3 = startY2 + 10;
  // var tax = 0;
  let taxV = 0;
  let taxL = "";

  for (let i = 0; data?.taxes?.length > i; i++) {
    taxV += parseFloat(data?.taxes?.[i]?.value);
    taxL = taxL + data?.taxes?.[i]?.label;

    if (i !== data.taxes.length - 1) {
      taxL += " + ";
    }
  }

  if(taxV > 0){
    // tax = (parseFloat(data?.amount) * (parseFloat(data?.taxes?.value) / 100)).toFixed(2);
    doc.text(`${taxL} (${taxV}%): $${parseFloat(data?.taxAmount).toFixed(2).padStart(4, '0')}`, 195, startY3, "right");
    startY3 = startY3 + 10;
  }

  doc.text(
    `Total: $${parseFloat(data?.total).toFixed(2)}`,
    195,
    startY3,
    "right"
  );

  const tipAmount = parseFloat(data?.tipAmount) ? parseFloat(data?.tipAmount) : 0;
  tipAmount > 0 && doc.text(`Tips: $${tipAmount.toFixed(2)}`, 195, startY3 + 10, "right");

  const percentage = (100 - (parseFloat(data?.amountPaid) * 100) / parseFloat(data?.total) );
  doc.text(
    `Amount Paid: $${data?.amountPaid.toFixed(2)}`,
    195,
    tipAmount > 0 ? (startY3 + 20) : (startY3 + 10),
    "right"
  );

  doc.setFontSize(14);
  doc.text(`Balance Due (${ percentage.toFixed(0) > 0 ? percentage.toFixed(0) : 0 }%): $${data?.balance.toFixed(2)}`, 
  195, tipAmount > 0 ? (startY3 + 30) : (startY3 + 20), "right");

  // (footer)
  doc.setFillColor(23, 61, 56);
  doc.rect(
    0,
    doc.internal.pageSize.height - 20,
    doc.internal.pageSize.width,
    20,
    "F"
  );

  // footer notes
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.setTextColor(255, 255, 255);
  const footerText =
    "Thank you very much for purchasing from Tunnel49.";
  const textWidth =
    (doc.getStringUnitWidth(footerText) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
  doc.text(footerText, textOffset, doc.internal.pageSize.height - 8);

  // save and download PDF
  const currentDate = new Date();
  const dateString = currentDate.toLocaleDateString();
  const timeString = currentDate.toLocaleTimeString();
  var filename = client?.client ? (`Invoice_${client?.client.replaceAll(
    " ",
    "_"
  )}_${dateString}_${timeString}.pdf`) : (client ? (`Invoice_${client.replaceAll(
    " ",
    "_"
  )}_${dateString}_${timeString}.pdf`) : (`Invoice_${data?.client?.label.replaceAll(
    " ",
    "_")}_${dateString}_${timeString}.pdf`));

  if (download) {    
    doc.save(filename);
  } else {
    return doc.output("datauristring");
  }
};

export default generateInvoicePDF;
