import { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css';
import { columns_trasport_excel } from '../../../services/Tools';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {  getTransportReport } from '../../../services/ConfigReservations';


function FormConfig({ title, action, setModal }) {

    const [id, setId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const _export = useRef(null);

    // useEffect(() => {
    //     if (lastData) {
    //         setId(lastData?.id);
    //         setStartDate(lastData?.startDate);
    //         setStock(lastData?.stock);
    //     }
    // }, [lastData])

    const excelExport = (aux) => {
        if (_export.current !== null) {
            _export.current.save(aux, columns_trasport_excel(), 'Driver_report.xlsx');
        }
    };

    const actionExport = async (data) => {

        try {
            // Llamar a la función getTransportReport
            const aux = await getTransportReport(data?.startDate, data?.endDate);

            // Verifica que si haya devuelto algo, sino muestra mensaje de error.
            if (aux?.length > 0) {
                excelExport(aux);
                action(aux);
            } else {
              Swal.fire({
                icon: "info",
                title: "We're sorry!",
                text: "No reservations found in the selected date range",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
              });
            }


        } catch (error) {
            console.error("Error al editar la reserva:", error);
        }
     
    };

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row mt-4 mb-4'>
                <div className='col-md-6'>

                    <Form.Group className="mb-3" controlId="formStartDate">
                        <div className="d-inline-flex">
                            <Form.Label style={{ fontWeight: 'bold' }}>Start date</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="date" placeholder="0"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                </div>

                <div className='col-md-6'>

                    <Form.Group className="mb-6" controlId="formEndDate">
                        <div className="d-inline-flex">
                            <Form.Label style={{ fontWeight: 'bold' }}>End date</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="date" placeholder="0"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                </div>
                <h6 className='mb-2 fs-6'>Here you can generate a report of driver names in a selected date range</h6>
                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <ExcelExport ref={_export}>

                    <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {
                        var data = {
                            id,
                            startDate,
                            endDate,
                        };

                        if (startDate !== '' && endDate !== '') {
                            actionExport(data);

                        } else {
                            Swal.fire({
                                icon: "error", //Constants = success, error, warning
                                title: "Error!",
                                text: "All fields with (*) are required",
                                confirmButtonColor: '#173d38',
                                confirmButtonText: "OK",
                            });
                        }
                    }
                    }>
                        Export
                    </Button>
                </ExcelExport>

            </div>
        </form>
    );
}

export default FormConfig;