import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'

function FormConfig({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [adventure, setAdventure] = useState("");
    const [adultRate, setAdultRate] = useState("");
    const [youthRate, setYouthRate] = useState("");
    const [waterFee, setWaterFee] = useState(true);
    const [color, setColor] = useState("");

    useEffect(() => {
        if (lastData) {
            setId(lastData.id);
            setAdventure(lastData?.adventure);
            setAdultRate(lastData?.adultRate);
            setYouthRate(lastData?.youthRate);
            setWaterFee(lastData?.waterFee);
            setColor(lastData?.color);
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formAdventure">
                        <div className="d-inline-flex">
                            <Form.Label>Adventure</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the adventure"
                            value={adventure}
                            onChange={(e) => setAdventure(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBookingType">
                        <div className="d-inline-flex">
                            <Form.Label>Add water use tax?</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={waterFee}
                                onChange={() => setWaterFee(true)}
                            />Yes
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={!waterFee}
                                onChange={() => setWaterFee(false)}
                            />No
                        </div>
                    </Form.Group> 

                    <Form.Group className="mb-3" controlId="formColor">
                        <div className="d-inline-flex">
                            <Form.Label>Color</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="color"
                            value={color}
                            onChange={(e) => lastData?.color ? ("") : (setColor(e.target.value))}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                </div>

                <div className='col-md-6'>
                    
                    <Form.Group className="mb-3" controlId="formAdultRate">
                        <div className="d-inline-flex">
                            <Form.Label>Adult rate</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="number" placeholder="0"
                            value={adultRate}
                            onChange={(e) => setAdultRate(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formYouthRate">
                        <div className="d-inline-flex">
                            <Form.Label>Youth rate</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="number" placeholder="0"
                            value={youthRate}
                            onChange={(e) => setYouthRate(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>
                </div>
                <h5 className="required">The field with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {

                    var data = {
                        id,
                        adventure,
                        adultRate,
                        youthRate,
                        waterFee,
                        color,
                    };

                    if (adventure !== '' && adultRate !== '' && youthRate !== '') {
                        action(data);
                    } else {
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "The field are required",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                          });
                    }
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;