import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'
import Select from 'react-select';
import { paymentTypes } from '../../../services/Tools';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import { getCardType } from "../../../services/Tools";


function FormConfig({ title, actionPay, data, setModalPayment }) {

    const [id, setId] = useState("");
    const [idReservation, setIdReservation] = useState("");
    const [service, setService] = useState("");
    const [tax, setTax] = useState("");

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [paymentBalance, setPaymentBalance] = useState([]);

    const [amount, setAmount] = useState("");
    const [paymentType, setPaymentType] = useState({ value: "Credit", label: "Credit" });
    const [cardType, setCardType] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [expirationMonth, setExpirationMonth] = useState("");
    const [expirationYear, setExpirationYear] = useState("");
    const [cvv, setCvv] = useState("");
    const [cardName, setCardName] = useState("");
    const [playerName, setPlayerName] = useState("");
    const [idSale, setIdSale] = useState("");
    
    const [cardTypeListAux, setCardTypeListAux] = useState([]);
    const [cardTypeList, setCardTypeList] = useState([{ value: '', label: '' }]);



    useEffect(() => {
        setCardTypeListAux(getCardType);
    }, []);

    useEffect(() => {
        if (data) {
            //setId(data?.id);
            setIdReservation(data?.id);
            //setCardType(data?.cardType);
            setService(data?.shuttle?.label);
            setTax(data?.tax?.label);

            setAmount(data?.total);
            //setPaymentType({ value: data?.value, label: data?.label });

            let newPaymentBalance = [];
            for (let i = 0; data?.payment_balance_matriz?.length > i; i++) {
                newPaymentBalance[i] = data?.payment_balance_matriz[i];
            }

            setPaymentBalance(newPaymentBalance);

        }
    }, [data])

    
    //Use by card Types
    useEffect(() => {

        if (getCardType?.length > 0) {
            var aux = [];
            getCardType?.forEach(element => {
                aux.push({ value: element.value, label: element.label });
            });
            setCardTypeList(aux);
        }
    }, [getCardType]);

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <div>
                        <Form.Group className="mb-3">
                            <div className="d-inline-flex">
                                <Form.Label>Payment Type</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Select placeholder={paymentTypes[0].value} options={paymentTypes} value={paymentType[0]}
                                onChange={(e) => setPaymentType(e)} />
                        </Form.Group>  

                        { paymentType?.value === "Credit" ? (
                            <>
                            <Form.Group style={{ padding: 5 }}>
                                <div className="d-block" style={{ width: "100%" }}>
                                <div style={{ width: "100%" }}>
                                    <div className="d-inline-flex">
                                        <Form.Label>Card Type </Form.Label>
                                        <h5 className="required">*</h5>
                                    </div>
                                </div>
                                <Select options={cardTypeList} value={cardType}
                                     onChange={(e) => setCardType(e)} />
                                
                                </div>
                            </Form.Group>
                            </>
                        ) : ("")}

                            {/* {paymentType?.value === "Credit" || paymentType?.value == null ? (
                            <>
                                <Form.Group style={{ padding: 5 }}>
                                    <div className="d-block" style={{ width: "100%" }}>
                                    <div style={{ width: "100%" }}>
                                        <div className="d-inline-flex">
                                            <Form.Label>Card Number </Form.Label>
                                            <h5 className="required">*</h5>
                                        </div>
                                    </div>

                                    <div className="d-inline-flex" style={{ width: "100%" }}>
                                        <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>Visa</strong></Form.Label>
                                        <Form.Control type="text" placeholder="Card Number"
                                            value={cardNumber}
                                            onChange={(e) => setCardNumber(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        />
                                    </div>
                                    </div>
                                </Form.Group>

                                <Form.Group style={{ padding: 5 }}>
                                    <div className="d-inline-flex" style={{ width: "100%" }}>
                                        <div className="d-block">
                                            <div style={{ width: "90%" }}>
                                                <div className="d-inline-flex">
                                                    <Form.Label>Expiration Month</Form.Label>
                                                    <h5 className="required">*</h5>
                                                </div>
                                            </div>
                                            <div className="d-inline-flex" style={{ width: "100%" }}>
                                                <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>MM</strong></Form.Label>
                                                <Form.Control type="number" placeholder="MM" maxLength={2} minLength={2}
                                                    value={expirationMonth}
                                                    onChange={(e) => setExpirationMonth(e.target.value)}
                                                    onKeyDown={(e) => keypress(e)}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-block">
                                            <div style={{ width: "100%" }}>
                                                <div className="d-inline-flex">
                                                    <Form.Label>Expiration Year</Form.Label>
                                                    <h5 className="required">*</h5>
                                                </div>
                                            </div>
                                            <div className="d-inline-flex" style={{ width: "100%" }}>
                                                <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>YYYY</strong></Form.Label>
                                                <Form.Control type="text" placeholder="YYYY" maxLength={4} minLength={4}
                                                    value={expirationYear}
                                                    onChange={(e) => setExpirationYear(e.target.value)}
                                                    onKeyDown={(e) => keypress(e)}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-block">
                                            <div style={{ width: "100%" }}>
                                                <div className="d-inline-flex">
                                                    <Form.Label>CVV</Form.Label>
                                                    <h5 className="required">*</h5>
                                                </div>
                                            </div>
                                            <div className="d-inline-flex" style={{ width: "100%" }}>
                                                <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>CVV</strong></Form.Label>
                                                <Form.Control type="number" placeholder="CVV" maxLength={5}
                                                    value={cvv}
                                                    onChange={(e) => setCvv(e.target.value)}
                                                    onKeyDown={(e) => keypress(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                        <div className="d-inline-flex">
                                            <Form.Label>Name On Card</Form.Label>
                                            <h5 className="required">*</h5>
                                        </div>
                                        <Form.Control type="text" placeholder="Name"
                                            value={cardName}
                                            onChange={(e) => setCardName(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        />
                                </Form.Group>
                            </>
                            ) : (
                                <>
                                <Form.Group className="mb-3">
                                    <div className="d-inline-flex">
                                        <Form.Label>Player Name</Form.Label>
                                        <h5 className="required">*</h5>
                                    </div>
                                    <Form.Control type="text" placeholder="Name"
                                        value={playerName}
                                        onChange={(e) => setPlayerName(e.target.value)}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </Form.Group>
                                </>
                            )} */}

                    </div>
                </div>

                <div className='col-md-6'>
                    <div>
                        <Form.Group  className="mb-3">
                            <div className="d-block" style={{ width: "100%" }}>
                            <div style={{ width: "100%" }}>
                                <div className="d-inline-flex">
                                    <Form.Label>Amount </Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
                            </div>

                            <div className="d-inline-flex" style={{ width: "100%" }}>
                                <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>$</strong></Form.Label>
                                <Form.Control type="number" placeholder="Amount"
                                    value={amount}
                                    readOnly
                                    onChange={(e) => setAmount(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <h5 className="required">The field with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModalPayment(false) }}>Cancel</Button>

                <Button style={{ background: '#173d38' }} className='button-save' onClick={() => {
                    //makePayment();

                    var newData = {
                        id,
                        idReservation,
                        paymentType,
                        amount,
                        cardType,
                        service,
                        tax
                    };

                    if (paymentType?.label !== '' && amount !== '') {                        
                        actionPay(newData,data);
                    } else {
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "The field are required",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                          });
                    }
                }
                }>
                    Make Payment
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;