import React, { useEffect, useState } from 'react';
import { GoogleButton } from 'react-google-button';
import '../style/style.css'
import Loading from '../components/loading';
import { useAuthState } from "react-firebase-hooks/auth";
import Swal from "sweetalert2";
import { editDataAdventures } from '../services/ConfigAdventures';
import { editDataReservations } from '../services/ConfigReservations';
import { editDataRentals } from '../services/ConfigRentals';
import { editDataRetails } from '../services/ConfigRetails';
import { addDataPayments, deleteTemporaryReservationData, getTemporaryReservationData } from '../services/payment';
import { addDataPaymentsAdventures, deleteTemporaryAdventureData, getTemporaryAdventureData } from '../services/paymentAdventure';
import { addDataPaymentsRentals, deleteTemporaryRentalsData, getTemporaryRentalsData } from '../services/paymentRental';
import { addDataPaymentsRetails, deleteTemporaryRetailsData, getTemporaryRetailsData } from '../services/paymentRetail';
// import checkIcon from './check-icon.png'; // Ruta de la imagen del icono de verificación
import checkIcon from '../assets/check-png.png'

const idRole = 'SuccessPayment';

const SuccessPayment = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const fetchData = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
      
        if (token) {
          let temp = await getTemporaryReservationData(token);
          let adventure = false;
          let rental = false;
          let retail = false;

          if(!temp){
            temp = await getTemporaryAdventureData(token);
            if(temp){
              adventure = true;
            }
          }

          if(!temp){
            temp = await getTemporaryRentalsData(token);
            if(temp){
              rental = true;
            }
          }

          if(!temp){
            temp = await getTemporaryRetailsData(token);
            if(temp){
              retail = true;
            }
          }
  
          if (!temp) {
            return;
          }
  
          if(adventure){

            // await editDataAdventures(temp?.aux, temp.newData, false);
            await editDataAdventures(temp?.aux, false);
            await addDataPaymentsAdventures(temp?.newData, false);

            setTimeout(() => {
              setLoading(false);
              deleteTemporaryAdventureData(token);
              const url = new URL(window.location);
              url.searchParams.delete('token');
              window.history.pushState({}, '', url);
            }, 500); 

          }else if(rental){

            await editDataRentals(temp?.aux, false);
            await addDataPaymentsRentals(temp?.newData, false);

            setTimeout(() => {
              setLoading(false);
              deleteTemporaryRentalsData(token);
              const url = new URL(window.location);
              url.searchParams.delete('token');
              window.history.pushState({}, '', url);
            }, 500);

          }else if(retail){

            await editDataRetails(temp?.aux, false);
            await addDataPaymentsRetails(temp?.newData, false);

            setTimeout(() => {
              setLoading(false);
              deleteTemporaryRetailsData(token);
              const url = new URL(window.location);
              url.searchParams.delete('token');
              window.history.pushState({}, '', url);
            }, 500);

          }else{

            await editDataReservations(temp?.aux, false);
            await addDataPayments(temp?.newData, false);

            setTimeout(() => {
              setLoading(false);
              deleteTemporaryReservationData(token);
              const url = new URL(window.location);
              url.searchParams.delete('token');
              window.history.pushState({}, '', url);
            }, 500);

          }   
        }
      }

      fetchData()
  });

  return (

    <section className="vh-100 gradient-custom">
      <div className="container py-5">
        <div className="row d-flex justify-content-center align-items-center">
          {loading ? (
          <>
          <div className="payment-successful">
              <Loading></Loading>
            </div>
          </>
          ) : (
          <>
            <div className="payment-successful">
              <div className="payment-successful-container">
                <img src={checkIcon} alt="Check icon" className="icon" />
                <h1 className="headline">Payment successful!</h1>
                <p className="description">Your payment has been processed successfully.</p>
                <p className="gratitude">Thanks for your purchase!</p>
              </div>
            </div>
            </>
            )}
        </div>
      </div>
    </section>

  );
};

export default SuccessPayment;