import jsPDF from 'jspdf';
import imgw from "../../../assets/Word_Logo_Color_Cream_compress_2.png";
import imgWater from "../../../assets/White_water_rafting.jpg";
import imgVan from "../../../assets/Van_13.jpg";
import imgTrees from "../../../assets/Trees.jpg";
import imgMountain1 from "../../../assets/Montains1.jpg";
import imgMountain2 from "../../../assets/Montains2.jpg";

import instagram from "../../../assets/Instagram.png";
import facebook from "../../../assets/Facebook.png";


async function transConfirmationPDF(data) {
  const doc = new jsPDF();
  const compressedImgw = await compressImage(imgw);
  const compressedImgWater = await compressImage(imgWater);
  const compressedImgVan = await compressImage(imgVan);
  const compressedImgTrees = await compressImage(imgTrees);
  const compressedImgMount1 = await compressImage(imgMountain1);
  const compressedImgMount2 = await compressImage(imgMountain2);
  // const compressedImgInstagram = await compressImage(instagram);
  // const compressedImgFacebook = await compressImage(facebook);

  /////////////////*****************************/////////////////
  // Obtener las dimensiones de la imagen
  const imgWidthTree = 220; // Ancho de la imagen en unidades PDF
  const imgHeightTree = 170; // Alto de la imagen en unidades PDF

  // Obtener las dimensiones del PDF
  const pdfWidth = doc.internal.pageSize.width;
  const pdfHeight = doc.internal.pageSize.height;

  // Calcular las coordenadas para colocar la imagen en la parte inferior del PDF
  const x = (pdfWidth - imgWidthTree) / 2; // Centrar horizontalmente
  const y = pdfHeight - imgHeightTree; // Colocar en la parte inferior

  // Agregar la imagen al PDF
  doc.addImage(compressedImgTrees, 'JPEG', x, y, imgWidthTree, imgHeightTree);
  /////////////////*****************************/////////////////


  // Company image
  const imgWidth = 60;
  const imgOriginalWidth = 8105;
  const imgOriginalHeight = 1641;
  const imgHeight = (imgWidth * imgOriginalHeight) / imgOriginalWidth;
  const imgMarginTop = 25; 
  // const imgMarginLeft = (doc.internal.pageSize.width - imgWidth) / 2;
  const imgMarginLeft = 20;
  // doc.addImage(compressedImgTrees, 'JPG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
  doc.addImage(compressedImgw, "JPG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);

  // Agregar el texto proporcionado al PDF
  doc.setFontSize(12);
  doc.text(`Hi ${data?.clientName ? data?.clientName : (data?.client?.label ? data?.client?.label : "dear client")},`, 20, 50);
  doc.text("Thank you for booking with Tunnel49!", 20, 60);

  // Texto en negrita
  doc.setFont('helvetica', 'bold');
  doc.text("Please review the following information to ensure the accuracy of your reservation.", 20, 70);
  // Restaurar la fuente normal
  doc.setFont('helvetica', 'normal');

  let countNop = (Number(data?.adultNumber) || 0) + (Number(data?.youthNumber) || 0);

  doc.text(`White Water Rafting Full Day - ${ countNop } Guest(s)` , 30, 80);  
  doc.text(`Starts: ${ data?.startDate ? data?.startDate : "-" } at ${ data?.startTime } (6 hours)`, 30, 90);
  doc.text(`Invoice Total: ${ data?.total }`, 30, 100);
  doc.text(`Balance Due: ${ data?.balance }`, 30, 110);

  // Texto en negrita
  doc.setFont('helvetica', 'bold');
  doc.text("If you have any questions at all, please don't hesitate to call our office at", 20, 120);
  doc.text("250-423-5008, option 2.", 20, 127);
  doc.text("We look forward to seeing you soon!", 20, 137);
  doc.text("If you have any flexibility to raft on another day, before or after your booking ", 20, 147);
  doc.text("please specify or reply “noflexibility” to this email.", 20, 154);


  // Restaurar la fuente normal
  // doc.setFont('helvetica', 'normal');

  doc.setFont('helvetica', 'bold');
  doc.text("REMEMBER:", 20, 175);
  doc.setFont('helvetica', 'normal');

  // Texto subrayado
  const textWidthC = doc.getTextWidth("REMEMBER");
  const textXC = 20;
  const textYC = 175;
  doc.line(textXC, textYC + 2, textXC + textWidthC, textYC + 2); // Dibuja una línea debajo del texto

  doc.setFont('helvetica', 'normal');

  doc.text("- We are located on the highway at 802 BC-3, right next to Park Place Lodge.", 20, 185);
  doc.text("- Arrive at least 15 minutes prior to your departure so we can sign the adventure waivers.", 20, 195);
  doc.text("- After signing waivers, we'll suit you up in wetsuits, booties, life-jackets and helmets.", 20, 205);
  doc.text("- Bring a water bottle, sunscreen and a bathing suit to wear under your wetsuit.", 20, 215);
  doc.text("- It is not recommended to bring your own reusable water bottle just in case that you might", 20, 225);
  doc.text("drop it, or miss it. If you do decide to bring it, our guides have dry sacks where to keep it, ", 20, 232);
  doc.text("or you can leave it with the rest of your belongings in the shuttle.", 20, 239);
  doc.text("- Your belongings can be safely stored in the shuttle while you enjoy your whitewater adventure.", 20, 249);
  doc.text("- If you want, you can bring sunglasses with a secure strap, a surf/swim shirt, a pair of water", 20, 259);
  doc.text("shoes, a towel, and cash if you want to tip your awesome guide at the end of the trip.", 20, 266);


  // Verificar si la altura del contenido excede el límite de una sola página
  if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
    // Si el contenido cabe en una sola página, devolver el PDF
    return doc.output('datauristring');
  } else {
    // Si el contenido excede el límite de una sola página, agregar una nueva página
    doc.addPage();

    /////////////////*****************************/////////////////
    // Obtener las dimensiones de la imagen
    const imgWidthM = 220; // Ancho de la imagen en unidades PDF
    const imgHeightM = 120; // Alto de la imagen en unidades PDF

    // Obtener las dimensiones del PDF
    const pdfWidth = doc.internal.pageSize.width;
    const pdfHeight = doc.internal.pageSize.height - 10;

    // Calcular las coordenadas para colocar la imagen en la parte inferior del PDF
    const x = (pdfWidth - imgWidthM) / 2; // Centrar horizontalmente
    const y = pdfHeight - imgHeightM; // Colocar en la parte inferior

    // Agregar la imagen al PDF
    doc.addImage(compressedImgMount1, 'JPEG', x, y, imgWidthM, imgHeightM);

    //////////////********************************/////////////////

    doc.setFont('helvetica', 'bold');
    doc.text("KNOW BEFORE YOU GO:", 20, 20);
    doc.setFont('helvetica', 'normal');

     // Texto subrayado
    const textWidthR = doc.getTextWidth("KNOW BEFORE YOU GO:");
    const textXR = 20;
    const textYR = 20;
    doc.line(textXR, textYR + 2, textXR + textWidthR, textYR + 2); // Dibuja una línea debajo del texto

    // doc.text("Cancellation", 20, 240);
    // Texto subrayado
    const textWidthC = doc.getTextWidth("Whitewater rafting:");
    const textXC = 20;
    const textYC = 36;

    // Guardar el estado actual de la fuente
    const font = doc.internal.getFont();

    // Establecer la fuente en cursiva solo para el texto "Cancellation"
    doc.setFont(font.fontName, 'bolditalic');
    doc.text("Whitewater rafting:", textXC, textYC);

    // Restaurar el estado de la fuente
    doc.setFont(font.fontName, font.fontStyle);
    // doc.line(textXC, textYC + 2, textXC + textWidthC, textYC + 2); // Dibujar una línea debajo del texto

      
    doc.text("There are around 320 steps down into the Canyon where the White Water Rafting", 20, 44);
    doc.text(" trip will depart from; this is not suitable for anyone with mobility issues.", 20, 52);

    // Contenido a la segunda página
    // Texto subrayado
    const textWidthM = doc.getTextWidth("Be on time:");
    const textXM = 20;
    const textYM = 68;

    // Establecer la fuente en cursiva solo para el texto "Cancellation"
    doc.setFont(font.fontName, 'bolditalic');
    doc.text("Be on time:", textXM, textYM);

    // Restaurar el estado de la fuente
    doc.setFont(font.fontName, font.fontStyle);
    // doc.line(textXM, textYM + 2, textXM + textWidthM, textYM + 2); // Dibujar una línea debajo del texto

    doc.text("Please make sure you arrive at least 15 minutes early to check-in with our office.", 20, 76);


    const textWidthP = doc.getTextWidth("Gear we provide:");
    const textXP = 20;
    const textYP = 92;

    // Establecer la fuente en cursiva solo para el texto "Cancellation"
    doc.setFont(font.fontName, 'bolditalic');
    doc.text("Gear we provide:", textXP, textYP);

    // Restaurar el estado de la fuente
    doc.setFont(font.fontName, font.fontStyle);

    // doc.line(textXP, textYP + 2, textXP + textWidthP, textYP + 2); // Dibujar una línea debajo del texto

    doc.text("Wetsuits, personal floatation devices, neoprene booties, helmets, paddles, ", 20, 100);
    doc.text("rafts, kayaks, paddle boards, or SUPsquatch.", 20, 108);

    const textWidthI = doc.getTextWidth("Disclaimer:");
    const textXI = 20;
    const textYI = 124;

    // Establecer la fuente en cursiva solo para el texto "Cancellation"
    doc.setFont(font.fontName, 'bolditalic');
    doc.text("Disclaimer:", textXI, textYI);

    // Restaurar el estado de la fuente
    doc.setFont(font.fontName, font.fontStyle);
    // doc.line(textXI, textYI + 2, textXI + textWidthI, textYI + 2); // Dibujar una línea debajo del texto

    doc.text("Please notify us if anyone in your group has any food allergies, dietary restrictions,", 20, 132);
    doc.text("disabilities, is under 13 years old, weighs under 50lbs, or is not a confident swimmer.", 20, 140);


    // Texto subrayado
    const textWidthW = doc.getTextWidth("Cancellation:");
    const textXW = 20;
    const textYW = 156;

    // Establecer la fuente en cursiva solo para el texto "Cancellation"
    doc.setFont(font.fontName, 'bolditalic');
    doc.text("Cancellation:", textXW, textYW);

    // Restaurar el estado de la fuente
    doc.setFont(font.fontName, font.fontStyle);
    doc.line(textXW, textYW + 2, textXW + textWidthW, textYW + 2); // Dibujar una línea debajo del texto
    doc.text("- Reservations are non-refundable if canceled within 72 hours of scheduled time.", 20, 164);
    doc.text("- If a booking is canceled at least 8 days before the trip date, we will retain", 20, 172);
    doc.text("a 20% administration fee.", 20, 180);
    doc.text("- Tunnel 49 Adventures reserves the right to change or cancel any trip due to ", 20, 188);
    doc.text("water levels, weather or any other unforeseen circumstances.", 20, 196);


    doc.text("If you have any questions, don't hesitate to get in touch with us!", 48, 212);
    doc.text("Thank you for choosing Tunnel49.", 72, 220);
    doc.setTextColor(222, 168, 69); // Establecer color amarillo
    doc.textWithLink("www.t49.ca", 92, 228, { url: "https://www.t49.ca" });
    doc.setTextColor("normal"); // Establecer color normal

    // doc.text("(250) 423 - 5008", 87, 236);
    const phoneNumber = "(250) 423 - 5008";
    const numberX = 87;
    const numberY = 236;
    doc.text(phoneNumber, numberX, numberY);

    // Añade un enlace al número de teléfono
    const phoneLink = "tel:+12504235008";
    const widthPho = doc.getStringUnitWidth(phoneNumber) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const heightPho = doc.internal.getLineHeight() / doc.internal.scaleFactor;

    // Añadir el enlace sobre el texto del número de teléfono
    doc.link(numberX, numberY, widthPho, heightPho, { url: phoneLink });

    // (footer)
    doc.setFillColor(23, 61, 56);
    doc.rect(
      0,
      doc.internal.pageSize.height - 10,
      doc.internal.pageSize.width,
      20,
      "F"
    );

    if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
      // Si el contenido cabe en una sola página, devolver el PDF
      return doc.output('datauristring');
    } 
    else {
      // Si el contenido excede el límite de una sola página, agregar una nueva página
      doc.addPage();

      /////////////////*****************************/////////////////
      // Obtener las dimensiones de la imagen
      const imgWidthM2 = 220; // Ancho de la imagen en unidades PDF
      const imgHeightM2 = 120; // Alto de la imagen en unidades PDF

      // Obtener las dimensiones del PDF
      const pdfWidth = doc.internal.pageSize.width;
      const pdfHeight = doc.internal.pageSize.height - 10;

      // Calcular las coordenadas para colocar la imagen en la parte inferior del PDF
      const x = (pdfWidth - imgWidthM2) / 2; // Centrar horizontalmente
      const y = pdfHeight - imgHeightM2; // Colocar en la parte inferior

      // Agregar la imagen al PDF
      doc.addImage(compressedImgMount2, 'JPEG', x, y, imgWidthM2, imgHeightM2);

      //////////////********************************/////////////////
      

      doc.setFont('helvetica', 'bold');
      doc.setFontSize(16); // Aumenta el tamaño del texto a 16
      doc.text("More from Tunnel49:", 20, 25);
      doc.setFont('helvetica', 'normal');


      // White water rafting image
      const imgWidthWater = 90;
      const imgOriginalWidthWater = 500;
      const imgOriginalHeightWater = 307;
      const imgHeight = (imgWidthWater * imgOriginalHeightWater) / imgOriginalWidthWater;
      const imgMarginTopWater = 35;
      // const imgMarginLeft = (doc.internal.pageSize.width - imgWidthWater) / 2;
      const imgMarginLeftWater = 15;
      doc.addImage(compressedImgWater, "JPG", imgMarginLeftWater, imgMarginTopWater, imgWidthWater, imgHeight);

      doc.setFontSize(10); // El tamaño del texto a 10

      doc.text("Experience the ultimate river adventure today! Whether", 17, 97);
      doc.text("you crave the thrill of Class Four rapids or prefer a", 17, 102);
      doc.text("leisurely float down the Elk River with friends, we've", 17, 107);
      doc.text("got you covered. Book paddleboard tours, white water", 17, 112);
      doc.text("rafting, floats, kayaking tours, or rent your own river", 17, 117);
      doc.text("equipment! Visit ", 17, 122);
      doc.setTextColor(222, 168, 69); // Establecer color amarillo
      doc.textWithLink("t49.ca ", 44, 122, { url: "https://www.t49.ca" });
      doc.setTextColor("normal"); // Establecer color normal
      doc.text("to discover more.", 55, 122);


      // Van 13 image
      const imgWidthVan = 90;
      const imgOriginalWidthVan = 400;
      const imgOriginalHeightVan = 266;
      const imgHeightVan = ((imgWidthVan * imgOriginalHeightVan) / imgOriginalWidthVan) - 5;
      const imgMarginTopVan = 35;
      // const imgMarginLeft = (doc.internal.pageSize.width - imgWidthVan) / 2;
      const imgMarginLeftVan = 110;
      doc.addImage(compressedImgVan, "JPG", imgMarginLeftVan, imgMarginTopVan, imgWidthVan, imgHeightVan);

      doc.setFontSize(10); // El tamaño del texto a 10

      doc.text("Tunnel49 is the convenient and reliable service for your", 112, 97);
      doc.text("transportation needs! Offering a range of options from a", 112, 102);
      doc.text("14-seater van to a 56-seater coach, we provide flexibility", 112, 107);
      doc.text("for different group sizes and occasions. Providing", 112, 112);
      doc.text("transportation for events like weddings and sports", 112, 117);
      doc.text("games, we alleviate stress for both hosts and guests.", 112, 122);
      doc.text("Visit ", 112, 127);
      doc.setTextColor(222, 168, 69); // Establecer color amarillo
      doc.textWithLink("t49.ca/transportation-services/", 121, 127, { url: "https://t49.ca/transportation-services/" });
      doc.setTextColor("normal"); // Establecer color normal
      doc.text("to discover.", 170, 127);



      // (Footer)
      doc.setFillColor(23, 61, 56);
      doc.rect(
        0,
        doc.internal.pageSize.height - 10,
        doc.internal.pageSize.width,
        20,
        "F"
      );

    

    doc.setFillColor(23, 61, 56);
    doc.rect(0, doc.internal.pageSize.height - 10, doc.internal.pageSize.width, 20, "F");

    doc.setFontSize(10);
    doc.setTextColor(255, 255, 255);
    const textFacebook = 'facebook.com/Tunnel49';
    const textInstragram = 'tunnel49adventures';
    const textWidthF = doc.getStringUnitWidth(textFacebook) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const textWidthI = doc.getStringUnitWidth(textInstragram) * doc.internal.getFontSize() / doc.internal.scaleFactor;

    const totalWidth = textWidthF + 10 + 20; // Ancho del icono
    const startX = (doc.internal.pageSize.width - totalWidth) / 2;

    // Agrega el texto al PDF
    doc.textWithLink(textFacebook, 65, doc.internal.pageSize.height - 5, { url: 'https://www.facebook.com/Tunnel49' });
    doc.textWithLink(textInstragram, 115, doc.internal.pageSize.height - 5, { url: 'https://www.instagram.com/tunnel49adventures' });

    // Agrega la imagen de Facebook al PDF con un enlace
    doc.addImage(facebook, 'PNG', 59, doc.internal.pageSize.height - 9, 5, 5);
    doc.link(59, doc.internal.pageSize.height - 9, 5, 5, { url: 'https://www.facebook.com/Tunnel49' });
    // Agrega la imagen de Instagram al PDF con un enlace
    doc.addImage(instagram, 'PNG', 109, doc.internal.pageSize.height - 9, 5, 5);
    doc.link(109, doc.internal.pageSize.height - 9, 5, 5, { url: 'https://www.instagram.com/tunnel49adventures' });

    }

    // Obtener los datos binarios del PDF como una cadena
    const pdfData = doc.output();

    // Codificar los datos binarios en base64
    const pdfBase64 = btoa(pdfData);

    // Devolver los datos codificados en base64
    return pdfBase64;
    
  }

}

// Función para comprimir imágenes usando Canvas
const compressImage = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Add this line to handle CORS issues if needed
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      
      // Draw white background
      ctx.fillStyle = 'white'; // Tunnel49 color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw image on top of the white background
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      }, 'image/jpeg', 0.5); // Adjust quality here (0.5 means 50% quality)
    };
    img.src = imageUrl;
  });
};


export default transConfirmationPDF;
