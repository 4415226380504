import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'

function FormConfig({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [place, setPlace] = useState("");
    const [color, setColor] = useState("");
    // const [fixedRate, setFixedRate] = useState(false);
    // const [rate, setRate] = useState("");

    useEffect(() => {
        if (lastData) {
            setId(lastData?.id);
            setPlace(lastData?.place);
            setColor(lastData?.color);
            // setFixedRate(lastData?.fixedRate);
            // setRate(lastData?.rate);
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-12'>
                    <Form.Group className="mb-3" controlId="formPlace">
                        <div className="d-inline-flex">
                            <Form.Label>Place</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Example: Fernie BC"
                            value={place}
                            onChange={(e) => setPlace(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                </div>
                {/* <div className='col-md-6'>

                    <Form.Group className="mb-3" controlId="formPlace">
                        <div className="d-inline-flex">
                            <Form.Label>Identification color</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="color"
                            value={color}
                            onChange={(e) => lastData?.color ? ("") : (setColor(e.target.value))}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                </div> */}


                <h5 className="required">The field with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {

                    var data = {
                        id,
                        place,
                        color,
                        // fixedRate,
                        // rate
                    };

                    if (place !== '') {
                        action(data);
                    } else {
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "The field are required",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                          });
                    }
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;