// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzEV1_TCDXTiFhO1mtFnnOf6oaxW0vGOM",
  authDomain: "booking-t49.firebaseapp.com",
  projectId: "booking-t49",
  storageBucket: "booking-t49.appspot.com",
  messagingSenderId: "61596200572",
  appId: "1:61596200572:web:075ec7606ccc7ad79ae3f9",
  measurementId: "G-L5LRGXNGW1"
};

const firebaseCRMConfig = {
  apiKey: "AIzaSyCByksR_BKHm1lZIDPs6ZAUwQJHUDzuiJ0",
  authDomain: "crm-t49.firebaseapp.com",
  projectId: "crm-t49",
  storageBucket: "crm-t49.appspot.com",
  messagingSenderId: "497462423907",
  appId: "1:497462423907:web:5a6746e52feccc115a2356",
  measurementId: "G-32QMS47PTD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const appCrm = initializeApp(firebaseCRMConfig, "crm-t49");
const appCrm = initializeApp(firebaseCRMConfig, 'secondary');



export const auth = getAuth(app);
export const db = getFirestore(app);
export const dbCrm = getFirestore(appCrm);
export const storage = getStorage(app);