import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../style/formClient.css'
import Select from 'react-select';
import { paymentTypes } from '../../services/Tools';
import { refundTypes } from '../../services/Tools';

function FormConfig({ title, actionPay, lastData, setModalPayment }) {

    const [id, setId] = useState("");
    const [idClient, setIdClient] = useState("");
    const [retail, setRetail] = useState([]);
    const [paid, setPaid] = useState(false);
    const [taxes, setTaxes] = useState([]);
    const [discount, setDiscount] = useState({ value: "", label: "" });
    const [discountAmount, setDiscountAmount] = useState(0);
    const [amount, setAmount] = useState("");
    const [refund, setRefund] = useState(false);
    const [refundAmount, setRefundAmount] = useState("");
    const [refundType, setRefundType] = useState({ value: "", label: "" });
    const [revert, setRevert] = useState(false);
    const [revertAmount, setRevertAmount] = useState("");
    const [revertType, setRevertType] = useState({ value: "", label: "" });
    const [tipAmount, setTipAmount] = useState(0);
    const [tipAmountPaid, setTipAmountPaid] = useState(0);
    const [tipAmountTotalPaid, setTipAmountTotalPaid] = useState(0);
    const [amountPaid, setAmountPaid] = useState("");
    const [total, setTotal] = useState("");
    const [balance, setBalance] = useState("");
    const [newClient, setNewClient] = useState(false);
    const [newClientData, setNewClientData] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [idRetail, setIdRetail] = useState("");
    const [taxAmount, setTaxAmount] = useState(""); 
    const [taxAmountGST, setTaxAmountGST] = useState("");
    const [taxAmountPST, setTaxAmountPST] = useState("");

    const [paymentType, setPaymentType] = useState({ value: "Stripe", label: "Stripe" });
    const [newAmountPaid, setNewAmountPaid] = useState("");
    const [taxAmountPaid, setTaxAmountPaid] = useState("");
    const [taxAmountPaidGST, setTaxAmountPaidGST] = useState("");
    const [taxAmountPaidPST, setTaxAmountPaidPST] = useState("");
    const [capitalPaid, setCapitalPaid] = useState(0);

    const [cardSurcharge, setCardSurcharge] = useState(true);
    const [cardSurchargeAmount, setCardSurchargeAmount] = useState(0); 
    const [cardSurchargePercentage, setCardSurchargePercentage] = useState(2.4);
    const [cardSurchargePaid, setCardSurchargePaid] = useState(0);

    useEffect(() => {
        if (lastData) {
            setId(lastData?.id);
            setIdRetail(lastData?.id);
            setIdClient(lastData?.client?.value);
            setPaid(lastData?.paid);
            // setTaxes(lastData?.taxes);
            setDiscount({ value: lastData?.discount?.value, label: lastData?.discount?.label });
            setDiscountAmount(lastData?.discountAmount ? lastData?.discountAmount : 0);
            setAmount(lastData?.amount ? lastData?.amount : 0);
            setRefund(lastData?.refund ? lastData?.refund : false);
            setRefundAmount(lastData?.refundAmount ? lastData?.refundAmount : 0);
            setRefundType(lastData?.refundType ? lastData?.refundType : { value: "", label: "" });
            setRevert(lastData?.revert ? lastData?.revert : false);
            setRevertAmount(lastData?.revertAmount ? lastData?.revertAmount : 0);
            setRevertType(lastData?.revertType ? lastData?.revertType : { value: "", label: "" });
            setTipAmount(lastData?.tipAmount ? lastData?.tipAmount : 0);
            setTipAmountPaid(lastData?.tipAmountPaid ? lastData?.tipAmountPaid : 0);
            setTipAmountTotalPaid(lastData?.tipAmountTotalPaid ? lastData?.tipAmountTotalPaid : 0); 
            setAmountPaid(lastData?.amountPaid ? lastData?.amountPaid : 0);
            setTaxAmount(lastData?.taxAmount ? lastData?.taxAmount : 0);
            setTotal(lastData?.total ? lastData?.total : 0);
            setBalance(lastData?.balance ? lastData?.balance : 0);
            setNewClient(lastData?.newClient);
            setNewClientData(lastData?.newClientData);
            setStartDate(lastData?.startDate);
            setEndDate(lastData?.endDate);
            setStartTime(lastData?.startTime);
            setEndTime(lastData?.endTime);
            // setPaymentType({ value: lastData?.paymentType?.value, label: lastData?.paymentType?.label });
            setNewAmountPaid(lastData?.balance ? lastData?.balance : 0);
            setTaxAmountPaid(lastData?.taxAmount ? lastData?.taxAmount : 0);
            setCapitalPaid(parseFloat((parseFloat(parseFloat(lastData?.balance) / (1 + (parseFloat(lastData?.taxes?.value) / 100)))).toFixed(2)));
            setTaxAmountGST(lastData?.taxAmountGST ? lastData?.taxAmountGST : 0);
            setTaxAmountPST(lastData?.taxAmountPST ? lastData?.taxAmountPST : 0);

            setCardSurcharge(lastData?.cardSurcharge ? lastData?.cardSurcharge : false);
            setCardSurchargeAmount(lastData?.cardSurchargeAmount ? lastData?.cardSurchargeAmount : 0);
            setCardSurchargePercentage(lastData?.cardSurchargePercentage ? lastData?.cardSurchargePercentage : 2.4);
            setCardSurchargePaid(lastData?.cardSurchargePaid ? lastData?.cardSurchargePaid : 0);

            if (Array.isArray(retail)) {
                let data = [...retail];
                for (let i = 0; lastData?.retail?.length > i; i++) {
                    data[i] = lastData?.retail[i];
                }
                setRetail(data);
            } else {
                let data = [];
                data[0] = lastData?.retail[0];
                setRetail(data); 
            }

            if (Array.isArray(taxes)) {
                let data = [...taxes];
                for (let i = 0; lastData?.taxes?.length > i; i++) {
                    data[i] = lastData?.taxes[i];
                }
                setTaxes(data);
            } else {
                let data = [];
                data[0] = lastData?.taxes[0];
                setTaxes(data);
            }

            changeTotal(lastData?.balance, lastData?.taxes, lastData.total, lastData?.tipAmount, lastData?.tipAmountPaid, lastData?.cardSurcharge, lastData?.cardSurchargePercentage);

        }
    }, [lastData])


    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    const changePaymentType = (e) => {
        setPaymentType(e);

        if(e.value !== "Refund"){
            setRefundType({ value: "", label: "" });
        }

        if(e.value !== "Revert"){
            setRevertType({ value: "", label: "" });
        }
    }

    const changeTotal = (e, taxe, tot, tip = 0, tipsPaid = 0, cardRequired, cardSurcharge) => {
        let tax = 0;
        let taxG = 0;
        let taxP = 0;
        let taxGA = 0;
        let taxPA = 0;
        let amo = 0;
        let amount = e;
        let capital = 0;
        let ta = 0;
        let card = 0;
        let tips = 0;
        let tipP = 0; //Se calcula el tip pagado y se guarda aca 
        let tipsTotal = 0;

        if(taxe?.length > 0){
            taxe.forEach(element => {
                if(element !== ""){
                    ta += parseFloat(element?.value);

                    if(element?.label === 'GST'){
                        taxG = parseFloat(element?.value);
                    }

                    if(element?.label === 'PST'){
                        taxP = parseFloat(element?.value);
                    }
                }
            });
        }

        if(e !== ""){
            if(parseFloat(lastData?.balance) > 0){

                if(parseFloat(tot) !== parseFloat(e)){

                    let dif = parseFloat(tot) - parseFloat(lastData?.amountPaid ? lastData?.amountPaid : 0);

                    //PREGUNTA SI EL TOTAL ES MENOR QUE EL MONTO QUE QUIERE PAGAR EL USUARIO --ESTO ES PARA OBTENER LA INFORMACION DE LA PROPINA
                    if(parseFloat(dif) < parseFloat(e)){
                        
                        let difBalance = parseFloat(e) - parseFloat(lastData?.amountPaid ? lastData?.amountPaid : 0);

                        tipP = dif < 0 ? parseFloat(dif) : 0;

                        tips = ((parseFloat(e) + parseFloat(
                            difBalance < lastData?.balance ? lastData?.amountPaid : 0
                        )) - parseFloat(tot)) + tipP;

                        amount = parseFloat(((parseFloat(e)) - (parseFloat(tips))).toFixed(2));
                        tipsTotal = parseFloat (tips) + parseFloat(tipsPaid);

                    //SI EL TOTAL ES MAYOR AL MONTO QUE QUIERE PAGAR EL USUARIO ENTRA AQUI
                    }else if(parseFloat(tot) < parseFloat(lastData?.amountPaid ? lastData?.amountPaid : 0)){

                        tips = parseFloat(e); 
                        tipsTotal = parseFloat (tips) + parseFloat(tipsPaid);

                    //PREGUNTA SI LA DIFERENCIA ES MAYOR A 0
                    }else if(parseFloat(dif) > 0){ 
                        
                        tips = parseFloat(e) > parseFloat(dif) ? (parseFloat(e) - parseFloat(dif)) : 0; 
                        amount = parseFloat(((parseFloat(e)) - (parseFloat(tips))).toFixed(2));
                        tipsTotal = parseFloat (tips) + parseFloat(tipsPaid);

                    }

                }else{

                    amount = parseFloat(((parseFloat(e)) - (parseFloat(tip) - parseFloat(tipsPaid))).toFixed(2));
                    tips = (parseFloat (tip) - parseFloat(tipsPaid));
                    tipsTotal = (parseFloat (tip) - parseFloat(tipsPaid)) + parseFloat(tipsPaid);
                }

                if(taxe !== ''){
                    let total = parseFloat(lastData?.total ? lastData?.total : 0);
                    let amoPaid = parseFloat(lastData?.amountPaid ? lastData?.amountPaid : 0);

                    if( total !== parseFloat(e)){

                        let dif = total - amoPaid;
                        //PREGUNTA SI LA DIFERENCIA ES MENOR QUE EL MONTO QUE QUIERE PAGAR EL USUARIO
                        if(parseFloat(dif) < parseFloat(e)){

                            amount = parseFloat(dif) / (1 + (parseFloat(ta) / 100));
                            taxGA =  parseFloat(amount) * ( (parseFloat(taxG) / 100));
                            taxPA =  parseFloat(amount) * ( (parseFloat(taxP) / 100));
                        
                            tax = parseFloat(dif) - parseFloat(amount);

                        //ENTRA SI LA DIFERENCIA ES MAYOR QUE EL MONTO QUE QUIERE PAGAR EL USUARIO
                        }else{
                            amount = parseFloat(e) / (1 + (parseFloat(ta) / 100));
                            taxGA =  parseFloat(amount) * ( (parseFloat(taxG) / 100));
                            taxPA =  parseFloat(amount) * ( (parseFloat(taxP) / 100));
                        
                            tax = parseFloat(e) - parseFloat(amount);
                        }

                        //amo = (parseFloat(tot) > parseFloat(amount) ? parseFloat(amount) : parseFloat(tot)) / (1 + (parseFloat(taxe?.value) / 100));

                        
                        if(cardRequired){
                            if(lastData?.cardSurchargeAmount && parseFloat(lastData?.cardSurchargeAmount) > 0){

                                let dif = (parseFloat(amount) / (1 + ((parseFloat(cardSurcharge)) / 100))).toFixed(2);

                                card = (parseFloat(amount) - parseFloat(dif));                      
                                // Redondeo del dato segun el valor de este
                                card = (card % 1 >= 0.99) ? Math.ceil(card) : (card % 1 <= 0.01) ? Math.floor(card) : parseFloat(card.toFixed(2));
                            
                            }
                        }
                        
                        capital = parseFloat(amount) - parseFloat(card);


                    }else{
                        amount = parseFloat(e) / (1 + (parseFloat(ta) / 100));
                        taxGA =  parseFloat(amount) * ( (parseFloat(taxG) / 100));
                        taxPA =  parseFloat(amount) * ( (parseFloat(taxP) / 100));

                        if(cardRequired){
                            if(lastData?.cardSurchargeAmount && parseFloat(lastData?.cardSurchargeAmount) > 0){

                                let dif = (parseFloat(amount) / (1 + ((parseFloat(cardSurcharge)) / 100))).toFixed(2);

                                card = (parseFloat(amount) - parseFloat(dif));                      
                                // Redondeo del dato segun el valor de este
                                card = (card % 1 >= 0.99) ? Math.ceil(card) : (card % 1 <= 0.01) ? Math.floor(card) : parseFloat(card.toFixed(2));
                            
                            }
                        }

                        tax = parseFloat(e) - parseFloat(amount);
                        //tax = (parseFloat(tot) > parseFloat(amount) ? parseFloat(amount) : parseFloat(tot)) - parseFloat(amo);
                        capital = parseFloat(amount) - parseFloat(card);
                    }
                }

            }else{
                if(taxe?.value !== ''){

                    if(parseFloat(tipsPaid) > 0){
                        tips = parseFloat(-tipsPaid);
                    }
                    amount = ((parseFloat(e) - parseFloat(tipsPaid)) / (1 + (parseFloat(taxe?.value) / 100)));

                    tax =  (parseFloat(tot) > parseFloat(e) ? (parseFloat(e) + parseFloat(tipsPaid)) : parseFloat(tot)) - parseFloat(amount);

                    capital = parseFloat(amount);
                }
            }

        }

        setNewAmountPaid(e ? parseFloat(e) : e);
        setTaxAmountPaid(parseFloat(tax.toFixed(2)));
        setTaxAmountPaidGST(parseFloat(taxGA.toFixed(2)));
        setTaxAmountPaidPST(parseFloat(taxPA.toFixed(2)));
        setCapitalPaid(parseFloat(capital.toFixed(2)));
        setCardSurchargePaid(parseFloat(card.toFixed(2)));
        setTipAmountPaid(parseFloat(tips.toFixed(2)));
        setTipAmountTotalPaid(parseFloat(tipsTotal.toFixed(2)));
    }

    const checkPayment = (newData, copy_link = false) => {
        
        if ((newData?.newAmountPaid ? parseFloat(newData.newAmountPaid) : 0) !== 0) {

            if(newData?.paymentType?.value === "Refund"){
                if(newData?.refundType?.value === ""){
                    Swal.fire({
                        icon: "error", //Constants = success, error, warning
                        title: "Error!",
                        text: "You need to choose an option for 'Refund via'",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                    return;
                }
            }

            let newAmountPaid = parseFloat(newData?.newAmountPaid);
            let newPaid = parseFloat(amountPaid) + parseFloat(newAmountPaid);
            let newBalance = ((parseFloat(newData?.balance) + parseFloat(amountPaid)) - parseFloat(newPaid)).toFixed(2);

            let message1 = newData?.paymentType?.value == "Revert" ? ("You are trying to make a reversal, but the amount entered is greater than the balance.") :
            newData?.paymentType?.value == "Refund" ? ("You are trying to make a refund, but the amount entered is greater than the balance.") : 
                                                        ("Please select whether you would like to make a refund or reversal.");

            let message2 = newData?.paymentType?.value == "Revert" ? ("Do you want to do a reversal? This action can not be undone") :
            newData?.paymentType?.value == "Refund" ? ("Do you want to do a refund? This action can not be undone") : 
                                                        ("Please select whether you would like to make a refund or reversal.");
            
            if(parseFloat(newData?.balance) < 0){
                if(parseFloat(newBalance) > 0){
                    Swal.fire({
                        icon: "error", //Constants = success, error, warning
                        title: "Error!",
                        text: message1,
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                }else{
                    if(newData?.paymentType.value === "Stripe"){
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "We're sorry, but currently the system doesn't allow reversals or refunds through Stripe",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                        });
                    }else{
                        if(parseFloat(newAmountPaid) > 0){
                            Swal.fire({
                                icon: "error", //Constants = success, error, warning
                                title: "Error!",
                                text: "We're sorry, but to make a reversals or refunds the payment has to be negative.",
                                confirmButtonColor: '#173d38',
                                confirmButtonText: "OK",
                            });
                        }else{
                        if(newData?.paymentType?.value === "Revert" || newData?.paymentType?.value === "Refund"){
                            Swal.fire({
                                title: 'Are you sure?',
                                text: message2,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#173d38',
                                confirmButtonText: 'Confirm',
                                cancelButtonColor: '#dc3545',
                                cancelButtonText: 'Cancel',
                                }).then((result) => {
                                if (result.isConfirmed) {
                                    newData['balance'] = parseFloat(newBalance);
                                    newData['amountPaid'] = parseFloat(newPaid);
                                    if(parseFloat(newBalance) === 0){
                                        newData['paid'] = true;
                                    }else{
                                        newData['paid'] = false;
                                    }
                                    
                                    actionPay(newData, copy_link);
                                }
                            });
                        }else{
                            Swal.fire({
                                icon: "error", //Constants = success, error, warning
                                title: "Error!",
                                text: "Please select whether you would like to make a refund or reversal.",
                                confirmButtonColor: '#173d38',
                                confirmButtonText: "OK",
                            });
                        }
                        }
                    }
                }
            }else{
                if(parseFloat(newBalance) < 0){
                    // Swal.fire({
                    //     icon: "error", //Constants = success, error, warning
                    //     title: "Error!",
                    //     text: "Payment cannot be more than balance, balance is "+ newData?.balance,
                    //     confirmButtonColor: '#173d38',
                    //     confirmButtonText: "OK",
                    // });
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "Do you want to pay with a $" + parseFloat(-newBalance)+ " tip? This action can not be undone",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#173d38',
                        confirmButtonText: 'Confirm',
                        cancelButtonColor: '#dc3545',
                        cancelButtonText: 'Cancel',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            newData['balance'] = 0;
                            newData['tipAmount'] =  parseFloat(tipAmount) + parseFloat(-newBalance);
                            newData['amountPaid'] = parseFloat(newPaid);
                            newData['paid'] = false;

                            actionPay(newData, copy_link);
                        }
                    });
                }else{
                    if(newAmountPaid < 0.50 && newData?.paymentType.value === "Stripe"){
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "Sorry, Stripe does not allow payments under $0.50, and you are trying to pay $"+ newAmountPaid,
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                        });
                    }else{
                        Swal.fire({
                            title: 'Are you sure?',
                            text: 'This action can not be undone',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#173d38',
                            confirmButtonText: 'Confirm',
                            cancelButtonColor: '#dc3545',
                            cancelButtonText: 'Cancel',
                            }).then((result) => {
                            if (result.isConfirmed) {
                                newData['balance'] = parseFloat(newBalance);
                                newData['amountPaid'] = parseFloat(newPaid);
                                if(parseFloat(newBalance) === 0){
                                    newData['paid'] = true;
                                }else{
                                    newData['paid'] = false;
                                }

                                actionPay(newData, copy_link);
                            }
                        });
                    }
                }
            }
        } else {
            Swal.fire({
                icon: "error", //Constants = success, error, warning
                title: "Error!",
                text: "Please add a quantity other than 0",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
        }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <div>
                        <Form.Group className="mb-3">
                            <div className="d-inline-flex">
                                <Form.Label>Payment Type</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Select placeholder={paymentTypes[0].value} options={paymentTypes} value={paymentType}
                                onChange={(e) => changePaymentType(e)} />
                        </Form.Group>  
                    </div>

                    { paymentType.value === "Refund" ? (
                    <> 
                    <div>
                        <Form.Group className="mb-3">
                            <div className="d-inline-flex">
                                <Form.Label>Refund via</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Select placeholder={refundTypes[0].value} options={refundTypes} value={refundType}
                                onChange={(e) => setRefundType(e)} />
                        </Form.Group>
                    </div>
                    </>
                    ) : ""}

                    { paymentType.value === "Revert" ? (
                    <> 
                    <div>
                        <Form.Group className="mb-3">
                            <div className="d-inline-flex">
                                <Form.Label>Revert via</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Select 
                                placeholder={refundTypes[0].value} 
                                options={refundTypes} 
                                value={revertType}
                                onChange={(e) => setRevertType(e)} 
                                />
                        </Form.Group>
                    </div>
                    </>
                    ) : ""}
                </div>

                <div className='col-md-6'>
                    <div>
                        <Form.Group  className="mb-3">
                            <div className="d-block" style={{ width: "100%" }}>
                                <div style={{ width: "100%" }}>
                                    <div className="d-inline-flex">
                                        <Form.Label>Total to pay </Form.Label>
                                        <h5 className="required">*</h5>
                                    </div>
                                </div>

                                <div className="d-inline-flex" style={{ width: "100%" }}>
                                    <Form.Label style={{ margin: "auto", padding: "7px" }}><strong>$</strong></Form.Label>
                                    <Form.Control type="number" placeholder="0"
                                        value={newAmountPaid}
                                        onChange={(e) => changeTotal(e.target.value, taxes, total, lastData?.tipAmount, lastData?.tipAmountPaid, lastData?.cardSurcharge, lastData?.cardSurchargePercentage)}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <h5 className="required">The field with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModalPayment(false) }}>Cancel</Button>

                <Button style={{ background: '#173d38' }} className='button-save' onClick={() => {
                    var newData = {
                        id,
                        idRetail,
                        idClient,
                        paid,
                        taxes,
                        discount,
                        discountAmount,
                        amount,
                        amountPaid,
                        taxAmount,
                        taxAmountGST,
                        taxAmountPST,
                        taxAmountPaidGST,
                        taxAmountPaidPST,
                        refund,
                        refundAmount,
                        refundType,
                        revert, 
                        revertAmount,
                        revertType,
                        tipAmount,
                        tipAmountPaid,
                        tipAmountTotalPaid,
                        total,
                        balance,
                        newClient,
                        newClientData,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                        cardSurcharge, 
                        cardSurchargeAmount, 
                        cardSurchargePercentage, 
                        cardSurchargePaid,
                        paymentType,
                        newAmountPaid,
                        taxAmountPaid,
                        capitalPaid,
                        retail,
                    };
                    checkPayment(newData);
                }
                }>
                    Make Payment
                </Button>

                { paymentType?.label === "Stripe" ? (
                <Button style={{ background: '#173d38' }} className='button-save' onClick={() => {
                    var newData = {
                        id,
                        idRetail,
                        idClient,
                        paid,
                        taxes,
                        discount,
                        discountAmount,
                        amount,
                        amountPaid,
                        taxAmount,
                        taxAmountGST,
                        taxAmountPST,
                        taxAmountPaidGST,
                        taxAmountPaidPST,
                        refund,
                        refundAmount,
                        refundType,
                        revert, 
                        revertAmount,
                        revertType,
                        tipAmount,
                        tipAmountPaid,
                        tipAmountTotalPaid,
                        total,
                        balance,
                        newClient,
                        newClientData,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                        cardSurcharge, 
                        cardSurchargeAmount, 
                        cardSurchargePercentage, 
                        cardSurchargePaid,
                        paymentType,
                        newAmountPaid,
                        taxAmountPaid,
                        capitalPaid,
                        retail,
                    };
                    checkPayment(newData, true);
                }
                }>
                    Copy link
                </Button>
                ) : ("") }
            </div>
        </form>
    );
}

export default FormConfig;