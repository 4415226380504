import Swal from 'sweetalert2';
import { API } from '../../emailNotification'; // Importa la configuración del API

export const sendEmail = async (recipient, subject, text, pdfBytes) => {
  try {
    const response = await fetch(`${API}send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ recipient, subject, text, pdfBytes }),
    });

    if (response.ok) {
      // El correo se ha enviado correctamente
      console.log("Email sent successfully!");
      Swal.fire({
        icon: "success",
        title: "Email sent successfully!",
        text: "The email has been sent successfully.",
        confirmButtonColor: '#173d38',
        confirmButtonText: "Ok",
      });
    } else {
      // Error al enviar el correo
      console.log("Failed to send email");
      throw new Error('Failed to send email');
    }
  } catch (error) {
    console.error('Failed to send email:', error);
    // Muestra un mensaje de error al usuario
    Swal.fire({
      icon: "error",
      title: "Email failed!",
      text: "Failed to send email. Please try again later.",
      confirmButtonColor: '#173d38',
      confirmButtonText: "Ok",
    });
  }
};
