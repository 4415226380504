import React, {useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import '../../style/calendar.css'
import { getDataRentals, editDataRentals } from '../../services/ConfigRentals';
import { getClientReservations } from '../../services/ConfigReservations';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { UserAuth } from "../../context/AuthContext";
import ModalC from "../modal";
import FormConfigR from "../bookingTable/rentals/formConfig";
import { useParams } from 'react-router-dom';
import Loading from '../loading';
import Button from '../button'; 

const now = new Date();
/*
const events = [
  {
    id: 0,
    title: 'Activities',
    allDay: true,
    start: new Date(2022, 11, 0),
    end: new Date(2022, 11, 1),
    status: 'Activities',
  },
  {
    id: 14,
    title: 'Rentals',
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
    status: 'Rentals',
  },
  {
    id: 15,
    title: 'Reservations',
    start: now,
    end: now,
    status: 'Reservations',
  }
]
*/
const localizer = momentLocalizer(moment);

function MyCalendar({ clients }){

  const [myEvents, setEvents] = useState([]);
  // const [dataA, setDataA] = useState({ rows: [] });
  const [dataR, setDataR] = useState({ rows: [] });
  // const [dataReser, setDataReser] = useState({ rows: [] });
  const [pending, setPending] = useState(true);
  const [pendingReser, setPendingReser] = useState(true);
  const [modalA, setModalA] = useState(false);
  const [modalR, setModalR] = useState(false);
  const [modalReser, setModalReser] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [edit, setEdit] = useState(null);
  const { user } = UserAuth();
  const [ reservationData, setReservationData ] = useState({rows: []});
  let { tap } = useParams();

  useEffect(() => {
    if (user != null) {
      getDataRentals(setDataR, setPending);
    }
  }, [user]);


  useEffect(() => {
    if (dataR?.rows?.length !== 0) {
      setEvents([]);
      // if(tap?.length > 1){
      //   prepareActivites(reservationData.rows);
      // }else{
        prepareActivites(dataR?.rows);
      // }
    }
  }, [dataR]);

  const prepareActivites = (data) => {
    data.forEach(element => {
      if(element?.refund && element?.balance === 0){

      }else{
        if(element?.rental){
          for (let i = 0; element?.rental?.length > i; i++) {
            if(element?.rental?.[i] !== ""){
              let event  = ({
                id: element.id,
                title: element?.rental?.[i]?.label + " " +element?.pickupTime + "-" + element?.dropOffTime,
                start: new Date(element?.pickupDate+"T"+element?.pickupTime),
                end: new Date(element?.dropOffDate+"T"+element?.dropOffTime),
                status: 'Rentals',
                color: element?.rental?.[i]?.value,
                rental: element
              });
              setEvents((prev) => [...prev, event]);
            }
          }
        }
      }
    });
  }

  const action = (data) => {
    if (edit) {
      if(data?.rental){
        editDataRentals(data, true);
        setModalR(false);
      }
    } 
    setTimeout(() => {
      getDataRentals(setDataR, setPending);
    }, 500);
  }

  // No delete, is import for future
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt('New Event Name')
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }])
      }
    },
    [setEvents]
  )

  const handleSelectEvent = useCallback(
    (event) => {
      if(event?.status === 'Rentals'){
        setModalR(true); 
        setEdit(event?.rental);
        setTitleModal('Edit rental');
      }
    },
    []
  )

  const eventPropGetter = useCallback(
    (event) => {

      let backgroundColor = event?.color;// ? "#173d38" : "green";
      return { style: { backgroundColor } }
      
    },[]
  )

  return (
    <>
    <div className="App calendar">
      { tap?.length > 1 ? (
          !pendingReser ? (
            reservationData?.rows?.length > 1? (
              <>
              <Calendar
              defaultDate={ moment(dataR?.rows?.[0]?.startDate, 'YYYY-MM-DD') }
              defaultView={"month"}
              localizer={localizer}
              resizable
              style={{ height: "100vh" }}
              eventPropGetter={eventPropGetter}
              events={myEvents}
              onSelectEvent={handleSelectEvent}
              //onSelectSlot={handleSelectSlot}
              selectable
            />
            </>
            ) : (
              <>
              <Calendar
                defaultDate={ moment().toDate() }
                defaultView={"month"}
                localizer={localizer}
                resizable
                style={{ height: "100vh" }}
                eventPropGetter={eventPropGetter}
                events={myEvents}
                onSelectEvent={handleSelectEvent}
                //onSelectSlot={handleSelectSlot}d
                selectable
              />
              </>
            )

          ) : ( 
            <div className='d-flex justify-content-center'>
              <Loading></Loading>
            </div>
          )
      ) : (

          pending ? (
              <div className='d-flex justify-content-center'>
                <Loading></Loading>
              </div>
          ) : (
            <>

            <Calendar
                defaultDate={ moment().toDate() }
                defaultView={"month"}
                localizer={localizer}
                resizable
                style={{ height: "100vh" }}
                eventPropGetter={eventPropGetter}
                events={myEvents}
                onSelectEvent={handleSelectEvent}
                //onSelectSlot={handleSelectSlot}d
                selectable
              />
            </>
          )
          
      ) }

      <ModalC
        show={modalR}
        onHide={() => setModalR(false)}
        changeSize={true}> 
        <FormConfigR title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModalR} clients={clients}></FormConfigR>
      </ModalC> 

    </div>
    </>
  );
  
}

export default MyCalendar;