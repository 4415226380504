import jsPDF from "jspdf";
import "jspdf-autotable";
import imgw from "../../assets/Word_Logo_Color_Cream.png";
// import imgz from "../../assets/Horizontal_Logo.png";

const generateInvoicePDF = async (data, client, download = true) => {

  const doc = new jsPDF();

    // Comprime imagen antes de agregarla al PDF
    const compressedImgw = await compressImage(imgw);
  
  // Header
  doc.setFillColor(23, 61, 56);
  doc.rect(0, 0, doc.internal.pageSize.width, 25, "F");

  // Company image
  const imgWidth = 60;
  const imgOriginalWidth = 8105;
  const imgOriginalHeight = 1641;
  const imgHeight = (imgWidth * imgOriginalHeight) / imgOriginalWidth;
  const imgMarginTop = 5;
  const imgMarginLeft = (doc.internal.pageSize.width - imgWidth) / 2;
  // doc.addImage(imgw, "PNG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);

  // Agrega la imagen comprimida al documento
  doc.addImage(compressedImgw, "PNG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);

  // Text of the superior rigth corner
  doc.setFontSize(8);
  doc.setTextColor(255, 255, 255);
  doc.text("802 BC-3", doc.internal.pageSize.width - 18, 10, "right");
  doc.text("Fernie, V0B1M0", doc.internal.pageSize.width - 18, 15, "right");
  doc.text("PO Box 836", doc.internal.pageSize.width - 18, 20, "right");

  // Subtítulo
  const imgzWidth = 40;
  const imgzOriginalWidth = 8105;
  const imgzOriginalHeight = 1641;
  const imgzHeight = (imgzWidth * imgzOriginalHeight) / imgzOriginalWidth;
  const imgzMarginTop = 35;
  const imgzMarginLeft = 14;
  // doc.addImage(
  //   imgz,
  //   "PNG",
  //   imgzMarginLeft,
  //   imgzMarginTop,
  //   imgzWidth,
  //   imgzHeight
  // );

  doc.setFontSize(19);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(23, 61, 56);
  doc.text("Invoice", 14, 33 + imgzHeight);

  doc.setFontSize(12);
  // doc.setFont("helvetica", "bold");
  // doc.text("Client Information", 14, 50);
  // doc.setFont("helvetica", "normal");
  // doc.text(`Contact: ${data?.user}`, 14, 60);
  // doc.text(`Customer: ${data?.client}`, 14, 67);

  // Client information
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Invoice for:", 14, 50);
  doc.setFont("helvetica", "normal");
  doc.text(`${client?.client ? client?.client : (client ? client : data?.client?.label)}`, 14, 60);
  doc.text(`${client?.email ? client?.email : ""}`, 14, 67);
  doc.text(`${client?.phone ? client?.phone : ""}`, 14, 74);
  if(client?.organisation !== ""){
    doc.text(`${client?.organisation ? client?.organisation : ""}`, 14, 81);
  }

  doc.setFont("helvetica", "bold");
  doc.text("Invoice Details", 140, 50);
  doc.setFont("helvetica", "normal");
  doc.text(`Invoice #:`, 140, 60);
  doc.text(`${data?.id.slice(0, 8)}`, 140, 67);
  // doc.text(`Order Date: ${new Date().toLocaleDateString()}`, 140, 74);
  // doc.text(`Payment Type: ${data?.paymentType?.label}`, 140, 84);
  const parts = data?.created_date ? (data?.created_date?.seconds ? data?.created_date?.toDate().toLocaleDateString() : data?.created_date.split('/') ) : "";

  const day = data?.created_date ? (!data?.created_date?.seconds ? parts[0] : 0) : "";
  const month = data?.created_date ? (!data?.created_date?.seconds ? parts[1] : 1) : "";
  const year = data?.created_date ? (!data?.created_date?.seconds ? parts[2].split(',')[0] : "") : ""; // Para eliminar la parte de la hora
  const date = data?.created_date ? (!data?.created_date?.seconds ? `${month}/${day}/${year}` : parts) : "";

  doc.text(`Order Date: ${date}`, 140, 74);

  var tableRows = [[data?.shuttle?.label, data?.nop, data?.startDate, data?.pickUp?.label, "$ "+data?.amount]];
  var extraAmount = 0;
  var extra1 = 0;

  for (let i = 0; data?.extraCost?.length > i; i++) {
    if(data?.extraCost?.[i] !== ""){
      extra1 = parseFloat(data?.extraCost?.[i]?.quantity) * parseFloat(data?.extraCost?.[i]?.value);
      var row = [data?.extraCost?.[i]?.label, data?.extraCost?.[i]?.quantity, data?.startDate, data?.pickUp?.label, "$ " + extra1];

      extraAmount = parseFloat( parseFloat(extraAmount) + parseFloat(extra1));
      tableRows.push(row);
    }
  }

  var fuelSurcharge = 0;
  if(data?.fuelSurcharge){
    var row = ["Fuel Surcharge", "1", data?.startDate, "-", "$ " + data?.fuelSurchargeAmount];
    fuelSurcharge = parseFloat(data?.fuelSurchargeAmount);

    tableRows.push(row);
  }

  // Service details table
  doc.autoTable({
    headStyles: { fillColor: [23, 61, 56] },
    head: [["SERVICE", "QTY", "START DATE", "PICK UP", "PRICE"]],
    body: tableRows,
    startY: 90,
  });

  // Totals and payment information
  const subtotal = parseFloat(data?.amount + extraAmount + fuelSurcharge);
  const startY = doc.autoTable.previous.finalY + 10;
  doc.setFontSize(12);
  doc.text(`Subtotal: $${subtotal.toFixed(2)}`, 195, startY, "right");
  // doc.text(
  //   `${data?.tax?.label} (${data?.tax?.value}%): $${data.taxAmount.toFixed(2)}`,
  //   195,
  //   startY + 10,
  //   "right"
  // );

  var startY2 = startY; 

  if(data?.discountAmount > 0){
    startY2 = startY + 10;
    doc.text(`Discount: $${parseFloat(data?.discountAmount).toFixed(2).padStart(4, '0')}`, 195, startY2, "right");
  }

  var startY3 = startY2 + 10;

  var tax = 0;

  // for (let i = 0; data?.taxes?.length > i; i++) {
  //   tax = (parseFloat(data?.amount) * (parseFloat(data?.taxes?.[i].value) / 100)).toFixed(2);
  //   doc.text(`${data?.taxes?.[i]?.label} (${data?.taxes?.[i]?.value}%): $${tax}`, 195, startY2, "right");
  //   startY2 = startY2 + 10;
  // }
  // for (let i = 0; data?.taxes?.length > i; i++) {
    if(data?.taxes !== ''){
      doc.text(`${data?.taxes?.label} (${data?.taxes?.value}%): $${parseFloat(data?.taxAmount).toFixed(2).padStart(4, '0')}`, 195, startY3, "right");
      startY3 = startY3 + 10;
    }



  // var startY3 = startY2;

  // for (let i = 0; data?.extraCost?.length > i; i++) {
  //   doc.text(`${data?.extraCost?.[i]?.label}: $${data?.extraCost?.[i]?.value}`, 195, startY3, "right");
  //   startY3 = startY3 + 10;
  // }

  // doc.text(`Extra cost: $${data?.extraAmount.toFixed(2)}`, 195, startY + val, "right");
  
  // text(`Extra cost: $${data?.extraAmount.toFixed(2)}`, 195, startY, "right");

  doc.text(
    `Total: $${parseFloat(data?.total).toFixed(2)}`,
    195,
    startY3,
    "right"
  );


  const tipAmount = parseFloat(data?.tipAmount) ? parseFloat(data?.tipAmount) : 0;
  tipAmount > 0 && doc.text(`Tips: $${tipAmount.toFixed(2)}`, 195, startY3 + 10, "right");

  const percentage = (100 - (parseFloat(data?.amountPaid) * 100) / parseFloat(data?.total) );
  doc.text(
    `Amount Paid: $${data?.amountPaid.toFixed(2)}`,
    195,
    tipAmount > 0 ? (startY3 + 20) : (startY3 + 10),
    "right"
  );

  doc.setFontSize(14);
  doc.text(`Balance Due (${ percentage.toFixed(0) > 0 ? percentage.toFixed(0) : 0 }%): $${data?.balance.toFixed(2)}`, 
  195, tipAmount > 0 ? (startY3 + 30) : (startY3 + 20), "right");

  // (footer)
  doc.setFillColor(23, 61, 56);
  doc.rect(
    0,
    doc.internal.pageSize.height - 20,
    doc.internal.pageSize.width,
    20,
    "F"
  );

  // footer notes
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.setTextColor(255, 255, 255);
  const footerText =
    "Notes: Deposit to be paid in full to secure the booking. The remaining balance to be cleared in full 7 days prior to trip";
  const textWidth =
    (doc.getStringUnitWidth(footerText) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
  doc.text(footerText, textOffset, doc.internal.pageSize.height - 8);

  // save and download PDF
  const currentDate = new Date();
  const dateString = currentDate.toLocaleDateString();
  const timeString = currentDate.toLocaleTimeString();

  var filename = client?.client ? (`Invoice_${client?.client.replaceAll(
    " ",
    "_"
  )}_${dateString}_${timeString}.pdf`) : (client ? (`Invoice_${client.replaceAll(
    " ",
    "_"
  )}_${dateString}_${timeString}.pdf`) : (`Invoice_${data?.client?.label.replaceAll(
    " ",
    "_")}_${dateString}_${timeString}.pdf`));

  if (download) {    
    doc.save(filename);
  } else {
    return doc.output("datauristring");
  }
};

// Función para comprimir imágenes usando Canvas
const compressImage = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Add this line to handle CORS issues if needed
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      
      // Draw white background
      ctx.fillStyle = '#173D38'; // Tunnel49 color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw image on top of the white background
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      }, 'image/jpeg', 0.5); // Adjust quality here (0.5 means 50% quality)
    };
    img.src = imageUrl;
  });
};

// const compressImage = async (imageUrl) => {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.onload = () => {
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//       canvas.width = img.width;
//       canvas.height = img.height;
//       ctx.drawImage(img, 0, 0, img.width, img.height);
//       canvas.toBlob((blob) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(blob);
//         reader.onloadend = () => {
//           resolve(reader.result);
//         };
//       }, 'image/jpeg', 0.5); // Adjust quality here (0.5 means 50% quality)
//       //}, 'image/png'); // Cambia 'image/jpeg' por 'image/png' para guardar la imagen como PNG

//     };
//     img.src = imageUrl;
//   });
// };

export default generateInvoicePDF;
