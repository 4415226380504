import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';
import { API, STRIPE_PUBLISHABLE_KEY } from '../../stripe';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export const createCheckoutSession = async (price, description, id, copy_link = false) => {
  const stripe = await stripePromise;

  const response = await fetch(`${API}create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      amount: price,
      description: description,
      id: id,
      response: !copy_link ? window.location.href : window.location.origin + "/success",
    }),
  });

  const session = await response.json();
  
  if (copy_link) {
    // Copy session.url to clipboard
    navigator.clipboard.writeText(session.url).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  
    Swal.fire({
      icon: "info",
      title: "Payment link!",
      text: "Link copied to clipboard",
      confirmButtonColor: '#173d38',
      confirmButtonText: "Ok",
    });
  
    return;
  }
  const result = await stripe.redirectToCheckout({
    sessionId: session.id,
  });

  if (result.error) {
    Swal.fire({
      icon: "error",
      title: "Payment failed!",
      text: result.error.message,
      confirmButtonColor: '#173d38',
      confirmButtonText: "Ok",
    });
  }
};
