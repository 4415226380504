import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Swal from "sweetalert2";
import '../../../style/pointOfSale/pointOfSale.css';
import { getDataProduct } from '../../../services/inventory/products';
// import Card from '@uiw/react-card';
import {Card, CardGroup} from "react-bootstrap";


function FormConfig({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    //const [identifier, setIdentifier] = useState("");
    // This is necessary to fill in the selects and reuse the code from the config request type services.
    const [product, setProduct] = useState([]);
    const [productListAux, setProductListAux] = useState([]);
    const [productList, setProductList] = useState([{ value: '', label: '' }]);

    const [balance, setBalance] = useState("");

    //Use by product List
    useEffect(() => {
        getDataProduct(setProductListAux);
    }, []);

    //Use by product List
    useEffect(() => {
        if (productListAux?.rows?.length > 0) {
            var aux = [];
            productListAux?.rows?.forEach(element => {
                aux.push({ product: element.product, stock: element.stock, price: element.price });
            });
            setProductList(aux);
        }
    }, [productListAux]);

    useEffect(() => {
        if (lastData) {
            setId(lastData.id);
            //set(lastData.);
            //setIdentifier(lastData.identifier);
            setProduct({ product: lastData.product, stock: lastData.stock, price: lastData.price });

            setBalance(lastData.balance);
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='col-md-12'>
                <div className='col-md-12'>
                    <CardGroup>
                        {productList.map((request, index) => (
                            <div key={index} className="card_body">
                                <Card style={{ width: 200, textAlign:'center', height:230 }} id={`requested-${index + 1}`}>
                                    <div style={{ textAlign:'center', height:75 }}>
                                        <h5 className="card-title">{request.product}</h5>
                                        <h6 className="card-title">${request.price}</h6>
                                    </div>
                                    <hr></hr>
                                    <div style={{ padding: 5 }}>
                                        <div style={{ padding: 5 }}>
                                        <Form.Control style={{ width: 100, margin: 'auto', textAlign: 'center' }} type="number" min="1" placeholder="1"
                                                value={balance ? (balance) : 1 }
                                                onChange={(e) => setBalance(e.target.value)}
                                                onKeyDown={(e) => keypress(e)}
                                            />
                                        </div>
                                        <Button style={{ width:150, background: '#173d38' }} className="card-text">Add</Button>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </CardGroup>
                </div>
                
            </div>
            <div className='col-md-2'>
                    <Form.Group className="mb-3" controlId="formProduct">
                        <div className="d-inline-flex">
                            <Form.Label>Product</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Select options={productList} value={product}
                            onChange={(e) => setProduct(e)} />
                    </Form.Group>  
                </div>

            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button variant="primary" className='button-save' onClick={() => {

                    var data = {
                        id,
                        product,
                        balance,
                    };

                    if (product !== '' && balance !== '' ) {
                        action(data);
                    } else {
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "All fields with (*) are required",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                          });
                    }
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;