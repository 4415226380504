import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsAgentsReportConfig } from '../../../services/columnsList';
import Button from '../../button';
import ModalC from '../../modal';
import FormConfig from './formConfig';
import Swal from "sweetalert2";
import { addDataReservations, getDataReservations, getAgentReport, editDataReservations } from '../../../services/ConfigReservations';
import { getAgentReportAdventure } from '../../../services/ConfigAdventures';
import { getAgentReportRental } from '../../../services/ConfigRentals';
import { UserAuth } from '../../../context/AuthContext';
import { Input } from 'antd';
import '../../../style/table.css'
import ExportPDF from "../../../services/exports/exportPDF";
import ExportCSV from "../../../services/exports/exportCSV";
import { transformedData } from '../../../services/exports/transformedData';
import { DateRangePicker } from "rsuite";
import { Table } from 'react-bootstrap';

function TableConfig() {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState({ rows: [] });
  const [dataAdven, setDataAdven] = useState({ rows: [] });
  const [dataRental, setDataRental] = useState({ rows: [] });
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [edit, setEdit] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();
  const [amount, setAmount] = useState("");
  const [total, setTotal] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsDate, setFilteredItemsDate] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    if (user != null) {
      getAgentReport(setData, setPending);
      getAgentReportAdventure(setDataAdven, setPending);
      getAgentReportRental(setDataRental, setPending);
    }
  }, [user]);

  useEffect(() => {
    if (data != null) {

    let newData = [];
    let amountTotal = 0;
    let totalTotal = 0;
    let allData = data?.rows.concat(dataAdven?.rows).concat(dataRental?.rows);
    
    if(allData){
      allData?.forEach(element => {
        amountTotal = parseFloat(amountTotal) + parseFloat(element?.amount);
        totalTotal = totalTotal + (parseFloat(element?.amount) * (parseFloat(element?.agents?.commission)/100));
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(parseFloat(amountTotal.toFixed(2)));
    setTotal(parseFloat(totalTotal.toFixed(2))); 
    changeFilter("");

    }
  }, [data]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const action = (data) => {
    handleClearRows();

    if (edit) {
      editDataReservations(data);
    } else {
      addDataReservations(data, true);
    }

    setModal(false);
    setTimeout(() => {
      getAgentReport(setData);
    }, 500);
  }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const editObj = (id) => {
    let aux = null;
    filteredItems.forEach(element => {
      if (element.id === id) {
        aux = element;
      }
    });
    setModal(true);
    setEdit(aux);
    setTitleModal('Edit payment');
  }

  const changeFilter = (e) => { 
    
    let newFilter = [];
    let allData = data?.rows.concat(dataAdven?.rows).concat(dataRental?.rows);

    if(allData?.length > 0){
      newFilter = allData?.filter(
        item => item?.agents?.label && item?.agents?.label.toLowerCase().includes(e.toLowerCase())
        || item?.shuttle?.label && item?.shuttle?.label.toLowerCase().includes(e.toLowerCase())
        || item?.pickUp?.label && item?.pickUp?.label.toLowerCase().includes(e.toLowerCase())
      );
      setFilteredItemsDate([]);
      setFilteredItems(newFilter);
    }
    setFilterText(e);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    let newData = [];
    let amountTotal = 0;
    let totalTotal = 0; 

    if(filteredItems){
      filteredItems.forEach(element => {
        amountTotal = parseFloat(amountTotal) + parseFloat(element?.amount);
        totalTotal = totalTotal + (parseFloat(element?.amount) * (parseFloat(element?.agents?.commission)/100));
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2)); 

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => changeFilter(e.target.value)} filtertext={filterText} />
      </>
      // <>
      //   <h5 className='search-label'>Search</h5>
      //   <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      // </>
    );
  }, [filterText, resetPaginationToggle]);

  const selectDate = (e) => {
    let aux = [];
    let newData = [];
    let date1 = getDate(new Date(e[0]));
    let date2 = getDate(new Date(e[1]));
    let amountTotal = 0;
    let totalTotal = 0; 

    filteredItems.forEach(element => {
      if(element?.startDate >= date1 && element?.startDate <= date2){
        aux.push(element);
        amountTotal = parseFloat(amountTotal) + parseFloat(element?.amount);
        totalTotal = totalTotal + (parseFloat(element?.amount) * (parseFloat(element?.agents?.commission)/100));
      }
    });
    
    if(aux?.length === 0){
      aux = [{
        agents:{commission:"0",label:"-"},
        shuttle:{value:"-",label:"-"},
        pickUp:{value:"-",label:"-"},
        shuttle:{value:"-",label:"-"},
        amount:0,
        startDate:"-",
      }];
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2)); 
    setFilteredItemsDate(aux);
    setStartDate(date1);
    setEndDate(date2);
    // setFilteredItems(aux);
  } 

  const getDate = (date) => {
    let day = `${(date.getDate())}`.padStart(2,'0');
    let month = `${(date.getMonth()+1)}`.padStart(2,'0');
    let year = date.getFullYear();
    return (`${year}-${month}-${day}`)
  }

  return (
    <div className="content">
      <div className='row'>

        <div className="col-md-6 text-center">
          <DateRangePicker 
            placeholder="Today"
            onChange={selectDate}
          />
        </div>
        <div className="d-flex justify-content-between flex-row-reverse col-md-6">
          <div className="gap-2 m-3">    
              <ExportPDF data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAgentsReportConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAgentsReportConfig(), true)} totals={[amount,total]} agents={true} filename={"Agents Report"} ></ExportPDF>
              <ExportCSV data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAgentsReportConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsAgentsReportConfig(), true)} totals={[amount,total]} agents={true} startDate={startDate} endDate={endDate} filename={"Agents Report"}></ExportCSV>
          </div>
        </div>
      </div>
      <div className="m-3">
        <DataTable
          columns={columnsAgentsReportConfig()}
          data={filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems}
          selectableRows
          onSelectedRowsChange={handleChange}
          pagination
          clearSelectedRows={toggledClearRows}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          progressPending={pending}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
        <ModalC
          show={modal}
          onHide={() => setModal(false)}>
          <FormConfig title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModal}></FormConfig>
        </ModalC>

        { amount !== "0.00" ? (

          <Table style={{ maxWidth: "400px", marginLeft: "auto" }} striped bordered hover>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Total to pay</th>
            </tr>
          </thead>
          <tbody>
            {tableList.map((item, index) => (
              <tr key={index}>
                <td>$ {item.amount}</td>
                <td>$ {item.total}</td>
              </tr>
            ))}
          </tbody>
          </Table>

        // <div style={{textAlign:"end"}}>
        //   <div className='col-md-6' style={{marginLeft:"auto"}}>
        //     <div style={{display:"inline"}}>
        //       <h4>Amount</h4> <h5>$ {amount}</h5>
        //     </div>
        //     <h4>Total</h4> <h5>$ {total}</h5>

        //   </div>
        // </div>
        ) : ("")}

      </div>
    </div>
  );
}

export default TableConfig;
