import { query, collection, getDocs, doc, setDoc, where, updateDoc } from "firebase/firestore";
import { auth, db } from '../../firebase';
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { server } from '../serverName/Bookings';

export const getDataCarts = async (setdata, setPending = null) => {

  try {
    if (auth.currentUser != null) {
      const q = query(collection(db, server + "_carts"));
      const querySnapshot = await getDocs(q);

      setdata({ rows: [] });

      var array = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        array.push(data);
      });
      setdata({
        rows: array
      });

      if (setPending) {
        setPending(false);
      }
    }
  } catch (error) {
    console.log(error);
  }
}


export const getCart = async (uuid, setData, getItemLocal = false) => {
    if (!getItemLocal)
      try {
    
        const all = query(collection(db, server === 'bookings' ? "bookings_carts" : 'dev_carts'), where('id', '==', uuid));
  
        const querySnapshot = await getDocs(all);
  
        let data = null;
        querySnapshot.forEach((doc) => {
          data = doc.data();
        });
  
        data['items'] = JSON.parse(data['items']);
        data['user'] = JSON.parse(data['user']);
  
        setData(data);
      } catch (error) {
        console.log(error);
      }
  
    return localStorage.getItem("current_cart");
}

export const setCart = async (data, id = null, message = false) => {
  try {
    if (id === null){
      id = uuid();
    }

    const docRef = doc(
      db,
      (server === 'bookings' ? "bookings_carts" : 'dev_carts'),
      id
    );

    const userId = auth.currentUser;
    let today = new Date();
    let now = today.toLocaleString();

    if (userId !== null) {
      data['created_by_id'] = userId.uid;
      data['created_by_email'] = userId.email;
      data['created_by_name'] = userId.displayName;
      data['last_modification'] = now;
      data['id'] = id;

      await setDoc(docRef, data);

      if (message) {
        Swal.fire({
          icon: "success",
          title: "Added",
          text: "Was created successfully",
          confirmButtonColor: '#173d38',
          confirmButtonText: "Ok",
        });
      }
      localStorage.setItem("current_cart", id);
    }
  } catch (error) {
    console.log(error);
  }
}

export const editCart = async (edit) => {
  try {
      const docRef = doc(db, (server === 'bookings' ? "bookings_carts" : 'dev_carts'), edit.id);
      //(server === 'bookings' ? "carts" : 'dev_carts'),
      const userId = auth.currentUser;
      let today = new Date();
      let now = today.toLocaleString();
      
      edit['updated_by_id'] = userId.uid;
      edit['updated_by_email'] = userId.email;
      edit['updated_by_name'] = userId.displayName;
      edit['updated_date'] = now;

      await updateDoc(docRef, edit);

      // Swal.fire({
      // icon: "success",
      // title: "Edited",
      // text: "The sale was edited successfully",
      // confirmButtonColor: '#173d38',
      // confirmButtonText: "Ok",
      // });
  } catch (error) {
      console.log(error);
  }
}