import { useEffect, useState, useMemo, useRef } from "react";
import DataTable from "react-data-table-component";
import { columnsNotifications } from "../../services/Tools";
import { DateRangePicker } from "rsuite";
import Swal from "sweetalert2";
import { UserAuth } from "../../context/AuthContext";
import Form from "react-bootstrap/Form";
import "../../style/table.css";
import { getAdventureNum, getRentalsNum, getReservationsNum } from "../../services/Home";
import { useNavigate } from 'react-router-dom';

function TableNotifications() {
  const [pending, setPending] = useState(true);
  const [adventures, setAdventures] = useState(0);
  const [rentals, setRentals] = useState(0);
  const [reservations, setReservations] = useState(0);
  const { user } = UserAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (user != null) {
      let today = [];
      today.push(new Date());
      today.push(new Date());
      //getData(setData, setPending);
      getAdventureNum(setAdventures, today);
      getRentalsNum(setRentals, today);
      getReservationsNum(setReservations, today);
    }
  }, [user]);

  const dateRange = (e) => {
    getAdventureNum(setAdventures, e);
    getRentalsNum(setRentals, e);
    getReservationsNum(setReservations, e);
  }

  return (
    <div className="content">
    <div className="p-4 m-3">
      <div className="gap-2 m-3">
        <Form.Group className="mb-3" controlId="formAdventure">
          <div className="d-block">
            <h2 className="text-center">Dashboard</h2>
            <br></br>
            <DateRangePicker 
              placeholder="Today"
              onChange={dateRange}
            />
          </div>
        </Form.Group>
      </div>
  
      <div className="d-flex justify-content-center flex-wrap">
        <div className="col-12 col-md-4 mb-4" style={{ cursor: 'pointer' }} onClick={() => { setTimeout(() => { navigate(`/rentals/1`); }, 200); }}>
          <h2 className="text-center mb-3">Rentals Starting</h2>
          <div className="text-center">
            <h1 className="text-primary">{rentals}</h1>
          </div>
        </div>
  
        <div className="col-12 col-md-4 mb-4" style={{ cursor: 'pointer' }} onClick={() => { setTimeout(() => { navigate(`/adventures/1`); }, 200); }}>
          <h2 className="text-center mb-3">Adventures Starting</h2>
          <div className="text-center">
            <h1 className="text-primary">{adventures}</h1>
          </div>
        </div>
  
        <div className="col-12 col-md-4 mb-4" style={{ cursor: 'pointer' }} onClick={() => { setTimeout(() => { navigate(`/booking/1`); }, 200); }}>
          <h2 className="text-center mb-3">Transports Starting</h2>
          <div className="text-center">
            <h1 className="text-primary">{reservations}</h1>
          </div>
        </div>
      </div>
    </div>
  
    <div className="card p-5 m-5" hidden>
      <div className="text-right">
        <h2>Notifications center</h2>
      </div>
      <div className="m-3">
        <DataTable
          defaultSortAsc={false}
          progressPending={pending}
        />
      </div>
    </div>
  </div>
  
  );
}

export default TableNotifications;
