import React, { useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import { Card, CardGroup, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { taxes } from '../../../services/serverName/Bookings';

const idRole = 'invoice';

const Invoice = ({data = []}) => {

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (loading) {
        // maybe trigger a loading screen
        return;
        }
        if (!user) navigate("/signin");
    }, [user, loading]);

    useEffect(() => {
      if (data) {
      // maybe trigger a loading screen
      // console.log(data);
      return;
      }

    }, [data]);


    const checkout = () => {      
      navigate(`/sale/${data?.id}`);
    }

    return (
        <div style={{ textAlign: 'initial', fontSize:15 }}>
            <h5 style={{ textAlign: 'initial' }}>INVOICE</h5>
          <hr></hr>
          <Form.Group style={{ padding: 5 }}>
            <div className="row" style={{ width: "100%" }}>
              <div className='col-12'>
                <Form.Label>{data?.items.length} Item(s) </Form.Label>
              </div>
              <div className='col-12'>
                {data?.items.length > 0 ? (
                  data?.items.map((item, index) => (
                    <div className='col-12 mb-2 border border-success p-2 mb-2 border-opacity-25 d-flex' key={index}>
                      <p className='my-auto w-100'>{item?.product}</p>
                      <p >${item.price}</p>
                    </div>
                  ))) : ("")
                }
              </div>
            </div>
          </Form.Group>
          <hr></hr>
          <Form.Group style={{ padding: 5 }}>
            <div className="d-inline-flex" style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <Form.Label>Sub Total: </Form.Label>
              </div>
              <div style={{ width: "50%", textAlign: "end" }}>
              {data?.subTotal ? (<p>$ {JSON.parse(data?.subTotal).toFixed(2)}</p>) : ("") }
              </div>
            </div>
          </Form.Group>
          <Form.Group style={{ padding: 5 }}>
            <div className="d-inline-flex" style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <Form.Label>Total: </Form.Label>
              </div>
              <div style={{ width: "50%", textAlign: "end" }}>
              {data?.total ? (<p>$ {JSON.parse(data?.total).toFixed(2)}</p>) : ("") }
              </div>
            </div>
          </Form.Group>
          <Form.Group style={{ padding: 5 }}>
            <div className="d-inline-flex" style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <Form.Label>Paid: </Form.Label>
              </div>
              <div style={{ width: "50%", textAlign: "end" }}>
              {data?.paid ? (<p>$ {JSON.parse(data?.paid).toFixed(2)}</p>) : ("") }
              </div>
            </div>
          </Form.Group>
          <Form.Group style={{ padding: 5 }}>
            <div className="d-inline-flex" style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <Form.Label>Balance: </Form.Label>
              </div>
              <div style={{ width: "50%", textAlign: "end" }}>
              {data?.balance ? (<p>$ {JSON.parse(data?.balance).toFixed(2)}</p>) : ("") }
              </div>
            </div>
          </Form.Group>
          <Form.Group style={{ padding: 5 }}>
            <Button style={{ width: "-webkit-fill-available", background: '#173d38' }}  onClick={() => { checkout() }} className="card-text">Back to sale</Button>
          </Form.Group>
        </div>
      )
}

export default Invoice;