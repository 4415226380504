import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'

function FormConfig({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [discount, setDiscount] = useState("");
    const [type, setType] = useState("");

    useEffect(() => {
        if (lastData) {
            setId(lastData.id);
            setDiscount(lastData.discount);
            setType(lastData.type);
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formType">
                        <div className="d-inline-flex">
                            <Form.Label>Type of discount</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the type of discount"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>
                </div>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formDiscount">
                        <div className="d-inline-flex">
                            <Form.Label>Discount in %</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="number" placeholder="0"
                            value={discount}
                            onChange={(e) => setDiscount(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>
                </div>
                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {
                    var data = {
                        id,
                        discount,
                        type
                    };

                    if (discount !== '' && type !== '') {
                        action(data);
                    } else {
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "The field are required",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                          });
                    }
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;