import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsClientReservation } from '../../../services/columnsList';
import Button from '../../button'; 
import ModalC from '../../modal';
import FormConfig from './formConfig';
import Swal from "sweetalert2";
import {  getClientReservations, getDataReservations, addDataReservations, editDataReservations, deleteDataReservations } from '../../../services/ConfigReservations';
// import {  getDataInvoice } from '../../../services/invoice';
import { UserAuth } from '../../../context/AuthContext';
import { Input } from 'antd';
import '../../../style/table.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function TableConfig() {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState({ rows: [] });
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [edit, setEdit] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();
  let { id } = useParams();
  const navigate = useNavigate();
  // const [allBookings, setAllBookings ] = useState(true);
  // const [dataInvoiceList, setDataInvoiceList] = useState({ rows: [] });



  useEffect(() => {
    if (user != null) {
      getClientReservations(id,setData, setPending);    
      // getDataInvoice(setDataInvoiceList);
    }
  }, [user]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const action = (data, lastData) => {
    handleClearRows(); 
    if (edit) {
      editDataReservations(data, true, lastData);
    } else {
      addDataReservations(data, true);
    }

    // setFilterText('');
    setPending(true);
    setModal(false);
    setTimeout(() => {   
      // getDataReservations(setData, setPending, !allBookings);
      getClientReservations(id,setData, setPending);    
      // getDataInvoice(setDataInvoiceList);
    }, 500);
  }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const editObj = (id) => {
    let aux = null;
    filteredItems.forEach(element => {
      if (element.id === id) {
        aux = element;
      }
    });
    setModal(true);
    setEdit(aux);
    setTitleModal('Edit reservation');
  }

  const deleteObj = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let aux = null;
        filteredItems.forEach(element => {
          if (element.id === id) {
            aux = element;
          }
        });
        setPending(true);
        setData({ rows: [""] });
        deleteDataReservations(aux);
        Swal.fire({
          title: 'Deleted!',
          text: 'Has been removed successfully.',
          icon: 'success',
          confirmButtonColor: '#173d38',
        })        
        setModal(false);
        setTimeout(() => {
          getClientReservations(id,setData, setPending);
          // handleClearRows();
        }, 500)

      }
    })
  }

  const filteredItems = data.rows.filter(
    item => item?.client?.label && item?.client?.label.toLowerCase().includes(filterText.toLowerCase()) ||
    item?.shuttle?.label && item?.shuttle?.label.toLowerCase().includes(filterText.toLowerCase()) ||
    item?.leadStatus?.label && item?.leadStatus?.label.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const goToClient = () => {
    navigate(`/clients`);
  }
  const reloadPage = () => {
    window.location.reload(false);
  }

  const showOnCalendar = () =>{
    if(data){
      navigate(`/booking/${data?.rows?.[0]?.idClient}`);
    }
    // window.location.reload(false);
  }

  return (

    <>
      <div className="text-center">
        <h1>{ data?.rows?.[0] ? (data?.rows?.[0]?.client?.label) : ("")}</h1>
      </div>

    <div className="content">
      <div className="m-2">
        { data?.rows?.length > 0 ? (
          <>
          <div className="inline-row-reverse mx-5 p-1 text-end">
            <Button className="color-green btn btn-outline-secondary mx-1" onClick={() => { goToClient() }}>Go to client list.</Button>
            <Button className="color-green btn btn-outline-secondary mx-3" onClick={() => { showOnCalendar()}}>Calendar View</Button>
          </div>
        </>
        ) : ("") }

        { 
        
        !pending ? ( 
        
        data?.rows?.[0] ?
        (
        
        <div className="m-5">
          <DataTable
            columns={columnsClientReservation(editObj, deleteObj)}
            data={filteredItems}
            selectableRows
            onSelectedRowsChange={handleChange}
            pagination
            clearSelectedRows={toggledClearRows}
            defaultSortFieldId={9}
            defaultSortAsc={false}
            progressPending={pending}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
          />
          <ModalC
            show={modal}
            onHide={() => setModal(false)}
            changeSize={true}> 
            <FormConfig title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModal}></FormConfig>
          </ModalC>
        </div>
        )

        : (
          <div className="text-center">
            <h2>The client has no reservations to display.</h2>
            <Button className="color-green btn btn-outline-secondary p-2 m-4" onClick={() => { reloadPage() }}>Load page again.</Button>
            <Button className="color-green btn btn-outline-secondary p-2 m-4" onClick={() => { goToClient() }}>Go to client list.</Button>
          </div>
        )
        ) : (<h2 className='text-center'>Loading...</h2>)
        }
      </div>
    </div>
    </>
  );
}

export default TableConfig;
