import React from "react";
import "../style/style.css";

const Loading = ({small = false}) => {
    return (
        <div className="spinner-container">
            { small ? (
                <div className="loading-spinner-secondary">
                </div>
            ) : (
                <div className="loading-spinner">
                </div>
            )}
        </div>
    );
};

export default Loading;