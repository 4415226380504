import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import '../style/booking.css'
import Accordion from 'react-bootstrap/Accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Agents from '../components/shuttleSettings/agents/tableConfig';
import Products from '../components/inventory/products/tableConfig';
import RentalItem from '../components/rentalSettings/rentalItem/tableConfig';
import RetailItem from '../components/retailSettings/retailItem/tableConfig';
import Adventure from '../components/adventureSettings/adventures/tableConfig';
import Dietary from '../components/adventureSettings/dietary/tableConfig';
import LeadOwner from '../components/dynamicList/leadOwner/tableConfig';
import LeadSource from '../components/dynamicList/leadSource/tableConfig';
import Shuttle from '../components/shuttleSettings/shuttle/tableConfig';
import Vehicle from '../components/shuttleSettings/vehicle/tableConfig';
import SearchedOptions from '../components/shuttleSettings/searchedOptions/tableConfig';
import PaymentType from '../components/dynamicList/paymentType/tableConfig';
import TypesOfTaxes from '../components/dynamicList/typesOfTaxes/tableConfig';
import Discounts from '../components/dynamicList/discounts/tableConfig';
import ExtraCost from '../components/shuttleSettings/extraCost/tableConfig';
import ExtraCostAdventure from '../components/adventureSettings/extraCost/tableConfig';
import Places from '../components/shuttleSettings/places/tableConfig';
import { getDataOneUser } from '../services/auth/users';
import { roleVisit } from "../services/Tools";
import Loading from "../components/loading";

import 'react-tabs/style/react-tabs.css';

const idRole = 'settings';

const Settings = () => {

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [userInfo, setUserInfo ] = useState('');
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        if (loading) {
          return;
        }
        //if (!user) navigate("/signin");
        if (user != null) {
          const getUserData = async () => {
            try {
              const userDocSnap = await getDataOneUser(user.uid);
              if (userDocSnap?.length > 0) {
                const userRole = userDocSnap[0]?.role;
                if (userRole !== roleVisit) {
                  setShowLoading(false);
                  setUserInfo(userDocSnap[0]);
                } else {
                  localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
                  window.location.href = '/home';
                }
              }
            } catch (error) {
              console.error('Error fetching user data, getDataOneUser Settings:');
              // Manejar el error apropiadamente, por ejemplo, mostrar un mensaje de error al usuario
            }
          };
      
          getUserData();
        } else {
          navigate("/signin");
        }

      }, [user, loading]);

    return (
      <div className="container">
        { showLoading ? (
        <>
        <div style={{textAlign:"center"}}>
          <Loading></Loading>
        </div>
        </>
        ) 
        : (
        <>
        <div className='row'>
          <div className='col-12 text-center mb-5'>
            <h1 className='display-3'>Settings</h1>
          </div>
          <div className='col-12'>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Transport settings</Accordion.Header>
                  <Accordion.Body>
                    <Tabs>
                      <TabList>
                        <Tab>Places</Tab>
                        <Tab>Extra cost</Tab>
                        <Tab>Shuttle</Tab>
                        <Tab>Vehicle</Tab>
                        <Tab>Searched</Tab>
                      </TabList>

                      <TabPanel>
                          <Places></Places>
                      </TabPanel>
                      <TabPanel>
                        <ExtraCost></ExtraCost>
                      </TabPanel>
                      <TabPanel>
                        <Shuttle></Shuttle>
                      </TabPanel>
                      <TabPanel>
                        <Vehicle></Vehicle>
                      </TabPanel>
                      <TabPanel>
                        <SearchedOptions></SearchedOptions>
                      </TabPanel>
                      
                    
                    </Tabs>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Adventure settings</Accordion.Header>
                  <Accordion.Body>
                  <Tabs>
                    <TabList>
                      <Tab>Adventure</Tab>
                      <Tab>Extra cost</Tab>
                      <Tab>Dietary</Tab>
                      {/* <Tab>Rental item</Tab> */}
                    </TabList>

                    <TabPanel>
                      <Adventure></Adventure>
                    </TabPanel>
                    <TabPanel>
                      <ExtraCostAdventure></ExtraCostAdventure>
                    </TabPanel>
                    <TabPanel>
                      <Dietary></Dietary>
                    </TabPanel>
                    {/* <TabPanel>
                      <RentalItem></RentalItem>
                    </TabPanel> */}
                  
                  </Tabs>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Rental settings</Accordion.Header>
                  <Accordion.Body>
                  <Tabs>
                    <TabList>
                      {/* <Tab>Adventure</Tab> */}
                      <Tab>Rental item</Tab>
                      {/* <Tab>Rental duration</Tab> */}
                    </TabList>

                    {/* <TabPanel>
                      <Adventure></Adventure>
                  </TabPanel> */}

                    <TabPanel>
                      <RentalItem></RentalItem>
                    </TabPanel>
                    {/* <TabPanel>
                      <Duration></Duration>
                    </TabPanel> */}
                  
                  </Tabs>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Retail settings</Accordion.Header>
                  <Accordion.Body>
                  <Tabs>
                    <TabList>
                      <Tab>Retail item</Tab>
                    </TabList>

                    <TabPanel>
                      <RetailItem></RetailItem>
                    </TabPanel>
                  
                  </Tabs>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Other settings</Accordion.Header>
                  <Accordion.Body>
                    <Tabs>
                      <TabList>
                        <Tab>Agents</Tab>
                        <Tab>Lead Source</Tab>
                        <Tab>Lead Owner</Tab>
                        <Tab>Taxes</Tab>
                        <Tab>Discounts</Tab>

                        {/* <Tab>Payment Type</Tab> */}
                      </TabList>

                      <TabPanel>
                        <Agents></Agents>
                      </TabPanel>
                      <TabPanel>
                        <LeadSource></LeadSource>
                      </TabPanel>
                      <TabPanel>
                        <LeadOwner></LeadOwner>
                      </TabPanel>   
                      <TabPanel>
                        <TypesOfTaxes></TypesOfTaxes>
                      </TabPanel>
                      <TabPanel>
                        <Discounts></Discounts>
                      </TabPanel>
                      
                      {/* <TabPanel>
                        <PaymentType></PaymentType>
                      </TabPanel> */}
                    
                    </Tabs>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
          </div>
        </div>
        </>
        )}
      </div>
      )
    }
    
export default Settings