import React, { useMemo } from "react";
import { CSVLink } from "react-csv";

const ExportCSV = ({
  data = [],
  filename,
  headers = [],
  totals = [],
  allActivities = false,
  allRevenue = false,
  startDate = null,
  endDate = null
}) => {

  // Filtrar y separar los datos con "Invoiced via QB" solo una vez
  const { invoiceViaQBData, nonInvoiceViaQBData } = useMemo(() => {
    const invoiceViaQBData = data.filter(item => item[4] === "Invoiced via QB");
    const nonInvoiceViaQBData = data.filter(item => item[4] !== "Invoiced via QB");
    return { invoiceViaQBData, nonInvoiceViaQBData };
  }, [data]);

  // // Modificar datos donde item[3] es "Refund"
  // const modifiedData = nonInvoiceViaQBData.map(item => {
  //   if (item[3] === "Refund" && item[5]) {
  //     return [...item.slice(0, 3), item[5], ...item.slice(4)];
  //   }
  //   return item;
  // });

  const modifiedData = nonInvoiceViaQBData;

  // Ordenar los datos según el valor en la posición #3 solo si allRevenue es true
  let sortedData = allActivities ? [...modifiedData].sort((a, b) => {
    if (a[4] < b[4]) return -1;
    if (a[4] > b[4]) return 1;
    return 0;
  }) : modifiedData;

  // Agregar título al inicio del CSV
  const csvData = [];

  if (startDate) {
    csvData.push(["Daily report: ", startDate, startDate ? "to" : "", endDate]);
    csvData.push([]); // Espacio vacío
  }

  // Agregar encabezados de las columnas (excluir los índices especificados)
  csvData.push(headers.filter((_, i) => ![].includes(i)));

  // Separar los datos de "Card Payments" y "E-transfer/EFT" y ordenarlos alfabéticamente
  const cardPaymentMethods = ["Visa", "Mastercard", "AMEX", "Debit"];
  const cardPaymentsData = sortedData.filter(item => cardPaymentMethods.includes(item[4])).sort((a, b) => {
    if (a[3] < b[3]) return -1; 
    if (a[3] > b[3]) return 1;
    return 0;
  });

  const eTransferMethods = ["E-transfer", "EFT"];
  const eTransferData = sortedData.filter(item => eTransferMethods.includes(item[4])).sort((a, b) => {
    if (a[3] < b[3]) return -1;
    if (a[3] > b[3]) return 1;
    return 0;
  });

  const stripeMethods = ["QuickBooks", "Stripe", "Stripe Online"];
  const stripeData = sortedData.filter(item => stripeMethods.includes(item[4])).sort((a, b) => {
    if (a[3] < b[3]) return -1;
    if (a[3] > b[3]) return 1;
    return 0;
  });

  const cashMethods = ["Cash", "Cheque"];
  const cashData = sortedData.filter(item => cashMethods.includes(item[4])).sort((a, b) => {
    if (a[3] < b[3]) return -1;
    if (a[3] > b[3]) return 1;
    return 0;
  });

  const creditMethods = ["Credit"];
  const creditData = sortedData.filter(item => creditMethods.includes(item[4])).sort((a, b) => {
    if (a[3] < b[3]) return -1;
    if (a[3] > b[3]) return 1;
    return 0;
  });

  let currentGroupKey = null;
  let totalSumsTotal = 0; // Para obtener el Gran Total
  let totalSumsTip = 0; // Para obtener el Gran Total
  let totalViaQBSums = { tip: 0, total: 0 };; // Para obtener el Gran Total
  let groupSums = { tip: 0, total: 0 };
  let cashChequeSums = {tip: 0, total: 0 };
  let creditSums = {tip: 0, total: 0 };
  let cardPaymentsSums = { tip: 0, total: 0 };
  let eTransferSums = { tip: 0, total: 0 };
  let stripeSums = { tip: 0, total: 0 };
  let invoicedViaQBSums = {tip: 0, total: 0 };

  // Función para limpiar y convertir valores de texto a número
  const parseValue = (value) => {
    return parseFloat(value.replace(/[$\s]/g, '')) || 0; 
  };

  // Filtrar el resto de los datos
   // Filtrar el resto de los datos
   const nonCardPaymentsAndETransferData = sortedData.filter(item => !cardPaymentMethods.includes(item[4]) 
   && !eTransferMethods.includes(item[4]) 
   && !stripeMethods.includes(item[4]) 
   && !cashMethods.includes(item[4])
   && !creditMethods.includes(item[4]));

  //const nonCardPaymentsAndETransferData = sortedData;

  // Función para agregar fila de resumen
  const addGroupSummaryRow = (key, sums, viaQB) => {

    if (key) {

      csvData.push(["", "", `Total for ${key}`, "", "", "",
        `$${sums.tip.toFixed(2)}`, `$${sums.total.toFixed(2)}`]);

      if(viaQB){

        totalViaQBSums.tip += parseFloat(sums.tip);
        totalViaQBSums.total += parseFloat(sums.total);

      }else{  
        totalSumsTip += parseFloat(sums.tip);
        totalSumsTotal += parseFloat(sums.total);
      }
      
      csvData.push([]);
    }
  };

  /////// Procesar y agregar datos
  nonCardPaymentsAndETransferData.forEach((item, index) => {

      const row = [];
      headers.forEach((header, i) => {
        row.push(item[i]);
      });

      // Actualizar la clave del grupo actual
      const groupKey = item[4];

      if (currentGroupKey && currentGroupKey !== groupKey) {      
            
        addGroupSummaryRow(currentGroupKey, groupSums, false);
        // Reiniciar las sumas del grupo
        groupSums = { tip: 0, total: 0 };
      }

      // Sumar los valores de las columnas al total del grupo
      groupSums.tip += parseValue(item[6]);
      groupSums.total += parseValue(item[7]);

      // Actualizar la clave del grupo actual
      currentGroupKey = groupKey;

      csvData.push(row);

      // Verificar si es el último elemento y agregar la fila de resumen
      if (index === nonCardPaymentsAndETransferData.length - 1) {

        addGroupSummaryRow(currentGroupKey, groupSums, false);
        groupSums = { tip: 0, total: 0 };
      }

    
  });
  
  //////// Agregar datos de Card Payments y su resumen
  cardPaymentsData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      row.push(item[i]);
    });

    cardPaymentsSums.tip += parseValue(item[6]);
    cardPaymentsSums.total += parseValue(item[7]);
    
    csvData.push(row);
  });

  if (cardPaymentsData.length > 0) {
    totalSumsTip += parseFloat(cardPaymentsSums.tip);
    totalSumsTotal += parseFloat(cardPaymentsSums.total);
    csvData.push(["", "", "Total for Card Payments", "", "", "", `$${cardPaymentsSums.tip.toFixed(2)}`, `$${cardPaymentsSums.total.toFixed(2)}`]);
    csvData.push([]);
  }
  
  ////// Agregar datos de E-transfer/EFT y su resumen
  eTransferData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      row.push(item[i]);
    });

    eTransferSums.tip += parseValue(item[6]);
    eTransferSums.total += parseValue(item[7]);

    csvData.push(row);
  });

  if (eTransferData.length > 0) {
    totalSumsTip += parseFloat(eTransferSums.tip);
    totalSumsTotal += parseFloat(eTransferSums.total);
    csvData.push(["", "", "Total for E-transfer/EFT", "", "", "", `$${eTransferSums.tip.toFixed(2)}`, `$${eTransferSums.total.toFixed(2)}`]);
    csvData.push([]);
  }

  ////// Agregar datos de Stripe y su resumen
  stripeData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      row.push(item[i]);
    });

    stripeSums.tip += parseValue(item[6]);
    stripeSums.total += parseValue(item[7]);

    csvData.push(row);
  });

  if (stripeData.length > 0) {
    totalSumsTip += parseFloat(stripeSums.tip);
    totalSumsTotal += parseFloat(stripeSums.total);
    csvData.push(["", "", "Total for Stripe", "", "", "", `$${stripeSums.tip.toFixed(2)}`, `$${stripeSums.total.toFixed(2)}`]);
    csvData.push([]);
  }

  ////// Agregar datos de Cash & Cheque y su resumen
  cashData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      row.push(item[i]);
    });

    cashChequeSums.tip += parseValue(item[6]);
    cashChequeSums.total += parseValue(item[7]);

    csvData.push(row);
  });

  if (cashData.length > 0) {
    totalSumsTip += parseFloat(cashChequeSums.tip);
    totalSumsTotal += parseFloat(cashChequeSums.total);
    csvData.push(["", "", "Total for Cash/Cheque", "", "", "", `$${cashChequeSums.tip.toFixed(2)}`, `$${cashChequeSums.total.toFixed(2)}`]);
    csvData.push([]);
  }

  ////// Agregar datos de Credit y su resumen
  creditData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      row.push(item[i]);
    });

    creditSums.tip += parseValue(item[6]);
    creditSums.total += parseValue(item[7]);

    csvData.push(row);
  });

  if (creditData.length > 0) {
    totalSumsTip += parseFloat(creditSums.tip);
    totalSumsTotal += parseFloat(creditSums.total);
    csvData.push(["", "", "Total for Credit", "", "", "", `$${creditSums.tip.toFixed(2)}`, `$${creditSums.total.toFixed(2)}`]);
    csvData.push([]);
  }


  /////SE MUESTRA EL GRAN TOTAL
  if (totalSumsTip && totalSumsTotal) {
    csvData.push([]);
    csvData.push(["", "", "Grand Total", "", "", "", 
      `$${parseFloat(totalSumsTip).toFixed(2)}`, `$${parseFloat(totalSumsTotal).toFixed(2)}`]);
      csvData.push([]);
  }

  currentGroupKey = null;


  csvData.push([]);
  csvData.push([]);
  csvData.push([]);

  // Agregar los datos de "Invoiced via QB" al final
  invoiceViaQBData.forEach((item, index) => {
    const row = [];
    headers.forEach((header, i) => {
        row.push(item[i]);
    });

    // Ejecutar la lógica de agrupamiento y suma solo si allRevenue es true
    const groupKey = item[4]; 

    if (currentGroupKey && currentGroupKey !== groupKey) {
      addGroupSummaryRow(currentGroupKey, invoicedViaQBSums, true);

      // Reiniciar las sumas del grupo
      invoicedViaQBSums = { tip: 0, total: 0 };

    }

    // Actualizar la clave del grupo actual
    currentGroupKey = groupKey;

    invoicedViaQBSums.tip += parseValue(item[6]);
    invoicedViaQBSums.total += parseValue(item[7]);

    csvData.push(row);
      // Verificar si es el último elemento y agregar la fila de resumen
      if (index === invoiceViaQBData.length - 1) {
        addGroupSummaryRow(currentGroupKey, invoicedViaQBSums, true);
        invoicedViaQBSums = { tip: 0, total: 0 };
      }
  });

  if (totalViaQBSums) {
    csvData.push([]);
    csvData.push(["", "", "Grand Total", "", "", "",
      `$${parseFloat(totalViaQBSums.tip).toFixed(2)}`, `$${parseFloat(totalViaQBSums.total).toFixed(2)}`]);
      csvData.push([]);
  }

  return (
    <CSVLink
      data={csvData}
      filename={filename}
      className="color-green btn btn-outline-secondary p-2 mx-2"
      target="_blank"
    >
      Export CSV
    </CSVLink>
  );
};

export default ExportCSV;
