import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import '../style/booking.css'
import Accordion from 'react-bootstrap/Accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Agents from '../components/shuttleSettings/agents/tableConfig';
import EventsAddRental from '../components/events/rentals/tableConfigAdd';
import EventsEditRental from '../components/events/rentals/tableConfigEdit';
import EventsDeleteRental from '../components/events/rentals/tableConfigDelete';

import EventsAddReservation from '../components/events/reservations/tableConfigAdd';
import EventsEditReservation from '../components/events/reservations/tableConfigEdit';
import EventsDeleteReservation from '../components/events/reservations/tableConfigDelete';

import EventsAddAdventures from '../components/events/adventures/tableConfigAdd';
import EventsEditAdventures from '../components/events/adventures/tableConfigEdit';
import EventsDeleteAdventures from '../components/events/adventures/tableConfigDelete';

import EventsAddRetails from '../components/events/retails/tableConfigAdd';
import EventsEditRetails from '../components/events/retails/tableConfigEdit';
import EventsDeleteRetails from '../components/events/retails/tableConfigDelete';

import { getDataOneUser } from '../services/auth/users';
import { roleVisit } from "../services/Tools";
import Loading from "../components/loading";
import 'react-tabs/style/react-tabs.css';

const idRole = 'events';

const Events = () => {

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [userInfo, setUserInfo ] = useState('');
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        if (loading) {
          return;
        }
        // if (!user) navigate("/signin");
        if (user != null) {
          const getUserData = async () => {
            try {
              const userDocSnap = await getDataOneUser(user.uid);
              if (userDocSnap?.length > 0) {
                const userRole = userDocSnap[0]?.role;
                if (userRole !== roleVisit) {
                  setShowLoading(false);
                  setUserInfo(userDocSnap[0]);
                } else {
                  localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
                  window.location.href = '/home';
                }
              }
            } catch (error) {
              console.error('Error fetching user data:', error);
              // Manejar el error apropiadamente, por ejemplo, mostrar un mensaje de error al usuario
            }
          };
      
          getUserData();
        } else {
          navigate("/signin");
        }

      }, [user, loading]);

    return (
      <div className="container">
        { showLoading ? (
        <>
        <div style={{textAlign:"center"}}>
          <Loading></Loading>
        </div>
        </>
        ) 
        : (
        <>
        <div className='row'>
          <div className='col-12'>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Events Transports</Accordion.Header>
              <Accordion.Body>
              <Tabs>
                <TabList>
                  <Tab>Events "Add"</Tab>
                  <Tab>Events "Edit"</Tab>
                  <Tab>Events "Delete"</Tab>
                </TabList>

                <TabPanel>
                  <EventsAddReservation></EventsAddReservation>
                </TabPanel>
                <TabPanel>
                  <EventsEditReservation></EventsEditReservation>
                </TabPanel>
                <TabPanel>
                  <EventsDeleteReservation></EventsDeleteReservation>
                </TabPanel>
           
              
              </Tabs>
              </Accordion.Body>
            </Accordion.Item>
           
            <Accordion.Item eventKey="1">
              <Accordion.Header>Events Adventures</Accordion.Header>
              <Accordion.Body>
              <Tabs>
                <TabList>
                  <Tab>Events "Add"</Tab>
                  <Tab>Events "Edit"</Tab>
                  <Tab>Events "Delete"</Tab>
                </TabList>

                <TabPanel>
                  <EventsAddAdventures></EventsAddAdventures>
                </TabPanel>
                <TabPanel>
                  <EventsEditAdventures></EventsEditAdventures>
                </TabPanel>
                <TabPanel>
                  <EventsDeleteAdventures></EventsDeleteAdventures>
                </TabPanel>
           
              
              </Tabs>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Events rentals</Accordion.Header>
              <Accordion.Body>
              <Tabs>
                <TabList>
                  <Tab>Events "Add"</Tab>
                  <Tab>Events "Edit"</Tab>
                  <Tab>Events "Delete"</Tab>
                </TabList>

                <TabPanel>
                  <EventsAddRental></EventsAddRental>
                </TabPanel>
                <TabPanel>
                  <EventsEditRental></EventsEditRental>
                </TabPanel>
                <TabPanel>
                  <EventsDeleteRental></EventsDeleteRental>
                </TabPanel>
           
              
              </Tabs>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Events retails</Accordion.Header>
              <Accordion.Body>
              <Tabs>
                <TabList>
                  <Tab>Events "Add"</Tab>
                  <Tab>Events "Edit"</Tab>
                  <Tab>Events "Delete"</Tab>
                </TabList>

                <TabPanel>
                  <EventsAddRetails></EventsAddRetails>
                </TabPanel>
                <TabPanel>
                  <EventsEditRetails></EventsEditRetails>
                </TabPanel>
                <TabPanel>
                  <EventsDeleteRetails></EventsDeleteRetails>
                </TabPanel>
           
              
              </Tabs>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </div>
        </div>
        </>
        )}
      </div>
      )
    }
    
export default Events