import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc, orderBy, where, limit, serverTimestamp  } from "firebase/firestore";
import { auth, db } from '../firebase';
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { numtoDate, formatDate } from './Tools';
import { server } from './serverName/Bookings';
import { ExcelRenderer } from 'react-excel-renderer';

export const fileHandler = (event, setData) => {
  let fileObj = event.target.files[0];
  //just pass the fileObj as parameter
  ExcelRenderer(fileObj, async (err, resp) => {
    var array = [];
    if (err) {
      console.log(err);
    }
    else {
      resp.rows.forEach(async (element, i) => {
        if (i !== 0 && element[0]) {

          if(element[8] !== "-"){
            var newObject = {
              rental: {
                value: element?.color, 
                label: element?.rental, 
                priceByDay: element?.priceByDay, 
                priceByHour: element?.priceByHour, 
                quantity: 1,

                // adultRate: element[15] ? element[15] : 0,
                // label: element[13] ? element[13] : '',
                // value: element[14] ? element[14] : '',
                // youthRate: element[16] ? element[16] : 0,
                // waterFee: true,
              },
              client: {
                label: '',
                value: '',
              },
              newClientData: element[0] ? element[0] : '',
              newClient: element[0] ? true : false,
              startDate: element[2] ? formatDate(numtoDate(element[2]), 'yyyy-MM-dd') : '',
              endDate: element[3] ? formatDate(numtoDate(element[3]), 'yyyy-MM-dd') : '',
              startTime: element[17],
              endTime: element[18],
              paid: element[6] === 0 ? true : false,
              amount: element[4] ? element[4] : 0,
              amountPaid: element[7] ? element[7] : 0,
              taxAmount: element[5] ? element[5] : 0,
              total: element[8] ? element[8] : '',
              balance: element[6] ? element[6] : 0,
              note: element[19] ? element[19] : '',
              taxes: { 
                label: 'GST',
                value: 5,
              },
              discount: { value: '', label: '' },
              discountAmount: 0,
            }
            // id,
            // client,
            // newClientData,
            // newClient,
            // bookingType,
            // agents,
            // note,
            // paid,
            // taxes,
            // discount,
            // discountAmount,
            // amount,
            // amountPaid,
            // taxAmount,
            // total, 
            // balance,
            // durationInHour,
            // pickupDate,
            // dropOffDate,
            // pickupTime,
            // dropOffTime,

            await addDataRentals(newObject);
            array.push(newObject);
          }
        }
      });
      setData({ rows: array });
    }

    Swal.fire({
      icon: "success",
      title: "Imported",
      text: "The rentals was imported successfully",
      confirmButtonColor: '#173d38',
      confirmButtonText: "Ok",
    });
  });
}

export const getDataRentals = async (setdata, setPending = null, showAll = false) => {

    try {
      if (auth.currentUser != null) {
        // const q = query(collection(db, server + "_rentals"), orderBy("pickupDate", "desc"));
        // const querySnapshot = await getDocs(q);

        let q = null;
        let querySnapshot = null;
  
        if(!showAll){
          q = query(collection(db, server + "_rentals"), orderBy("pickupDate", "desc"));
          querySnapshot = await getDocs(q);
        }else{
          let today = new Date();
          q = query(collection(db, server + "_rentals"), where('pickupDate', '==', formatDate(today, "yyyy-MM-dd")));
          querySnapshot = await getDocs(q);
        }
  
        setdata({ rows: [] });
  
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });
        setdata({
          rows: array
        });
  
        if (setPending) {
          setPending(false);
        }
      }
    } catch (error) {
      console.log(error)
    }
}

export const getAgentReportRental = async (setdata, setPending = null) => {
  try {
 
    if (auth.currentUser != null) {
        const all = query(collection(db, server === 'bookings' ? "bookings_rentals" : "dev_rentals"), 
        where("bookingType", "==", "Agent"), where("balance","==",0));
        const querySnapshot = await getDocs(all);

        setdata({ rows: [] });
        
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });

        setdata({
          rows: array
        });

        if (setPending) {
          setPending(false);
        }
    }

  } catch (error) {
    console.log(error)
  }
}

export const getEventsAddRental = async (setdata, setPending = null) => {
  try {
 
    if (auth.currentUser != null) {
        const all = query(collection(db, server === 'bookings' ? "bookings_events_add_rentals" : "dev_events_add_rentals"), 
        orderBy("created_date", "desc"), limit(300));
        
        const querySnapshot = await getDocs(all);

        setdata({ rows: [] });
        
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });

        setdata({
          rows: array
        });

        if (setPending) {
          setPending(false);
        }
    }

  } catch (error) {
    console.log(error)
  }
}

export const getEventsEditRental = async (setdata, setPending = null) => {
  try {
 
    if (auth.currentUser != null) {
        const all = query(collection(db, server === 'bookings' ? "bookings_events_edit_rentals" : "dev_events_edit_rentals"), 
        orderBy("updated_date", "desc"), limit(300));

        const querySnapshot = await getDocs(all);

        setdata({ rows: [] });
        
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });

        setdata({
          rows: array
        });

        if (setPending) {
          setPending(false);
        }
    }

  } catch (error) {
    console.log(error)
  }
}

export const getEventsDeleteRental = async (setdata, setPending = null) => {
  try {
 
    if (auth.currentUser != null) {
        const all = query(collection(db, server === 'bookings' ? "plan_1_delete_911_rentals" : 'dev_delete_911_rentals'),
        orderBy("deleted_date", "desc"), limit(300));

        const querySnapshot = await getDocs(all);

        setdata({ rows: [] });
        
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });

        setdata({
          rows: array
        });

        if (setPending) {
          setPending(false);
        }
    }

  } catch (error) {
    console.log(error)
  }
}

export const addDataRentals = async (data, message = false, del = false) => {
    try {
      var id = uuid();
      const docRef = doc(
        db,
        del ? (server === 'bookings' ? "plan_1_delete_911_rentals" : 'dev_delete_911_rentals') : server + "_rentals",
        id
      );

      const userId = auth.currentUser;
      let today = new Date();
      let now = today.toLocaleString();

      if (userId !== null) {

        if (del) {
          data['deleted_by_id'] = userId.uid;
          data['deleted_by_email'] = userId.email;
          data['deleted_by_name'] = userId.displayName;
          data['deleted_date'] = serverTimestamp();
          data['id_event'] = data?.id ? data?.id : id;
        }else{
          data['created_by_id'] = userId.uid;
          data['created_by_email'] = userId.email;
          data['created_by_name'] = userId.displayName;
          data['created_date'] = data?.created_date ? data?.created_date : serverTimestamp();
          data['id'] = id;
        }
        
        if(!del){
          eventAddRentals(data);
        }

        await setDoc(docRef, data);
  
        if (message) {
          Swal.fire({
            icon: "success",
            title: "Added",
            text: "The rental was created successfully",
            confirmButtonColor: '#173d38',
            confirmButtonText: "Ok",
          });
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Problems with the connection to the database.",
        confirmButtonColor: '#173d38',
        confirmButtonText: "OK",
      });
    }
}

export const eventAddRentals = async (data) => {
  try {
    var id = uuid();
    const docRef = doc(db, server + "_events_add_rentals", id );
    const userId = auth.currentUser;
    data['client_name'] = data?.client?.label ? (data?.client?.label) : (data?.newClientData + " (UNREGISTERED)");

    if (userId !== null) {
      await setDoc(docRef, data);
    }
  } catch (error) {
    console.log(error);
  }
}

export const editDataRentals = async (edit, message = false, lastData = null) => {
    try {
      const docRef = doc(db, server + "_rentals", edit?.id);
      const userId = auth.currentUser;
      let today = new Date();
      let now = today.toLocaleString();

      edit['updated_by_id'] = userId?.uid ? userId?.uid : "Customer-Payment";
      edit['updated_by_email'] = userId?.email ? userId?.email : "Customer-Payment";
      edit['updated_by_name'] = userId?.displayName ? userId?.displayName : "Customer-Payment"; 
      edit['updated_date'] = serverTimestamp();

      if(lastData){
        let datas = { 
          new_data: edit, 
          old_data: lastData,
          client_name: edit?.client?.label ? (edit?.client?.label) : (edit?.newClientData + " (UNREGISTERED)"),
          id_event: edit?.id,
          updated_by_id: edit['updated_by_id'],
          updated_by_email: edit['updated_by_email'],
          updated_by_name: edit['updated_by_name'],
          updated_date: edit['updated_date'],
        };
        eventEditRentals(datas);
      }
    
      await updateDoc(docRef, edit);

      if(message){
        Swal.fire({
          icon: "success",
          title: "Edited",
          text: "The rental was edited successfully",
          confirmButtonColor: '#173d38',
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Problems with the connection to the database.",
        confirmButtonColor: '#173d38',
        confirmButtonText: "OK",
      });
    }
}

export const eventEditRentals = async (data) => {
  try {
    var id = uuid();
    const docRef = doc(db, server + "_events_edit_rentals", id );
    const userId = auth.currentUser;

    if (userId !== null) {
      await setDoc(docRef, data);
    }
  } catch (error) {
    console.log(error);
  }
}

export const deleteDataRentals = async (element) => {
    try {
        var element_duplicated = Object.assign({}, element);
        await addDataRentals(element_duplicated, false, true);

        const docRef = doc(db, server + "_rentals", element.id);
        await deleteDoc(docRef);
    } catch (error) {
        console.log(error);
    }
}