import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { GrDocumentPdf } from "react-icons/gr";
import Swal from "sweetalert2";
import '../../../style/urlPDF.css';
import Select from 'react-select';
import { paymentTypes } from '../../../services/Tools';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth, storage } from '../../../firebase';
import Loading from '../../../components/loading';

import { saveInStorageItinerary, editInStorageItinerary, saveInStorageContract, editInStorageContract } from '../../../services/localStorage/storage';
import { getDataItineraryById, getDataContractById } from '../../../services/localStorage/storage';

// import firebase from '../../../firebase';


function FormConfig({ title, data, textButton, uploadPDFObj, setModalUploadPDF, }) { 

    const [id, setId] = useState("");
    const [client, setClient] = useState("");
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");
    const [idReservation, setIdReservation] = useState("");
    const [idClient, setIdClient] = useState("");
    const [urlItinerary, setUrlItinerary] = useState("");
    const [urlContract, setUrlContract] = useState("");

    const [auxItinerary, setAuxItinerary] = useState("");
    const [auxContract, setAuxContract] = useState("");

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
   
    const [fileItinerary , setFileItinerary] = useState(null);
    const [fileNameItinerary, setFileNameItinerary]  = useState("");
    const [fileContract , setFileContract] = useState(null);
    const [fileNameContract, setFileNameContract]  = useState("");

    const [pendingItinerary, setPendingItinerary] = useState(false);
    const [pendingContract, setPendingContract] = useState(false);

    const [addItinerary, setAddItinerary] = useState(false);
    const [addContract, setAddContract] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
          if (data) {
            setId(data?.id);
    
            const formattedClient = data?.clientName || data?.client?.label || ''; // Consolidated client handling
            setClient(formattedClient);
    
            setStartDate(data?.startDate || '');
            setStartTime(data?.startTime || '');
            setEndDate(data?.endDate || '');
            setEndTime(data?.endTime || '');
            setIdReservation(data?.id || null); // Consistent null assignment
            setIdClient(data?.idClient || null);
        
            try {
                const auxI = await getDataItineraryById(data?.id);
                const auxC = await getDataContractById(data?.id);

                setUrlItinerary(auxI?.[0]?.file_url);
                setUrlContract(auxC?.[0]?.file_url);
                setAuxItinerary(auxI?.[0]);
                setAuxContract(auxC?.[0]);

            } catch (error) {
              console.error("Error fetching itinerary or contract data");
              // Handle errors gracefully (e.g., display error message to user)
            }
          }
        };
    
        fetchData();
      }, [data]);

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    const handleFileChangeItinerary = (e) => {
        const file = e.target.files[0];

        // Verifica que el archivo sea un PDF por su tipo MIME
        if (file.type !== "application/pdf") {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Please select a PDF file only.",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
            return; 
        }
    
        // Verifica que el archivo sea un PDF
        if (!file.name.toLowerCase().match(/\.(pdf)$/)) {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Please select a PDF file only.",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
            return; 
        }
    
        // Verifica que el archivo no exceda los 25MB
        const maxFileSize = 25 * 1024 * 1024; // 25MB en bytes
        if (file.size > maxFileSize) {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "The file size exceeds 25MB.",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
            return; 
        }
        
        setFileItinerary(file);
        setFileNameItinerary(file.name);
    };

    const handleFileChangeContract = (e) => {
        const file = e.target.files[0];

        // Verifica que el archivo sea un PDF por su tipo MIME
        if (file.type !== "application/pdf") {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Please select a PDF file only.",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
            return; 
        }

        // Verifica que el archivo sea un PDF
        if (!file.name.toLowerCase().match(/\.(pdf)$/)) {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Please select a PDF file only.",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
            return; 
        }

        // Verifica que el archivo no exceda los 25MB
        const maxFileSize = 25 * 1024 * 1024; // 25MB en bytes
        if (file.size > maxFileSize) {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "The file size exceeds 25MB.",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
            return; 
        }

        setFileContract(file);
        setFileNameContract(file.name);
    };

    const handleFileCancelItinerary = () => {
        setFileItinerary(null);
        setFileNameItinerary(null);
    };

    const handleFileCancelContract = () => {
        setFileContract(null);
        setFileNameContract(null);
    };

    const handleUploadItinerary = async () => {
        setPendingItinerary(true);
        if (fileItinerary) {
            let doc = {
                client: data?.clientName ? data?.clientName : data?.client?.label,
                startDate: data?.startDate,
                startTime: data?.startTime,
                endDate: data?.endDate,
                endTime: data?.endTime,
                idReservation: data?.id,
                idClient: data?.idClient,
            }

            try {            
                if (urlItinerary) {            
                    if (auxItinerary) {
                        await editInStorageItinerary(fileItinerary, auxItinerary, true, setPendingItinerary, setUrlItinerary);
                    } else {
                        const auxI = await getDataItineraryById(data?.id);
                        setUrlItinerary(auxI?.[0]?.file_url);
                        setAuxItinerary(auxI?.[0]);
            
                        if (auxI?.[0]) {
                            await editInStorageItinerary(fileItinerary, auxI?.[0], true, setPendingItinerary, setUrlItinerary);
                        }
                    }
                } else {
                    await saveInStorageItinerary(fileItinerary, doc, true, setPendingItinerary, setUrlItinerary);
                }
            } catch (error) {
                console.error('Error to proccess the request1');
            }
            

            setAddItinerary(false);
            handleFileCancelItinerary();
        }else{
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Please add a itinerary PDF to upload something",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
            setPendingItinerary(false);
        }
    };

    const handleUploadContract = async () => {
        setPendingContract(true);
        if (fileContract) {
            let doc = {
                client: data?.clientName ? data?.clientName : data?.client?.label,
                startDate: data?.startDate,
                startTime: data?.startTime,
                endDate: data?.endDate,
                endTime: data?.endTime,
                idReservation: data?.id,
                idClient: data?.idClient,
            }

            try {            
                if (urlContract) {            
                    if (auxContract) {
                        await editInStorageContract(fileContract, auxContract, true, setPendingContract, setUrlContract);
                    } else {
                        const auxC = await getDataContractById(data?.id);
                        setUrlContract(auxC?.[0]?.file_url);
                        setAuxContract(auxC?.[0]);
            
                        if (auxC?.[0]) {
                            await editInStorageContract(fileContract, auxC?.[0], true, setPendingContract, setUrlContract);
                        }
                    }
                } else {
                    await saveInStorageContract(fileContract, doc, true, setPendingContract, setUrlContract);
                }
            } catch (error) {
                console.error('Error to proccess the request2');
            }

            setAddContract(false);
            handleFileCancelContract();
        }else{
            Swal.fire({
                icon: "error", 
                title: "Error!",
                text: "Please add a contract PDF to upload something",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
            setPendingContract(false);
        }

    };

    return (
        <form>
            {/* <h2 className='title text-center'>{title}</h2> */}
            {/* <h3 className='title text-center'>For</h3> */}
            <h2 className='title text-center'>{client}</h2>

            <div className="row m-auto d-flex justify-content-center">
            <div className='col-md-6 text-center m-1 p-5 border border-dark rounded' style={{maxWidth: "45%"}}>

                { pendingItinerary ? (
                <>
                    <div style={{textAlign:"center"}}>
                        <Loading></Loading>
                    </div>
                </>
                ) 
                : (
                    <div className='upload-container d-flex flex-column justify-content-center align-items-center'>
                        <h4>Itinerary</h4>
                        <Form.Label>{fileNameItinerary}</Form.Label>

                        { !fileItinerary ? (     
                        addItinerary ? (
                                <>
                                <div className='upload-container d-flex flex-column justify-content-center align-items-center'>
                                    <input 
                                        className='p-2' 
                                        type="file" 
                                        style={{maxWidth: "44%"}} 
                                        onChange={handleFileChangeItinerary} 
                                        accept=".pdf" // Suggest PDF files to the browser
                                    />
                                    <Button variant='danger' className='button-cancel' onClick={() => { setAddItinerary(false); }}>Cancel</Button>
                                </div>
                                </>

                            ) : (
                                <>
                                    <Button style={{ background: '#173d38' }} className='button-save' onClick={() => { setAddItinerary(true); }}>
                                        Allow editing 
                                    </Button>
                                </>
                            )
                        ) : (
                            <></>
                        )}    

                        
                        <>
                            { fileNameItinerary ? (
                                <>
                                <div className='footer-buttons'>
                                    <Button variant='danger' className='button-cancel' onClick={() => {{ handleFileCancelItinerary()} }}>Cancel</Button>
                                    <Button style={{ background: '#173d38' }} className='button-save' onClick={() => { handleUploadItinerary() }}>
                                        Save
                                    </Button>
                                </div>
                                </>
                            ) : ("")}
                        </>
                        { urlItinerary ? (
                            <a 
                                className="transparent-button p-1 mx-1" 
                                onClick={() => { window.open(urlItinerary, "_blank"); }}
                                title="See the document"
                            ><GrDocumentPdf></GrDocumentPdf></a> 
                        ) : ("")}
                    </div>
                )}
                </div>

                <div className='col-md-6 text-center m-1 p-5 border border-dark rounded' style={{maxWidth: "45%"}}>

                { pendingContract ? (
                <>
                    <div style={{textAlign:"center"}}>
                        <Loading></Loading>
                    </div>
                </>
                ) 
                : (
                    <div className='upload-container d-flex flex-column justify-content-center align-items-center'>
                        <h4>Contract</h4>
                        <Form.Label>{fileNameContract}</Form.Label>

                        { !fileContract ? ( 
                        addContract ? (
                                <>
                                <div className='upload-container d-flex flex-column justify-content-center align-items-center'>
                                    <input 
                                        className='p-2' 
                                        type="file" 
                                        style={{maxWidth: "44%"}} 
                                        onChange={handleFileChangeContract} 
                                        accept=".pdf" // Suggest PDF files to the browser
                                    />
                                    <Button variant='danger' className='button-cancel' onClick={() => { setAddContract(false); }}>Cancel</Button>
                                </div>
                                </>

                            ) : (
                                <>
                                    <Button style={{ background: '#173d38' }} className='button-save' onClick={() => { setAddContract(true); }}>
                                        Allow editing 
                                    </Button>
                                </>
                            )
                        ) : (
                            <></>
                        )}    

                        
                        <>
                            { fileNameContract ? (
                                <>
                                <div className='footer-buttons'>
                                    <Button variant='danger' className='button-cancel' onClick={() => {{ handleFileCancelContract()} }}>Cancel</Button>
                                    <Button style={{ background: '#173d38' }} className='button-save' onClick={() => { handleUploadContract() }}>
                                        Save
                                    </Button>
                                </div>
                                </>
                            ) : ("")}
                        </>

                        { urlContract ? (
                            <a 
                                className="transparent-button p-1 mx-1" 
                                onClick={() => { window.open(urlContract, "_blank"); }}
                                title="See the document"
                            ><GrDocumentPdf></GrDocumentPdf></a> 
                        ) : ("")}

                    </div>
                )}
                </div>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => {{ setModalUploadPDF(false); } }}>Cancel</Button>
            </div>
        </form>
    );
}

export default FormConfig;