import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from 'react-router-dom';
import MyCalendar from '../components/calendars/calendarRetails';
// import Activities from '../components/bookingTable/adventures/tableConfig';
// import Reservations from '../components/bookingTable/reservations/tableConfig';
import Retail from '../components/bookingTable/retails/tableConfig';
import { auth } from '../firebase';
import '../style/booking.css';
import '../style/style.css';
import Accordion from 'react-bootstrap/Accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getClientReservations } from '../services/ConfigReservations';
import { getData } from "../services/crm/clients";
import { getDataOneUser } from '../services/auth/users';
import { roleVisit } from "../services/Tools";
import Loading from "../components/loading";

const idRole = 'retails';

const Retails = () => {

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [reservationData, setReservationData ] = useState({rows: []});
    const [pendingReser, setPendingReser] = useState(true);
    const [userInfo, setUserInfo ] = useState('');
    const [showLoading, setShowLoading] = useState(true);
    
    const [clientListAux, setClientListAux] = useState({ rows: [] });

    let { tap } = useParams();

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const defaultTabIndex = token || tap?.length > 0 ? 1 : 0;

    useEffect(() => {
        if (loading) {
          // setTimeout(() => {
          //   if (tap) {
          //     getClientReservations(tap, setReservationData, setPendingReser);
          //   }
          // }, 300);

          return;
        }
        //if (!user) navigate("/signin");
        if (user != null) {
          const getUserData = async () => {
            try {
              const userDocSnap = await getDataOneUser(user.uid);
              if (userDocSnap?.length > 0) {
                const userRole = userDocSnap[0]?.role;
                if (userRole !== roleVisit) {
                  setShowLoading(false);
                  setUserInfo(userDocSnap[0]);
                } else {
                  localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
                  window.location.href = '/home';
                }
              }
            } catch (error) {
              console.error('Error fetching user data:', error);
              // Manejar el error apropiadamente, por ejemplo, mostrar un mensaje de error al usuario
            }
          };
      
          getUserData();
        } else {
          navigate("/signin");
        }

      }, [user, loading]);
      
      useEffect(() => {
        if (user != null) {

          if(clientListAux?.rows?.length < 1 && defaultTabIndex === 0){
            getData(setClientListAux);
          }
          
        }
      }, [user]);


    return (
      <div className="container-dashboard">
        { showLoading ? (
        <>
        <div style={{textAlign:"center"}}>
          <Loading></Loading>
        </div>
        </>
        ) 
        : (
        <>
        <div className='row'>
          <div className='col-12'>
              <Tabs defaultIndex={defaultTabIndex}>
                <TabList> 
                  {/* <Tab>Dashboard</Tab> */}
                  {/* <Tab>Activities</Tab> */}
                  <Tab>Retails</Tab>
                  {/* <Tab>Transports</Tab> */}
                </TabList>

                {/* <TabPanel>
                  <MyCalendar clients={clientListAux}></MyCalendar>
                </TabPanel> */}
                {/* <TabPanel>
                    <Activities></Activities>
                </TabPanel> */}
                <TabPanel>
                  <Retail clients={clientListAux}></Retail>
                </TabPanel>
                {/* <TabPanel>
                  <Reservations></Reservations>
                </TabPanel> */}
              
              </Tabs>
          </div>
        </div>
        </>
        )}
      </div>
      )
    }
    
export default Retails