import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Swal from "sweetalert2";
import '../../../style/formClient.css'
import { getDataRetail } from '../../../services/retailSettings/retailItem';
import { getDataTypeOfTaxes } from '../../../services/dynamicList/typesOfTaxes';
import { getDataDiscounts } from '../../../services/dynamicList/discounts';
// import { getData } from "../../../services/crm/clients";
import { AiOutlineDelete } from "react-icons/ai";
import { differenceInDays } from 'date-fns';
import { BiAddToQueue } from "react-icons/bi";
// import { getDataAgents } from '../../../services/dynamicList/agents';
import { addData, getData /*, deleteData, editData*/ } from "../../../services/crm/clients";
import ModalC from '../../modal';
import FormClient from '../../clients/formClient';
import { formatDate } from '../../../services/Tools';
import { UserAuth } from '../../../context/AuthContext';
import { getDataOneUser } from '../../../services/auth/users';
import { roleVisit } from '../../../services/Tools';

function FormConfig({ title, action, lastData, setModal, clients }) {

    const [id, setId] = useState("");
    const [client, setClient] = useState({ value: "", label: "" });
    const [clientName, setClientName] = useState("");
    const [client_name, setClient_Name] = useState("");
    const [retail, setRetail] = useState([{ value: '', label: '', price: '', quantity: '' }]);
    const [note, setNote] = useState("");
    const [paid, setPaid] = useState(false);
    const [taxes, setTaxes] = useState([]);
    const [discount, setDiscount] = useState({ value: "", label: "" });
    const [discountAmount, setDiscountAmount] = useState(0);
    const [amount, setAmount] = useState("");
    const [amountPaid, setAmountPaid] = useState(""); 
    const [total, setTotal] = useState("");
    const [balance, setBalance] = useState("");
    const [agents, setAgents] = useState([]);
    const [bookingType, setBookingType] = useState("Directly");
    const [modalClient, setModalClient] = useState(false);
    const [titleModalClient, setTitleModalClient] = useState('');
    const [durationType, setDurationType] = useState('');
    const [duration, setDuration] = useState('');
    const [refund, setRefund] = useState(false);
    const [refundAmount, setRefundAmount] = useState(""); 
    const [tipAmount, setTipAmount] = useState(0);
    const [taxAmount, setTaxAmount] = useState("");
    const [taxAmountGST, setTaxAmountGST] = useState("");
    const [taxAmountPST, setTaxAmountPST] = useState("");

    // This is necessary to fill in the selects and reuse the code from the config request type services.
    const [retailListAux, setRetailListAux] = useState([]);
    const [retailList, setRetailList] = useState([{ value: '', label: '' }]);

    const [newClient, setNewClient] = useState(true);
    const [newClientData, setNewClientData] = useState("");

    const [taxListAux, setTaxListAux] = useState([]);
    const [taxList, setTaxList] = useState([{ value: '', label: '' }]);

    const [discountListAux, setDiscountListAux] = useState([]);
    const [discountList, setDiscountList] = useState([{ value: '', label: '' }]);

    const [clientListAux, setClientListAux] = useState([]);
    const [clientList, setClientList] = useState([{ value: '', label: '', email: '', phone: '', organization: '' }]);
    
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const { user, logOut } = UserAuth();
    const [userInfo, setUserInfo ] = useState('');

    const [cardSurcharge, setCardSurcharge] = useState(false);
    const [cardSurchargeAmount, setCardSurchargeAmount] = useState(0);
    const [cardSurchargePercentage, setCardSurchargePercentage] = useState(2.4);

    //Use by get all the List
     useEffect(() => {
        if (user != null) {
        getDataRetail(setRetailListAux);
        getDataTypeOfTaxes(setTaxListAux);
        getDataDiscounts(setDiscountListAux);
        
        if(clients?.rows?.length > 0){
            setClientListAux(clients);
        }else{
            getData(setClientListAux);
        }

        const getUserData = async () => {
            const userDocSnap = await getDataOneUser(user.uid);
            if(userDocSnap?.length > 0){
            // if(userDocSnap[0?.role !== roleVisit]){
                setUserInfo(userDocSnap[0]);
            // }
            // else{
            //   localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
            //   window.location.href = '/';
            // }
            }
        };
    
        getUserData();
        
        }
        
    }, []);

    useEffect(() => {
        // Obtener la hora actual en formato 'HH:mm'
        const getCurrentTime = () => {
          const now = new Date();
          const horas = now.getHours().toString().padStart(2, '0');
          const minutos = now.getMinutes().toString().padStart(2, '0');
          return `${horas}:${minutos}`;
        };

        // Función para obtener la fecha actual en formato 'YYYY-MM-DD'
            const getCurrentDate = () => {
            const now = new Date();
            const año = now.getFullYear();
            const mes = (now.getMonth() + 1).toString().padStart(2, '0');
            const dia = now.getDate().toString().padStart(2, '0');
            return `${año}-${mes}-${dia}`;
        };
    
        // Establecer la hora y fecha actuales al montar el componente
        const currentTime = getCurrentTime();
        const currrentDate = getCurrentDate();

        setStartTime(currentTime);
        setEndTime(currentTime);
        setStartDate(currrentDate);
        setEndDate(currrentDate);
      }, []); // El segundo argumento del useEffect es un array de dependencias, en este caso está vacío para que se ejecute solo una vez al montar el componente
    

    //Use by retails List
    useEffect(() => {
        if (retailListAux?.rows?.length > 0) {
            var aux = [];
            retailListAux?.rows?.forEach(element => {
                aux.push({ value: element?.retail, label: element?.retail, price: element?.price, quantity: 1 });
            });
            setRetailList(aux);
        }
    }, [retailListAux]);

    //Use by taxes
    useEffect(() => {
        if (taxListAux?.rows?.length > 0) {
            var aux = [];
            taxListAux?.rows?.forEach(element => {                
                if(element?.type !== "Fuel Surcharge"){
                    aux.push({ value: element?.tax, label: element?.type });
                }
            });
            setTaxList(aux);
        }
    }, [taxListAux]);

    // //Use by taxes
    // useEffect(() => {
    //     if (taxListAux?.rows?.length > 0) {
    //         var aux = [];
    //         taxListAux?.rows?.forEach(element => {
    //             aux.push({ value: element.tax, label: element.type });
    //         });
    //         setTaxList(aux);
    //     }
    // }, [taxListAux]);

    //Use by discount
    useEffect(() => {
        if (discountListAux?.rows?.length > 0) {
            var aux = [];
            discountListAux?.rows?.forEach(element => {                
                aux.push({ value: element?.discount, label: (element?.type + ( " " + element?.discount +"%")) });
            });
            setDiscountList(aux);
        }
    }, [discountListAux]);

    //Use by client List
    useEffect(() => {
        if (clientListAux?.rows?.length > 0) {
            var aux = [];
            clientListAux?.rows?.forEach(element => {   
                aux.push({ value: element?.id, label: element?.client }); 
            });
            setClientList(aux);
        }
    }, [clientListAux]);

    useEffect(() => {
        if (lastData) {
            setId(lastData?.id);
            setClient(lastData?.client);
            setNote(lastData?.note);
            setNewClient(lastData?.newClient);
            setNewClientData(lastData?.newClientData);
            setClientName(lastData?.client?.label ? lastData?.client?.label : lastData?.newClientData);
            setClient_Name(lastData?.client_name ? lastData?.client_name : (lastData?.client?.label ? lastData?.client?.label : lastData?.newClientData));
            setPaid(lastData?.paid);
            setRefund(lastData?.refund ? lastData?.refund : false);
            setRefundAmount(lastData?.refundAmount ? lastData?.refundAmount : 0);
            setTipAmount(lastData?.tipAmount ? lastData?.tipAmount : 0);
            setAmount(lastData?.amount ? lastData?.amount : 0);
            setAmountPaid(lastData?.amountPaid ? lastData?.amountPaid : 0);
            setTaxAmount(lastData?.taxAmount ? lastData?.taxAmount : 0);
            setTotal(lastData?.total ? lastData?.total : 0);
            setBalance(lastData?.balance ? lastData?.balance : 0);
            setDiscount({ value: lastData?.discount?.value, label: lastData?.discount?.label });
            setDiscountAmount(lastData?.discountAmount);
            setBookingType(lastData?.bookingType ? lastData?.bookingType : "Directly");
            setStartDate(lastData?.startDate);
            setEndDate(lastData?.endDate);
            setStartTime(lastData?.startTime);
            setEndTime(lastData?.endTime);
            setDurationType(lastData?.durationType);
            setDuration(lastData?.duration);
            setTaxAmountGST(lastData?.taxAmountGST ? lastData?.taxAmountGST : 0);
            setTaxAmountPST(lastData?.taxAmountPST ? lastData?.taxAmountPST : 0);
            setCardSurcharge(lastData?.cardSurcharge ? lastData?.cardSurcharge : false);
            setCardSurchargeAmount(lastData?.cardSurchargeAmount ? lastData?.cardSurchargeAmount : 0);
            setCardSurchargePercentage(lastData?.cardSurchargePercentage ? lastData?.cardSurchargePercentage : 2.4); 

            // setTax({ value: lastData?.tax?.value, label: lastData?.tax?.label });
            // setTaxes({ value: lastData?.taxes?.value, label: lastData?.taxes?.label });



            if(lastData?.agents?.value){
                setAgents({ value: lastData?.agents?.value, label: lastData?.agents?.label, commission: lastData?.agents?.commission });
            }

            if (Array.isArray(retail)) {
                let data = [...retail];
                for (let i = 0; lastData?.retail?.length > i; i++) {
                    data[i] = lastData?.retail[i];
                }
                setRetail(data);
            } else {
                let data = [];
                data[0] = lastData?.retail[0];
                setRetail(data);
            }

            if (Array.isArray(taxes)) {
                let data = [...taxes];
                for (let i = 0; lastData?.taxes?.length > i; i++) {
                    data[i] = lastData?.taxes[i];
                }
                setTaxes(data);
            } else {
                let data = [];
                data[0] = lastData?.taxes[0];
                setTaxes(data);
            }

        }
    }, [lastData])

    const calculateTotal = (value, taxe, disco, tips, card = cardSurcharge) => {
        // let amo = 0; //Monto sin impuestos ni descuento
        // let imp = 0; //Captura el impuesto
        let impG = 0; //Captura el GST
        let impP = 0; //Captura el PST
        let tot = 0; //total con los impuestos y descuento
        let bal = 0; //Balance = total - paid
        let paid = 0; //El monto pagado previamente
        let dis = 0; //El descuento que agreguen
        let tax = 0; //Guarda la suma de los impuestos
        let taxG = 0; //Guarda el impuesto GST
        let taxP = 0; //Guarda el impuesto PST
        let tip = tips ? tips : 0;
        let car = 0; //Se guarda el monto del card surcharge

        if(taxe?.length > 0){
            taxe.forEach(element => {
                if(element !== ""){
                    // tax += parseFloat(element?.value);
                    
                    if(element?.label === 'GST'){
                        taxG = parseFloat(element?.value);
                    }

                    if(element?.label === 'PST'){
                        taxP = parseFloat(element?.value);
                    }
                }
            });
            
            
            if(lastData){
                bal = lastData?.balance ? lastData?.balance : 0;
                paid = lastData?.amountPaid >= 0 ? lastData?.amountPaid : 0;                 
            }

            if(value !== ""){
                setAmount(parseFloat(value));
                if(card){
                    car = parseFloat(value) * (parseFloat(cardSurchargePercentage) / 100);
                }

                if(disco?.value){
                    dis = parseFloat(value) * (parseFloat(disco?.value) / 100);
                }
                
                
                if(taxG !== 0){
                    impG = ((parseFloat(value)  + parseFloat(car) - parseFloat(dis))) * (parseFloat(taxG) / 100); 
                }

                if(taxP !== 0){
                    impP = ((parseFloat(value)  + parseFloat(car) - parseFloat(dis))) * (parseFloat(taxP) / 100); 
                }

                // imp = ((parseFloat(value) - parseFloat(dis))) * (parseFloat(tax) / 100); 
                // tot = parseFloat(value) + parseFloat(imp) - parseFloat(dis); 
                tax = parseFloat(impG) + parseFloat(impP); 
                tot = parseFloat(value) + parseFloat(impG) + parseFloat(impP) + parseFloat(car) - parseFloat(dis); 
                bal = (parseFloat(tot) + parseFloat(tip)) - parseFloat(paid);

            }else{
                setAmount("");
            }

            setCardSurchargeAmount(parseFloat(car.toFixed(2)));
            setDiscountAmount(parseFloat(dis.toFixed(2)));
            setTaxAmount(parseFloat(tax.toFixed(2)));
            setTaxAmountGST(parseFloat(impG.toFixed(2)));
            setTaxAmountPST(parseFloat(impP.toFixed(2)));
            setTotal(parseFloat(tot.toFixed(2)));
            setAmountPaid(parseFloat(paid.toFixed(2)));
            setBalance(parseFloat(bal.toFixed(2)));

        }else{
            Swal.fire({
                icon: "warning",
                title: "Error!",
                text: "Please, choose the tax rate first",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
        }
    }

    const automaticTotal = (retail, taxe, disco, startDate, tips, card = cardSurcharge) => {

        let amo = 0; //Monto sin impuestos ni descuento
        // let imp = 0; //Captura el impuesto 
        let impG = 0; //Captura el impuesto GST
        let impP = 0; //Captura el impuesto PST
        let tot = 0; //total con los impuestos y descuento
        let bal = 0; //Balance = total - paid
        let paid = 0; //El monto pagado previamente
        let dis = 0; //El descuento que agregue
        let tax = 0; //Guarda la suma de los impuestos
        let taxG = 0; //Guarda el impuesto GST
        let taxP = 0; //Guarda el impuesto PST
        let car = 0; //Se guarda el monto del card surcharge

        let tip = tips ? tips : 0;

        if(lastData){
            bal = lastData?.balance ? lastData?.balance : 0;
            paid = lastData?.amountPaid >= 0 ? lastData?.amountPaid : 0;                 
        }

        if(taxe?.length > 0){
            taxe.forEach(element => {
                if(element !== ""){
                    // tax += parseFloat(element?.value);

                    if(element?.label === 'GST'){
                        taxG = parseFloat(element?.value);
                    }

                    if(element?.label === 'PST'){
                        taxP = parseFloat(element?.value);
                    }
                }
            });
        }

        if(retail?.length > 0){
            retail.forEach(element => {
                
                if(element?.value !== ""){

                    if(startDate){
                        amo = parseFloat(amo) + (parseFloat(element?.price) * parseFloat(element?.quantity));
                    }
                    
                    if(amo > 0){
                        if(disco?.value){
                            dis = parseFloat(amo) * (parseFloat(disco?.value) / 100);
                        }

                        if(card){
                            car = parseFloat(amo) * (parseFloat(cardSurchargePercentage) / 100);
                        }
    
                        // if(tax !== 0){
                        //     imp = ((parseFloat(amo) - parseFloat(dis))) * (parseFloat(tax) / 100);
                        // }

                        if(taxG !== 0){
                            impG = ((parseFloat(amo) + parseFloat(car) - parseFloat(dis))) * (parseFloat(taxG) / 100);
                        }

                        if(taxP !== 0){
                            impP = ((parseFloat(amo) + parseFloat(car) - parseFloat(dis))) * (parseFloat(taxP) / 100);
                        }
                    }

                    tax = parseFloat(impG) + parseFloat(impP); 
                    tot = parseFloat(amo) + parseFloat(impG) + parseFloat(impP) + parseFloat(car) - parseFloat(dis); 
                    bal = (parseFloat(tot) + parseFloat(tip)) - parseFloat(paid);
                }
            });
        }

        setCardSurchargeAmount(parseFloat(car.toFixed(2)));
        setDiscountAmount(parseFloat(dis.toFixed(2)));
        setAmount(parseFloat(amo.toFixed(2)));
        setTaxAmount(parseFloat(tax.toFixed(2))); 
        setTaxAmountGST(parseFloat(impG.toFixed(2))); 
        setTaxAmountPST(parseFloat(impP.toFixed(2))); 
        setTotal(parseFloat(tot.toFixed(2)));
        setAmountPaid(parseFloat(paid.toFixed(2)));
        setBalance(parseFloat(bal.toFixed(2)));

    }

    const addInputFieldRetail = (e) => {
        e.preventDefault();
        setRetail([...retail, ""]);
    }

    const handleInputChangeRetail = (index,extra,e) => {
        let data = [...retail];
        data[index] = extra;

        if(data[index]){
            data[index].quantity = e ? e : 1;
        }

        setRetail(data);
        automaticTotal(data, taxes, discount, startDate, tipAmount, cardSurcharge);
    }

    const removeFieldsRetail = (index) => {
        let data = [...retail];
        data.splice(index, 1);

        setRetail(data);
        automaticTotal(data, taxes, discount, startDate, tipAmount, cardSurcharge);
    }

    const addInputField = (e) => {
        e.preventDefault();
        setTaxes([...taxes, ""]);
    }

    const handleInputChange = (index, e) => {
        let data = [...taxes];
        data[index] = e;
        setTaxes(data);
        if(taxes.length > 0){
            automaticTotal(retail, data, discount, startDate, tipAmount, cardSurcharge);
        }
    }

    const removeFields = (index) => {
        let data = [...taxes];
        data.splice(index, 1);
        setTaxes(data);
        automaticTotal(retail, data, discount, startDate, tipAmount, cardSurcharge);
    }

    const changeBookingType = (e) => {
        if(e === "Directly"){
            setAgents([]);
            setBookingType("Directly");
        }else{
            setBookingType("Agent");
        }
    }

    const checkData = (data) => {

        let success = true;
        let retailNull = false;
        let taxesNull = false;

        clientListAux?.rows?.forEach(element => {
            if(element?.client === data?.client?.label){
                data['client'] = { value: element?.id, label: element?.client };
                setClient({ value: element?.id, label: element?.client });
                setClientName(element?.client);
                setClient_Name(element?.client); 
            }

        });

        if (retail !== '' && startDate !== '' && taxes?.length > 0 && amount !== '') {

            if(retail?.length < 1){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: 'Retail item required',
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }else{
                retail.forEach(element => {
                    if(element === ''){
                        success = false;
                        retailNull = true;
                    }
                });
            }

            if(retailNull){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to add a retail item.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
            }

            if(taxes?.length < 1){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: 'Retail item required',
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }else{
                taxes.forEach(element => {
                    if(element === ''){
                        success = false;
                        taxesNull = true;
                    }
                });
            }

            if(taxesNull){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to add a tax item.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
            }
            
            if(success){
                if(balance < 0){
                    Swal.fire({
                        title: 'Negative Balance',
                        text: 'Are you sure about this action?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#173d38',
                        confirmButtonText: 'Confirm',
                        cancelButtonColor: '#dc3545',
                        cancelButtonText: 'Cancel',
                      }).then((result) => {
                        if (result.isConfirmed){
                            data['refund'] = (parseFloat(data?.balance) + parseFloat(data?.amountPaid)) == 0 ? true : false;
                            data['refundAmount'] = parseFloat(balance);
                            data['paid'] = false;
                            action(data, lastData);
                        }
                      });
                }else{
                    if(balance === 0){
                        data['paid'] = true;
                    }else{
                        data['paid'] = false;
                    }
                    action(data, lastData);
                }
            }

        } else {
            Swal.fire({
                icon: "error", //Constants = success, error, warning
                title: "Error!",
                text: "All fields with (*) are required",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
              });
        }
    }

    const openClientModal = () => {
        setModalClient(true);
        setTitleModalClient('Add Client');
    };

    const actionClient = (data) => {
        // handleClearRows();
        data['owner'] = data['owner'] ? data['owner'].value : "";
        setNewClientData(data['client']);

        addData(data, true);
        setModalClient(false);

        getData(setClientListAux);
        setClient({ value: '', label: data['client'] });
        setClientName(data['client']);
        setClient_Name(data['client']);
        
        setNewClient(false);
        setNewClientData("");

    }

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }
    
    const changeDurationType = (e) => {
        setDuration("");
        setDurationType(e);
    }

    

    return (
        <>
        <div>
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-4'>

                    <Form.Group className="mb-3" controlId="formNoRegister">
                    <div className="d-inline-flex">
                        <Form.Label>Add client?</Form.Label>
                    </div>
                    <div className="d-block">
                        <div style={{display: "-webkit-box"}}>
                        <input
                            style={{ margin: "7px" }}
                            type="checkbox"
                            checked={newClient === false}
                            onChange={() => { setNewClient(false); setNewClientData(""); }}
                        />Yes
                        <input
                            style={{ margin: "7px" }}
                            type="checkbox"
                            checked={newClient === true}
                            onChange={() => { setNewClient(true); setClient({ value: "", label: "" }); }}
                        />No
                        { !newClient ? (
                            client?.value !== "" ?
                                ("")
                            : (
                                <div style={{marginLeft: "auto"}}>
                                    <Button className={'color-green rounded-circle'} onClick={openClientModal}><BiAddToQueue></BiAddToQueue></Button>
                                </div>
                            )
                        ) : (
                            ""
                        )}
                        </div>
                    </div>
                    </Form.Group>

                    { !newClient ? (
                    <Form.Group className="mb-3" controlId="formClientName">
                        <div className="d-inline-flex">
                            <Form.Label>Client name</Form.Label>
                        </div>

                        <Select options={clientList} value={client}
                            onChange={(e) => {setClient(e); setClientName(e?.label); setClient_Name(e?.label); }} />

                    </Form.Group>
                    ) : (
                        ""
                    )}


                    <Form.Group className="mb-3">
                        <div className="retail-container-retail">
                            <div className='flex d-flex justify-content-between mb-2'>
                                <div className="d-inline-flex">
                                    <Form.Label>Retail item</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
                                <Button className={'color-green rounded-circle'} onClick={addInputFieldRetail}><BiAddToQueue></BiAddToQueue></Button>
                            </div>

                            <div className="retail-scroll-container-retail">
                                {retail.map((reta, index) => (
                                    <div key={index} className="input-group mb-3">
                                        <Select
                                            options={retailList}
                                            className="form-control"
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            id={`idRetail-${index + 1}`}
                                            value={reta}
                                            onChange={(e) => handleInputChangeRetail(index, e, document.getElementById(`idRetail-${index}`).value)}
                                        />

                                        <Form.Control type="number" key={index} id={`idRetail-${index}`}
                                            // placeholder={reta?.quantity}
                                            value={reta?.quantity}
                                            style={{ maxWidth: "20%", textAlign: "center" }}
                                            onChange={(e) => handleInputChangeRetail(index, reta, e.target.value)}
                                            onKeyDown={(e) => keypress(e)} />

                                        <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c", zIndex: 0 }} onClick={() => removeFieldsRetail(index)}><AiOutlineDelete ></AiOutlineDelete></Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Form.Group>


                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formStartDate">
                                <div className="d-inline-flex">
                                    <Form.Label>Date</Form.Label>
                                </div>

                                <Form.Control type="date"
                                    value={startDate}
                                    readOnly
                                    // onChange={(e) => durationType?.value ? 
                                    //     (changeDuration(duration, e.target.value, startTime)) : 
                                    //     (setStartDate(e.target.value), setEndDate(e.target.value))}
                                    onChange={(e) => { setStartDate(e.target.value); setEndDate(e.target.value) }}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group>
                        </div>

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formStartTime">
                                <div className="d-inline-flex">
                                    <Form.Label>Time</Form.Label>
                                </div>

                                <Form.Control type="time"
                                    value={startTime}
                                    readOnly
                                    onChange={(e) => { setStartTime(e.target.value); setEndTime(e.target.value) }}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group> 
                        </div>
                    </div>

                </div>

                <div className='col-md-4'> 

                {/* <Form.Group className="mb-3" controlId="formTaxRate">
                        <div className="d-inline-flex">
                            <Form.Label>Tax rate</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select options={taxList} value={taxes}
                            onChange={(e) => {
                                setTaxes(e); (amount ? calculateTotal(amount,e, discount, tipAmount) : automaticTotal(retail, e, discount, startDate, tipAmount));
                            }} />

                    </Form.Group>  */}

                    <Form.Group className="mb-3">
                        <div className='flex d-flex justify-content-between mb-2'>
                            <div className="d-inline-flex">
                                <Form.Label>Tax rate</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Button className={'color-green rounded-circle'} onClick={addInputField}><BiAddToQueue></BiAddToQueue></Button>
                        </div>
                        {taxes.map((tax, index) => (
                            <div key={index} className="input-group mb-3">
                                <span className="input-group-text">{`Tax ${index + 1}`}</span>
                                <Select options={taxList} className="form-control"
                                    id={`tax-${index + 1}`}
                                    value={tax}
                                    onChange={(e) => handleInputChange(index, e)} />

                                <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c" }} onClick={() => removeFields(index)}><AiOutlineDelete ></AiOutlineDelete></Button>
                            </div>
                        ))}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formDiscount">
                        <div className="d-inline-flex">
                            <Form.Label>Discount</Form.Label>
                        </div>
                        <div className="d-flex">
                            <div style={discount?.value !== "" ? {width: "90%"} : {width: "100%"}}>
                                <Select options={discountList} value={discount}
                                        defaultValue={discountList[0]}
                                    onChange={(e) => {
                                        setDiscount(e); (amount ? calculateTotal(amount, taxes, e, tipAmount, cardSurcharge) : automaticTotal(retail, taxes, e, startDate, tipAmount, cardSurcharge));
                                    }} 
                                />
                            </div>

                            { discount?.value !== "" ? (
                                <div style={{width: "10%"}}>
                                    <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c" }} onClick={() => { setDiscount({ value: "", label: "" }); 
                                    ( amount ? calculateTotal(amount, taxes, { value: "", label: "" }, tipAmount, cardSurcharge) : 
                                    automaticTotal(retail, taxes, { value: "", label: "" }, startDate, tipAmount, cardSurcharge)); }}>
                                    <AiOutlineDelete ></AiOutlineDelete></Button> 
                                </div>
                            ) : (<></>)}
                        </div>

                    </Form.Group> 

                    <Form.Group className="mb-2" controlId="formCardSurcharge">
                        <div className="d-inline-flex">
                            <Form.Label>Add Card Surcharge?</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={cardSurcharge === true}
                                onChange={() => { 
                                    setCardSurcharge(true); 
                                    automaticTotal(retail, taxes, discount, startDate, tipAmount, true);
                                }}
                            />Yes
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={cardSurcharge === false}
                                onChange={() => { 
                                    setCardSurcharge(false); 
                                    setCardSurchargeAmount(0); 
                                    automaticTotal(retail, taxes, discount, startDate, tipAmount, false);
                                }}
                            />No
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formCardSurcharge">
                        <div className="d-inline-flex">
                            <Form.Label>                        
                                {cardSurcharge ? ("The Card Surcharge is " + cardSurchargePercentage +"%") : ("")}
                            </Form.Label>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formTotal">
                        <div className="d-inline-flex">
                            <Form.Label>Amount</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ marginRight: '5px' }}>$</span>
                            <Form.Control type="number" placeholder="0"
                                value={amount}
                                onChange={(e) => calculateTotal(e.target.value,taxes, discount, tipAmount, cardSurcharge)}
                                onKeyDown={(e) => keypress(e)}
                            />    
                        </div>
                    </Form.Group> 

                    {/* <Form.Group className="mb-3" controlId="formTip">
                        <div className="d-inline-flex">
                            <Form.Label>Tip</Form.Label>
                        </div>

                        <Form.Control type="number" placeholder="0"
                            value={tipAmount}
                            onChange={(e) => { setTipAmount(e.target.value); (amount ? calculateTotal(amount, taxes, discount, e.target.value) :  automaticTotal(retail, taxes, discount, startDate, e.target.value)); }}
                            onKeyDown={(e) => keypress(e)}
                        />    

                    </Form.Group>   */}

                    <div className='row'>

                        { cardSurchargeAmount > 0  ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formCardSurchargeAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Card Surcharge</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(cardSurchargeAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setCardSurchargeAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        /> 
                                    </div>
                                </Form.Group> 
                            </div>
                        ) : ("")}

                        { discountAmount ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formDiscountAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Discount amount</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(discountAmount ? discountAmount : 0).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            onKeyDown={(e) => keypress(e)}
                                        /> 
                                    </div> 
                                </Form.Group>
                            </div>
                        ) : ("")}

                    </div>

                    {/* { taxAmount ? (
                        <Form.Group className="mb-3" controlId="formTaxAmount">
                            <div className="d-inline-flex">
                                <Form.Label>Tax amount</Form.Label>
                            </div>
                            <Form.Control type="number" placeholder="0"
                                value={parseFloat(taxAmount ? taxAmount : 0).toFixed(2).padStart(4, '0')}
                                readOnly
                                onKeyDown={(e) => keypress(e)}
                            />    
                        </Form.Group>
                    ) : ("")} */}

                    <div className='row'>
                        { taxAmountGST ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formTaxAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>GST Tax</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(taxAmountGST ? taxAmountGST : 0).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            onKeyDown={(e) => keypress(e)}
                                        /> 
                                    </div>
                                </Form.Group>
                            </div>
                        ) : ("")}

                        { taxAmountPST ? (
                            <>
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formTaxAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>PST Tax</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(taxAmountPST ? taxAmountPST : 0).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            onKeyDown={(e) => keypress(e)}
                                        />
                                    </div> 
                                </Form.Group>
                            </div>
                            </>
                        ) : ("")}
                    </div>

                </div>

                <div className='col-md-4'> 
                    
                    <Form.Group className="mb-3" controlId="formTotal">
                        <div className="d-inline-flex">
                            <Form.Label>Total</Form.Label>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ marginRight: '5px' }}>$</span>
                            <Form.Control type="number" placeholder="0"
                                value={parseFloat(total ? total : 0).toFixed(2).padStart(4, '0')}
                                readOnly
                                onKeyDown={(e) => keypress(e)}
                            /> 
                        </div>   

                    </Form.Group> 

                    { tipAmount ? ( 
                    <Form.Group className="mb-3" controlId="formTip">
                        <div className="d-inline-flex">
                            <Form.Label>Tip</Form.Label>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ marginRight: '5px' }}>$</span>
                            <Form.Control type="number" placeholder="0"
                                value={parseFloat(tipAmount ? tipAmount : 0).toFixed(2).padStart(4, '0')}
                                readOnly
                                onKeyDown={(e) => keypress(e)}
                            /> 
                        </div>  

                    </Form.Group> 
                    ) : ("")}

                    <Form.Group className="mb-3" controlId="formAmountPaid">
                        <div className="d-inline-flex">
                            <Form.Label>Amount paid</Form.Label>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ marginRight: '5px' }}>$</span>
                            <Form.Control type="number" placeholder="0"
                                value={parseFloat(amountPaid ? amountPaid : 0).toFixed(2).padStart(4, '0')}
                                readOnly
                                onKeyDown={(e) => keypress(e)}
                            />
                        </div>
                    </Form.Group> 

                    <Form.Group className="mb-3" controlId="formBalance">
                        <div className="d-inline-flex">
                            <Form.Label>Balance</Form.Label>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ marginRight: '5px' }}>$</span>
                            <Form.Control type="number" placeholder="0"
                                value={parseFloat(balance ? balance : 0).toFixed(2).padStart(4, '0')}
                                readOnly
                                onKeyDown={(e) => keypress(e)}
                            />
                        </div>
                    </Form.Group>

                </div>

                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                { userInfo?.role !== roleVisit ? (
                    <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {
                        var data = {
                            id,
                            client,
                            clientName,
                            client_name,
                            newClientData,
                            newClient,
                            retail,
                            refund,
                            refundAmount,
                            tipAmount,
                            note,
                            paid,
                            taxes,
                            discount,
                            discountAmount,
                            amount,
                            amountPaid,
                            taxAmount,
                            taxAmountGST,
                            taxAmountPST,
                            total, 
                            balance,
                            startDate,
                            endDate,
                            startTime,
                            endTime,
                            cardSurcharge,
                            cardSurchargeAmount,
                            cardSurchargePercentage,
                        };
                        checkData(data);
                    }
                    }>
                        Save
                    </Button>
                ) : ("")}

            </div>
        </form>

        <ModalC
        show={modalClient}
        onHide={() => setModalClient(false)}>
        <FormClient title={titleModalClient} lastData={{client: newClientData}} textButton={titleModalClient} action={actionClient} setModal={setModalClient}></FormClient>
        </ModalC>
        </div>

        </>
    );
}

export default FormConfig;