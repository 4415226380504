import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsRetails } from '../../../services/columnsList';
import Button from '../../button'; 
import ModalC from '../../modal';
import FormConfig from './formConfig';
import Swal from "sweetalert2";
import {  getDataRetails, addDataRetails, editDataRetails, deleteDataRetails } from '../../../services/ConfigRetails';
import { addDataPaymentsRetails, deleteTemporaryRetailsData, getTemporaryRetailsData, storeTemporaryRetailsData } from '../../../services/paymentRetail';
import { getDataOneUser } from '../../../services/auth/users';
import retailInvoicePDF from '../../../services/exports/retailInvoicePDF';
import { createCheckoutSession } from '../../payments/paymentForm';
import FormPayment from '../../invoiceRetail/formConfig';
import { getData } from "../../../services/crm/clients";
import { UserAuth } from '../../../context/AuthContext';
import Loading from '../../../components/loading';
import { Input } from 'antd';
import '../../../style/table.css';
import { roleVisit } from '../../../services/Tools';

function TableConfig({ clients }) {
  
  const [pending, setPending] = useState(true); 
  const [data, setData] = useState({ rows: [] });
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [edit, setEdit] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [allBookings, setAllBookings ] = useState(true);
  const { user } = UserAuth();
  const [userInfo, setUserInfo ] = useState('');

  const [modalPayment, setModalPayment] = useState(false); 
  const [paymentEdit, setPaymentEdit] = useState(null);
  const [titleModalPayment, setTitleModalPayment] = useState('');

  const [clientListAux, setClientListAux] = useState({ rows: [] });
  const [clientList, setClientList] = useState([{ value: '', label: '', email: '', phone: '', organization: '' }]);

  useEffect(() => {
    if (user != null) {
      const fetchData = async () => {

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
      
        if (token) {
          let temp = await getTemporaryRetailsData(token);

          if (!temp) {
            showAll();
            return;
          }

          await editDataRetails(temp?.aux, false);
          await addDataPaymentsRetails(temp?.newData, true);

          setTimeout(() => {
            showAll();
            deleteTemporaryRetailsData(token);
            const url = new URL(window.location);
            url.searchParams.delete('token');
            window.history.pushState({}, '', url);
          }, 500);  

        }else{
          showAll();
          return;
        }
      }
      fetchData();

      const getUserData = async () => {
        const userDocSnap = await getDataOneUser(user.uid);
        if(userDocSnap?.length > 0){
          // if(userDocSnap[0?.role !== roleVisit]){
            setUserInfo(userDocSnap[0]);
          // }
          // else{
          //   localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
          //   window.location.href = '/';
          // }
        }
      };
  
      getUserData();
      
    }
    
  }, [user]);

  //Use by client List
  useEffect(() => {
    if (clientListAux?.rows?.length > 0) {
        var aux = [];
        clientListAux?.rows?.forEach(element => {
            let orga = element.organisation;
            if(orga){
                aux.push({ value: element.id, label: element.client});
            }else{
                aux.push({ value: element.id, label: element.client});
            }
        });
        setClientList(aux);
    }
  }, [clientListAux]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const showAll = () => {
    setPending(true);
    if(!allBookings){
      getDataRetails(setData, setPending, false);
      setAllBookings(true);
    }else{
      getDataRetails(setData, setPending, true);
      setAllBookings(false);
    }
    setFilterText('');

    if(clients?.rows?.length > 0){
      setClientListAux(clients);
    }else{
      getData(setClientListAux);
    }

  }

  const action = (data, lastData) => {
    handleClearRows();

    if (edit) {
      editDataRetails(data, true, lastData);
    } else {
      addDataRetails(data, true);
    }

    setModal(false);
    setTimeout(() => {
      getDataRetails(setData, setPending, !allBookings);
      // getData(setClientListAux);
    }, 500);
  }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const editObj = (id) => {
    let aux = null;
    filteredItems.forEach(element => {
      if (element.id === id) {
        aux = element;
      }
    });
    setModal(true);
    setEdit(aux);
    setTitleModal('Edit retail');
  }

  const deleteObj = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let aux = null;
        filteredItems.forEach(element => {
          if (element.id === id) {
            aux = element;
          }
        });
        deleteDataRetails(aux);
        Swal.fire({
          title: 'Deleted!',
          text: 'Has been removed successfully.',
          icon: 'success',
          confirmButtonColor: '#173d38',
        })
        setTimeout(() => {
          setModal(false);
          getDataRetails(setData, setPending, !allBookings);
          handleClearRows();
        }, 1000)
      }
    })
  }

  const generateInvoice = async (id) => {
    let aux = null;
    let auxClient = null;

    data?.rows.forEach(element => {
      if (element.id === id) {
        aux = element;
      }
    });

    if(!aux?.newClient){
      clientListAux?.rows?.forEach(element => {
        if (element?.id === aux?.client?.value) {
          auxClient = element; 
        }
      });
    }else{
      auxClient = aux?.newClientData;
    }
    retailInvoicePDF(aux, auxClient);
  }

  const paymentObj = (id) => { 
    let aux = null;

   
    data?.rows.forEach(element => {
      if (element?.id === id) {
        aux = element;
      }
    });
    setModalPayment(true);
    setPaymentEdit(aux);
    setTitleModalPayment('Retail Payment');
  }

  const actionPay = async (newData, copy_link = false) => {
    handleClearRows();
    let aux = null;

    filteredItems.forEach(element => {
      if (element.id === newData?.idRetail) {
        aux = element;
      }
    });

    aux['balance'] = newData?.balance;
    aux['paid'] = newData?.paid;
    aux['amountPaid'] = newData?.amountPaid;
    aux['tipAmount'] = newData?.tipAmount;
    newData['client'] = aux?.client;
    
    if(newData?.paymentType?.label === "Stripe"){
      if (paymentEdit) {
        let temp = {
          aux: aux,
          newData: newData
        }

        let id = await storeTemporaryRetailsData(temp);
        createCheckoutSession(newData?.newAmountPaid, newData?.retail?.[0]?.label, id, copy_link);
      }
    }else{
      editDataRetails(aux, false);
      addDataPaymentsRetails(newData, true);
      setModalPayment(false);
    }
    
  }

  const filteredItems = data?.rows.filter(
    item => item?.client?.label && item?.client?.label?.toLowerCase().includes(filterText.toLowerCase()) ||
    item?.newClientData && item?.newClientData?.toLowerCase().includes(filterText.toLowerCase()) ||
    item?.startDate && item?.startDate?.toLowerCase().includes(filterText.toLowerCase()) ||
    item?.id && item?.id?.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
    { pending ? (
      <>
      <div style={{textAlign:"center"}}>
        <Loading></Loading>
      </div>
      </>
      ) 
      : (
      <>
      <div>
        <h3 style={{textAlign:"center"}}> { allBookings ? ("All retails") : ("Today's retails")}</h3> 
      </div>

        <div className="content">
          <div className="d-flex justify-content-between flex-row-reverse ">
            <div className="gap-2 m-3">

              <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
                setExpand(!expand);
              }} >{expand ? "Expand" : "Compress"}</Button>

              <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { showAll()
                }} > { allBookings ? ( "Show today") : ("Show all") }</Button>

                { userInfo?.role !== roleVisit ? (
                  <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
                    setModal(true);
                    setTitleModal('Add new retail');
                    setEdit(null);
                  }} >+Add retail</Button>
                ) : ("")}

            </div>
          </div>
          <div className="m-3">
            <DataTable
              columns={columnsRetails(expand, editObj, deleteObj, generateInvoice, paymentObj, userInfo)}
              data={filteredItems}
              selectableRows
              onSelectedRowsChange={handleChange}
              pagination
              clearSelectedRows={toggledClearRows}
              defaultSortFieldId={9}
              defaultSortAsc={false}
              progressPending={pending}
              paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
            <ModalC
              show={modal}
              onHide={() => setModal(false)}
              changeSize={true}> 
              <FormConfig title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModal} clients={clientListAux}></FormConfig>
            </ModalC>

            <ModalC
              show={modalPayment}
              onHide={() => setModalPayment(false)}>
              <FormPayment title={titleModalPayment} lastData={paymentEdit} textButton={titleModalPayment} actionPay={actionPay} setModalPayment={setModalPayment}></FormPayment>
            </ModalC>
          </div>
        </div>
        </>
    )}
    </>
  );
}

export default TableConfig;
