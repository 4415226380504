import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsHistoryReservationConfig } from '../../services/columnsList';
import Button from '../button';
import ModalC from '../modal';
// import FormConfig from './formConfig';
import Swal from "sweetalert2";
import { getDataReservationsHistory } from '../../services/ConfigReservations';
import { UserAuth } from '../../context/AuthContext';
import { Input } from 'antd';
import '../../style/table.css'
import ExportPDF from "../../services/exports/exportPDF";
import ExportCSV from "../../services/exports/exportCSV";
import { transformedData } from '../../services/exports/transformedData';
import { DateRangePicker } from "rsuite";
import { formatDate } from '../../services/Tools';
import { Table } from 'react-bootstrap';

function TableConfig() {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState({ rows: [] });
  const [dataAdventure, setDataAdventure] = useState({ rows: [] });
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [edit, setEdit] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();
  const [amount, setAmount] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [total, setTotal] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsDate, setFilteredItemsDate] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [surcharge, setSurcharge] = useState("");
  const [discount, setDiscount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const options = {
    style: 'decimal', // Puedes usar 'currency' para formato de moneda
    // currency: 'CAD', // Código de moneda (por ejemplo, USD para dólares estadounidenses)
    minimumFractionDigits: 2, // Número mínimo de dígitos decimales
    maximumFractionDigits: 2, // Número máximo de dígitos decimales
  };

  useEffect(() => {
    if (user != null) {
      getDataReservationsHistory(setData, setPending);
    }
  }, [user]);

  useEffect(() => {
    if (data != null) {
    let newData = [];
    let amountTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalTotal = 0;

    if(data){
      data?.rows?.forEach(element => {
        amountTotal = element?.amount ? parseFloat(amountTotal) + parseFloat(element?.amount) : parseFloat(amountTotal);
        taxTotal = element?.taxAmount ? parseFloat(taxTotal) + parseFloat(element?.taxAmount) : parseFloat(taxTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        surchargeTotal = element?.fuelSurchargeAmount ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargeAmount) : parseFloat(surchargeTotal);
        totalTotal = element?.total ? parseFloat(totalTotal) + parseFloat(element?.total) : parseFloat(totalTotal);
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      surcharge: surchargeTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(parseFloat(amountTotal.toFixed(2)));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setSurcharge(parseFloat(surchargeTotal.toFixed(2)));
    setTaxAmount(parseFloat(taxTotal.toFixed(2)));
    setTotal(parseFloat(totalTotal.toFixed(2)));
    changeFilter("");

    }
  }, [data]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const changeFilter = (e) => { 

    let newFilter = [];
    if(data?.rows?.length > 0){
      newFilter = data?.rows?.filter(
      item => item?.shuttle?.label && item?.shuttle?.label.toLowerCase().includes(e.toLowerCase()) 
      || item?.startDate && item?.startDate.toLowerCase().includes(e.toLowerCase()) 
    );

    setFilteredItemsDate([]);
    setFilteredItems(newFilter);
  }
  setFilterText(e);

  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    let newData = [];
    let amountTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalTotal = 0;

    if(filteredItems){
      filteredItems.forEach(element => {
        amountTotal = element?.amount ? parseFloat(amountTotal) + parseFloat(element?.amount) : parseFloat(amountTotal);
        taxTotal = element?.taxAmount ? parseFloat(taxTotal) + parseFloat(element?.taxAmount) : parseFloat(taxTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        surchargeTotal = element?.fuelSurchargeAmount ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargeAmount) : parseFloat(surchargeTotal);
        totalTotal = element?.total ? parseFloat(totalTotal) + parseFloat(element?.total) : parseFloat(totalTotal);
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      surcharge: surchargeTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setSurcharge(parseFloat(surchargeTotal.toFixed(2)));
    setTaxAmount(taxTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2));

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => changeFilter(e.target.value)} filtertext={filterText} />
      </>
      // <>
      //   <h5 className='search-label'>Search</h5>
      //   <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      // </>
    );
  }, [filterText, resetPaginationToggle]);

  const selectDate = (e) => {
    let aux = [];
    let newData = [];

    let date1 = getDate(new Date(e[0]));
    let date2 = getDate(new Date(e[1]));
    let amountTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalTotal = 0;

    filteredItems.forEach(element => {
      if(element?.startDate >= date1 && element?.startDate <= date2){
        aux.push(element);
        amountTotal = element?.amount ? parseFloat(amountTotal) + parseFloat(element?.amount) : parseFloat(amountTotal);
        taxTotal = element?.taxAmount ? parseFloat(taxTotal) + parseFloat(element?.taxAmount) : parseFloat(taxTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        surchargeTotal = element?.fuelSurchargeAmount ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargeAmount) : parseFloat(surchargeTotal);
        totalTotal = element?.total ? parseFloat(totalTotal) + parseFloat(element?.total) : parseFloat(totalTotal);
      }
    });

    if(aux?.length === 0){
      aux = [{
        service:"-",
        shuttle: {
          label:"-",
        },
        amount: 0,
        discountAmount: 0,
        fuelSurchargeAmount: 0,
        taxAmount: 0,
        total: 0,
        startDate:"-",
      }];
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      surcharge: surchargeTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setSurcharge(parseFloat(surchargeTotal.toFixed(2)));
    setTaxAmount(taxTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2));
    setFilteredItemsDate(aux);
    setStartDate(date1);
    setEndDate(date2);
    //setFilteredItems(aux);
  } 

  const getDate = (date) => {
    let day = `${(date.getDate())}`.padStart(2,'0');
    let month = `${(date.getMonth()+1)}`.padStart(2,'0');
    let year = date.getFullYear();
    return (`${year}-${month}-${day}`)
  }

  return (
    <div className="content">
      <div className='row'>
          <div className="col-md-6 text-center">
            <h6>Range Date</h6>
            <DateRangePicker 
              placeholder="Today"
              onChange={selectDate}
            />
          </div>
          <div className="d-flex justify-content-between flex-row-reverse col-md-6">
            <div className="gap-2 m-3">
                <ExportPDF data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsHistoryReservationConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsHistoryReservationConfig(), true)} totals={[amount, surcharge, taxAmount, total, 0, discount]} agents={false} transport={true} filename={"Sales Transport Report"}></ExportPDF>
                <ExportCSV data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsHistoryReservationConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsHistoryReservationConfig(), true)} totals={[amount, surcharge, taxAmount, total, 0, discount]} agents={false} transport={true} startDate={startDate} endDate={endDate} filename={"Sales Transport Report"}></ExportCSV>
              </div>
          </div>
      </div>
      <div className="m-3">
        <DataTable
          columns={columnsHistoryReservationConfig()}
          data={filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems}
          // data={filteredItems}
          selectableRows
          onSelectedRowsChange={handleChange}
          pagination
          clearSelectedRows={toggledClearRows}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          progressPending={pending}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />

        { amount !== "0.00" && amount !== 0  ? (

          <Table style={{ maxWidth: "700px", marginLeft: "auto" }} striped bordered hover>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Fuel Surcharge</th>
              <th>Discount</th>
              <th>Tax</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {tableList.map((item, index) => (
              <tr key={index}>
                <td>$ {item.amount}</td>
                <td>$ {item.surcharge}</td>
                <td>$ {item.discount}</td>
                <td>$ {item.tax}</td>
                <td>$ {item.total}</td>
              </tr>
            ))}
          </tbody>
          </Table>
          // <div style={{textAlign:"end"}}>
          //   <div className='col-md-6' style={{marginLeft:"auto"}}>
          //     <div style={{display:"inline"}}>
          //     {/* <h4>Amount</h4> <h5>$ {(parseFloat(amount).toFixed(2).padStart(4, '0')).toLocaleString()}</h5> */}
          //     <h4>Amount</h4> <h5>$ {parseFloat(amount).toLocaleString(undefined, options)}</h5>
          //     </div>
          //     <h4>Tax</h4> <h5>$ {parseFloat(taxAmount).toLocaleString(undefined, options)}</h5>
          //     <h4>Total</h4> <h5>$ {parseFloat(total).toLocaleString()}</h5>

          //   </div>
          // </div>
        ) : ("")} 
      </div>
    </div>
  );
}

export default TableConfig;
