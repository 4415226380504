import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc, startAfter, limit, getCountFromServer, orderBy, getDoc, where } from "firebase/firestore";
import { auth, db } from '../../firebase';
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { server } from '../serverName/Bookings';

export const getDataProduct = async (setdata, setPending = null, skip = 0, take = 6000, search = '', order = 'created_date', calculate = false, items = []) => {
  try {
    if (auth.currentUser != null) {

      const all = query(collection(db, server + "_products_config"), orderBy('created_date'));
      const documentSnapshots = await getDocs(all);

      // Get the last visible document
      const lastVisible = documentSnapshots.docs[skip];

      let q = null
      if (search.trim().length > 1) {
        q = query(
          collection(db, server + "_products_config"),
          orderBy('product'),
          where('product', '>=', search.toUpperCase()),
          where('product', '<=', search.toLowerCase() + '\uf8ff'),
          limit(20),
        );
      }
      else {
        if(lastVisible){
          q = query(
            collection(db, server + "_products_config"),
            orderBy(order),
            startAfter(lastVisible),
            limit(take),
          );
        }else{
          q = query(
            collection(db, server + "_products_config"),
            orderBy(order),
            limit(take),
          );
        }
      }

      const querySnapshot = await getDocs(q);

      //setdata({ rows: [] });

      var array = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        array.push(data);
      });

      if (search.trim().length > 1) {
        var result = array.map(function (item, index) {
          var exist_product_name = 1;
          var aux_product = item.product;
          exist_product_name = aux_product.toLowerCase().search(search.trim().toLowerCase()) !== -1;
          if (exist_product_name) {
            return item;
          }
        });
        result = result.filter(function (element) {
          return element !== undefined;
        });

        result.sort((a, b) => {
          a = a.product.toLowerCase();
          b = b.product.toLowerCase();
          var an = a.indexOf(search.trim().toLowerCase());
          var bn = b.indexOf(search.trim().toLowerCase());
          if (an === bn)
            return (a.product > b.product) ? 1 : ((b.product > a.product) ? -1 : 0);
          else
            return an > bn ? 1 : -1;

        });

        array = result;
      }

      array.forEach(element => {
        if (!element?.allStock)
          element.allStock = element.stock;

        if (calculate) {
          let previosStock = 0;
          items?.forEach(item => {
            if (element.id === item.id) {
              previosStock++;
            }
          });
          element.stock = (element?.allStock - previosStock) <= 0 ? 0 : (element?.allStock - previosStock);
        }
      });

      setdata({
        rows: array
      });

      if (setPending) {
        setPending(false);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const getDataProductCount = async (setCount) => {
  const coll = collection(db, server + "_products_config");
  const snapshot = await getCountFromServer(coll);

  setCount(snapshot.data().count);
}

export const addDataProduct = async (data, message = false, del = false) => {
  try {
    var id = uuid();
    const docRef = doc(
      db,
      del ? (server === 'bookings' ? "plan_1_delete_911_products_config" : 'dev_delete_911_products_config') : server + "_products_config",
      id
    );

    const userId = auth.currentUser;
    let today = new Date();
    let now = today.toLocaleString();

    if (userId !== null) {
      data['created_by_id'] = userId.uid;
      data['created_by_email'] = userId.email;
      data['created_by_name'] = userId.displayName;
      data['created_date'] = now;
      data['id'] = id;
      data['allStock'] = data?.stock;

      if (del) {
        data['deleted_by_id'] = userId.uid;
        data['deleted_by_email'] = userId.email;
        data['deleted_by_name'] = userId.displayName;
        data['deleted_date'] = now;
      }

      await setDoc(docRef, data);

      if (message) {
        Swal.fire({
          icon: "success",
          title: "Added",
          text: "The product was created successfully",
          confirmButtonColor: '#173d38',
          confirmButtonText: "Ok",
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const editDataProduct = async (edit) => {
  try {
    const docRef = doc(db, server + "_products_config", edit.id);
    const userId = auth.currentUser;
    let today = new Date();
    let now = today.toLocaleString();

    edit['updated_by_id'] = userId.uid;
    edit['updated_by_email'] = userId.email;
    edit['updated_by_name'] = userId.displayName;
    edit['updated_date'] = now;

    await updateDoc(docRef, edit);

    Swal.fire({
      icon: "success",
      title: "Edited",
      text: "The product was edited successfully",
      confirmButtonColor: '#173d38',
      confirmButtonText: "Ok",
    });
  } catch (error) {
    console.log(error);
  }
}

export const deleteDataProduct = async (element) => {
  try {
    var element_duplicated = Object.assign({}, element);
    await addDataProduct(element_duplicated, false, true);

    const docRef = doc(db, server + "_products_config", element.id);
    await deleteDoc(docRef);
  } catch (error) {
    console.log(error);
  }
}
