import React, { useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import '../../style/pointOfSale/pointOfSale.css'
import PointOfSales from '../../components/pointOfSale/dashboard/tableConfig';

const idRole = 'pointofsale';

const PointOfSale = () => {
    
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (loading) {
          return;
        }
        if (!user) navigate("/signin");
      }, [user, loading]);


    return (
        <div className="container">
          <div className='row'>
            <div className='col-12'>
              <PointOfSales></PointOfSales>
            </div>
          </div>
        </div>
      )
    }
    
export default PointOfSale