import React, { useMemo } from "react";

import { CSVLink } from "react-csv";

const ExportCSV = ({
  data = [],
  filename,
  headers = [],
  totals = [],
  agents = false,
  transport = false,
  rental = false,
  adventure = false,
  retails = false,
  allActivities = false,
  allRevenue = false,
  sales = false,
  startDate = null,
  endDate = null
}) => {

  
  // Filtrar y separar los datos con "Invoiced via QB" solo una vez
const { invoiceViaQBData, nonInvoiceViaQBData } = useMemo(() => {
  const invoiceViaQBData = data.filter(item => item[3] === "Invoiced via QB");
  const nonInvoiceViaQBData = data.filter(item => item[3] !== "Invoiced via QB");
  return { invoiceViaQBData, nonInvoiceViaQBData };
}, [data]);

// Modificar datos donde item[3] es "Refund"
const modifiedData = nonInvoiceViaQBData.map(item => {
  if (item[3] === "Refund" && item[5]) {
    return [...item.slice(0, 3), item[5], ...item.slice(4)];
  }
  return item;
});


  // Ordenar los datos según el valor en la posición #3 solo si allRevenue es true
  let sortedData = allRevenue ? [...modifiedData].sort((a, b) => {
    if (a[3] < b[3]) return -1;
    if (a[3] > b[3]) return 1;
    return 0;
  }) : allActivities ? [...modifiedData].sort((a, b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;
    if (a[2] < b[2]) return -1;
    if (a[2] > b[2]) return 1;
    return 0;
  }) : retails ? [...modifiedData].sort((a, b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;
    return 0;
  }) : modifiedData;

  // Agregar título al inicio del CSV
  const csvData = [];

  if (startDate) {
    const reportType = transport
      ? "transport"
      : adventure
      ? "adventure"
      : rental
      ? "rental"
      : retails
      ? "retail"
      : "";

    csvData.push(["Daily " + reportType + " report: ", startDate, startDate ? "to" : "", endDate]);
    csvData.push([]); // Espacio vacío
  }

  // Agregar encabezados de las columnas (excluir los índices especificados)
  csvData.push(headers.filter((_, i) => ![5, 6, 7, 8, 9, 10].includes(i)));

  let currentGroupKey = null;
  let totalSums = 0; //Para obtener el Gran Total
  let groupSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };
  let cashChequeSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };
  let cardPaymentsSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };
  let eTransferSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };
  let stripeSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };
  let cashSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };
  let invoicedViaQBSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };

  // Función para limpiar y convertir valores de texto a número
  const parseValue = (value) => {
    return parseFloat(value.replace(/[$\s]/g, '')) || 0;
  };

  // Separar los datos de "Card Payments" y "E-transfer/EFT" y ordenarlos alfabéticamente
  const cardPaymentMethods = ["Visa", "Mastercard", "AMEX", "Debit"];
  const cardPaymentsData = sortedData.filter(item => cardPaymentMethods.includes(item[3])).sort((a, b) => {
    if (a[3] < b[3]) return -1; 
    if (a[3] > b[3]) return 1;
    return 0;
  });

  const eTransferMethods = ["E-transfer", "EFT"];
  const eTransferData = sortedData.filter(item => eTransferMethods.includes(item[3])).sort((a, b) => {
    if (a[3] < b[3]) return -1;
    if (a[3] > b[3]) return 1;
    return 0;
  });

  const stripeMethods = ["QuickBooks", "Stripe", "Stripe Online"];
  const stripeData = sortedData.filter(item => stripeMethods.includes(item[3])).sort((a, b) => {
    if (a[3] < b[3]) return -1;
    if (a[3] > b[3]) return 1;
    return 0;
  });

  const cashMethods = ["Cash", "Cheque"];
  const cashData = sortedData.filter(item => cashMethods.includes(item[3])).sort((a, b) => {
    if (a[3] < b[3]) return -1;
    if (a[3] > b[3]) return 1;
    return 0;
  });

  // Filtrar el resto de los datos
  const nonCardPaymentsAndETransferData = sortedData.filter(item => !cardPaymentMethods.includes(item[3]) 
  && !eTransferMethods.includes(item[3]) 
  && !stripeMethods.includes(item[3]) 
  && !cashMethods.includes(item[3]));

  // Función para agregar fila de resumen
  const addGroupSummaryRow = (key, sums) => {
    if (key) {
      csvData.push(["", "", `Total for ${key}`, "", "", `$${sums.total.toFixed(2)}`]);
      totalSums += sums.total;
    }
  };

  // Procesar y agregar datos
  nonCardPaymentsAndETransferData.forEach((item, index) => {
    const row = [];
    headers.forEach((header, i) => {
      if (![5, 6, 7, 8, 9, 10].includes(i)) {
        row.push(item[i]);
      }
    });

    // Ejecutar la lógica de agrupamiento y suma solo si allRevenue es true
    if (allRevenue) {
      const groupKey = item[3];

      if (currentGroupKey && currentGroupKey !== groupKey) {
        addGroupSummaryRow(
          currentGroupKey, 
          currentGroupKey === "Cash/Cheque" ? cashChequeSums : 
          groupSums 
        );
        // Reiniciar las sumas del grupo
        groupSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };

        if (currentGroupKey === "Cash/Cheque") {
          cashChequeSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };
        } 
      }

      // Actualizar la clave del grupo actual
      currentGroupKey = groupKey;

      // Sumar los valores de las columnas al total del grupo
      if (groupKey === "Cash/Cheque") {
        cashChequeSums.amount += parseValue(item[6]);
        cashChequeSums.fuel += parseValue(item[7]);
        cashChequeSums.fee += parseValue(item[8]);
        cashChequeSums.discount += parseValue(item[9]);
        cashChequeSums.tax += parseValue(item[10]);
        cashChequeSums.total += parseValue(item[11]);
      } else {        
        groupSums.amount += parseValue(item[6]);
        groupSums.fuel += parseValue(item[7]);
        groupSums.fee += parseValue(item[8]);
        groupSums.discount += parseValue(item[9]);
        groupSums.tax += parseValue(item[10]);
        groupSums.total += parseValue(item[11]);
      }
    }

    csvData.push(row);

    // Verificar si es el último elemento y agregar la fila de resumen
    if (index === nonCardPaymentsAndETransferData.length - 1) {
      addGroupSummaryRow(
        currentGroupKey, 
        currentGroupKey === "Cash/Cheque" ? cashChequeSums : 
        groupSums 
      );
    }
  });

  // Agregar datos de Card Payments y su resumen
  cardPaymentsData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      if (![5, 6, 7, 8, 9, 10].includes(i)) {
        row.push(item[i]);
      }
    });

    cardPaymentsSums.amount += parseValue(item[6]);
    cardPaymentsSums.fuel += parseValue(item[7]);
    cardPaymentsSums.fee += parseValue(item[8]);
    cardPaymentsSums.discount += parseValue(item[9]);
    cardPaymentsSums.tax += parseValue(item[10]);
    cardPaymentsSums.total += parseValue(item[11]);
    
    csvData.push(row);
  });

  if (cardPaymentsData.length > 0) {
    totalSums += cardPaymentsSums.total;
    csvData.push(["", "", "Total for Card Payments", "", "", `$${cardPaymentsSums.total.toFixed(2)}`]);
  }
 
  // Agregar datos de E-transfer/EFT y su resumen
  eTransferData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      if (![5, 6, 7, 8, 9, 10].includes(i)) {
        row.push(item[i]);
      }
    });

    eTransferSums.amount += parseValue(item[6]);
    eTransferSums.fuel += parseValue(item[7]);
    eTransferSums.fee += parseValue(item[8]);
    eTransferSums.discount += parseValue(item[9]);
    eTransferSums.tax += parseValue(item[10]);
    eTransferSums.total += parseValue(item[11]);

    csvData.push(row);
  });

  if (eTransferData.length > 0) {
    totalSums += eTransferSums.total;
    csvData.push(["", "", "Total for E-transfer/EFT", "", "", `$${eTransferSums.total.toFixed(2)}`]);
  }

  // Agregar datos de Stripe y su resumen
  stripeData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      if (![5, 6, 7, 8, 9, 10].includes(i)) {
        row.push(item[i]);
      }
    });

    stripeSums.amount += parseValue(item[6]);
    stripeSums.fuel += parseValue(item[7]);
    stripeSums.fee += parseValue(item[8]);
    stripeSums.discount += parseValue(item[9]);
    stripeSums.tax += parseValue(item[10]);
    stripeSums.total += parseValue(item[11]);

    csvData.push(row);
  });

  if (stripeData.length > 0) {
    totalSums += stripeSums.total;
    csvData.push(["", "", "Total for Stripe", "", "", `$${stripeSums.total.toFixed(2)}`]);
  }

  // Agregar datos de Cash & Cheque y su resumen
  cashData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      if (![5, 6, 7, 8, 9, 10].includes(i)) {
        row.push(item[i]);
      }
    });

    cashSums.amount += parseValue(item[6]);
    cashSums.fuel += parseValue(item[7]);
    cashSums.fee += parseValue(item[8]);
    cashSums.discount += parseValue(item[9]);
    cashSums.tax += parseValue(item[10]);
    cashSums.total += parseValue(item[11]);

    csvData.push(row);
  });

  if (stripeData.length > 0) {
    totalSums += cashSums.total;
    csvData.push(["", "", "Total for Cash/Cheque", "", "", `$${cashSums.total.toFixed(2)}`]);
  }

  if (totalSums) {
    csvData.push([]);
    csvData.push(["", "", "Grand Total", "", "", `$${totalSums.toFixed(2)}`]);
  }


  csvData.push([]);
  csvData.push([]);
  csvData.push([]);

  // Agregar los datos de "Invoiced via QB" al final
  invoiceViaQBData.forEach(item => {
    const row = [];
    headers.forEach((header, i) => {
      if (![5, 6, 7, 8, 9, 10].includes(i)) {
        row.push(item[i]);
      }
    });

    invoicedViaQBSums.amount += parseValue(item[6]);
    invoicedViaQBSums.fuel += parseValue(item[7]);
    invoicedViaQBSums.fee += parseValue(item[8]);
    invoicedViaQBSums.discount += parseValue(item[9]);
    invoicedViaQBSums.tax += parseValue(item[10]);
    invoicedViaQBSums.total += parseValue(item[11]);

    csvData.push(row);
  });
  if (stripeData.length > 0) {
    csvData.push(["", "", "Total for Invoiced via QB", "", "", `$${invoicedViaQBSums.total.toFixed(2)}`]);
  }


  return (
    <CSVLink
      data={csvData}
      filename={filename}
      className="color-green btn btn-outline-secondary p-2 mx-2"
      target="_blank"
    >
      Export CSV
    </CSVLink>
  );
};

export default ExportCSV;
