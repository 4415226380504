import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc, orderBy, getDoc } from "firebase/firestore";
import { auth, db } from '../firebase';
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { server } from './serverName/Bookings';
import { formatDate } from './Tools';

export const getDataPaymentsRetails = async (setdata, setPending = null) => {

    try {
      if (auth.currentUser != null) {
        const q = query(collection(db, server + "_payments_retails"), orderBy("created_date", "desc"));
        const querySnapshot = await getDocs(q);
  
        setdata({ rows: [] });
  
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });
        setdata({
          rows: array
        });
  
        if (setPending) {
          setPending(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
}

export const getDataPaymentsJoinRetails = async (setdata, setPending = null) => {
  try {
    if (auth.currentUser != null) {
      const paymentQuery = query(collection(db, server + "_payments_retails"), orderBy("created_date", "desc"));
      const retailsQuery = query(collection(db, server + "_retails"), orderBy("created_date", "desc"));

      const [paymentSnapshot, retailsSnapshot] = await Promise.all([
        getDocs(paymentQuery),
        getDocs(retailsQuery)
      ]);

      const retailsMap = new Map();
      retailsSnapshot.forEach(doc => {
        const retailsData = doc.data();
        retailsMap.set(retailsData.id, retailsData);
      });

      const combinedResults = [];
      paymentSnapshot.forEach(doc => {
        const paymentData = doc.data();
        const idRetail = paymentData?.idRetail;

        let combinedData;
        if (retailsMap.has(idRetail)) {
          const retailsData = retailsMap.get(idRetail);
          // Combinar los datos, dando prioridad a los de paymentSnapshot
          combinedData = { ...retailsData, ...paymentData, id: doc.id };
        } else {
          // Si no hay datos en transportSnapshot, usar los de paymentSnapshot directamente
          combinedData = { ...paymentData, id: doc.id };
        }
        combinedResults.push(combinedData);
      });

      if (setPending) {
        setPending(false);
      }

      setdata({
        rows: combinedResults
      });
    }
  } catch (error) {
    console.error("Error en getDataPaymentsJoinRetails:", error);
  }
};

export const addDataPaymentsRetails = async (data, message = false, del = false) => {
    try {
      var id = uuid();
      const docRef = doc(
        db,
        del ? (server === 'bookings' ? "plan_1_delete_911_payments_retails" : 'dev_delete_911_payments_retails') : server + "_payments_retails",
        id
      );

      const userId = auth.currentUser;
      let today = new Date();
      let now = formatDate(today,"yyyy-MM-dd");

      data['created_by_id'] = userId?.uid ? userId.uid : "Customer-Payment";
      data['created_by_email'] = userId?.email ? userId.email : "Customer-Payment";
      data['created_by_name'] = userId?.displayName ? userId.displayName : "Customer-Payment";
      
      data['created_date'] = now;
      data['id'] = id;
      data['idInvoice'] = data?.id;

      if (del) {
        data['deleted_by_id'] = userId.uid;
        data['deleted_by_email'] = userId.email;
        data['deleted_by_name'] = userId.displayName;
        data['deleted_date'] = now;
      }

      await setDoc(docRef, data);

      if (message) {
        if(parseFloat(data?.capitalPaid) < 0){
          Swal.fire({
            icon: "success",
            title: data?.paymentType?.value +"ed",
            text: "The " + (data?.paymentType?.value === "Revert" ? ("reversal") : ("refund"))  + " was successful",
            confirmButtonColor: '#173d38',
            confirmButtonText: "Ok",
          });
        }else{
          Swal.fire({
            icon: "success",
            title: "Paid",
            text: "The payment was successful",
            confirmButtonColor: '#173d38',
            confirmButtonText: "Ok",
          });
        }
      }
    } catch (error) {
      alert(error);
    }
}

export const storeTemporaryRetailsData = async (data) => {
    try {
      var id = uuid();
      const docRef = doc(
        db,
        server + "_temp_retails_data",
        id
      );

      // Set user data
      const userId = auth.currentUser;
      let today = new Date();
      let now = formatDate(today,"yyyy-MM-dd");

      // Check user status
      if (userId !== null) {
        data['created_by_id'] = userId.uid;
        data['created_by_email'] = userId.email;
        data['created_by_name'] = userId.displayName;
        data['created_date'] = now;

        // Save data to Firestore
        await setDoc(docRef, data);
        // Return the newly generated UUID
        return id;
      }
    } catch (error) {
      alert(error);
    }
}

export const getTemporaryRetailsData = async (id) => {
  try {

      const docRef = doc(db, server + "_temp_retails_data", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          return docSnap.data();
      } else {
          console.log("No such document!");
          return null;
      }
  } catch (error) {
      console.error("Error getting document:", error);
  }
};

export const deleteTemporaryRetailsData = async (id) => {
  try {
    const docRef = doc(db, server + "_temp_retails_data", id);
    await deleteDoc(docRef);
    console.log("Document successfully deleted!");
  } catch (error) {
    console.error("Error deleting document:", error);
  }
};

export const editDataPaymentsRetails = async (edit) => {
    try {
      const docRef = doc(db, server + "_payments_retails", edit.id);
      const userId = auth.currentUser;
      let today = new Date();
      let now = today.toLocaleString();

      edit['updated_by_id'] = userId.uid;
      edit['updated_by_email'] = userId.email;
      edit['updated_by_name'] = userId.displayName;
      edit['updated_date'] = now;

      await updateDoc(docRef,edit);

      Swal.fire({
        icon: "success",
        title: "Edited",
        text: "The payment was edited successfully",
        confirmButtonColor: '#173d38',
        confirmButtonText: "Ok",
      });
    } catch (error) {
      console.log(error);
    }
}

export const deleteDataPaymentsRetails = async (element) => {
    try {
        var element_duplicated = Object.assign({}, element);
        await addDataPaymentsRetails(element_duplicated, false, true);

        const docRef = doc(db, server + "_payments_retails", element.id);
        await deleteDoc(docRef);
    } catch (error) {
        console.log(error);
    }
}