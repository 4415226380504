import React, { useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { getDocument } from "pdfjs-dist";
import * as pdfjs from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PreviewModalPDF = ({ show, onHide, pdfData }) => {
  const canvasRef = useRef();

  useEffect(() => {
    if (pdfData && show) {
      const renderPDF = async () => {
        const pdf = await getDocument({
          data: atob(pdfData.split("base64,")[1]),
        }).promise;
        const page = await pdf.getPage(1);
        const scale = 1;
        const viewport = page.getViewport({ scale });
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        page.render({
          canvasContext: context,
          viewport,
        });
      };

      renderPDF();
    }
  }, [pdfData, show]);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Invoice Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <canvas
          ref={canvasRef}
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        ></canvas>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewModalPDF;
