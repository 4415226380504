import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc, orderBy, getDoc } from "firebase/firestore";
import { auth, db } from '../firebase';
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { server } from './serverName/Bookings';
import { formatDate } from './Tools';

export const getDataPayments = async (setdata, setPending = null) => {

    try {
      if (auth.currentUser != null) {
        const q = query(collection(db, server + "_payments_reservation"), orderBy("created_date", "desc"));
        const querySnapshot = await getDocs(q);
  
        setdata({ rows: [] });
  
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });
        setdata({
          rows: array
        });
  
        if (setPending) {
          setPending(false);
        }
      }
    } catch (error) {
      console.log(error)
    }
}

export const getDataPaymentsJoinTransport = async (setdata, setPending = null) => {
  try {
    if (auth.currentUser != null) {
      const paymentQuery = query(collection(db, server + "_payments_reservation"), orderBy("created_date", "desc"));
      const transportQuery = query(collection(db, server + "_reservations"), orderBy("created_date", "desc"));

      const [paymentSnapshot, trasportSnapshot] = await Promise.all([
        getDocs(paymentQuery),
        getDocs(transportQuery)
      ]);

      const transportMap = new Map();
      trasportSnapshot.forEach(doc => {
        const transportData = doc.data();
        transportMap.set(transportData.id, transportData);
      });

      const combinedResults = [];
      paymentSnapshot.forEach(doc => {
        const paymentData = doc.data();
        const idTransport = paymentData?.idReservation;

        let combinedData;
        if (transportMap.has(idTransport)) {
          const transportData = transportMap.get(idTransport);
          // Combinar los datos, dando prioridad a los de paymentSnapshot
          combinedData = { ...transportData, ...paymentData, id: doc.id };
        } else {
          // Si no hay datos en transportSnapshot, usar los de paymentSnapshot directamente
          combinedData = { ...paymentData, id: doc.id };
        }
        combinedResults.push(combinedData);
      });

      if (setPending) {
        setPending(false);
      }

      setdata({
        rows: combinedResults
      });
    }
  } catch (error) {
    console.error("Error en getDataPaymentsJoinTransport:", error);
  }
};

export const addDataPayments = async (data, message = false, del = false) => {
    try {
      var id = uuid();
      const docRef = doc(
        db,
        del ? (server === 'bookings' ? "plan_1_delete_911_payments_reservation" : 'dev_delete_911_payments_reservation') : server + "_payments_reservation",
        id
      );

      const userId = auth.currentUser;
      let today = new Date();
      let now = formatDate(today,"yyyy-MM-dd");

      data['created_by_id'] = userId?.uid ? userId.uid : "Customer-Payment";
      data['created_by_email'] = userId?.email ? userId.email : "Customer-Payment";
      data['created_by_name'] = userId?.displayName ? userId.displayName : "Customer-Payment";
      
      data['created_date'] = now;
      data['id'] = id;
      data['idInvoice'] = data?.id;

      if (del) {
        data['deleted_by_id'] = userId.uid;
        data['deleted_by_email'] = userId.email;
        data['deleted_by_name'] = userId.displayName;
        data['deleted_date'] = now;
      }

      await setDoc(docRef, data);

      if (message) {
        if(parseFloat(data?.capitalPaid) < 0){
          Swal.fire({
            icon: "success",
            title: data?.paymentType?.value +"ed",
            text: "The " + (data?.paymentType?.value === "Revert" ? ("reversal") : ("refund"))  + " was successful",
            confirmButtonColor: '#173d38',
            confirmButtonText: "Ok",
          });
        }else{
          Swal.fire({
            icon: "success",
            title: "Paid",
            text: "The payment was successful",
            confirmButtonColor: '#173d38',
            confirmButtonText: "Ok",
          });
        }
      }
    } catch (error) {
      alert(error);
    }
}

export const storeTemporaryReservationData = async (data) => {
    try {
      var id = uuid();

      const docRef = doc(
        db,
        server + "_temp_reservation_data",
        id
      );

      // Set user data
      const userId = auth.currentUser;
      let today = new Date();
      let now = formatDate(today,"yyyy-MM-dd");

      // Check user status
      if (userId !== null) {
        data['created_by_id'] = userId.uid;
        data['created_by_email'] = userId.email;
        data['created_by_name'] = userId.displayName;
        data['created_date'] = now;

        // Save data to Firestore
        await setDoc(docRef, data);
        // Return the newly generated UUID
        return id;
      }
    } catch (error) {
      console.log(error);
    }
}

export const getTemporaryReservationData = async (id) => {
  try {
      const docRef = doc(db, server + "_temp_reservation_data", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          return docSnap.data();
      } else {
          console.log("No such document!");
          return null;
      }
  } catch (error) {
      console.error("Error getting document:", error);
  }
};

export const deleteTemporaryReservationData = async (id) => {
  try {
    const docRef = doc(db, server + "_temp_reservation_data", id);
    await deleteDoc(docRef);
    console.log("Document successfully deleted!");
  } catch (error) {
    console.error("Error deleting document:", error);
  }
};

export const editDataPayments = async (edit) => {
    try {
      const docRef = doc(db, server + "_payments_reservation", edit.id);
      const userId = auth.currentUser;
      let today = new Date();
      let now = today.toLocaleString();
      
      edit['updated_by_id'] = userId.uid;
      edit['updated_by_email'] = userId.email;
      edit['updated_by_name'] = userId.displayName;
      edit['updated_date'] = now;

      await updateDoc(docRef,edit);

      Swal.fire({
        icon: "success",
        title: "Edited",
        text: "The payment was edited successfully",
        confirmButtonColor: '#173d38',
        confirmButtonText: "Ok",
      });
    } catch (error) {
      console.log(error);
    }
}

export const deleteDataPayments = async (element) => {
    try {
        var element_duplicated = Object.assign({}, element);
        await addDataPayments(element_duplicated, false, true);

        const docRef = doc(db, server + "_payments_reservation", element.id);
        await deleteDoc(docRef);
    } catch (error) {
        console.log(error);
    }
}