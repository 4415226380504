import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import imgw from "../../assets/Word_Logo_Color_Cream.png";
import imgz from "../../assets/Logo_Icon.png";

const ExportPDF = ({ data, headers, filename, totals, agents = false, transport = false, rental = false, allActivities = false, adventure = false, retail = false, sales = false}) => {

  async function generatePDF() {
    //const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Establecer la orientación horizontal
    });

    // Comprime imagen antes de agregarla al PDF
    const compressedImgw = await compressImage(imgw);
    const compressedImgz = await compressImage(imgz);

    // Header
    doc.setFillColor(23, 61, 56);
    doc.rect(0, 0, doc.internal.pageSize.width, 25, "F");
    
    // Company image
    const imgWidth = 60;
    const imgOriginalWidth = 8105;
    const imgOriginalHeight = 1641;
    const imgHeight = (imgWidth * imgOriginalHeight) / imgOriginalWidth;
    const imgMarginTop = 5;
    const imgMarginLeft = (doc.internal.pageSize.width - imgWidth) / 2;
    // doc.addImage(imgw, "PNG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);
    doc.addImage(compressedImgw, "PNG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);
    
    // Text of the superior rigth corner
    doc.setFontSize(8);
    doc.setTextColor(255, 255, 255);
    doc.text("802 BC-3", doc.internal.pageSize.width - 18, 10, "right");
    doc.text("Fernie, V0B1M0", doc.internal.pageSize.width - 18, 15, "right");

    // Subtítulo
    const imgzWidth = 25;
    const imgzOriginalWidth = 3105;
    const imgzOriginalHeight = 1641;
    const imgzHeight = (imgzWidth * imgzOriginalHeight) / imgzOriginalWidth;
    const imgzMarginTop = 5;
    const imgzMarginLeft = 14;
    doc.addImage(
      compressedImgz,
      "PNG",
      imgzMarginLeft,
      imgzMarginTop,
      imgzWidth,
      imgzHeight
    );

    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text(filename, 10, 40);
    doc.setFontSize(12);
    doc.text(`Report created: ${new Date().toLocaleDateString()}`, 10, 50);
    doc.setFontSize(11);

    let size = 8;

    // Configuración de estilos para el encabezado
    const headStyles = { 
      fillColor: '#1E645B', // Cambia el color de fondo aquí (Un poco mas claro que el de la empresa)
      // fillColor: '#173D38', // Cambia el color de fondo aquí (El de la empresa)
    };
    const headStyles2 = { 
      fillColor: '#DEA844', // Cambia el color del encabezado aquí (Un poco mas claro que el de la empresa)
    };

    if (data && data.length > 0) {
      
      if(agents){
        
        // doc.text(`Total amount: $ ${parseFloat(totals?.[0]).toLocaleString()}`, 10, doc.autoTable.previous.finalY + 10);
        // doc.text(`Total to pay:  $ ${parseFloat(totals?.[1]).toLocaleString()}`, 10, doc.autoTable.previous.finalY + 20);

        doc.autoTable({ 
          head: [headers], 
          body: data, 
          startY: 60,
          headStyles: headStyles, 
          bodyStyles: {
            fontSize: size,
          },
        });

        const tableData = [
          ['Total amount', 'Total to pay',],
          [`$ ${parseFloat(totals?.[0]).toLocaleString()}`, `$ ${parseFloat(totals?.[1]).toLocaleString()}`,]
        ]; 

        // Agregamos la tabla al documento
        doc.autoTable({
            head: [tableData[0]], // Encabezado de la tabla
            body: tableData.slice(1), // Cuerpo de la tabla (sin el encabezado)
            startY: doc.autoTable.previous.finalY + 15, // Posición inicial de la tabla en la página
            headStyles: headStyles2,
            bodyStyles: {
              fontSize: size, 
            }, 
        });

      }else{

        doc.autoTable({ 
          head: [headers], 
          body: data, 
          startY: 60,
          headStyles: headStyles,
          bodyStyles: {
            fontSize: size,
          },
        });
        
        if(allActivities){

            // Definimos los datos de la tabla
            const tableData = [
              ['Amounts', 'Fees', 'Fuel surcharge', 'Discounts', 'Taxes', 'Tips', 'Totals'],
              [`$ ${parseFloat(totals?.[0]).toLocaleString()}`, `$ ${parseFloat(totals?.[1]).toLocaleString()}`,
              `$ ${parseFloat(totals?.[4]).toLocaleString()}`, `$ ${parseFloat(totals?.[6]).toLocaleString()}`,
              `$ ${parseFloat(totals?.[2]).toLocaleString()}`, `$ ${parseFloat(totals?.[5]).toLocaleString()}`,
              `$ ${parseFloat(totals?.[3]).toLocaleString()}`,
              ],
            ]; 

            // Agregamos la tabla al documento
            doc.autoTable({
                head: [tableData[0]], // Encabezado de la tabla
                body: tableData.slice(1), // Cuerpo de la tabla (sin el encabezado)
                startY: doc.autoTable.previous.finalY + 15, // Posición inicial de la tabla en la página
                headStyles: headStyles2, 
                bodyStyles: {
                  fontSize: size,
                },
            });
            
        }else{

          if(transport){

            // Definimos los datos de la tabla
            const tableData = [
              ['Amount', 'Fuel surcharge', 'Discount', 'Tax', 'Tip', 'Total'],
              [`$ ${parseFloat(totals?.[0]).toLocaleString()}`, `$ ${parseFloat(totals?.[1]).toLocaleString()}`,
              `$ ${parseFloat(totals?.[5]).toLocaleString()}`, `$ ${parseFloat(totals?.[2]).toLocaleString()}`,
              `$ ${parseFloat(totals?.[4]).toLocaleString()}`, `$ ${parseFloat(totals?.[3]).toLocaleString()}`,
              ],
            ];

            // Agregamos la tabla al documento
            doc.autoTable({
                head: [tableData[0]], // Encabezado de la tabla
                body: tableData.slice(1), // Cuerpo de la tabla (sin el encabezado)
                startY: doc.autoTable.previous.finalY + 15, // Posición inicial de la tabla en la página
                headStyles: headStyles2, 
                bodyStyles: {
                  fontSize: size, 
                },
            });

          }else if(rental){ 
          
          // Definimos los datos de la tabla
          const tableData = [
            ['Amount', 'Discount', 'Tax', 'Tip', 'Total'],
            [`$ ${parseFloat(totals?.[0]).toLocaleString()}`, `$ ${parseFloat(totals?.[4]).toLocaleString()}`,
            `$ ${parseFloat(totals?.[1]).toLocaleString()}`, `$ ${parseFloat(totals?.[3]).toLocaleString()}`,
            `$ ${parseFloat(totals?.[2]).toLocaleString()}`,
            ],
          ];

          // Agregamos la tabla al documento
          doc.autoTable({
              head: [tableData[0]],
              body: tableData.slice(1), 
              startY: doc.autoTable.previous.finalY + 15, 
              headStyles: headStyles2, 
              bodyStyles: {
                fontSize: size, 
              },
          });

          }else if(adventure){

            // Definimos los datos de la tabla
            const tableData = [
              ['Amount', 'Fee', 'Discount', 'Tax', 'Tip', 'Total'],
              [`$ ${parseFloat(totals?.[0]).toLocaleString()}`, `$ ${parseFloat(totals?.[1]).toLocaleString()}`,
              `$ ${parseFloat(totals?.[5]).toLocaleString()}`, `$ ${parseFloat(totals?.[2]).toLocaleString()}`,
              `$ ${parseFloat(totals?.[4]).toLocaleString()}`, `$ ${parseFloat(totals?.[3]).toLocaleString()}`,
              ],
            ];

            // Agregamos la tabla al documento
            doc.autoTable({
                head: [tableData[0]],
                body: tableData.slice(1), 
                startY: doc.autoTable.previous.finalY + 15, 
                headStyles: headStyles2, 
                bodyStyles: {
                  fontSize: size, 
                },
            });

          }else if(retail){
            // Definimos los datos de la tabla
            const tableData = [
              ['Amount', 'Discounts', 'Tax', 'Tip', 'Total'],
              [`$ ${parseFloat(totals?.[0]).toLocaleString()}`, `$ ${parseFloat(totals?.[4]).toLocaleString()}`,
              `$ ${parseFloat(totals?.[1]).toLocaleString()}`, `$ ${parseFloat(totals?.[3]).toLocaleString()}`,
              `$ ${parseFloat(totals?.[2]).toLocaleString()}`,
              ],
            ]; 

            // Agregamos la tabla al documento
            doc.autoTable({
                head: [tableData[0]],
                body: tableData.slice(1), 
                startY: doc.autoTable.previous.finalY + 15, 
                headStyles: headStyles2, 
                bodyStyles: {
                  fontSize: size, 
                },
            });
          }else if(sales){
            // Definimos los datos de la tabla
            const tableData = [
              ['Amount', 'Water Fee', 'Fuel surcharge', 'Discount', 'Tax', 'Total'],
              [`$ ${parseFloat(totals?.[0]).toLocaleString()}`, `$ ${parseFloat(totals?.[5]).toLocaleString()}`,
               `$ ${parseFloat(totals?.[1]).toLocaleString()}`, `$ ${parseFloat(totals?.[4]).toLocaleString()}`, 
               `$ ${parseFloat(totals?.[2]).toLocaleString()}`, `$ ${parseFloat(totals?.[3]).toLocaleString()}`,
              ],
            ];

            // Agregamos la tabla al documento
            doc.autoTable({
                head: [tableData[0]], // Encabezado de la tabla
                body: tableData.slice(1), // Cuerpo de la tabla (sin el encabezado)
                startY: doc.autoTable.previous.finalY + 15, // Posición inicial de la tabla en la página
                headStyles: headStyles2, 
                bodyStyles: {
                  fontSize: size, 
                },
            });
          }

        }
        
      }
      doc.save(filename);
    }
  }

  return (
    <button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={generatePDF}>
      Export PDF
    </button>
  );
};

// Función para comprimir imágenes usando Canvas
const compressImage = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Add this line to handle CORS issues if needed
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      
      // Draw white background
      ctx.fillStyle = '#173D38'; // Tunnel49 color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw image on top of the white background
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      }, 'image/jpeg', 0.5); // Adjust quality here (0.5 means 50% quality)
    };
    img.src = imageUrl;
  });
};

export default ExportPDF;
