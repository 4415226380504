import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../../firebase';
import '../../style/pointOfSale/pointOfSale.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import { getDataProduct, getDataProductCount } from '../../services/inventory/products';
import { getCart } from '../../services/pointOfSale/carts';
import { Card } from "react-bootstrap";
import Cart from '../../components/shoppingCart';
import Loading from '../../components/loading';
import { Input } from 'antd';

const idRole = 'sale';

const Sale = () => {
  // This is necessary to fill in the selects and reuse the code from the config request type services.
  const [productList, setProductList] = useState({ rows: [] });
  const [pending, setPending] = useState(true);
  const [user, loading] = useAuthState(auth);
  const [filterText, setFilterText] = useState('');
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(12);
  const [auxStock, setAuxStock] = useState([]);
  const navigate = useNavigate();
  let { back } = useParams();

  // I am using this, for save current items in the cart
  const [items, setItems] = useState([]);
  const [data, setData] = useState(null);

  //Use by product List
  useEffect(() => {
    if (user != null) {
      getDataProduct(setProductList, setPending, skip, take);
      getDataProductCount(setCount);

      setTimeout(() => {
        //if(back === "back"){
          // let cartId = getCart(null, null, true);
          // cartId.then(id => {

            if (back) {
              Swal.fire({
                icon: "warning",
                title: "Load Cart",
                text: "There is a cart, you want to load it",
                confirmButtonColor: '#173d38',
                showCancelButton: true,
                confirmButtonText: "Ok",
                cancelButtonText: "Cancel"
              }).then((result) => {
                if (result.isConfirmed) {
                  getCart(back, setData);
                }else{
                  window.history.replaceState("","", "/sale")
                }
              })
            }

          // });
        //}
      }, 300);
    }
  }, [user]);

  //Use by product List
  useEffect(() => {
    getDataProduct(setProductList, setPending, skip, take, filterText, "created_date", true, items);
    getDataProductCount(setCount);
  }, [skip, filterText]);

    //Use by product List
    useEffect(() => {
      if (data) {
        setItems(data.items);
      }
    }, [data]);

  useEffect(() => {
    if (count !== 0) {
      let aux_pages = Math.ceil(+(count / take));
      let aux_pages_array = [];
      for (let i = 0; i < aux_pages; i++) {
        aux_pages_array.push(i + 1);
      }
      setPages(aux_pages_array);
    }
  }, [count]);

  useEffect(() => {
    if (items.length > 0) {
      calculateStock();
    }
  }, [items]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) navigate("/signin");
  }, [user, loading]);


  const addItem = (product) => {
    for (let i = 0; getStock(product) > i; i++) {
      setItems((prev) => [...prev, product]);
    }
  }

  const removeItem = (item,total) => {

    let paid = 0;
    total = parseFloat(total) - parseFloat(item?.price);

    if(data?.paid){
      paid = JSON.parse(data?.paid);
      paid = parseFloat(paid);
    }

    if (total < paid){
      Swal.fire({
        title: 'Error!',
        text: '$'+paid+' have already been paid, the product cannot be deleted because the balance will be negative', //This is the text
        icon: 'error',
        confirmButtonColor: '#173d38',
      })

    }else{
      let oneDelete = false;
      let arrayItems = [];

      items.forEach(element => {
        if (element.id === item.id && !oneDelete) {
          let auxProducts = [];
          productList?.rows?.forEach(product => {
            if (element.id === product.id) {
              product.stock = product?.allStock;
            }
            auxProducts.push(product);
          });
          setProductList({rows: auxProducts});
          oneDelete = true;
        } else { 
          arrayItems.push(element);
        }
      });

      setItems(arrayItems);
    }
    
  }

  const pagination = (page) => {
    if (page === "prev") {
      setSkip(0);
      setCurrentPage(1);
    } else {
      if (page === "nex") {
        setSkip(+((pages.length - 1) * take));
        setCurrentPage(pages.length);
      } else {
        setSkip(+((page - 1) * take));
        setCurrentPage(page);
      }
    }
  }

  const setStock = (value, item) => {
    
    let temp_stock = {
      id: item.id,
      stock: +value,
      max: item.stock
    }

    let isNew = true;
    let newAuxStock = [];

    auxStock.forEach(element => {
      if (element.id === item.id) {
        newAuxStock.push(temp_stock);
        isNew = false;
      } else {
        newAuxStock.push(element);
      }
    });

    if (isNew) {
      newAuxStock.push(temp_stock);
    }

    setAuxStock(newAuxStock);
    calculateStock();
  }

  const getStock = (item) => {
    let auxStockItem = 0;
    auxStock.forEach(element => {
      if (element.id === item.id) {
        auxStockItem = element.stock;
      }
    });

    return auxStockItem;
  }

  const calculateStock = () => {
    var auxProducts = [];
    productList?.rows?.forEach(product => {
      let previosStock = 0;
      items.forEach(element => {
        if (product.id === element.id) {
          previosStock++;
        }
      });
      //                             3                       0                  3
      product.stock = (product.allStock - previosStock) <= 0 ? 0: (product.allStock - previosStock);
      auxProducts.push(product);
    });
    setProductList({rows: auxProducts});
  }
  return (
    <div className='container-fluid'>
      {/* <div className='title mb-4'>
        <h2 className='title text-center'>Add new sale</h2>
      </div> */}
      {pending ?
        <div className='d-flex justify-content-center'>
          <Loading></Loading>
        </div>
        :
        <div className='row'>
          <div className='row col-md-8 px-3 b-gray'>
            <div className='col-12 d-inline'>
                <div className='col-12 d-flex'>
                  <div className='col-6'>

                    <h5 className='search-label'>Search</h5>
                    <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
                  </div>
                    <div className='col-6' style={{ textAlign: 'end', marginTop: 'auto' }}>
                      <a style={{ marginLeft: '70%' }} className="card-text" href="/pointofsale">Go to sales</a>
                  </div>
                </div>

              <hr></hr>

            </div>
            <div className='row col-md-12'>
              <div className='col-md-12'>
                <div className='row'>
                  {productList?.rows?.length > 0 ? (
                    productList?.rows?.map((product, index) => (
                      <div className='col-6 col-lg-4 col-xl-3 mb-3' key={index}>
                        <Card className={auxStock?.find(element => element?.id === product?.id)?.stock > product?.stock ? "border border-danger" : ""} style={{ textAlign: 'center', height: 250 }} id={`requested-${index + 1}`}>
                          <div style={{ textAlign: 'center', height: "35%" }}>
                            <h5 className="card-title">{product.product}</h5>
                            <h6 className="card-title">$ {product.price}</h6>
                          </div>
                          <hr></hr>
                          <div className='p-3'>
                            <div className='m-4 my-3'>
                              <Form.Control className='text-center' type="number" min="1"
                                value={auxStock?.find(element => element?.id === product?.id)?.stock ? auxStock?.find(element => element?.id === product.id)?.stock : 0}
                                onChange={(e) => setStock(e.target.value, product)}
                              />

                            </div>
                            {auxStock?.find(element => element?.id === product?.id)?.stock > product?.stock ?
                              (
                                <span style={{fontSize: '12px'}}>*Not enough inventory <br></br> Units available: {product?.stock}</span>
                              ) :
                              (
                                <Button style={{ width: "50%", background: '#173d38' }} onClick={() => { 
                                  addItem(product);
                                }} className="card-text">Add</Button>
                              )}
                          </div>
                        </Card>
                      </div>
                    ))) : (
                    <div style={{ margin: 'auto', marginTop: 25 }}>
                      <h3 style={{ textAlign: 'center' }}>
                        No products to show
                      </h3>
                    </div>
                  )
                  }
                  {count > take && filterText.trim().length <= 1 ? (
                    <nav>
                      <ul className="pagination justify-content-center">
                        <li className="page-item">
                          <a className={`page-link ${currentPage === 1 ? 'active' : ''}`} onClick={() => {
                            pagination(currentPage == 1? 1 : currentPage - 1);
                          }} tabIndex="-1">Previous</a>
                        </li>
                        {pages?.length > 0 ? (
                          pages?.map((page, index) => (
                            <li className="page-item" key={page}><a className={`page-link ${currentPage === page ? 'active' : ''}`}
                              onClick={() => {
                                pagination(page);
                              }}>{page}</a></li>
                          ))
                        ) : ("")}
                        <li className="page-item">
                          <a className={`page-link ${currentPage === pages?.length ? 'active' : ''}`}
                            onClick={() => {
                              pagination(currentPage == pages?.length ? pages?.length : currentPage + 1 );
                            }}>Next</a>
                        </li>
                      </ul>
                    </nav>
                  ) : (
                    ""
                  )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <Cart user={user} items={items} remove={removeItem} paid={data?.paid}></Cart>
          </div>
        </div>
      }
    </div>
  )
}

export default Sale