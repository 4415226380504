export const transformedData = (data, header, onlyheader = false) => {
  const names = header
  .filter(item => !item.omit)
  .map(item => item.name);
  if (onlyheader) {
    return names;
  }
  let results = [];
  data.forEach((element) => {
    let row = [];
    header.forEach(colum => {
      if (!colum.omit) {
        row.push(typeof colum?.selector === "function" ? colum.selector(element) : '');
      }
    });
    results.push(row);
  });
  return results;
}