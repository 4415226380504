import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import logo from '../assets/Logo_Icon.png';
import imgw from '../assets/Word_Logo_Color_Cream.png';
import { getDataOneUser } from '../services/auth/users';
import { roleVisit } from '../services/Tools';
import { UserAuth } from '../context/AuthContext';
import '../style/style.css';
import '../style/navbar.css';

const AvatarDropdown = ({ user, userInfo, handleSignOut }) => (
  
  user && user.photoURL && (
    <Dropdown className='m-auto'>
      <Dropdown.Toggle variant="null">
        <img className="nav-avatar" src={`https://via.placeholder.com/50/12675c/FFFFFF/?text=${user.displayName.charAt(0)}`} alt="User Avatar" />
        <h5 className="username">{user.displayName}</h5>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-right">
        <Dropdown.Item href="/home">Home</Dropdown.Item>
        <Dropdown.Item href="/hybrid">Hybrid</Dropdown.Item>
        <Dropdown.Item href="/adventures">Adventure</Dropdown.Item>
        <Dropdown.Item href="/booking">Transport</Dropdown.Item>
        
        {userInfo?.role !== roleVisit && (
          <>
            <Dropdown.Item href="/rentals">Rental</Dropdown.Item>
            <Dropdown.Item href="/retails">Retail</Dropdown.Item>
            <Dropdown.Item href="/clients">Clients</Dropdown.Item>
            <Dropdown.Item href="/report">Report</Dropdown.Item>
            <Dropdown.Item href="/events">Events</Dropdown.Item>
            <Dropdown.Item href="/settings">Settings</Dropdown.Item>
          </>
        )}
        <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

  )
);


const Navbars = () => {
  const { user, logOut } = UserAuth();
  const [userInfo, setUserInfo] = useState('');

  useEffect(() => {
    const getUserData = async () => {
      const userDocSnap = await getDataOneUser(user.uid);
      if (userDocSnap?.length > 0) {
        setUserInfo(userDocSnap[0]);
      }
    };
    if (user) {
      getUserData();
    }
  }, [user]);

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }

  };

  return (
    <>
      <Navbar className="navbar" expand="lg">
        <Container>
          <Navbar.Brand className='col-md-3'>
            <img className="logo" src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Brand className='col-md-8'>
            <img className="imag" src={imgw} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Brand className='col-md-1'>
          </Navbar.Brand>
        </Container>
        <AvatarDropdown user={user} userInfo={userInfo} handleSignOut={handleSignOut} />
      </Navbar>
    </>
  );
};

export default Navbars;
