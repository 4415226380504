import "./style/style.css";
import Navbar from "./components/navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Adventures from "./pages/Adventures";
import Booking from "./pages/Booking";
import Rentals from "./pages/Rentals";
import Retails from "./pages/Retails";
import Hybrid from "./pages/Hybrid";
import Events from "./pages/Events";
import PointOfSale from "./pages/pointOfSale/pointOfSale";
import Payment from "./components/pointOfSale/payment/dashboard";
import Sale from "./pages/pointOfSale/sale";
import Accounting from "./pages/Accounting";
import Settings from "./pages/Settings";
import Signin from "./pages/Signin";
import Clients from './pages/Clients';
import Report from './pages/Report';
import { AuthContextProvider } from "./context/AuthContext";
import ClientBooking from './components/bookingTable/reservations/tableClient';
import SuccessPayment from './pages/SuccessPayment';

//A 404 error is not really necessary, because this project works in root, with a SPA (Single Page Application) scheme; 
//IS JUST A TEST
function App() {
  return (
    <>
      <AuthContextProvider>
        <Navbar />
        <Routes>
          <Route path="/adventures" element={<Adventures />} />
          <Route path="/adventures/:tap" element={<Adventures />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/booking/:tap" element={<Booking />} />
          <Route path="/sale" element={<Sale />} />
          <Route path="/sale/:back" element={<Sale />} />
          <Route path="/pointofsale" element={<PointOfSale />} />
          <Route path="/rentals" element={<Rentals />} />
          <Route path="/rentals/:tap" element={<Rentals />} />
          <Route path="/retails" element={<Retails />} />
          <Route path="/payment/:uuid" element={<Payment />} />
          <Route path="/accounting" element={<Accounting />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/bookings/:id" element={<ClientBooking />} />
          <Route path="/report" element={<Report />} />
          <Route path="/hybrid" element={<Hybrid />} />
          <Route path="/events" element={<Events />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/success" element={<SuccessPayment />} /> 
        </Routes>
      </AuthContextProvider>
    </>
  );
}

export default App;


