import React, { useMemo } from "react";
import { CSVLink } from "react-csv";

const ExportCSV = ({
  data = [],
  filename,
  headers = [],
  totals = [],
  allActivities = false,
  allRevenue = false,
  startDate = null,
  endDate = null
}) => {

  // Función para limpiar y convertir valores de texto a número
  const parseValue = (value) => {
    return parseFloat(value.replace(/[$\s]/g, '')) || 0;
  };

  const { invoiceViaQBData, nonInvoiceViaQBData } = useMemo(() => {
    const invoiceViaQBData = data.filter(item => item[4] === "Invoiced via QB");
    const nonInvoiceViaQBData = data.filter(item => item[4] !== "Invoiced via QB");
    return { invoiceViaQBData, nonInvoiceViaQBData };
  }, [data]);
  
  // Filtrar los datos para que solo incluyan aquellos con el valor en la posición #5 mayor a 0
  const filteredData = nonInvoiceViaQBData.filter(item => parseValue(item[5]) > 0);
  const filteredDataInvoiceViaQB = invoiceViaQBData.filter(item => parseValue(item[5]) > 0);
  
  // Ordenar los datos filtrados según el valor en la posición #2, y en caso de igualdad, por la posición #1
  const sortedData = allActivities ? [...filteredData].sort((a, b) => {
    // Comparar primero por la posición #2 (a[2] y b[2])
    if (a[2] < b[2]) return -1;
    if (a[2] > b[2]) return 1;

    // Si son iguales, comparar por la posición #1 (a[1] y b[1])
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;

    // Si todos los criterios son iguales
    return 0;
  }) : filteredData;

 // Ordenar los datos filtrados según el valor en la posición #1, y en caso de igualdad, por la posición #3 para todos los que son invoiceViaQB
 const sortedDataInvoiceViaQB = allActivities ? [...filteredDataInvoiceViaQB].sort((a, b) => {
  // Comparar primero por la posición #0 (a[0] y b[0])
  if (a[0] < b[0]) return -1;
  if (a[0] > b[0]) return 1;

  // Si son iguales, comparar por la posición #2 (a[2] y b[2])
  if (a[1] < b[1]) return -1;
  if (a[1] > b[1]) return 1;

  // Si son iguales, comparar por la posición #2 (a[2] y b[2])
  if (a[2] < b[2]) return -1;
  if (a[2] > b[2]) return 1;

  // Si todos los criterios son iguales
  return 0;
}) : filteredDataInvoiceViaQB; 

  // Agregar título al inicio del CSV
  const csvData = [];

  if (startDate) {
    csvData.push(["Daily report: ", startDate, startDate ? "to" : "", endDate]);
    csvData.push([]); // Espacio vacío
  }

  // Agregar encabezados de las columnas (excluir los índices especificados)
  csvData.push(headers.filter((_, i) => ![].includes(i)));

  let currentGroupKey = null;
  let currentGroupKeyClient = null;
  let totalSums = { amount: 0, discount: 0, total: 0, card: 0, fuel: 0, fee: 0,  tax: 0, total2: 0 }; // Para obtener el Gran Total
  let totalViaQBSums = { amount: 0, discount: 0, total: 0, card: 0, fuel: 0, fee: 0,  tax: 0, total2: 0 }; // Para obtener el Gran Total para invoiced via
  let totalViaQBSumsClient = { amount: 0, discount: 0, total: 0, card: 0, fuel: 0, fee: 0,  tax: 0, total2: 0 }; // Para obtener el Total para clientes
  let groupSums = { amount: 0, discount: 0, total: 0, card: 0, fuel: 0, fee: 0,  tax: 0, total2: 0 };
  let invoicedViaQBSums = { amount: 0, discount: 0, total: 0, card: 0, fuel: 0, fee: 0,  tax: 0, total2: 0  };


  // Filtrar el resto de los datos
  const nonCardPaymentsAndETransferData = sortedData;

  // Función para agregar fila de resumen
  const addGroupSummaryRow = (key, sums, viaQB) => { 
    if (key) { 

      csvData.push(["", "", `Total for ${key}`, "", "", 
        `$${sums.amount.toFixed(2)}`, `$${sums.discount.toFixed(2)}`, 
        `$${sums.total.toFixed(2)}`, `$${sums.card.toFixed(2)}`, 
        `$${sums.fuel.toFixed(2)}`, `$${sums.fee.toFixed(2)}`,
        `$${sums.tax.toFixed(2)}`, `$${sums.total2.toFixed(2)}`]);

      if(viaQB){

        //Se suman los totales para invoicedViaQB para una actividad
        totalViaQBSums.amount += parseFloat(sums.amount);
        totalViaQBSums.discount += parseFloat(sums.discount);
        totalViaQBSums.total += parseFloat(sums.total);
        totalViaQBSums.card += parseFloat(sums.card);
        totalViaQBSums.fuel += parseFloat(sums.fuel);
        totalViaQBSums.fee += parseFloat(sums.fee);
        totalViaQBSums.tax += parseFloat(sums.tax);
        totalViaQBSums.total2 += parseFloat(sums.total2);

        //Se suman los totales para los clientes
        totalViaQBSumsClient.amount += parseFloat(sums.amount);
        totalViaQBSumsClient.discount += parseFloat(sums.discount);
        totalViaQBSumsClient.total += parseFloat(sums.total);
        totalViaQBSumsClient.card += parseFloat(sums.card);
        totalViaQBSumsClient.fuel += parseFloat(sums.fuel);
        totalViaQBSumsClient.fee += parseFloat(sums.fee);
        totalViaQBSumsClient.tax += parseFloat(sums.tax);
        totalViaQBSumsClient.total2 += parseFloat(sums.total2);

      }else{  
        totalSums.amount += parseFloat(sums.amount);
        totalSums.discount += parseFloat(sums.discount);
        totalSums.total += parseFloat(sums.total);
        totalSums.card += parseFloat(sums.card);
        totalSums.fuel += parseFloat(sums.fuel);
        totalSums.fee += parseFloat(sums.fee);
        totalSums.tax += parseFloat(sums.tax);
        totalSums.total2 += parseFloat(sums.total2);
      }      

      csvData.push([]);
    }
  };

  // Función para agregar fila de resumen para un cliente cuando es un invoicedViaQB
  const addGroupSummaryRowClient = (key, sums) => { 
    if (key) { 

      csvData.push(["", "", `Total for ${key}`, "", "", 
        `$${sums.amount.toFixed(2)}`, `$${sums.discount.toFixed(2)}`, 
        `$${sums.total.toFixed(2)}`, `$${sums.card.toFixed(2)}`, 
        `$${sums.fuel.toFixed(2)}`, `$${sums.fee.toFixed(2)}`,
        `$${sums.tax.toFixed(2)}`, `$${sums.total2.toFixed(2)}`]);

        totalViaQBSums.amount += parseFloat(sums.amount);
        totalViaQBSums.discount += parseFloat(sums.discount);
        totalViaQBSums.total += parseFloat(sums.total);
        totalViaQBSums.card += parseFloat(sums.card);
        totalViaQBSums.fuel += parseFloat(sums.fuel);
        totalViaQBSums.fee += parseFloat(sums.fee);
        totalViaQBSums.tax += parseFloat(sums.tax);
        totalViaQBSums.total2 += parseFloat(sums.total2);   

      csvData.push([]);
      csvData.push([]);
    }
  };

  // Procesar y agregar datos
  nonCardPaymentsAndETransferData.forEach((item, index) => {
    // Verificar si item[5] es mayor a 0 antes de agregar al CSV
    if (parseValue(item[5]) > 0) {
      const row = [];
      headers.forEach((header, i) => {
        row.push(item[i]);
      });
  
      // Actualizar la clave del grupo actual
      const groupKey = item[2];
  
      if (currentGroupKey && currentGroupKey !== groupKey) {                  
        addGroupSummaryRow(currentGroupKey, groupSums, false);
        // Reiniciar las sumas del grupo
        groupSums = { amount: 0, discount: 0, total: 0,  card: 0, fuel: 0, fee: 0, tax: 0, total2: 0 };
      }
  
      // Sumar los valores de las columnas al total del grupo
      groupSums.amount += parseValue(item[5]);
      groupSums.discount += parseValue(item[6]);
      groupSums.total += parseValue(item[7]);
      groupSums.card += parseValue(item[8]);
      groupSums.fuel += parseValue(item[9]);
      groupSums.fee += parseValue(item[10]);
      groupSums.tax += parseValue(item[11]);
      groupSums.total2 += parseValue(item[12]);

      // Actualizar la clave del grupo actual
      currentGroupKey = groupKey;
  
      csvData.push(row);
  
      // Verificar si es el último elemento y agregar la fila de resumen
      if (index === nonCardPaymentsAndETransferData.length - 1) {
        addGroupSummaryRow(currentGroupKey, groupSums, false);
        groupSums = { amount: 0, discount: 0, total: 0, card: 0, fuel: 0, fee: 0, tax: 0, total2: 0 };
      }
    }
  });  

  if (totalSums) {
    csvData.push([]);
    csvData.push(["", "", "Grand Total", "", "", 
      `$${parseFloat(totalSums.amount).toFixed(2)}`, `$${parseFloat(totalSums.discount).toFixed(2)}`,
      `$${parseFloat(totalSums.total).toFixed(2)}`, `$${parseFloat(totalSums.card).toFixed(2)}`,
      `$${parseFloat(totalSums.fuel).toFixed(2)}`, `$${parseFloat(totalSums.fee).toFixed(2)}`,
      `$${parseFloat(totalSums.tax).toFixed(2)}`, `$${parseFloat(totalSums.total2).toFixed(2)}`]);

  }

  currentGroupKey = null;


  csvData.push([]);
  csvData.push([]);
  csvData.push([]);

  // Agregar los datos de "Invoiced via QB" al final
  sortedDataInvoiceViaQB.forEach((item, index) => {
    if (parseValue(item[5]) > 0) {
      const row = [];
      headers.forEach((header, i) => {
          row.push(item[i]);
      });

      // Ejecutar la lógica de agrupamiento y suma solo si allRevenue es true
      const groupKey = item[2];
      const groupKeyClient = item[0];

      if (currentGroupKey && currentGroupKey !== groupKey) {
        addGroupSummaryRow(currentGroupKey, invoicedViaQBSums, true);
        // Reiniciar las sumas del grupo
        invoicedViaQBSums = { amount: 0, discount: 0, total: 0, card: 0, fuel: 0, fee: 0, tax: 0, total2: 0 };

      }

      if (currentGroupKeyClient && currentGroupKeyClient !== groupKeyClient) {
        addGroupSummaryRowClient(currentGroupKeyClient, totalViaQBSumsClient);
        // Reiniciar las sumas del grupo total para cliente
        totalViaQBSumsClient = { amount: 0, discount: 0, total: 0, card: 0, fuel: 0, fee: 0, tax: 0, total2: 0 };
      }

      // Actualizar la clave del grupo actual
      currentGroupKey = groupKey;
      currentGroupKeyClient = groupKeyClient;

      //Se suman los totales para invoicedViaQB para una actividad
      invoicedViaQBSums.amount += parseValue(item[5]);
      invoicedViaQBSums.discount += parseValue(item[6]);
      invoicedViaQBSums.total += parseValue(item[7]);
      invoicedViaQBSums.card += parseValue(item[8]);
      invoicedViaQBSums.fuel += parseValue(item[9]);
      invoicedViaQBSums.fee += parseValue(item[10]);
      invoicedViaQBSums.tax += parseValue(item[11]);
      invoicedViaQBSums.total2 += parseValue(item[12]);

      csvData.push(row);

      // Verificar si es el último elemento y agregar la fila de resumen
      if (index === sortedDataInvoiceViaQB.length - 1) {
        //Muestra el total para la posicion #2 = service
        addGroupSummaryRow(currentGroupKey, invoicedViaQBSums, true);
        invoicedViaQBSums = { amount: 0, fuel: 0, fee: 0, discount: 0, tax: 0, total: 0 };

        //Muestra el total para la posicion #0 = client name
        addGroupSummaryRowClient(currentGroupKeyClient, totalViaQBSumsClient);
        // Reiniciar las sumas del grupo total para cliente
        totalViaQBSumsClient = { amount: 0, discount: 0, total: 0, card: 0, fuel: 0, fee: 0, tax: 0, total2: 0 };

      }
    }
  });

  // if (totalViaQBSums) {
  //   csvData.push([]);
  //   csvData.push(["", "", "Grand Total", "", "", 
  //     `$${parseFloat(totalViaQBSums.amount).toFixed(2)}`, `$${parseFloat(totalViaQBSums.discount).toFixed(2)}`,
  //     `$${parseFloat(totalViaQBSums.total).toFixed(2)}`, `$${parseFloat(totalViaQBSums.card).toFixed(2)}`,
  //     `$${parseFloat(totalViaQBSums.fuel).toFixed(2)}`, `$${parseFloat(totalViaQBSums.fee).toFixed(2)}`,
  //     `$${parseFloat(totalViaQBSums.tax).toFixed(2)}`, `$${parseFloat(totalViaQBSums.total2).toFixed(2)}`]);
  // }

  return (
    <CSVLink
      data={csvData}
      filename={filename}
      className="color-green btn btn-outline-secondary p-2 mx-2"
      target="_blank"
    >
      Export CSV
    </CSVLink>
  );
};

export default ExportCSV;
