import React, {useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import '../../style/calendar.css'
import { getDataReservations, getReservationById, editDataReservations } from '../../services/ConfigReservations';
import { getClientReservations } from '../../services/ConfigReservations';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { UserAuth } from "../../context/AuthContext";
import ModalC from "../modal";
import FormConfigReser from "../bookingTable/reservations/formConfig";
import { useParams } from 'react-router-dom';
import Loading from '../loading';
import Swal from "sweetalert2";

const now = new Date();
const localizer = momentLocalizer(moment);

function MyCalendar({ clients }){

  const [myEvents, setEvents] = useState([]);
  const [dataReser, setDataReser] = useState({ rows: [] });
  const [pending, setPending] = useState(true);
  const [pendingReser, setPendingReser] = useState(true);
  const [modalReser, setModalReser] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [edit, setEdit] = useState(null);
  const { user } = UserAuth();
  const [ reservationData, setReservationData ] = useState({rows: []});
  let { tap } = useParams();

  useEffect(() => {
    if (user != null) {
      getDataReservations(setDataReser, setPending);
      setTimeout(() => {
        if (tap?.length > 1) {
          getClientReservations(tap, setReservationData, setPendingReser);
        }
      }, 300);
    }
  }, [user]);


  useEffect(() => {
    if (dataReser?.rows?.length !== 0) {
      setEvents([]);
      if(tap?.length > 1){
        prepareActivites(reservationData.rows);
      }else{
        prepareActivites(dataReser.rows);
      }
    }
  }, [reservationData,dataReser]);

  useEffect(() => {
    if (reservationData) {
      // maybe trigger a loading screen
      let event = [];

      // if(reservationData?.rows?.[0]){
      //   event = {
      //     id: reservationData?.rows?.[0]?.id,
      //     title: reservationData?.rows?.[0]?.shuttle?.label + " " +reservationData?.rows?.[0]?.startTime + "-" + reservationData?.rows?.[0]?.endTime,
      //     start: new Date(reservationData?.rows?.[0]?.reservationData?.rows?.[0]+"T"+reservationData?.rows?.[0]?.startTime),
      //     end: new Date(reservationData?.rows?.[0]?.endDate+"T"+reservationData?.rows?.[0]?.endTime),
      //     status: 'Reservations',
      //     reservation: reservationData?.rows?.[0]
      //   }
      // }
      
      handleSelectEvent(event);

      return;
    }
  }, [reservationData]);

  const prepareActivites = (data) => {
    data.forEach(element => {
      let event = [];

      if(element?.refund && element?.balance === 0){

      }else{
        if(element?.shuttle){

          let name = element?.newClient ? element?.newClientData : element?.client?.label;

          event = {
            id: element.id,
            // title: element?.pickUp?.label + " - " + element?.dropOff?.label,
            title: element?.pickUp?.label + "-" + element?.dropOff?.label + ", " + name + " (" + element?.nop  + ")",
            start: new Date(element?.startDate+"T"+element?.startTime),
            end: new Date(element?.endDate+"T"+element?.endTime),
            status: 'Reservations',
            startDate: element?.startDate,
            service: element?.shuttle?.label,
            endDate: element?.endDate,
            reservation: element
          }
        }
      }
      setEvents((prev) => [...prev, event])
    });
  }

  const action = (data) => {
    if (edit) {
      if(data?.shuttle){
        editDataReservations(data,true);
        setModalReser(false);
      } 
    } 
    setTimeout(() => {
      getDataReservations(setDataReser, setPending);
    }, 500);
  }

  // No delete, is import for future
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt('New Event Name')
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }])
      }
    },
    [setEvents]
  )

  const handleSelectEvent = useCallback(
    async (event) => {
      if (event?.status === 'Reservations') {  
        try {
          // Llamar a getReservationById y esperar a que se resuelva la promesa
          const aux = await getReservationById(event?.reservation?.id);

          // Verifica si se devolvió algo en aux
          if (aux[0]) {
            setModalReser(true);
            setEdit(aux[0]);
            setTitleModal('Edit reservation');
          } else {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Error editing, please try again later",
              confirmButtonColor: '#173d38',
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error to edit booking:", error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while processing your request",
            confirmButtonColor: '#173d38',
            confirmButtonText: "OK",
          });
        }
      }
    },
    []
  );

  const eventPropGetter = useCallback(
    (event) => {
      
      //let backgroundColor = event?.reservation?.pickUp?.value;// ? "#173d38" : "green";
      let backgroundColor = event?.reservation?.shuttle?.color ? event?.reservation?.shuttle?.color : "#EEEEEE";// ? "#173d38" : "green";

      return { style: { backgroundColor } }
      
    },[]
    
    // (event) => ({
    //   ...(
    //     event.status.includes('Reservations') && {
    //       style: {
    //         backgroundColor: '#173d38',
    //       },
    //     }
      
    //   ),
    //   // ...(event.status.includes('Activities') && {
        
    //   //   style: {
    //   //     backgroundColor: '#14b6ab',
    //   //   },
    //   // }),
    //   // ...(event.status.includes('Rentals') && {
    //   //   style: {
    //   //     backgroundColor: '#399c07',
    //   //   },
    //   // }),
      
    // }),
    // []
  )

  return (
    <>
    <div className="App calendar">
      { tap?.length > 1 ? (
          !pendingReser ? (
            reservationData?.rows?.length > 1? (
              
              <>              
              <div className="d-flex justify-content-between flex-row-reverse mb-2">
                <div style={{margin:"auto"}}>
                  <h3 style={{textAlign:"center"}}> { reservationData?.rows?.[0]?.client?.label} transports</h3> 
                </div>
              </div>

              <Calendar
              defaultDate={ moment(reservationData?.rows?.[0]?.startDate, 'YYYY-MM-DD') }
              defaultView={"month"}
              localizer={localizer}
              resizable
              style={{ height: "100vh" }}
              eventPropGetter={eventPropGetter}
              events={myEvents}
              onSelectEvent={handleSelectEvent}
              //onSelectSlot={handleSelectSlot}
              selectable
            />
            </>
            ) : (
              <>
              <Calendar
                defaultDate={ moment().toDate() }
                defaultView={"month"}
                localizer={localizer}
                resizable
                style={{ height: "100vh" }}
                eventPropGetter={eventPropGetter}
                events={myEvents}
                onSelectEvent={handleSelectEvent}
                //onSelectSlot={handleSelectSlot}
                selectable
              />
              </>
            )

          ) : ( 
            <div className='d-flex justify-content-center'>
              <Loading></Loading>
            </div>
          )
      ) : (

          pending ? (
              <div className='d-flex justify-content-center'>
                <Loading></Loading>
              </div>
          ) : (
            <>

            <Calendar
                defaultDate={ moment().toDate() }
                defaultView={"month"}
                localizer={localizer}
                resizable
                style={{ height: "100vh" }}
                eventPropGetter={eventPropGetter}
                events={myEvents}
                onSelectEvent={handleSelectEvent}
                //onSelectSlot={handleSelectSlot}
                selectable
              />
            </>
          )
          
      ) }

      <ModalC
        show={modalReser}
        onHide={() => setModalReser(false)}
        changeSize={true}>
        <FormConfigReser title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModalReser} clients={clients}></FormConfigReser>
      </ModalC>

    </div>
    </>
  );
  
}

export default MyCalendar;