import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../../../firebase';
import { Card, CardGroup, Form } from 'react-bootstrap';
import Invoice from './invoice';
import Payment from './payment';
import { getCart } from '../../../services/pointOfSale/carts';
import { taxes } from '../../../services/serverName/Bookings';
import { getData } from '../../../services/crm/clients';

const idRole = 'dashboard';

const Dashboard = () => {

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [data, setData] = useState(null);
    const [newData, setNewData] = useState(null);
    let { uuid } = useParams();
    let change = true;
    const [itemsList, setItemsList] = useState([{ value: '', label: '' }]);

    useEffect(() => {
        if (loading) {
        // maybe trigger a loading screen
        return;
        }
        if (!user) navigate("/signin");
    }, [user, loading]);


    useEffect(() => {
        if (uuid) {
            getCart(uuid, setData);
        }
    }, [uuid]);

    useEffect(() => {
        if (data?.items?.length > 0) {
            var aux = [];
            data?.items?.forEach(element => {
                aux.push(element);
            });
            setItemsList(aux);
        }
    }, [data]);

    const changeData = (newData) => {

        setNewData(newData);
        setData(newData);

        if(change){
            change = false;
        }else{
            change = true;
        }
    }

    return (
        <div className="dialog" style={{ display: "flex"}}>
            <div className='p-1' style={{ width: "55%"}}>
            <CardGroup>
                <Card className='p-3' style={{ textAlign: 'center'}}>
                    {change ? (<Invoice data={data}></Invoice>) : (<Invoice data={newData}></Invoice>)}
                    
                </Card>
            </CardGroup>
            </div>
            
            <div className='p-1' style={{ width: "45%"}}>
            <CardGroup>
                <Card className='p-2' style={{ textAlign: 'center'}}>
                    <Payment data={data} changeData={changeData}></Payment>
                </Card>
            </CardGroup>
            </div>
            
        </div>
      )
}

export default Dashboard;