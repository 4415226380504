import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'

function FormConfig({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [label, setLabel] = useState("");
    const [fixedRate, setFixedRate] = useState(false);
    const [rate, setRate] = useState("");
    const [color, setColor] = useState("");

    useEffect(() => {
        if (lastData) {
            setId(lastData.id);
            setLabel(lastData.label);
            setFixedRate(lastData?.fixedRate);
            setRate(lastData?.rate);  
            setColor(lastData?.color ? lastData?.color : "#EEEEEE");      
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    const changeState = (e) => {

        if(e === true){
            setFixedRate(true);
        }else{
            setFixedRate(false);
            setRate("");
        }
    }

    const checkData = (data) => {


        if (label !== '' && color !== '') {
            if(fixedRate){
                if(rate !== ''){
                    action(data, lastData);
                }else{
                    Swal.fire({
                        icon: "error", //Constants = success, error, warning
                        title: "Error!",
                        text: "Rate is required",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                }
            }else{
                action(data, lastData);
            }

        } else {
            Swal.fire({
                icon: "error", //Constants = success, error, warning
                title: "Error!",
                text: "The field are required",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
              });
        }

    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formShuttle">
                        <div className="d-inline-flex">
                            <Form.Label>Shuttle</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the shuttle"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBookingType">
                        <div className="d-inline-flex">
                            <Form.Label>Is it a fixed rate?</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={fixedRate}
                                onChange={() => changeState(true)}
                            />Yes
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={!fixedRate}
                                onChange={() => changeState(false)}
                            />No
                        </div>
                    </Form.Group> 
                </div>

                <div className='col-md-6'>

                    {fixedRate ? (
                        <Form.Group className="mb-3" controlId="formPlace">
                        <div className="flex">
                            <div className="d-inline-flex">
                                <Form.Label>Rate</Form.Label>
                                <h5 className="required">*</h5>
                            </div>

                            <div className="row">
                                <div className='d-inline-flex'>
                                    <h5 style={{margin: "auto"}}>$</h5>
                                    <Form.Control type="number" placeholder="0"
                                        value={rate}
                                        onChange={(e) => setRate(e.target.value)}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        </Form.Group>
                    ) : ("")
                    }

                    <Form.Group className="mb-3" controlId="formPlace">
                        <div className="d-inline-flex">
                            <Form.Label>Identification color</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="color"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>
                </div>
                <h5 className="required">All fields with (*) are required</h5>
            </div>
            
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {
                    var value = label;
                    var data = {
                        id,
                        label,
                        value,
                        fixedRate,
                        rate,
                        color,
                    };

                    checkData(data);
                 
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;