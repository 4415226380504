import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsClientServicesConfig } from '../../../services/columnsList';
import Button from '../../button';
import ModalC from '../../modal';
// import FormConfig from './formConfig';
import Swal from "sweetalert2";
import { getClientServicesReport } from '../../../services/ConfigReservations';
import { UserAuth } from '../../../context/AuthContext';
import { Input } from 'antd';
import '../../../style/table.css'
import ExportPDF from "../../../services/exports/exportPDF";
import ExportCSV from "../../../services/exports/exportCSV";
import { transformedData } from '../../../services/exports/transformedData';
import { DateRangePicker } from "rsuite";
import { formatDate } from '../../../services/Tools';
import { Table } from 'react-bootstrap';

function TableConfig() {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState({ rows: [] });
  const [dataAdventure, setDataAdventure] = useState({ rows: [] });
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [edit, setEdit] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();
  const [amount, setAmount] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [total, setTotal] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsDate, setFilteredItemsDate] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [waterFeePaid, setWaterFeePaid] = useState(0);
  const [surcharge, setSurcharge] = useState("");
  const [discount, setDiscount] = useState("");


  useEffect(() => {
    if (user != null) {
      getClientServicesReport(setData, setPending);
    }
  }, [user]);

  useEffect(() => {
    if (data != null) {
    let newData = [];
    let amountTotal = 0;
    let feeTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalTotal = 0;

    if(data){
      data?.rows?.forEach(element => {
        amountTotal = element?.amount ? parseFloat(amountTotal) + parseFloat(element?.amount) : parseFloat(amountTotal);
        feeTotal = element?.waterFeeAmount ? parseFloat(feeTotal) + parseFloat(element?.waterFeeAmount) : parseFloat(feeTotal);
        taxTotal = element?.taxAmount ? parseFloat(taxTotal) + parseFloat(element?.taxAmount) : parseFloat(taxTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        surchargeTotal = element?.fuelSurchargeAmount ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargeAmount) : parseFloat(surchargeTotal);
        totalTotal = element?.total ? parseFloat(totalTotal) + parseFloat(element?.total) : parseFloat(totalTotal);
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      fee: feeTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      surcharge: surchargeTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(parseFloat(amountTotal.toFixed(2)));
    setWaterFeePaid(parseFloat(feeTotal.toFixed(2)));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setSurcharge(parseFloat(surchargeTotal.toFixed(2)));
    setTaxAmount(parseFloat(taxTotal.toFixed(2)));
    setTotal(parseFloat(totalTotal.toFixed(2)));
    changeFilter("");

    }
  }, [data]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const changeFilter = (e) => { 

    let newFilter = [];
    if(data?.rows?.length > 0){
      newFilter = data?.rows?.filter(
      item => item?.shuttle?.label && item?.shuttle?.label.toLowerCase().includes(e.toLowerCase()) 
      || item?.adventure?.label && item?.adventure?.label.toLowerCase().includes(e.toLowerCase()) 
      || item?.startDate && item?.startDate.toLowerCase().includes(e.toLowerCase()) 
      || item?.id && item?.id.toLowerCase().includes(e.toLowerCase()) 
      || item?.client?.label && item?.client?.label.toLowerCase().includes(e.toLowerCase())
      || item?.newClientData && item?.newClientData.toLowerCase().includes(e.toLowerCase())      
      || item?.clientName && item?.clientName.toLowerCase().includes(e.toLowerCase()) 
      || item?.email && item?.email.toLowerCase().includes(e.toLowerCase()) 
    );

    setFilteredItemsDate([]);
    setFilteredItems(newFilter);
  }
  setFilterText(e);

  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    let newData = [];
    let amountTotal = 0;
    let feeTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalTotal = 0;

    if(filteredItems){
      filteredItems.forEach(element => {
        amountTotal = element?.amount ? parseFloat(amountTotal) + parseFloat(element?.amount) : parseFloat(amountTotal);
        feeTotal = element?.waterFeeAmount ? parseFloat(feeTotal) + parseFloat(element?.waterFeeAmount) : parseFloat(feeTotal);
        taxTotal = element?.taxAmount ? parseFloat(taxTotal) + parseFloat(element?.taxAmount) : parseFloat(taxTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        surchargeTotal = element?.fuelSurchargeAmount ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargeAmount) : parseFloat(surchargeTotal);
        totalTotal = element?.total ? parseFloat(totalTotal) + parseFloat(element?.total) : parseFloat(totalTotal);
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      fee: feeTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      surcharge: surchargeTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setWaterFeePaid(parseFloat(feeTotal.toFixed(2)));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setSurcharge(parseFloat(surchargeTotal.toFixed(2)));
    setTaxAmount(taxTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2));

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => changeFilter(e.target.value)} filtertext={filterText} />
      </>
      // <>
      //   <h5 className='search-label'>Search</h5>
      //   <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      // </>
    );
  }, [filterText, resetPaginationToggle]);

  const selectDate = (e) => {
    let aux = [];
    let newData = [];

    let date1 = getDate(new Date(e[0]));
    let date2 = getDate(new Date(e[1]));
    let amountTotal = 0;
    let feeTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    let surchargeTotal = 0;
    let totalTotal = 0;

    filteredItems.forEach(element => {
      if(element?.startDate >= date1 && element?.startDate <= date2){
        aux.push(element);
        amountTotal = element?.amount ? parseFloat(amountTotal) + parseFloat(element?.amount) : parseFloat(amountTotal);
        feeTotal = element?.waterFeeAmount ? parseFloat(feeTotal) + parseFloat(element?.waterFeeAmount) : parseFloat(feeTotal);
        taxTotal = element?.taxAmount ? parseFloat(taxTotal) + parseFloat(element?.taxAmount) : parseFloat(taxTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        surchargeTotal = element?.fuelSurchargeAmount ? parseFloat(surchargeTotal) + parseFloat(element?.fuelSurchargeAmount) : parseFloat(surchargeTotal);
        totalTotal = element?.total ? parseFloat(totalTotal) + parseFloat(element?.total) : parseFloat(totalTotal);
      }
    });

    if(aux?.length === 0){
      aux = [{
        service:"-",
        shuttle: {
          label:"-",
        },
        amount: 0,
        discountAmount: 0,
        fuelSurchargeAmount: 0,
        taxAmount: 0,
        total: 0,
        startDate:"-",
      }];
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      fee: feeTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      surcharge: surchargeTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setWaterFeePaid(parseFloat(feeTotal.toFixed(2)));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setSurcharge(parseFloat(surchargeTotal.toFixed(2)));
    setTaxAmount(taxTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2));
    setFilteredItemsDate(aux);
    //setFilteredItems(aux);
  } 

  const getDate = (date) => {
    let day = `${(date.getDate())}`.padStart(2,'0');
    let month = `${(date.getMonth()+1)}`.padStart(2,'0');
    let year = date.getFullYear();
    return (`${year}-${month}-${day}`)
  }

  return (
    <div className="content">
      <div className='row'>
          <div className="col-md-6 text-center">
            <h6>Range Date</h6>
            <DateRangePicker 
              placeholder="Today"
              onChange={selectDate}
            />
          </div>
          <div className="d-flex justify-content-between flex-row-reverse col-md-6">
            <div className="gap-2 m-3">
                <ExportPDF data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsClientServicesConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsClientServicesConfig(), true)} totals={[amount, surcharge, taxAmount, total, discount, waterFeePaid]} agents={false} transport={false} sales={true} filename={"Client Sales Report"}></ExportPDF>
                <ExportCSV data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsClientServicesConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsClientServicesConfig(), true)} totals={[amount, surcharge, taxAmount, total, discount, waterFeePaid]} agents={false} transport={false} sales={true} filename={"Client Sales Report"}></ExportCSV>
              </div>
          </div>
      </div>
      <div className="m-3">
      <DataTable
        columns={columnsClientServicesConfig()}
        data={filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems}
        selectableRows
        onSelectedRowsChange={handleChange}
        pagination
        clearSelectedRows={toggledClearRows}
        defaultSortFieldId={9}
        defaultSortAsc={false}
        progressPending={pending}
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />


        { amount !== "0.00" && amount !== 0  ? (

          <Table style={{ maxWidth: "700px", marginLeft: "auto" }} striped bordered hover>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Water Fee</th>
              <th>Fuel Surcharge</th>
              <th>Discount</th>
              <th>Tax</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {tableList.map((item, index) => (
              <tr key={index}>
                <td>$ {item.amount}</td>
                <td>$ {item.fee}</td>
                <td>$ {item.surcharge}</td>
                <td>$ {item.discount}</td>
                <td>$ {item.tax}</td>
                <td>$ {item.total}</td>
              </tr>
            ))}
          </tbody>
          </Table>
        ) : ("")} 
      </div>
    </div>
  );
}

export default TableConfig;
