import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import '../style/home.css';
import HomeActivities from '../components/home/homeActivities';
import Swal from 'sweetalert2';

const idRole = 'home';

const Home = () => {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const fetchErrorMessage = () => {
            if (!loading && !user) {
                navigate("/signin");
            }

            const errorMessageFromStorage = localStorage.getItem('errorMessage');
            if (errorMessageFromStorage) {
                setErrorMessage(errorMessageFromStorage);
                localStorage.removeItem('errorMessage');
            }
        };

        fetchErrorMessage();
    }, [loading, user, navigate]);

    useEffect(() => {
        if (errorMessage) {
            Swal.fire({
                icon: "warning",
                title: "Warning!",
                text: errorMessage,
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
        }
    }, [errorMessage]);

    return (
        <div className="dialog">
            <HomeActivities />
        </div>
    );
};

export default Home;
