import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'
import {  retailByItems  } from '../../../services/Tools';
import Select from 'react-select';

function FormConfig({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [retail, setRetail] = useState("");
    const [stock, setStock] = useState("");
    const [price, setPrice] = useState("");
    // const [color, setColor] = useState("");

    useEffect(() => {
        if (lastData) {
            setId(lastData?.id);
            setRetail(lastData?.retail);
            setStock(lastData?.stock);
            setPrice(lastData?.price);
            // setColor(lastData?.color);
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formRetailItem">
                        <div className="d-inline-flex">
                            <Form.Label>Retail Item</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Example: Water"
                            value={retail}
                            onChange={(e) => setRetail(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formStock">
                        <div className="d-inline-flex">
                            <Form.Label>Quantity in stock</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="number" placeholder="0"
                            value={stock}
                            onChange={(e) => setStock(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>

                </div>

                <div className='col-md-6'>

                    <Form.Group className="mb-6" controlId="formPrice">
                        <div className="d-inline-flex">
                            <Form.Label>Price</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-flex">
                            <div style={{ padding: 5 }}>
                                <p>$</p>
                            </div>
                            <div>
                                <Form.Control type="number" placeholder="0"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                ></Form.Control>
                            </div>
                           
                        </div>
                    </Form.Group>
   
                    {/* <Form.Group className="mb-3" controlId="formColor">
                        <div className="d-inline-flex">
                            <Form.Label>Identifier color</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="color"
                            value={color}
                            onChange={(e) => lastData?.color ? ("") : (setColor(e.target.value))}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group> */}

                </div>
                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {
                    var data = {
                        id,
                        retail,
                        stock,
                        price,
                        // color,
                    };

                    if (retail !== '' && stock !== '' && price !== '') {
                        action(data);
                    } else {
                        Swal.fire({
                            icon: "error", //Constants = success, error, warning
                            title: "Error!",
                            text: "All fields with (*) are required",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                          });
                    }
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfig;