import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsEventsDeleteRetails } from '../../../services/columnsList';
import {  getEventsDeleteRetails } from '../../../services/ConfigRetails';
import { createCheckoutSession } from '../../payments/paymentForm';
import { UserAuth } from '../../../context/AuthContext';
import Loading from '../../loading';
import { Input } from 'antd';
import '../../../style/table.css';

function TableConfig() {
  
  const [pending, setPending] = useState(true); 
  const [data, setData] = useState({ rows: [] });
  const [selects, setSelects] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [allBookings, setAllBookings ] = useState(true);
  const { user } = UserAuth();


  useEffect(() => {
    if (user != null) {
      getEventsDeleteRetails(setData, setPending);
    }
  }, [user]);


  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const showAll = () => {
    setPending(true);
    if(!allBookings){
      getEventsDeleteRetails(setData, setPending, false);
      setAllBookings(true);
    }else{
      getEventsDeleteRetails(setData, setPending, true);
      setAllBookings(false);
    }
    setFilterText('');

  }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const filteredItems = data?.rows.filter(
    item => item?.deleted_date && item?.deleted_date?.toDate().toLocaleString().toLowerCase().includes(filterText.toLowerCase())
    || item?.deleted_by_name && item?.deleted_by_name?.toLowerCase().includes(filterText.toLowerCase())
    || item?.id_event && item?.id_event?.toLowerCase().includes(filterText.toLowerCase())
    || item?.client?.label && item?.client?.label?.toLowerCase().includes(filterText.toLowerCase())
    || item?.newClientData && item?.newClientData?.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
    { pending ? (
      <>
      <div style={{textAlign:"center"}}>
        <Loading></Loading>
      </div>
      </>
      ) 
      : (
      <>
        <div className="content">
          <div className="m-3">
            <DataTable
              columns={columnsEventsDeleteRetails()}
              data={filteredItems}
              selectableRows
              onSelectedRowsChange={handleChange}
              pagination
              clearSelectedRows={toggledClearRows}
              defaultSortFieldId={9}
              defaultSortAsc={false}
              progressPending={pending}
              paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />

          </div>
        </div>
        </>
    )}
    </>
  );
}

export default TableConfig;
