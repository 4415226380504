import React, {useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import '../../style/calendar.css'
import { addDataAdventure, deleteDataAdventure, editDataAdventures, getDataAdventures, getAdventureById } from '../../services/ConfigAdventures';
import { getClientReservations } from '../../services/ConfigReservations';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { UserAuth } from "../../context/AuthContext";
import ModalC from "../modal";
import FormConfigA from "../bookingTable/adventures/formConfig";
import { useParams } from 'react-router-dom';
import Loading from '../loading';
import Swal from "sweetalert2";

const now = new Date();
const localizer = momentLocalizer(moment);

function CalendarAdventures({ clients }){

  const [myEvents, setEvents] = useState([]);
  const [dataA, setDataA] = useState({ rows: [] });
  const [pending, setPending] = useState(true);
  const [modalA, setModalA] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [edit, setEdit] = useState(null);
  const { user } = UserAuth();
  const [ reservationData, setReservationData ] = useState({rows: []});
  let { tap } = useParams();

  useEffect(() => {
    if (user != null) {
      getDataAdventures(setDataA, setPending);
      // setTimeout(() => {
      //   if (tap?.length > 1) {
      //     getClientReservations(tap, setReservationData, setPendingReser);
      //   }
      // }, 300);
    }
  }, [user]);

  useEffect(() => {
    if (dataA?.rows?.length !== 0) {
      setEvents([]);
      if(tap?.length > 1){
        prepareActivites(reservationData.rows);
      }else{
        prepareActivites(dataA.rows);
      }
    }
  }, [dataA]);

  const prepareActivites = (data) => {
    data.forEach(element => {
      let event = [];
      if(element?.refund && element?.balance === 0){

      }else{
        if(element?.adventure){
          let adult = element?.adultNumber ? element?.adultNumber : 0;
          let youth = element?.youthNumber ? element?.youthNumber : 0;
          let pax = parseFloat(adult) + parseFloat(youth);
          let name = element?.newClient ? element?.newClientData : element?.client?.label;
  
          event = {
            id: element.id,
            title: element?.adventure?.label + ", " + name + " (" + pax  + ")",
            start: new Date(element?.startDate + "T" + element?.startTime),
            end: new Date(element?.endDate+"T"+element?.endTime),
            status: 'Adventures',
            adventure: element
          }
        } 
      }
      setEvents((prev) => [...prev, event])
    });
  }

  const action = (data, invoice) => {
    if (edit) {
      if(data?.adventure){
        editDataAdventures(data, true);
        setModalA(false);
      }
    } 
    setTimeout(() => {
      getDataAdventures(setDataA, setPending);
    }, 500);
  }

  // No delete, is import for future
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt('New Event Name')
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }])
      }
    },
    [setEvents]
  )

  const handleSelectEvent = useCallback(
    async (event) => { 
      if(event?.status === 'Adventures'){

        try {
          // Llamar a getReservationById y esperar a que se resuelva la promesa
          const aux = await getAdventureById(event?.adventure?.id);

          // Verifica si se devolvió algo en aux
          if (aux[0]) {
            setModalA(true);
            setEdit(aux[0]);
            setTitleModal('Edit Adventure');
          } else {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Error editing, please try again later",
              confirmButtonColor: '#173d38',
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error to edit booking:", error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while processing your request",
            confirmButtonColor: '#173d38',
            confirmButtonText: "OK",
          });
        }
      } 
    },
    []
  )

  const eventPropGetter = useCallback(
    (event) => {

      let backgroundColor = event?.adventure?.adventure?.value;// ? "#173d38" : "green";

      return { style: { backgroundColor } }
      
    },[]
  );

  return (
    <>
    <div className="App calendar">
        {  pending ? (
              <div className='d-flex justify-content-center'>
                <Loading></Loading>
              </div>
          ) : (
            <>
              <Calendar
                defaultDate={ moment().toDate() }
                defaultView={"month"}
                localizer={localizer}
                resizable
                style={{ height: "100vh" }}
                eventPropGetter={eventPropGetter}
                events={myEvents}
                onSelectEvent={handleSelectEvent}
                //onSelectSlot={handleSelectSlot}
                selectable
              />
            </>
          )
        }

      <ModalC
        show={modalA}
        onHide={() => setModalA(false)}
        changeSize={true}>
        <FormConfigA title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModalA} clients={clients}></FormConfigA>
      </ModalC>

    </div>
    </>
  );
  
}

export default CalendarAdventures;