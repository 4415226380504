import React from "react";
import { useEffect, useState } from "react";

import { getDataAdventureToday } from "../../services/adventureSettings/adventures";
import { UserAuth } from "../../context/AuthContext";
import TableNotifications from "./tableNotifications";

const HomeAdventures = () => {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState({ rows: [] });
  const { user } = UserAuth();

  useEffect(() => {
    if (user != null) {
      getDataAdventureToday(setData, setPending);
    }
  }, [user]);

  return (
    <div className="">
      <TableNotifications></TableNotifications>
    </div>
  );
};

export default HomeAdventures;
