// //Estos son los datos para una cuenta de real en Stripe de Tunnel49
export const STRIPE_PUBLISHABLE_KEY = 'pk_live_zbob1Gz8Zm45oO9QkKvj5Xfy';
export const STRIPE_SECRET_KEY = 'sk_live_u8mSc4f5FWQ1kJu3CC4XbJw7';
export const API = 'https://us-central1-booking-t49.cloudfunctions.net/app/';


//Estos son los datos para una cuenta de prueba en Stripe de Tunnel49
// export const STRIPE_PUBLISHABLE_KEY = 'pk_test_VU9PpK2gBIrYmvHzq9zdb9oY';
// export const STRIPE_SECRET_KEY = 'sk_test_3pmixXMy1CMkiOcJmGzD1FvF';
// export const API = 'https://us-central1-booking-t49.cloudfunctions.net/app/';

//Estos son los datos para una cuenta de prueba en Stripe de una cuenta personal
// export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51NAwPOAtoe1mvIgG52BSO2btjfB4q0I3LHVh9UmPn8VN84prQL4dqn9FW4oQEhvfQyhGkw3VhgiLsU7lylvJXBTd00lQAmneDF';
// export const STRIPE_SECRET_KEY = 'sk_test_51NAwPOAtoe1mvIgGXo9H3TN3sOJ4VrOwtnDtkj2TuxNdgCfNsAi9hbtHdeMcUYauKRjluxYuklVr15jkxX8wKw7b00YpLLtGbN';
// export const API = 'http://localhost:5000/';