import { query, collection, getDocs, doc, setDoc, where, deleteDoc, updateDoc } from "firebase/firestore";
import { auth, db, storage } from '../../firebase'
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { server } from '../serverName/Bookings';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

//REGLA DE FIREBASE
//allow read, write: if false;


export const saveInStorageItinerary = async (file, data, message = null, setPendingItinerary = null, setUrlItinerary = null, del = false) => {
    try {
        var id = uuid();
        const docRef = doc(
            db,
            del ? (server === 'bookings' ? "plan_1_delete_911_pdf_for_transport_itinerary" : 'dev_delete_911_pdf_for_transport_itinerary') : server + "_pdf_for_transport_itinerary",
            id
        );

        const userId = auth.currentUser;
        let today = new Date();
        let now = today.toLocaleString();

        if (userId !== null && file) {

            const storageRef = ref(storage, `${server}/transport/pdfs/itinerary/${id}`);

            uploadBytes(storageRef, file).then(async (snapshot) => {
                const downloadURL = await getDownloadURL(storageRef);

                data['created_by_id'] = userId.uid;
                data['created_by_email'] = userId.email;
                data['created_by_name'] = userId.displayName;
                data['created_date'] = now;
                data['id'] = id;
                data['file_url'] = downloadURL;

                if (del) {
                    data['deleted_by_id'] = userId.uid;
                    data['deleted_by_email'] = userId.email;
                    data['deleted_by_name'] = userId.displayName;
                    data['deleted_date'] = now;
                }

                await setDoc(docRef, data);

                if (setPendingItinerary) {
                    setPendingItinerary(false);
                }

                if (setUrlItinerary) {
                    setUrlItinerary(downloadURL);
                }

                if (message) {
                    Swal.fire({
                        icon: "success",
                        title: "Added",
                        text: "The itinerary PDF was saved successfully",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "Ok",
                    });
                }
            }).catch((error) => {
                console.error('Error uploading itinerary PDF file');
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export const editInStorageItinerary = async (file, data, message = null, setPendingItinerary = null, setUrlItinerary = null, del = false) => {
    try {
        //var id = uuid();
        const docRef = doc(
            db,
            del ? (server === 'bookings' ? "plan_1_delete_911_pdf_for_transport_itinerary" : 'dev_delete_911_pdf_for_transport_itinerary') : server + "_pdf_for_transport_itinerary",
            data?.id
        );

        const userId = auth.currentUser;
        let today = new Date();
        let now = today.toLocaleString();

        if (userId !== null && file) {

            const storageRef = ref(storage, `${server}/transport/pdfs/itinerary/${data?.id}`);

            uploadBytes(storageRef, file).then(async (snapshot) => {
                const downloadURL = await getDownloadURL(storageRef);
                
                data['updated_by_id'] = userId.uid;
                data['updated_by_email'] = userId.email;
                data['updated_by_name'] = userId.displayName;
                data['updated_date'] = now;
                data['file_url'] = downloadURL;

                if (del) {
                    data['deleted_by_id'] = userId.uid;
                    data['deleted_by_email'] = userId.email;
                    data['deleted_by_name'] = userId.displayName;
                    data['deleted_date'] = now;
                }

                await updateDoc(docRef,
                    data
                );

                if (setPendingItinerary) {
                    setPendingItinerary(false);
                }

                if (setUrlItinerary) {
                    setUrlItinerary(downloadURL);
                }

                if (message) {
                    Swal.fire({
                        icon: "success",
                        title: "Added",
                        text: "The itinerary PDF was edited successfully",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "Ok",
                    });
                }
            }).catch((error) => {
                console.error('Error uploading itinerary PDF file');
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export const saveInStorageContract = async (file, data, message = null, setPendingContract = null, setUrlContract = null, del = false) => {
    try {
        var id = uuid();
        const docRef = doc(
            db,
            del ? (server === 'bookings' ? "plan_1_delete_911_pdf_for_transport_contract" : 'dev_delete_911_pdf_for_transport_contract') : server + "_pdf_for_transport_contract",
            id
        );

        const userId = auth.currentUser;
        let today = new Date();
        let now = today.toLocaleString();

        if (userId !== null && file) {

            const storageRef = ref(storage, `${server}/transport/pdfs/contract/${id}`);

            uploadBytes(storageRef, file).then(async (snapshot) => {
                const downloadURL = await getDownloadURL(storageRef);

                data['created_by_id'] = userId.uid;
                data['created_by_email'] = userId.email;
                data['created_by_name'] = userId.displayName;
                data['created_date'] = now;
                data['id'] = id;
                data['file_url'] = downloadURL;

                if (del) {
                    data['deleted_by_id'] = userId.uid;
                    data['deleted_by_email'] = userId.email;
                    data['deleted_by_name'] = userId.displayName;
                    data['deleted_date'] = now;
                }

                await setDoc(docRef, data);

                if (setPendingContract) {
                    setPendingContract(false);
                }

                if (setUrlContract) {
                    setUrlContract(downloadURL);
                }

                if (message) {
                    Swal.fire({
                        icon: "success",
                        title: "Added",
                        text: "The contract PDF was saved successfully",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "Ok",
                    });
                }
            }).catch((error) => {
                console.error('Error uploading contract PDF file');
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export const editInStorageContract = async (file, data, message = null, setPendingContract = null, setUrlContract = null, del = false) => {
    try {
        //var id = uuid();
        const docRef = doc(
            db,
            del ? (server === 'bookings' ? "plan_1_delete_911_pdf_for_transport_contract" : 'dev_delete_911_pdf_for_transport_contract') : server + "_pdf_for_transport_contract",
            data?.id
        );

        const userId = auth.currentUser;
        let today = new Date();
        let now = today.toLocaleString();

        if (userId !== null && file) {

            const storageRef = ref(storage, `${server}/transport/pdfs/contract/${data?.id}`);

            uploadBytes(storageRef, file).then(async (snapshot) => {
                const downloadURL = await getDownloadURL(storageRef);
                
                data['updated_by_id'] = userId.uid;
                data['updated_by_email'] = userId.email;
                data['updated_by_name'] = userId.displayName;
                data['updated_date'] = now;
                data['file_url'] = downloadURL;

                if (del) {
                    data['deleted_by_id'] = userId.uid;
                    data['deleted_by_email'] = userId.email;
                    data['deleted_by_name'] = userId.displayName;
                    data['deleted_date'] = now;
                }

                await updateDoc(docRef,
                    data
                );

                if (setPendingContract) {
                    setPendingContract(false);
                }

                if (setUrlContract) {
                    setUrlContract(downloadURL);
                }

                if (message) {
                    Swal.fire({
                        icon: "success",
                        title: "Added",
                        text: "The contract PDF was edited successfully",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "Ok",
                    });
                }
            }).catch((error) => {
                console.error('Error uploading contract PDF file');
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export const getDataItineraryById = async (id, setPending = null) => {
    try {
        if (auth.currentUser != null) {
            const all = query(collection(db, server === 'bookings' ? "bookings_pdf_for_transport_itinerary" : "dev_pdf_for_transport_itinerary"), where("idReservation", "==", id));
            const querySnapshot = await getDocs(all);
            
            var array = [];
            querySnapshot.forEach((doc) => {
            const data = doc.data();
            array.push(data);
            });

            return array;
        }
    } catch (error) {
        console.error("Error getDataItineraryById");
    }
}

export const getDataContractById = async (id, setPending = null) => {
    try {
        if (auth.currentUser != null) {
            const all = query(collection(db, server === 'bookings' ? "bookings_pdf_for_transport_contract" : "dev_pdf_for_transport_contract"), where("idReservation", "==", id));
            const querySnapshot = await getDocs(all);
            
            var array = [];
            querySnapshot.forEach((doc) => {
            const data = doc.data();
            array.push(data);
            });

            return array;
        }
    } catch (error) {
        console.error("Error getDataContractById");
    }
}